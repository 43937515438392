import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TagInput,
  Pagination,
  TextInput,
} from "evergreen-ui";
import DateText from "components/DateText";
import { diff, isEmpty } from "services/util";
import DatePicker from "react-datepicker";

const scheduleUrl = "/admin/bike/schedule";
const baseUrl = "/admin/bike/";

const BikeSchedule = () => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [bikeList, setBikeList] = useState([]);
  const [originalBikeInfo, setOriginalBikeInfo] = useState({});
  const [bikeInfo, setBikeInfo] = useState({});
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [bikeIdList, setBikeIdList] = useState([]);

  const [searchKeyword, setSearchKeyword] = useState(null);

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "스케줄 등록",
      action: () => {
        // if()
        beforeDate();
      },
    },
    MOD: {
      button: "수정하기",
      title: "스케줄 수정",
      action: () => {
        onModifyScheduleInfo();
      },
    },
  };

  useEffect(() => {
    console.log(bikeIdList);
  }, [bikeIdList]);
  useEffect(() => {
    getScheduleList();
  }, [currentPage]);

  const addScheduleModal = (item) => {
    openModal(item, "ADD");
  };
  const modifyScheduleModal = (item) => {
    let aLength = item.start_date.length;
    let bLength = item.end_date.length;

    const beforeA = item.start_date.slice(0, aLength - 1);
    const beforeB = item.end_date.slice(0, bLength - 1);

    const a = new Date(beforeA);
    const b = new Date(beforeB);

    // console.log(a);
    // console.log(b);

    setStartDate(a);
    setEndDate(b);

    openModal(item, "MOD");
  };

  const openModal = async (item, type) => {
    // console.log(item);
    await getBikeIdList();
    setBikeInfo(item);

    setModalType(type);
    if (type === "ADD") {
      setOriginalBikeInfo({ start_date: "", end_date: "", bike_id: "" });
    } else {
      setOriginalBikeInfo(item);
    }
    setIsModalOpened(true);
  };
  const closeModal = () => {
    setOriginalBikeInfo({});
    setBikeInfo({});
    setIsModalOpened(false);
    setStartDate(null);
    setEndDate(null);
  };
  //바이크 ID 목록
  const getBikeIdList = async () => {
    try {
      const response = await axios.get(baseUrl);
      console.log("getBikeIdList", response);
      const list = response.data.bike_list;
      // const waitingBikes = list.filter((bike) => bike.is_schedule === 0);
      // console.log("waitingBikes", waitingBikes);

      setBikeIdList(list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };
  //스케줄 목록
  const getScheduleList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: searchKeyword,
    };
    axios
      .get(scheduleUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.bike_schedule_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        let newData = data.map((i) => ({ ...i, bike_id: [i.bike_id] }));
        setBikeList(newData);
        console.log(newData);
        setIsModalOpened(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };
  //before
  const beforeDate = () => {
    console.log(startDate);
    console.log(endDate);
    console.log(Object.keys(bikeInfo).length);

    if (Object.keys(bikeInfo).length === 0) {
      alert("바이크 ID를 선택해주세요");
      return;
    }

    // console.log("Where are you");

    if (startDate && endDate !== null) {
      const afterStartDate = new Date(
        startDate.getTime() - startDate.getTimezoneOffset() * 60000
      ).toISOString();
      const afterEndDate = new Date(
        endDate.getTime() - endDate.getTimezoneOffset() * 60000
      ).toISOString();

      console.log(afterStartDate);
      console.log(afterEndDate);

      onAddScheduleInfo(afterStartDate, afterEndDate);
    } else {
      alert("시작 날짜/종료 날짜 를 확인해주세요");
      return;
    }
  };
  //스케줄 등록
  const onAddScheduleInfo = async (s, e) => {
    console.log("PEN FINEAPPLE APPLE PEN");
    console.log("bikeInfo", bikeInfo);
    console.log("originalBikeInfo", originalBikeInfo);

    bikeInfo.start_date = s;
    bikeInfo.end_date = e;

    delete bikeInfo.addbike_id;
    delete bikeInfo.deletebike_id;

    let params = diff(bikeInfo, originalBikeInfo);

    if (isEmpty(params)) {
      alert("변경 사항이 없습니다");
      // closeModal();
      return;
    }

    console.log(params);
    // return;

    for (const bike_id of bikeInfo.bike_id) {
      try {
        const response = await axios.post(
          `${baseUrl}${bike_id}/schedule`,
          params
        );
        console.log(response.data);
        alert("바이크 스케줄을 등록하였습니다.");
        getScheduleList();
      } catch (error) {
        console.log(error);
        const errorId = error.config.url.slice(12, 30);
        // alert(errorId);
        alert(`ID : ${errorId}에 ${error.response.data.message}`);
      }
    }

    // axios
    //   .post(`${baseUrl}${bikeInfo.bike_id}/schedule`, params)
    //   .then((res) => {
    //     console.log(res);
    //     alert("바이크 스케줄을 등록하였습니다.");
    //     getScheduleList();
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //     alert(error.response.data.message);
    //   });
  };
  //스케줄 수정
  const onModifyScheduleInfo = () => {
    let params = diff(bikeInfo, originalBikeInfo);

    console.log(startDate);
    params.start_date = startDate;
    params.end_date = endDate;
    if (isEmpty(params)) {
      closeModal();
      return;
    }

    console.log(params);

    axios
      .put(`${baseUrl}${bikeInfo.bike_id}/schedule`, params)
      .then((res) => {
        console.log(res);
        alert("바이크 스케줄을 수정하였습니다.");
        getScheduleList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const getTagInputTitle = (
    originalBikeInfo,
    bikeInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    console.log(fullList);
    // console.log(propName);
    // console.log(fullList);
    // console.log(listPropKey);
    // console.log(listPropName);
    // const titleList =
    //   bikeInfom[propName] &&
    //   bikeInfom[propName].map((item) => {
    //     console.log(bikeInfom);
    //     return fullList.find((listItem) => listItem[listPropKey] === item)[
    //       listPropName
    //     ];
    //   });
    // // console.log("타이틀리스트 ==> ", titleList);
    // return titleList;
    const titleList = [];

    if (
      bikeInfo &&
      propName &&
      bikeInfo[propName] &&
      Array.isArray(bikeInfo[propName])
    ) {
      bikeInfo[propName].forEach((item) => {
        const foundItem = fullList.find(
          (listItem) => listItem[listPropKey] === item
        );
        if (foundItem) {
          titleList.push(foundItem[listPropName]);
        }
      });
    }

    return titleList;
  };

  const setTagInputValue = (
    originalBikeInfo,
    bikeInfo,
    propName,
    value,
    fullList,
    listPropKey,
    listPropName
  ) => {
    console.log(fullList);
    let newBikeIdInfo = { ...bikeInfo };
    newBikeIdInfo[propName] = value
      .map((valueItem) => {
        let obj = fullList.find((item) => item[listPropName] === valueItem);
        console.log(obj);
        return obj ? obj[listPropKey] : null;
      })
      .filter((item) => item !== null);

    const add_prop_name = `add${propName}`;
    const delete_prop_name = `delete${propName}`;

    const added = newBikeIdInfo[propName]
      ? newBikeIdInfo[propName].filter((i) =>
          originalBikeInfo[propName]
            ? !originalBikeInfo[propName].includes(i)
            : true
        )
      : [];
    const deleted = originalBikeInfo[propName]
      ? originalBikeInfo[propName].filter((i) =>
          newBikeIdInfo[propName] ? !newBikeIdInfo[propName].includes(i) : true
        )
      : [];

    // console.log(newContentInfo, originalChallengeInfo)
    // console.log(add_prop_name,added,delete_prop_name,deleted)

    newBikeIdInfo[add_prop_name] = added;
    newBikeIdInfo[delete_prop_name] = deleted;

    setBikeInfo(newBikeIdInfo);
  };

  const tagInputAutoCompleteList = (
    bikeInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let data = fullList
      .filter(
        (item) =>
          !(
            bikeInfo[propName] && bikeInfo[propName].includes(item[listPropKey])
          )
      )
      .map((item) => item[listPropName]);
    console.log(data);
    return data;
  };
  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={`${modalTypeOption[modalType].button}`}
          onConfirm={modalTypeOption[modalType].action}
          hasCancel={false}
          hasClose={false}
          title={`${modalTypeOption[modalType].title}`}
        >
          <InputDiv>
            <InputSpan>바이크 ID</InputSpan>
            <TagInput
              width="280px"
              aria-required
              inputProps={{ placeholder: "ID 검색" }}
              values={getTagInputTitle(
                originalBikeInfo,
                bikeInfo,
                "bike_id",
                bikeIdList,
                "bike_id",
                "bike_id"
                // "title"
              )}
              onChange={(e) => {
                setTagInputValue(
                  originalBikeInfo,
                  bikeInfo,
                  "bike_id",
                  e,
                  bikeIdList,
                  "bike_id",
                  "bike_id"
                  //   "title"
                );
              }}
              autocompleteItems={tagInputAutoCompleteList(
                bikeInfo,
                "bike_id",
                bikeIdList,
                "bike_id",
                "bike_id"
                // "title"
              )}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>시작 날짜</InputSpan>
            <DatepickerWrapper>
              <SDatePicker
                // dateFormat={"yyyy-MM-dd"}
                // selected={startDate}
                // onChange={(date) => setStartDate(date)}
                // selectsStart
                // startDate={startDate}
                // endDate={endDate}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat={"yyyy-MM-dd"}
              />
            </DatepickerWrapper>
          </InputDiv>
          <InputDiv>
            <InputSpan>종료 날짜</InputSpan>
            <DatepickerWrapper>
              <SDatePicker
                // dateFormat={"yyyy-MM-dd"}
                // selected={endDate}
                // onChange={(date) => setEndDate(date)}
                // selectsEnd
                // startDate={startDate}
                // endDate={endDate}
                // minDate={startDate}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat={"yyyy-MM-dd"}
              />
            </DatepickerWrapper>
          </InputDiv>
        </Dialog>
      </Pane>

      <AnnouncementBox>
        <Pane>
          <AnnouncementH1>바이크 스케줄 관리</AnnouncementH1>
        </Pane>
        <Box>
          <SearchBtn>
            <TextInput
              placeholder="바이크 ID"
              width={"250px"}
              onChange={(e) => {
                const keyword = e.target.value;
                setSearchKeyword(keyword);
              }}
            ></TextInput>
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "36%",
                bottom: "20px",
                marginLeft: "10px",
              }}
              onClick={getScheduleList}
            >
              검색
            </Button>
          </SearchBtn>

          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={() => addScheduleModal({})}
          >
            스케줄 등록
          </Button>
        </Box>

        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell>바이크 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>시작 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell>종료 날짜</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {bikeList.map((item) => (
              <Table.Row
                key={item.bike_id}
                isSelectable
                onSelect={async () => {
                  modifyScheduleModal(item);
                }}
              >
                <Table.TextCell>{item.bike_id}</Table.TextCell>
                <Table.TextCell>
                  <DateText>{item.start_date}</DateText>
                </Table.TextCell>
                <Table.TextCell>
                  <DateText>{item.end_date}</DateText>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / 10)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </AnnouncementBox>
    </>
  );
};

// ModalBasic.defaultProps = {};
const AnnouncementBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const AnnouncementH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;
const DatepickerWrapper = styled.div`
  width: auto;
`;
const SDatePicker = styled(DatePicker)`
  width: 280px;
  border-color: #d8dae5;
  border: 1px solid #d8dae5;
  padding: 0 12px;
  line-height: 16px;
  font-size: 12px;
  transition: box-shadow 80ms ease-in-out;
  height: 32px;
  border-radius: 4px;
  color: #474d66;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
`;
const SearchBtn = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;
const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
`;
export default BikeSchedule;
