import React, { useState, useEffect, useRef } from "react";
import axios from "services/auth/jwt/config";

import styled from "styled-components";

function Birth(data) {
  // console.log(data);
  const [todayBirthdayPeople, setTodayBirthdayPeople] = useState([]);

  // const data = [
  //   {
  //     rank: 1,
  //     nickname: "user1",
  //     profile_image_url: null,
  //     calories: 80,
  //     matching_rate: 80,
  //     avg_heartbeat: 80,
  //     birth_date: "1993-04-26",
  //   },
  //   {
  //     rank: 2,
  //     nickname: "user2",
  //     profile_image_url: null,
  //     calories: 70,
  //     matching_rate: 70,
  //     avg_heartbeat: 70,
  //     birth_date: "1993-06-01",
  //   },
  //   {
  //     rank: 3,
  //     nickname: "user3",
  //     profile_image_url: null,
  //     calories: 60,
  //     matching_rate: 60,
  //     avg_heartbeat: 60,
  //     birth_date: "1993-06-01",
  //   },
  // ];

  useEffect(() => {
    if (Array.isArray(data)) {
      const today = new Date();
      const todayString = `${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

      const todayBirthdayPeople = data.filter(
        (person) => person.birth_date.substring(5) === todayString.substring(5)
      );
      setTodayBirthdayPeople(todayBirthdayPeople);

      console.log(`오늘 생일인 사람의 수: ${todayBirthdayPeople.length}`);
    } else {
      console.log("data가 배열이 아닙니다.");
    }
  }, [data]);

  return (
    <div>
      <p>{todayBirthdayPeople.length}</p>
    </div>
  );
}

const ChallengeBox = styled.div``;
export default Birth;
