export const isProduction = process.env.REACT_APP_MODE === 'production';

export const msToTime = (ms) =>{
  let mm = Math.floor(ms / 60000)
  let ss = (ms % 60000)/1000
  return `${String(mm).padStart(2,'0')}:${String(ss).padStart(2,'0')}`
}

export const stateToOptions = (obj) => {
  return Object.keys(obj).map(k=>({label:obj[k],value:k}))
}

export const valueToKey = (obj, value) => {
  return Object.keys(obj).find(k=>obj[k]===value)
}

export const getMod = (num1, num2) => {
  const share = parseInt(num1 / num2);
  const mod = num1 % num2;
  return { share, mod };
};

export const diff = (tgt, src) => {
  // console.log(tgt, src);

  if (Array.isArray(tgt) || src === null) {
    // console.log("1");
    // if you got array
    return tgt; // just copy it
  }

  // if you got object
  var rst = {};

  for (var k in tgt) {
    // visit all fields
    if (typeof src[k] === "object" && src[k] !== null) {
      // console.log("2");
      // if field contains object (or array because arrays are objects too)
      rst[k] = diff(tgt[k], src[k]); // diff the contents
    } else if (src[k] !== tgt[k]) {
      // console.log("3");
      // if field is not an object and has changed
      rst[k] = tgt[k]; // use new value
      // rst[k] = tgt[k]; // use new value
    }
    // otherwise just skip it
  }
  rst = removeTempProp(rst);
  // console.log(rst);
  return rst;
};

export const removeTempProp = (obj) => {
  // console.log(obj)
  let rst = null;
  if (Array.isArray(obj)) {
    // console.log("isArray", obj)
    return obj.map((i) => {
      // console.log(i)
      if (typeof i === "object") {
        return removeTempProp(i);
      }
      return i;
    });
  }

  rst = {};
  for (var k in obj) {
    // console.log("key", k, obj[k])
    if (k.startsWith("_")) {
      // console.log("starts with _.... skip")
    } else if (typeof obj[k] === "object") {
      // if field contains object (or array because arrays are objects too)
      // console.log("isObject")
      rst[k] = removeTempProp(obj[k]);
    } else {
      // console.log("not starts with _")
      rst[k] = obj[k];
    }
  }

  // console.log("rst",rst)
  return rst;
};

export const isEmpty = (param) => {
  return Object.keys(param).length === 0;
};

export const getLevelFromMetadata = (arrData) => {
  let jsonCount = 0;
  let sumTime = 0;
  let sumRpm = 0;
  let sumLevel = 0;

  for (let item of arrData) {
    jsonCount = jsonCount + 1;
    sumTime = sumTime + item.time;
    sumRpm = sumRpm + item.rpm;
    sumLevel = sumLevel + item.level;
  }

  const CNP = (sumTime * sumLevel * sumRpm) / 100000;

  const testResult = {
    CNP: Math.round(CNP),
    jsonCount: jsonCount,
    sumTime: sumTime,
    sumRpm: sumRpm,
    sumLevel: sumLevel,
  };

  const difficulty_CNP = isNaN(testResult.CNP) ? null : Number(testResult.CNP);

  let difficulty_TEMP = 0;
  // console.log("difficulty_CNP",difficulty_CNP)

  if (difficulty_CNP) {
    if (difficulty_CNP >= 0 && difficulty_CNP <= 5000) {
      difficulty_TEMP = 1;
    }
    if (difficulty_CNP > 5000 && difficulty_CNP <= 10000) {
      difficulty_TEMP = 2;
    }
    if (difficulty_CNP > 10000 && difficulty_CNP <= 25000) {
      difficulty_TEMP = 3;
    }
    if (difficulty_CNP > 25000 && difficulty_CNP <= 50000) {
      difficulty_TEMP = 4;
    }
    if (difficulty_CNP > 50000 && difficulty_CNP <= 75000) {
      difficulty_TEMP = 5;
    }
    if (difficulty_CNP > 75000 && difficulty_CNP <= 100000) {
      difficulty_TEMP = 6;
    }
    if (difficulty_CNP > 100000 && difficulty_CNP <= 150000) {
      difficulty_TEMP = 7;
    }
    if (difficulty_CNP > 150000 && difficulty_CNP <= 200000) {
      difficulty_TEMP = 8;
    }
    if (difficulty_CNP > 200000 && difficulty_CNP <= 250000) {
      difficulty_TEMP = 9;
    }
    if (difficulty_CNP > 250000 && difficulty_CNP <= 300000) {
      difficulty_TEMP = 10;
    }
    if (difficulty_CNP > 300000) {
      difficulty_TEMP = 10;
    }
  }

  return difficulty_TEMP;
};
