import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  RadioGroup,
  SelectMenu,
} from "evergreen-ui";
import FileUploader from "components/FileUploader";
import { diff, isEmpty } from "services/util";

const baseUrl = "/admin/message";

const LiveMessage = () => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [originalMessageInfo, setOriginalMessageInfo] = useState({});
  const [messageInfo, setMessageInfo] = useState({});
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  const [tagList, setTagList] = useState([]);
  const [category, setCategory] = useState(null);
  const [filter, setFilter] = useState("");
  const [itemKeyword, setItemKeyword] = useState("");

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "라이브 메시지 등록",
      action: () => {
        onAddMessageInfo();
      },
    },
    MOD: {
      button: "수정하기",
      title: "라이브 메시지 수정",
      action: () => {
        onModifyMessageInfo();
      },
    },
  };
  const [IsUseOption] = React.useState([
    { label: "사용", value: "1" },
    { label: "미사용", value: "0" },
  ]);

  const [filterOption] = React.useState([
    { label: "전체", value: "" },
    { label: "사용", value: "1" },
    { label: "미사용", value: "0" },
  ]);

  useEffect(() => {
    getMessageList();
    getCategoryList();
  }, [currentPage, filter]);

  useEffect(() => {
    console.log(messageInfo);
  }, [messageInfo]);

  const addModal = (item) => {
    openModal(item, "ADD");
  };
  const modifyModal = (item) => {
    openModal(item, "MOD");
  };

  const openModal = async (item, type) => {
    // await getCategoryList();
    setModalType(type);
    setOriginalMessageInfo(item);
    setMessageInfo(item);
    setIsModalOpened(true);
  };
  const closeModal = () => {
    setOriginalMessageInfo({});
    setMessageInfo({});
    setIsModalOpened(false);
  };

  const getCategoryList = async () => {
    try {
      const response = await axios.get(`${baseUrl}/category`);
      const data = response.data.message_category_list;
      setTagList(data);
      console.log(data);

      const newData = Object.fromEntries(
        data.map(({ category_code, category }) => [category_code, category])
      );
      setCategory(newData);
      //   console.log(newData);

      // const CategoryName = category[1];

      // console.log(CategoryName);
    } catch (error) {
      console.log(error);
      // alert(error.response.data.message);
    }
  };
  const setCategoryInfo = (item) => {
    if (category === null) {
      getCategoryList();
    } else {
      const CategoryName = category[item.category_code];
      return CategoryName;
    }
  };

  const getMessageList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: itemKeyword,
      is_use: filter,
    };

    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log("라이브 메시지 목록", res);
        const data = res.data.message_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        setMessageList(data);
        setIsModalOpened(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };
  //라이브 메시지 상세조회
  const getMessage = async (message_id) => {
    try {
      const res = await axios.get(`${baseUrl}/${message_id}`);
      console.log("라이브 메시지 상세", res);
      const data = res.data.message;

      let newContent = {
        ...data,
        _image_preview: data.image_url,
      };

      return newContent;
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
      return {};
    }
  };
  //라이브 메시지 등록
  const onAddMessageInfo = () => {
    let params = diff(messageInfo, originalMessageInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }

    // params.is_show = 1;
    if (typeof params.is_use === "string") {
      let a = Number(params.is_use);
      params.is_use = a;
    }
    if (typeof params.is_use_image === "string") {
      let b = Number(params.is_use_image);
      params.is_use_image = b;
    }
    if (typeof params.category_code === "string") {
      let c = Number(params.category_code);
      params.category_code = c;
    }
    console.log(params);

    axios
      .post(baseUrl, params)
      .then((res) => {
        console.log(res);
        alert("라이브 메시지를 등록하였습니다.");
        getMessageList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  //라이브 메시지 수정
  const onModifyMessageInfo = () => {
    let params = diff(messageInfo, originalMessageInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }

    if (typeof params.is_use === "string") {
      let a = Number(params.is_use);
      params.is_use = a;
    }
    if (typeof params.is_use_image === "string") {
      let b = Number(params.is_use_image);
      params.is_use_image = b;
    }
    if (typeof params.category_code === "string") {
      let c = Number(params.category_code);
      params.category_code = c;
    }

    axios
      .put(`${baseUrl}/${messageInfo.message_id}`, params)
      .then((res) => {
        console.log(res);
        alert("라이브 메시지를 수정하였습니다.");
        getMessageList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const onDeleteMessageInfo = (message_id) => {
    if (!window.confirm("라이브 메시지를 삭제하겠습니까?")) {
      closeModal();
      return;
    }

    axios
      .delete(`${baseUrl}/${message_id}`)
      .then((res) => {
        console.log(res);
        alert("라이브 메시지가 삭제되었습니다.");
        getMessageList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const handleChange = (e) => {
    setMessageInfo({
      ...messageInfo,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={`${modalTypeOption[modalType].button}`}
          onConfirm={modalTypeOption[modalType].action}
          hasCancel={false}
          hasClose={false}
          title={`${modalTypeOption[modalType].title}`}
        >
          {modalType === "MOD" ? (
            <div>
              <InputDiv>
                <InputSpan>라이브 메시지 ID</InputSpan>
                <TextInput
                  name="message_id"
                  onChange={handleChange}
                  disabled
                  defaultValue={originalMessageInfo.message_id}
                />
              </InputDiv>
            </div>
          ) : null}
          <InputDiv>
            <InputSpan>카테고리</InputSpan>
            <SelectMenu
              title="카테고리를 선택해주세요"
              closeOnSelect={true}
              options={tagList.map((item) => ({
                label: item.category,
                value: item.category_code,
              }))}
              selected={String(messageInfo.category_code)}
              onSelect={(item) =>
                setMessageInfo({
                  ...messageInfo,
                  category_code: item.value,
                })
              }
            >
              <Button>
                {messageInfo.category_code
                  ? tagList.length === 1
                    ? tagList[0].category
                    : tagList.find(
                        (i) => i.category_code === messageInfo.category_code
                      ).category
                  : "카테고리를 선택해주세요"}
              </Button>
            </SelectMenu>
          </InputDiv>
          <InputDiv>
            <InputSpan>문구</InputSpan>
            <TextInput
              maxLength={50}
              name="comment"
              onChange={handleChange}
              defaultValue={originalMessageInfo.comment}
            />
          </InputDiv>

          <InputDiv>
            <InputSpan>이미지 메시지 사용 여부</InputSpan>
            <RadioGroup
              display="flex"
              columnGap="10px"
              size={16}
              name="is_use_image"
              value={String(messageInfo.is_use_image)}
              defaultValue={String(messageInfo.is_use_image)}
              options={IsUseOption}
              onChange={handleChange}
            />
          </InputDiv>
          {messageInfo.is_use_image === "1" && (
            <>
              <InputDiv>
                <InputSpan>이미지</InputSpan>
                <FileUploader
                  directory="live/message"
                  preview_src={messageInfo._image_preview}
                  targetObj={messageInfo}
                  onUploaded={setMessageInfo}
                  targetPropName="image"
                  isImage={true}
                  accept={"image/*"}
                />
              </InputDiv>
            </>
          )}
          {messageInfo.is_use_image === 1 && (
            <>
              <InputDiv>
                <InputSpan>이미지</InputSpan>
                <FileUploader
                  directory="live/message"
                  preview_src={messageInfo._image_preview}
                  targetObj={messageInfo}
                  onUploaded={setMessageInfo}
                  targetPropName="image"
                  isImage={true}
                  accept={"image/*"}
                />
              </InputDiv>
            </>
          )}
          <InputDiv>
            <InputSpan>사용 여부</InputSpan>
            <RadioGroup
              display="flex"
              columnGap="10px"
              size={16}
              name="is_use"
              value={String(messageInfo.is_use)}
              defaultValue={String(messageInfo.is_use)}
              options={IsUseOption}
              onChange={handleChange}
            />
          </InputDiv>
        </Dialog>
      </Pane>

      <AnnouncementBox>
        <Pane>
          <AnnouncementH1>라이브 메시지</AnnouncementH1>
        </Pane>
        <Box>
          <div
            style={{ display: "flex", flexDirection: "column", width: "15%" }}
          >
            <RadioGroup
              style={{
                display: "flex",
                alignItems: "center",
                wordBreak: "keep-all",
                gap: "10px",
              }}
              value={filter}
              options={filterOption}
              onChange={(event) => setFilter(event.target.value)}
            />
          </div>

          <SearchBtn>
            <TextInput
              placeholder=""
              width={"250px"}
              onChange={(e) => {
                const keyword = e.target.value;
                setItemKeyword(keyword);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  getMessageList();
                }
              }}
            ></TextInput>
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "36%",
                bottom: "20px",
                marginLeft: "10px",
              }}
              onClick={() => {
                getMessageList();
              }}
            >
              검색
            </Button>
          </SearchBtn>

          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={() => addModal({})}
          >
            등록하기
          </Button>
        </Box>
        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell>메시지 카테고리</Table.TextHeaderCell>
            <Table.TextHeaderCell>메시지 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>문구</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120}>이미지</Table.TextHeaderCell>
            <Table.TextHeaderCell>사용 여부</Table.TextHeaderCell>
            <Table.TextHeaderCell>이미지 메세지 사용 여부</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {messageList.map((item) => {
              return (
                <Table.Row
                  key={item.message_id}
                  isSelectable
                  onSelect={async () => {
                    const _messageInfo = await getMessage(item.message_id);
                    console.log(_messageInfo);
                    modifyModal(_messageInfo);
                  }}
                  height={"auto"}
                  minHeight={"60px"}
                  fontSize={"1px"}
                >
                  <Table.TextCell> {setCategoryInfo(item)}</Table.TextCell>
                  <Table.TextCell>{item.message_id}</Table.TextCell>
                  <Table.TextCell>{item.comment}</Table.TextCell>
                  <Table.TextCell flexBasis={120}>
                    <Table.Cell justifyContent="center">
                      <ImgPreview
                        style={{ width: "150px" }}
                        src={item.image_url}
                      />
                    </Table.Cell>
                  </Table.TextCell>
                  <Table.TextCell>
                    {item.is_use ? "사용" : "미사용"}
                  </Table.TextCell>
                  <Table.TextCell>
                    {item.is_use_image ? "사용" : "미사용"}
                  </Table.TextCell>
                  <Table.TextCell
                    flexBasis={120}
                    flexShrink={0}
                    flexGrow={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      appearance="primary"
                      intent="danger"
                      onClick={(event) => {
                        onDeleteMessageInfo(item.message_id);
                        // event.preventDefault();
                        event.stopPropagation();
                      }}
                    >
                      삭제
                    </Button>
                  </Table.TextCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </AnnouncementBox>
    </>
  );
};

// ModalBasic.defaultProps = {};
const AnnouncementBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const AnnouncementH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 160px;
  font-size: 12px;
`;
const ImgPreview = styled.img`
  width: 200px;
`;
const SearchBtn = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Box = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
`;
export default LiveMessage;
