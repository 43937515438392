import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import { Pane, Table, Pagination, Button } from "evergreen-ui";

const Popup = (props) => {
  const [popupList, setpopupList] = useState([]);

  useEffect(() => {
    list();
  }, []);

  const list = () => {
    let config = {
      method: "get",
      url: axios.defaults.baseURL + "/admin/pop-up",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    console.log(config);
    axios(config)
      .then(function (response) {
        console.log(response);
        setpopupList(response.data.pop_up_list);
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  return (
    <>
      <PopupBox>
        <Pane>
          <PopupH1>팝업 목록</PopupH1>
        </Pane>
        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell>팝업 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>body_text</Table.TextHeaderCell>
            <Table.TextHeaderCell>button_text</Table.TextHeaderCell>
            <Table.TextHeaderCell>create_date</Table.TextHeaderCell>
            <Table.TextHeaderCell>is_show</Table.TextHeaderCell>
            <Table.TextHeaderCell>update_date</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {popupList.map((popup) => (
              <Table.Row
                key={popup.pop_up_id}
                // onSelect={() => alert(popup.nickname)}
              >
                {/* <Table.TextCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                ></Table.TextCell> */}
                <Table.TextCell>{popup.pop_up_id}</Table.TextCell>
                <Table.TextCell>{popup.body_text}</Table.TextCell>
                <Table.TextCell>{popup.button_text}</Table.TextCell>
                <Table.TextCell>{popup.create_date}</Table.TextCell>
                <Table.TextCell>{popup.is_show}</Table.TextCell>
                <Table.TextCell>{popup.update_date}</Table.TextCell>
                <Table.TextCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    onClick={() => {
                      console.log("삭제 기능 구현해야함");
                    }}
                  >
                    삭제
                  </Button>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          // width={"100%"}
          display="flex"
          alignItems="center"
          justifyContent="center"
          // border="default"
        >
          <Pagination page={1} totalPages={3}></Pagination>
        </Pane>
      </PopupBox>
    </>
  );
};
const PopupBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const PopupH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
export default Popup;
