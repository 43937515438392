import React from "react";
import {
  TagInput,
} from "evergreen-ui";

const Tag = ({placeholder,originalDataInfo,dataInfo,fullTagList,dataPropName,tagListPropKey,tagListPropName,setDataInfo}) => {
  // const placeholder = props.placeholder
  // const originalDataInfo = props.originalDataInfo
  // const dataInfo = props.dataInfo
  // const fullTagList = props.fullTagList
  // const dataPropName = props.dataPropName
  // const tagListPropKey = props.tagListPropKey
  // const tagListPropName = props.tagListPropName
  // const setDataInfo = props.setDataInfo

  const getTagInputTitle = () => {
    // console.log(propName, contentInfo[propName])
    const titleList = dataInfo[dataPropName] && dataInfo[dataPropName].map((item)=>{
      const itemFound = fullTagList.find((listItem)=>listItem[tagListPropKey] === item)
      return itemFound ? itemFound[tagListPropName] : ""
    })
    return titleList
  }

  const setTagInputValue = (value) => {
    let newDataInfo = {...dataInfo}
    newDataInfo[dataPropName] = value.map((valueItem)=>{
      let obj = fullTagList.find((item)=>item[tagListPropName] === valueItem)
      return obj ? obj[tagListPropKey] : null
    }).filter((item)=>item !== null)

    const add_prop_name = `add${dataPropName}`
    const delete_prop_name = `delete${dataPropName}`

    
    const added = newDataInfo[dataPropName] ? newDataInfo[dataPropName].filter(i => originalDataInfo[dataPropName] ? !originalDataInfo[dataPropName].includes(i) : true) : []
    const deleted = originalDataInfo[dataPropName] ? originalDataInfo[dataPropName].filter(i => newDataInfo[dataPropName] ? !newDataInfo[dataPropName].includes(i) : true) : []
    
    // console.log(newContentInfo, originalContentInfo)
    // console.log(add_prop_name,added,delete_prop_name,deleted)

    newDataInfo[add_prop_name] = added
    newDataInfo[delete_prop_name] = deleted

    setDataInfo(newDataInfo)
  }


  const tagInputAutoCompleteList = () => {
    let data = fullTagList
                .filter((item) => !(dataInfo[dataPropName] && dataInfo[dataPropName].includes(item[tagListPropKey])))
                .map(item=>item[tagListPropName])
    return data
  }

  return (
    <TagInput
      inputProps={{ placeholder: placeholder }}
      values={getTagInputTitle()}
      onChange={(e)=>{setTagInputValue(e)}}
      autocompleteItems={tagInputAutoCompleteList()}
    />
  )
}

export default Tag