import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";

import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  RadioGroup,
  toaster,
  Pagination,
} from "evergreen-ui";
import { diff, isEmpty } from "services/util";
import FileUploader from "components/FileUploader";

const baseUrl = "/admin/section";
const baseUrlContent = "/admin/content";

const Section = () => {
  // window.Buffer = window.Buffer || require("buffer").Buffer;

  const PAGE_SIZE = 5;
  const [chooseContentList, setChooseContentList] = useState([]);
  const [search, setSearch] = useState("");
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [originalSectionInfo, setOriginalSectionInfo] = useState({});
  const [sectionInfo, setSectionInfo] = useState({});
  const [totalCount, setTotalCount] = useState(1);
  // const [totalContentCount, setTotalContentCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // const [currentContentPage, setCurrentContentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  const [imageUrl, setImageUrl] = useState(null);
  const [tab, setTab] = useState("MAIN");
  const [isShownInfo, setIsShownInfo] = React.useState(false);

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "섹션 등록",
      action: () => {
        onAddSection();
      },
    },
    MOD: {
      button: "수정하기",
      title: "섹션 수정",
      action: () => {
        if (sectionInfo.title === "") {
          alert("제목을 입력해주세요");
          return;
        }
        onModifySection();
      },
    },
  };

  const onChangeSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const [sectionTypeOption] = React.useState([
    { label: "좌우 스크롤", value: "01" },
    { label: "상하좌우 스크롤", value: "02" },
  ]);
  const [sectionIsShowOption] = React.useState([
    { label: "노출", value: "1" },
    { label: "미노출", value: "0" },
  ]);
  const [displayTypeOption01] = React.useState([
    {
      label: "텍스트 수직 정렬, 이미지 246*136, 텍스트 2단 구성",
      value: "01-02-01",
    },
    {
      label: "텍스트 수직 정렬, 이미지 214*122, 텍스트 2단 구성",
      value: "01-03-01",
    },
    {
      label: "텍스트 수직 정렬, 이미지 306*196, 텍스트 2단 구성",
      value: "01-04-01",
    },
    {
      label: "텍스트 이미지 위 존재, 이미지 820*280, 텍스트 2단 구성",
      value: "03-05-01",
    },
  ]);
  const [displayTypeOption02] = React.useState([
    {
      label: "텍스트 수평 정렬, 이미지 162*90, 텍스트 2단 구성",
      value: "02-01-01",
    },
    {
      label: " 텍스트 수평 정렬, 이미지 214*122, 텍스트 2단 구성",
      value: "02-02-01",
    },
  ]);
  const [tabOptions] = React.useState([
    { label: "MAIN", value: "MAIN" },
    { label: "LESSON", value: "LESSON" },
    { label: "REAL", value: "REAL" },
    { label: "POP", value: "POP" },
  ]);

  useEffect(() => {
    getSectionList();
  }, [tab]);
  useEffect(() => {
    getContentList();
    // console.log(contentList);
  }, [currentPage]);
  useEffect(() => {}, [imageUrl]);
  useEffect(() => {
    console.log(sectionInfo);
  }, [sectionInfo]);

  //섹션 목록 조회
  const getSectionList = () => {
    // console.log(e);
    const params = {
      // limit: PAGE_SIZE,
      // offset: PAGE_SIZE * (currentPage - 1),
    };
    axios
      .get(`${baseUrl}/tab/${tab}`, { params })
      .then(function (res) {
        console.log(res);

        const data = res.data.section_list;
        // const dataCount = res.data.count;

        // setContentItem(data.content_list);
        // setTotalCount(dataCount);
        setSectionList(data);
        setIsModalOpened(false);
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //섹션 상세 조회
  const getSectionDetailList = async (sectionId) => {
    try {
      const res = await axios.get(`${baseUrl}/${sectionId}`);
      console.log(res);
      const data = res.data.section.content_list;
      const sectionData = res.data.section;
      let a = null;
      const aaa = data.map((item) => {
        // console.log(item.content.content_id);
        a = {
          content_id: item.content.content_id,
          title: item.content.title,
          thumbnail_image_url: item.content.thumbnail_image_url,
        };
        return a;
      });
      // console.log(aaa);

      setChooseContentList(aaa);

      let newContent = {
        ...sectionData,
        content_ids: "",
      };

      return newContent;
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
      return {};
    }
  };

  const beforeGetContentList = async () => {
    console.log(currentPage);
    await setCurrentPage(1);
    await setSearch("");

    getContentList();
  };
  //콘텐츠 조회
  const getContentList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: search,
    };
    axios
      .get(`${baseUrlContent}`, { params })
      .then(function (res) {
        console.log(res);
        const data = res.data.content_list;
        const dataCount = res.data.count;
        if (dataCount === 0) {
          alert("검색 결과가 없습니다");
        }
        setTotalCount(dataCount);
        // let objKeys = data.map((i) => i.content_id);
        // console.log(a);
        setContentList(data);
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  const addSectionModal = (section) => {
    // console.log(section);
    openSectionModal(section, "ADD");
  };
  const modifySectionModal = (section) => {
    openSectionModal(section, "MOD");
  };
  const openSectionModal = (section, type) => {
    // console.log("group", section);ㅌ

    getContentList();
    setModalType(type);
    setOriginalSectionInfo(section);
    setIsModalOpened(true);
    if (type === "ADD") {
      setSectionInfo({
        tab: section.tab,
        section_type: "01",
        display_type: "01-02-01",
      });
    } else {
      setSectionInfo(section);
    }
  };
  const closeSectionModal = () => {
    setOriginalSectionInfo({});
    setSectionInfo({});
    setIsModalOpened(false);
    setChooseContentList([]);
  };

  //등록
  const onAddSection = () => {
    console.log(sectionInfo, originalSectionInfo);

    const contentIdList = chooseContentList.map((item) => item.content_id);

    let addSectionInfo = {
      ...sectionInfo,
      content_type: 'MIXED', //TODO: 컨텐츠 등록된 타입에 맞게 전달해야함. 탭 수정시 validation 진행 필요
      content_ids: contentIdList,
    };
    setSectionInfo(addSectionInfo);
    console.log("addSectionInfo", addSectionInfo);

    if (typeof addSectionInfo.ordering_number === "string") {
      let before = Number(addSectionInfo.ordering_number);
      addSectionInfo.ordering_number = before;
    }
    if (typeof addSectionInfo.section_row === "string") {
      let before = Number(addSectionInfo.section_row);
      addSectionInfo.section_row = before;
    }
    if (typeof addSectionInfo.section_col === "string") {
      let before = Number(addSectionInfo.section_col);
      addSectionInfo.section_col = before;
    }

    addSectionInfo.is_show = 1;

    console.log("등록등록등록등록", addSectionInfo);
    // return;
    axios
      .post(baseUrl, addSectionInfo)
      .then((res) => {
        console.log(res);
        alert("섹션을 등록하였습니다.");
        getSectionList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  //수정
  const onModifySection = () => {
    const contentIdList = chooseContentList.map((item) => item.content_id);

    console.log(">>>>first");
    let modifySectionInfo = {
      ...sectionInfo,
      content_ids: contentIdList,
    };
    console.log(">>>>Second:", modifySectionInfo);
    setSectionInfo(modifySectionInfo);

    delete originalSectionInfo.content_list;
    delete modifySectionInfo.content_list;
    let params = diff(modifySectionInfo, originalSectionInfo);

    if (isEmpty(params)) {
      console.log("안바뀜?");
      closeSectionModal();
      return;
    }

    if (typeof params.section_row === "string") {
      let before = Number(params.section_row);
      params.section_row = before;
    }
    if (typeof params.section_col === "string") {
      let before = Number(params.section_col);
      params.section_col = before;
    }
    if (typeof params.ordering_number === "string") {
      let before = Number(params.ordering_number);
      params.ordering_number = before;
    }
    if (typeof params.is_show === "string") {
      let before = Number(params.is_show);
      params.is_show = before;
    }

    console.log(params);
    // return;

    axios
      .put(`${baseUrl}/${originalSectionInfo.section_id}`, params)
      .then((res) => {
        console.log(res);
        alert("섹션을 수정하였습니다.");
        getSectionList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
        console.log(err.config.data);
      });
  };

  const deleteBackgroundImage = (item) => {
    if (!window.confirm("배경 이미지를 삭제하겠습니까?")) {
      // closeSectionModal();
      return;
    }
    if (item.background_image_url !== null) {
      let params = { background_image_url: "" };

      axios
        .put(`${baseUrl}/${item.section_id}`, params)
        .then((res) => {
          console.log(res);
          alert("배경 이미지가 삭제되었습니다.");
          getSectionList();
        })
        .catch((err) => {
          console.log(err);
          alert(err.response.data.message);
          console.log(err.config.data);
        });
    } else {
      alert("배경이미지를 확인해주세요");
    }
  };

  //삭제
  const deleteSection = (section_id) => {
    if (!window.confirm("섹션을 삭제하겠습니까?")) {
      // closeSectionModal();
      return;
    }
    // const params = { section_id: section_id };

    axios
      .delete(`${baseUrl}/${section_id}`)
      .then(() => {
        alert("섹션이 삭제되었습니다.");
        getSectionList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const handleTabChange = (val) => {
    setTab(val);
    setSectionInfo({ tab: val });
  };

  //인풋 수정시
  const handleChange = (e) => {
    // console.log(e);
    setSectionInfo({ ...sectionInfo, [e.target.name]: e.target.value });
  };

  const handleDelete = (content_id) => {
    // console.log(content_id);
    const newList = chooseContentList.filter(
      (item) => item.content_id !== content_id
    );
    setChooseContentList(newList);
  };

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      getContentList(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  const imageErrorHandler = (e) => {
    // console.log(e.target);
    e.target.src = "Image/backgroundImg.png";
  };
  const clickHandle = () => {
    setCurrentPage(1);
    setSearch("");
    setIsShownInfo(true);
  };

  return (
    <>
      <Pane>
        <Dialog
          width={"660px"}
          isShown={isModalOpened}
          hasClose={false}
          intent="danger"
          confirmLabel={"삭제하기"}
          onConfirm={() => deleteSection(originalSectionInfo.section_id)}
          onCloseComplete={() => closeSectionModal()}
          cancelLabel={`${modalTypeOption[modalType].button}`}
          onCancel={modalTypeOption[modalType].action}
          title={`${modalTypeOption[modalType].title}`}
        >
          {modalType === "MOD" ? (
            <div>
              <InputDiv>
                <InputSpan>섹션 ID</InputSpan>
                <TextInput
                  disabled
                  name="title"
                  value={sectionInfo.section_id}
                  onChange={handleChange}
                ></TextInput>
              </InputDiv>
              <InputDiv>
                <InputSpan>노출 여부</InputSpan>
                <RadioGroup
                  display="flex"
                  columnGap="10px"
                  size={16}
                  name="is_show"
                  value={String(sectionInfo.is_show)}
                  defaultValue={String(sectionInfo.is_show)}
                  options={sectionIsShowOption}
                  onChange={handleChange}
                />
              </InputDiv>
            </div>
          ) : null}

          <InputDiv>
            <InputSpan>탭</InputSpan>
            <RadioGroup
              display="flex"
              columnGap="10px"
              size={16}
              name="tab"
              options={tabOptions}
              value={sectionInfo.tab}
              // value={"MAIN"}
              // defaultValue={"MAIN"}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>섹션 타입</InputSpan>
            <RadioGroup
              display="flex"
              columnGap="10px"
              size={16}
              name="section_type"
              options={sectionTypeOption}
              value={sectionInfo.section_type}
              // value={"01"}
              // defaultValue={"01"}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputDiv>

          {sectionInfo.section_type === "02" ? (
            <div>
              <InputDiv>
                <InputSpan>디스플레이 타입</InputSpan>
                <RadioGroup
                  width="476px"
                  display="flex"
                  flexWrap="wrap"
                  columnGap="10px"
                  size={16}
                  name="display_type"
                  options={displayTypeOption02}
                  value={sectionInfo.display_type}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </InputDiv>
              <InputDiv>
                <InputSpan>섹션 가로 개수</InputSpan>
                <TextInput
                  name="section_row"
                  onChange={handleChange}
                  value={sectionInfo.section_row}
                ></TextInput>
              </InputDiv>
              <InputDiv>
                <InputSpan>섹션 세로 개수</InputSpan>
                <TextInput
                  name="section_col"
                  onChange={handleChange}
                  value={sectionInfo.section_col}
                ></TextInput>
              </InputDiv>
            </div>
          ) : (
            <InputDiv>
              <InputSpan>디스플레이 타입</InputSpan>
              <RadioGroup
                width="476px"
                display="flex"
                flexWrap="wrap"
                columnGap="10px"
                size={16}
                name="display_type"
                options={displayTypeOption01}
                value={sectionInfo.display_type}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </InputDiv>
          )}

          <InputDiv>
            <InputSpan>배경 이미지</InputSpan>
            <FileUploader
              directory="section"
              preview_src={sectionInfo.background_image_url}
              targetObj={sectionInfo}
              onUploaded={setSectionInfo}
              targetPropName="background_image"
              isImage={true}
            />
            {modalType === "MOD" ? (
              <Button
                appearance="primary"
                intent="danger"
                onClick={(event) => {
                  deleteBackgroundImage(sectionInfo);
                  // event.preventDefault();
                  event.stopPropagation();
                }}
              >
                배경이미지 삭제
              </Button>
            ) : null}
          </InputDiv>
          <InputDiv>
            <InputSpan>섹션 제목</InputSpan>
            <TextInput
              name="title"
              value={sectionInfo.title}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>섹션 설명</InputSpan>
            <TextInput
              maxLength={100}
              name="description"
              value={sectionInfo.description}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>

          <InputDiv>
            <InputSpan>섹션 순서</InputSpan>
            <TextInput
              type="number"
              name="ordering_number"
              onChange={handleChange}
              value={sectionInfo.ordering_number}
            ></TextInput>
          </InputDiv>

          <InputDiv>
            <InputSpan>콘텐츠 목록</InputSpan>
            <Button
              marginRight={16}
              appearance="primary"
              intent="none"
              onClick={() => clickHandle()}
              // onClick={() => setIsShownInfo(true)}
            >
              검색하기
            </Button>
          </InputDiv>

          <ContentDiv>
            {chooseContentList.map((item) => (
              <ContentBox key={item.content_id}>
                <ContentInputId readOnly value={item.content_id} />
                <ContentInputTitle readOnly value={item.title} />
                <ContentInputImg
                  width={"120px"}
                  src={item.thumbnail_image_url}
                ></ContentInputImg>
              </ContentBox>
            ))}
          </ContentDiv>
        </Dialog>

        <Dialog
          isShown={isShownInfo}
          onCloseComplete={() => setIsShownInfo(false)}
          intent="danger"
          hasClose={false}
          title="콘텐츠 검색 "
          hasCancel={false}
          width={"900px"}
          height={"80vh"}
          confirmLabel="완료"
        >
          <div style={{ marginBottom: "20px" }}>
            <TextInput
              placeholder="검색내용"
              value={search}
              onChange={onChangeSearch}
              onKeyPress={handleOnKeyPress}
            ></TextInput>
            <Button onClick={beforeGetContentList}>검색하기</Button>
          </div>
          <Table>
            <Table.Head height={40}>
              <Table.TextHeaderCell>콘텐츠 ID</Table.TextHeaderCell>
              <Table.TextHeaderCell>제목</Table.TextHeaderCell>
              <Table.TextHeaderCell>설명</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body height="auto">
              {contentList.map((content) => (
                // <Table.Row
                //   key={content.content_id}
                //   isSelectable
                //   onSelect={() => {
                //     const newObj = {
                //       content_id: content.content_id,
                //       title: content.title,
                //       thumbnail_image_url: content.thumbnail_image_url,
                //     };

                //     const newList = [...chooseContentList, newObj];
                //     console.log(newList);

                //     const result = newList.filter(
                //       (v, i) =>
                //         newList.findIndex(
                //           (x) => x.content_id === v.content_id
                //         ) === i
                //     );
                //     setChooseContentList(result);

                //     // handleChangeContent(content);

                //     let num = sectionInfo.section_row * sectionInfo.section_col;
                //     console.log(num);

                //     if (
                //       sectionInfo.section_type === "02" &&
                //       newList.length >= num
                //     ) {
                //       alert(`추가 가능한 콘텐츠의 갯수는 ${num}개입니다`);
                //       // handleDelete(sectionInfo.content_id);
                //     } else {
                //       toaster.success(`${content.title} 이/가 추가되었습니다`);
                //     }
                //   }}
                // >
                <Table.Row
                  height={40}
                  key={content.content_id}
                  isSelectable
                  onSelect={() => {
                    const newObj = {
                      content_id: content.content_id,
                      title: content.title,
                      thumbnail_image_url: content.thumbnail_image_url,
                    };

                    const isAlreadyAdded = chooseContentList.some(
                      (item) => item.content_id === newObj.content_id
                    );
                    if (isAlreadyAdded) {
                      toaster.warning("이미 추가된 콘텐츠입니다.");
                      return;
                    }

                    const newList = [...chooseContentList, newObj];

                    // 필터링하여 중복 요소 제거
                    const result = newList.filter(
                      (v, i, arr) =>
                        arr.findIndex((x) => x.content_id === v.content_id) ===
                        i
                    );
                    setChooseContentList(result);

                    let num = sectionInfo.section_row * sectionInfo.section_col;
                    if (
                      sectionInfo.section_type === "02" &&
                      newList.length >= num
                    ) {
                      alert(`추가 가능한 콘텐츠의 갯수는 ${num}개입니다`);
                    } else {
                      toaster.success(`${content.title} 이/가 추가되었습니다`);
                    }
                  }}
                >
                  <Table.TextCell>{content.content_id}</Table.TextCell>
                  <Table.TextCell>{content.title}</Table.TextCell>
                  <Table.TextCell>{content.description}</Table.TextCell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Pane
            height={120}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Pagination
              page={currentPage}
              totalPages={Math.ceil(totalCount / PAGE_SIZE)}
              onNextPage={() => {
                setCurrentPage(currentPage + 1);
              }}
              onPreviousPage={() => {
                setCurrentPage(currentPage - 1);
              }}
              onPageChange={(page) => setCurrentPage(page)}
            ></Pagination>
          </Pane>

          <ContentDiv>
            {chooseContentList.map((item) => (
              <ContentBox key={item.content_id}>
                <ContentInputId readOnly value={item.content_id} />
                <ContentInputTitle readOnly value={item.title} />
                <ContentInputImg
                  width={"120px"}
                  height={"70px"}
                  src={item.thumbnail_image_url}
                  alt="썸네일 이미지"
                ></ContentInputImg>
                <Button
                  intent="danger"
                  onClick={() => handleDelete(item.content_id)}
                >
                  삭제
                </Button>
              </ContentBox>
            ))}
          </ContentDiv>
        </Dialog>
      </Pane>

      <SectionBox>
        <Pane>
          <SectionH1>섹션 목록 - {[tab]}</SectionH1>
        </Pane>
        <Button
          appearance="primary"
          intent="none"
          style={{
            position: "inherit",
            left: "36%",
            bottom: "20px",
          }}
          onClick={() =>
            addSectionModal({
              tab,
            })
          }
        >
          등록하기
        </Button>
        <Table width="95%" textAlign="center">
          <ListUl>
            <ListLi
              onClick={() => handleTabChange("MAIN")}
              className={tab === "MAIN" && "checked"}
            >
              MAIN
            </ListLi>
            <ListLi
              onClick={() => handleTabChange("LESSON")}
              className={tab === "LESSON" && "checked"}
            >
              LESSON
            </ListLi>
            <ListLi
              onClick={() => handleTabChange("REAL")}
              className={tab === "REAL" && "checked"}
            >
              REAL
            </ListLi>

            <ListLi
              onClick={() => handleTabChange("POP")}
              className={tab === "POP" && "checked"}
            >
              POP
            </ListLi>
          </ListUl>
          <Table.Head>
            <Table.TextHeaderCell>섹션 ID</Table.TextHeaderCell>
            {/* <Table.TextHeaderCell>섹션 타입</Table.TextHeaderCell> */}
            <Table.TextHeaderCell>제목</Table.TextHeaderCell>
            {/* <Table.TextHeaderCell>섹션 콘텐츠 타입</Table.TextHeaderCell> */}
            <Table.TextHeaderCell>순서</Table.TextHeaderCell>
            <Table.TextHeaderCell>노출 여부</Table.TextHeaderCell>
            <Table.TextHeaderCell>배경 이미지</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={400}>
              컨텐츠 썸네일 이미지
            </Table.TextHeaderCell>
            {/* <Table.TextHeaderCell flexBasis={180} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell> */}
          </Table.Head>
          <Table.VirtualBody height={660}>
            {sectionList.map((item) => (
              <Table.Row
                height={65}
                key={item.section_id}
                isSelectable
                onSelect={async () => {
                  const _section = await getSectionDetailList(item.section_id);
                  console.log(_section);
                  modifySectionModal(_section);

                  // setCreateObjectURL(sectionInfo.thumbnail_image_url);
                }}
              >
                <Table.TextCell>{item.section_id}</Table.TextCell>
                {/* <Table.TextCell>{section.section_type}</Table.TextCell> */}
                <Table.TextCell>{item.title}</Table.TextCell>
                {/* <Table.TextCell>{section.display_type}</Table.TextCell> */}
                <Table.TextCell>{item.ordering_number}</Table.TextCell>
                <Table.TextCell>
                  {item.is_show ? "노출" : "미노출"}
                </Table.TextCell>
                <Table.Cell>
                  <img
                    width="90px"
                    src={item.background_image_url || ""}
                    // src="Image/backgroundImg.png"
                    alt="배경이미지"
                    onError={imageErrorHandler}
                  />
                </Table.Cell>

                <Table.Cell flexBasis={400} overflow="auto" gap="2px">
                  {item.content_list.map((s, index) => (
                    <img
                      width="90px"
                      key={index}
                      src={s.thumbnail_image_url}
                      alt="콘텐츠이미지"
                    ></img>
                  ))}
                </Table.Cell>
                {/* <Table.TextCell
                  flexBasis={180}
                  flexShrink={0}
                  flexGrow={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    appearance="primary"
                    marginRight="5px"
                    intent="danger"
                    onClick={(event) => {
                      deleteSection(item.section_id);
                      // event.preventDefault();
                      event.stopPropagation();
                    }}
                  >
                    삭제
                  </Button>
                </Table.TextCell> */}
                {/* <Table.TextCell
                  flexBasis={90}
                  flexShrink={0}
                  flexGrow={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    appearance="primary"
                    intent="success"
                    onClick={(event) => {
                      setIsShownInfo(true);
                      setContentItem(section);
                      // setSelectedContent2(selectedContent[index]);
                      event.stopPropagation();
                      // console.log(index);
                    }}
                  >
                    콘텐츠
                  </Button>
                </Table.TextCell> */}
              </Table.Row>
            ))}
          </Table.VirtualBody>
        </Table>
        {/* <Pane
          height={120}
          // width={"100%"}
          display="flex"
          alignItems="center"
          justifyContent="center"
          // border="default"
        >
          <Pagination page={1} totalPages={3}></Pagination>
        </Pane> */}
      </SectionBox>
    </>
  );
};

const SectionBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const SectionH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const ListUl = styled.ul``;
const ListLi = styled.li`
  &:hover,
  &.checked {
    background-color: #7b9cff;
    color: white;
  }
  cursor: pointer;
  display: inline-block;
  padding: 20px 40px;
`;

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;
const ContentDiv = styled.div`
  margin-top: 20px;
`;
const ContentBox = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentInputId = styled.input`
  height: 70px;
  border: 1px solid #e6e8f0;
  font-size: 12px;
  border-radius: 3px;
  padding-left: 8px;
`;
const ContentInputTitle = styled.input`
  height: 70px;
  width: 440px;
  border: 1px solid #e6e8f0;
  font-size: 12px;
  border-radius: 3px;
  padding-left: 8px;
`;
const ContentInputImg = styled.img`
  border: 1px solid #e6e8f0;
  border-radius: 3px;
  margin-right: 20px;
`;
export default Section;
