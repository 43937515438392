import React, { useEffect, useState, memo } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  toaster,
} from "evergreen-ui";

const baseUrl = "/admin/content";

const ContentSearch = ({ musicIds, initialFullMusicList, onSetMusicIds }) => {
  const PAGE_SIZE = 5;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [fullMusicList, setFullMusicList] = useState(initialFullMusicList);
  const [musicSearchList, setMusicSearchList] = useState([]);
  const [musicIdsInfo, setMusicIdsInfo] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  const [contentKeyword, setContentKeyword] = useState("");

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const modalTypeOption = {
    ADD: {
      button: "저장",
      title: "콘텐츠 검색",
      action: () => {
        onSaveMusicList();
      },
    },
  };

  useEffect(() => {
    getContentList();
  }, [currentPage]);

  const musicSearchModal = async () => {
    setMusicIdsInfo(musicIds);
    getFullMusicList();

    openModal();
  };

  const openModal = async () => {
    await setIsModalOpened(true);
    await setContentKeyword("");
    await setCurrentPage(1);

    getContentList();
  };

  const closeModal = () => {
    setContentKeyword("");
    setMusicIdsInfo([]);
    setIsModalOpened(false);
  };

  const getFullMusicList = async () => {
    const params = {
      content_type: "REAL",
    };

    try {
      const response = await axios.get(baseUrl, { params });
      setFullMusicList(response.data.content_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };
  const beForeGetContentList = () => {
    if (currentPage !== 1) {
      console.log("ASDF");
      setCurrentPage(1);
      getContentList();
    } else {
      getContentList();
    }
  };
  const getContentList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: contentKeyword,
      content_type: "REAL",
    };

    console.log(params);

    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.content_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);

        setMusicSearchList(data);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  const handleButtonClick = (content_id) => {
    if (!isButtonClicked) {
      console.log("f");
      setIsButtonClicked(true);
      onAddMusicList(content_id);
    } else {
      toaster.warning("콘텐츠는 1개만 등록 가능합니다");
    }
  };
  const onAddMusicList = (content_id) => {
    let newMusicIdsInfo = [...musicIdsInfo, content_id];
    setMusicIdsInfo(newMusicIdsInfo);
  };

  const onRemoveMusicList = (content_id) => {
    // console.log(content_id);
    let newMusicIdsInfo = musicIds.filter((i) => i !== content_id);
    // console.log(musicIds, newMusicIdsInfo);
    // setMusicIdsInfo(newMusicIdsInfo)
    onSetMusicIds(newMusicIdsInfo);
  };

  const onSaveMusicList = () => {
    console.log(musicIdsInfo);
    onSetMusicIds(musicIdsInfo);
    closeModal();
  };

  return (
    <Pane style={{ width: "100%" }}>
      <Dialog
        width={"900px"}
        isShown={isModalOpened}
        onCloseComplete={() => closeModal()}
        confirmLabel={modalTypeOption[modalType].button}
        onConfirm={modalTypeOption[modalType].action}
        hasClose={false}
        title={modalTypeOption[modalType].title}
      >
        <InputDiv>
          <InputSpan>콘텐츠 검색</InputSpan>
          <TextInput
            onChange={(e) => {
              const keyword = e.target.value;
              setContentKeyword(keyword);
            }}
          ></TextInput>
          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={beForeGetContentList}
          >
            검색
          </Button>
        </InputDiv>
        {musicSearchList.length > 0 ? (
          <Pane>
            <Table width="100%" textAlign="center">
              <Table.Head>
                <Table.TextHeaderCell>제목</Table.TextHeaderCell>
                <Table.TextHeaderCell>타입</Table.TextHeaderCell>
                <Table.TextHeaderCell>설명</Table.TextHeaderCell>
                <Table.TextHeaderCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                >
                  관리
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height="auto">
                {musicSearchList.map((item) => {
                  return (
                    <Table.Row key={item.content_id}>
                      <Table.TextCell>{item.title}</Table.TextCell>
                      <Table.TextCell>{item.content_type}</Table.TextCell>
                      <Table.TextCell>{item.description}</Table.TextCell>
                      <Table.TextCell
                        flexBasis={120}
                        flexShrink={0}
                        flexGrow={0}
                      >
                        {musicIdsInfo.find((i) => i === item.content_id) ? (
                          <Button disabled>등록됨</Button>
                        ) : (
                          <Button
                            appearance="primary"
                            onClick={() => {
                              handleButtonClick(item.content_id);
                            }}
                          >
                            추가
                          </Button>
                        )}
                      </Table.TextCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            <Pane
              height={80}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Pagination
                page={currentPage}
                totalPages={Math.ceil(totalCount / PAGE_SIZE)}
                onNextPage={() => {
                  setCurrentPage(currentPage + 1);
                }}
                onPreviousPage={() => {
                  setCurrentPage(currentPage - 1);
                }}
                onPageChange={(page) => setCurrentPage(page)}
              ></Pagination>
            </Pane>
          </Pane>
        ) : (
          <NoResult>검색 결과가 없습니다</NoResult>
        )}
      </Dialog>
      <InputDiv>
        {musicIds && musicIds.length > 0 && (
          <Pane>
            <Table textAlign="center">
              <Table.Head>
                <Table.TextHeaderCell>제목</Table.TextHeaderCell>
                <Table.TextHeaderCell>타입</Table.TextHeaderCell>
                <Table.TextHeaderCell>설명</Table.TextHeaderCell>
                <Table.TextHeaderCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                >
                  관리
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height="auto">
                {musicIds.map((i) => {
                  // console.log(i, fullMusicList)
                  const item = fullMusicList.find((it) => it.content_id === i);
                  if (!item) return <></>;

                  return (
                    <Table.Row key={item.content_id}>
                      <Table.TextCell>{item.title}</Table.TextCell>
                      <Table.TextCell>{item.content_type}</Table.TextCell>
                      <Table.TextCell>{item.description}</Table.TextCell>
                      <Table.TextCell
                        flexBasis={120}
                        flexShrink={0}
                        flexGrow={0}
                      >
                        <Button
                          appearance="primary"
                          intent="danger"
                          onClick={() => {
                            onRemoveMusicList(i);
                          }}
                        >
                          제거
                        </Button>
                      </Table.TextCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Pane>
        )}
      </InputDiv>
      <Button
        appearance="primary"
        intent="none"
        style={{
          position: "inherit",
          left: "36%",
          bottom: "20px",
        }}
        onClick={() => musicSearchModal()}
      >
        콘텐츠 추가
      </Button>
    </Pane>
  );
};

const memorizedMusicSearch = memo(ContentSearch);

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;
const NoResult = styled.div`
  text-align: center;
  display: block;
  padding: 40px;
`;

export default memorizedMusicSearch;
