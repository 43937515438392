import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Pane } from "evergreen-ui";
import styled from "styled-components";
// import Sidebar from "./Sidebar";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import LocalStorageService from "services/storage/LocalStorageService";
const localStorageService = LocalStorageService.getService();

function Header(props) {
  const isLogin = localStorageService.getAccessToken() !== null;
  const location = useLocation();
  const navigate = useNavigate();

  const logout = () => {
    localStorageService.clearToken();
    navigate("/login");
  };

  if (!isLogin) {
    if (location.pathname !== "/login") {
      logout();
      return;
    }
  }

  // if (isLogin) {
  //   // console.log(location);
  //   if (location.pathname === "/liveRoomOperation") {
  //     // console.log("ASDFASDFASDF");
  //   }
  // }
  return location.pathname === "/liveRoomOperation" ||
    location.pathname === "/liveRoomPrompt" ||
    !isLogin ? (
    <></>
  ) : (
    <>
      <Pane maxWidth={"1240px"}>
        <HeaderBox>
          {/* <Button
            style={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translate(0, -50%)",
            }}
            onClick={() => collapseSidebar()}
          >
            Menu
          </Button> */}
          <Sidebar
            width="170px"
            collapsedWidth="0px"
            style={{
              position: "absolute",
              left: "0px",
              top: "60px",
              height: "100vh",
              lineHeight: "1.2",
            }}
          >
            <Menu
              renderExpandIcon={({ open }) => <span>{open ? "-" : "+"}</span>}
            >
              <MenuItem routerLink={<Link to="/" />}>HOME</MenuItem>
              <SubMenu
                label="콘텐츠"
                style={{
                  overflow: "visible",
                }}
              >
                <MenuItem routerLink={<Link to="/content" />}>
                  콘텐츠 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/challenge" />}>
                  챌린지 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/" />}>
                  콘텐츠 평가어 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/tag" />}>
                  콘텐츠 태그 관리
                </MenuItem>
              </SubMenu>

              <SubMenu
                label="음원"
                style={{
                  overflow: "visible",
                }}
              >
                <MenuItem routerLink={<Link to="/music" />}>음원 관리</MenuItem>
                <MenuItem routerLink={<Link to="/musictag" />}>
                  음원 태그 관리
                </MenuItem>
              </SubMenu>

              <SubMenu
                label="라이브"
                style={{
                  overflow: "visible",
                }}
              >
                <MenuItem routerLink={<Link to="/liveContent" />}>
                  라이브 콘텐츠 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/liveRoom" />}>
                  라이브 방송 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/message" />}>
                  메시지 템플릿 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/messageCategory" />}>
                  메시지 카테고리 관리
                </MenuItem>
              </SubMenu>

              <SubMenu
                label="APP 운영"
                style={{
                  overflow: "visible",
                }}
              >
                <MenuItem routerLink={<Link to="/section" />}>
                  섹션 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/banner" />}>
                  배너 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/contentKeyword" />}>
                  추천 검색어 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/notice" />}>
                  공지 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/operationInfo" />}>
                  FAQ 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/question" />}>
                  1:1문의 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/noticeCategory" />}>
                  공지 카테고리 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/operationInfoCategory" />}>
                  FAQ 카테고리 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/questionCategory" />}>
                  1:1문의 카테고리 관리
                </MenuItem>
              </SubMenu>
              <SubMenu
                label="광고"
                style={{
                  overflow: "visible",
                }}
              >
                <MenuItem routerLink={<Link to="/screenSaver" />}>
                  화면 보호기
                </MenuItem>
                <MenuItem routerLink={<Link to="/contract" />}>
                  광고 계약 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/InplayAdvertising" />}>
                  인플레이 광고 관리
                </MenuItem>
              </SubMenu>

              <SubMenu
                label="바이크"
                style={{
                  overflow: "visible",
                }}
              >
                <MenuItem routerLink={<Link to="/bike" />}>
                  바이크 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/bikeSchedule" />}>
                  무료 바이크 관리
                </MenuItem>
              </SubMenu>

              <SubMenu
                label="사용자"
                style={{
                  overflow: "visible",
                }}
              >
                <MenuItem routerLink={<Link to="/user" />}>회원 관리</MenuItem>
                <MenuItem routerLink={<Link to="/instructor" />}>
                  강사 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/group" />}>
                  사용자 그룹 관리
                </MenuItem>
              </SubMenu>

              <SubMenu
                label="결제"
                style={{
                  overflow: "visible",
                }}
              >
                {/* <MenuItem routerLink={<Link to="/" />}>결제 관리</MenuItem> */}
                <MenuItem routerLink={<Link to="/item" />}>
                  결제 이용권 관리
                </MenuItem>
              </SubMenu>

              {/* <SubMenu
                label="광고"
                style={{
                  overflow: "visible",
                }}
              >
                <MenuItem routerLink={<Link to="/advertising" />}>
                  광고 관리
                </MenuItem>
                <MenuItem routerLink={<Link to="/contract" />}>
                  광고 계약 관리
                </MenuItem>
              </SubMenu>

              <SubMenu
                label="화면 보호기"
                style={{
                  overflow: "visible",
                }}
              >
                <MenuItem routerLink={<Link to="/screenSaver" />}>
                  화면 보호기
                </MenuItem>
              </SubMenu> */}

              {/* <HeaderHr></HeaderHr> */}
              <SubMenu
                label="통계"
                style={{
                  overflow: "visible",
                }}
              ></SubMenu>

              <SubMenu
                label="설정"
                style={{
                  overflow: "visible",
                }}
              ></SubMenu>
            </Menu>
          </Sidebar>

          {/* <Button position={"absolute"} left={"20px"} onClick={toggleSide}>
            Menu
          </Button>
          <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} /> */}
          <HeaderH1>
            <Link to="/">POPPRIKA V2</Link>
          </HeaderH1>

          <Button
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translate(0, -50%)",
            }}
            onClick={() => logout()}
          >
            <Link to="/">Logout</Link>
          </Button>
        </HeaderBox>
      </Pane>
    </>
  );
}
const HeaderBox = styled.div`
  position: sticky;
  top: 0;
  height: 60px;
  display: flex;
  border-bottom: 1px solid #f1f1f1;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  width: 100vw;
`;
const HeaderH1 = styled.h1`
  font-size: 1.5vw;
  margin-left: 170px;
`;
const HeaderHr = styled.hr`
  background-color: #cecece;
  height: 1px;
  border: none;
  margin: 0;
`;
export default Header;
