import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import AWS from "aws-sdk";
import { nanoid } from "nanoid";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  RadioGroup,
  TagInput,
  Pagination,
} from "evergreen-ui";

import DateText from "components/DateText";
import { diff, isEmpty, getMod } from "services/util";
import FileUploader from "components/FileUploader";

const baseUrl = "/admin/banner";
const groupUrl = "/admin/group";

const MobileBanner = () => {
  window.Buffer = window.Buffer || require("buffer").Buffer;

  const PAGE_SIZE = 10;
  const [bannerList, setBannerList] = useState([]);
  const [bannerInfo, setBannerInfo] = useState([]);
  const [originalBannerInfo, setOriginalBannerInfo] = useState([]);
  const [tab, setTab] = useState("MAIN");
  const [groupList, setGroupList] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [createObjectURL, setCreateObjectURL] = useState(null);
  const [modalType, setModalType] = useState("ADD");
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [deviceOptions] = React.useState([
    { label: "태블릿", value: "T" },
    { label: "모바일", value: "M" },
  ]);

  const [tabOptions] = React.useState([
    { label: "MAIN", value: "MAIN" },
    { label: "LESSON", value: "LESSON" },
    { label: "REAL", value: "REAL" },
    { label: "POP", value: "POP" },
  ]);
  const [isShowOption] = React.useState([
    { label: "표시", value: "1" },
    { label: "미표시", value: "0" },
  ]);
  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "배너 등록",
      action: () => {
        onAddBanner();
      },
    },
    MOD: {
      button: "수정하기",
      title: "배너 정보 수정",
      action: () => {
        onModifyBanner();
      },
    },
  };
  const handleTabChange = (val) => {
    setTab(val);
    setBannerInfo({ tab: val });
  };

  const addMusicModal = (item) => {
    openModal(item, "ADD");
  };

  const modifyMusicModal = (item) => {
    openModal(item, "MOD");
  };

  const openModal = async (item, type) => {
    await getGroupList();

    setOriginalBannerInfo(item);
    setBannerInfo(item);
    setModalType(type);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setOriginalBannerInfo({});
    setBannerInfo({});
    setIsModalOpened(false);
    setCreateObjectURL(null);
  };

  const fileName = nanoid(8);
  const region = "ap-northeast-2";
  const bucket = "popprika-cms-version2";
  const directory = "banner";
  const objectParams_preSign = {
    Bucket: bucket,
    Key: `${directory}/`,
    // Expires: 600, // 60초 동안만 url 유지
  };

  useEffect(() => {
    getBannerList();
  }, [tab]);

  useEffect(() => {}, [imageUrl]);

  //인풋 수정시
  const handleChange = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    setBannerInfo({ ...bannerInfo, [e.target.name]: e.target.value });
  };
  const getGroupList = async () => {
    try {
      const response = await axios.get(groupUrl);
      console.log("groupList", response);
      setGroupList(response.data.group_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };
  const getBannerList = () => {
    const params = {
      limit: 10,
      offset: PAGE_SIZE * (currentPage - 1),
    };
    axios
      .get(`${baseUrl}/M/${tab}`, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.banner_list;
        const dataCount = res.data.count;

        const newData = data.map((item) => {
          return {
            ...item,
          };
        });
        // console.log("newData", newData)
        setTotalCount(dataCount);
        setBannerList(newData);
        setIsModalOpened(false);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //AWS SETTING
  AWS.config.update({
    region: region,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_SECRETKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: bucket },

    region: region,
  });

  //배너 등록
  const onAddBanner = () => {
    let newBannerInfo = {
      ...bannerInfo,
    };

    console.log(newBannerInfo);
    let params = diff(newBannerInfo, originalBannerInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }
    if (typeof params.ordering_number === "string") {
      let a = Number(params.ordering_number);
      params.ordering_number = a;
    }
    // if (params.image_original_filename) {
    //   newBannerInfo.image_url = imageUrl;
    // }

    params.is_show = 1;
    console.log(params);
    // return;
    axios
      .post(baseUrl, params)
      .then((response) => {
        console.log(response);
        alert("등록되었습니다");
        getBannerList();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //배너 수정
  const onModifyBanner = () => {
    console.log(bannerInfo);
    let params = diff(bannerInfo, originalBannerInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }

    if (typeof params.ordering_number === "string") {
      let a = Number(params.ordering_number);
      params.ordering_number = a;
    }
    if (typeof params.is_show === "string") {
      let a = Number(params.is_show);
      params.is_show = a;
    }
    // if (params.image_original_filename) {
    //   bannerInfo.image_url = imageUrl;
    // }
    console.log(params);
    // console.log(originalBannerInfo);

    axios
      .put(`${baseUrl}/${originalBannerInfo.banner_id}`, params)
      .then((response) => {
        console.log(response);
        alert("수정되었습니다");
        getBannerList();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //배너 삭제
  const deleteBanner = (banner_id) => {
    if (!window.confirm("삭제하겠습니까?")) {
      closeModal();
      return;
    }
    // const params = { banner_id: banner_id };

    axios
      .delete(`${baseUrl}/${banner_id}`)
      .then(() => {
        alert("삭제되었습니다.");
        getBannerList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const getBannerInfo = async (banner_id) => {
    try {
      const res = await axios.get(`${baseUrl}/${banner_id}`);
      console.log(res);
      const data = res.data.banner;

      let newContent = {
        ...data,
        _group_codes: data.group_codes.split(",").map((i) => Number(i)),
      };

      console.log(newContent);
      return newContent;
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
      return {};
    }
  };

  const getTagInputTitle = (
    originalContentInfo,
    bannerInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    // console.log(propName, bannerInfo[propName])
    const titleList =
      bannerInfo[propName] &&
      bannerInfo[propName].map((item) => {
        return fullList.find((listItem) => listItem[listPropKey] === item)[
          listPropName
        ];
      });
    return titleList;
  };
  const setTagInputValue = (
    originalContentInfo,
    bannerInfo,
    propName,
    value,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let newBannerInfo = { ...bannerInfo };
    newBannerInfo[propName] = value
      .map((valueItem) => {
        let obj = fullList.find((item) => item[listPropName] === valueItem);
        return obj ? obj[listPropKey] : null;
      })
      .filter((item) => item !== null);

    const add_prop_name = `add${propName}`;
    const delete_prop_name = `delete${propName}`;

    const added = newBannerInfo[propName]
      ? newBannerInfo[propName].filter((i) =>
          originalContentInfo[propName]
            ? !originalContentInfo[propName].includes(i)
            : true
        )
      : [];
    const deleted = originalContentInfo[propName]
      ? originalContentInfo[propName].filter((i) =>
          newBannerInfo[propName] ? !newBannerInfo[propName].includes(i) : true
        )
      : [];

    // console.log(newContentInfo, originalContentInfo)
    // console.log(add_prop_name,added,delete_prop_name,deleted)

    newBannerInfo[add_prop_name] = added;
    newBannerInfo[delete_prop_name] = deleted;

    setBannerInfo(newBannerInfo);
  };

  const tagInputAutoCompleteList = (
    bannerInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let data = fullList
      .filter(
        (item) =>
          !(
            bannerInfo[propName] &&
            bannerInfo[propName].includes(item[listPropKey])
          )
      )
      .map((item) => item[listPropName]);
    return data;
  };

  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={modalTypeOption[modalType].button}
          onConfirm={modalTypeOption[modalType].action}
          hasClose={false}
          title={modalTypeOption[modalType].title}
        >
          {modalType === "MOD" ? (
            <InputDiv>
              <InputSpan>배너 ID</InputSpan>
              <TextInput
                disabled
                name="banner_id"
                defaultValue={bannerInfo.banner_id}
                onChange={handleChange}
              ></TextInput>
            </InputDiv>
          ) : null}

          <InputDiv>
            <InputSpan>대상 기기</InputSpan>
            <RadioGroup
              display="flex"
              columnGap="10px"
              size={16}
              name="target_device"
              value={bannerInfo.target_device}
              options={deviceOptions}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>탭</InputSpan>
            <RadioGroup
              display="flex"
              columnGap="10px"
              size={16}
              name="tab"
              value={bannerInfo.tab}
              options={tabOptions}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>배너 이미지</InputSpan>
            <FileUploader
              directory="banner"
              preview_src={bannerInfo.image_url}
              targetObj={bannerInfo}
              onUploaded={setBannerInfo}
              targetPropName="image"
              isImage={true}
            />
            {/* <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <img width="130px" name="image_url" src={createObjectURL}></img>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileInput(e)}
              />
            </div> */}
          </InputDiv>
          <InputDiv>
            <InputSpan>배너 순서</InputSpan>
            <TextInput
              type="number"
              name="ordering_number"
              defaultValue={bannerInfo.ordering_number}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          {/* <InputDiv>
            <InputSpan>노출 여부</InputSpan>
            <RadioGroup
              display="flex"
              columnGap="10px"
              size={16}
              name="is_show"
              value={String(bannerInfo.is_show)}
              defaultValue={String(bannerInfo.is_show)}
              options={isShowOption}
              onChange={handleChange}
            />
          </InputDiv> */}
          <InputDiv>
            <InputSpan>클릭 이벤트</InputSpan>
            <TextInput
              name="click_event"
              defaultValue={bannerInfo.click_event}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>그룹 코드</InputSpan>
            <TagInput
              inputProps={{ placeholder: "Enter something..." }}
              values={getTagInputTitle(
                originalBannerInfo,
                bannerInfo,
                "_group_codes",
                groupList,
                "group_code",
                "title"
              )}
              onChange={(e) => {
                setTagInputValue(
                  originalBannerInfo,
                  bannerInfo,
                  "_group_codes",
                  e,
                  groupList,
                  "group_code",
                  "title"
                );
              }}
              autocompleteItems={tagInputAutoCompleteList(
                bannerInfo,
                "_group_codes",
                groupList,
                "group_code",
                "title"
              )}
            />
          </InputDiv>
        </Dialog>
      </Pane>

      <BannerBox>
        <Pane>
          <BannerH1>모바일 배너 목록 - {[tab]}</BannerH1>
        </Pane>
        <Button
          appearance="primary"
          intent="none"
          style={{
            position: "inherit",
            left: "36%",
            bottom: "20px",
          }}
          onClick={() => addMusicModal({})}
        >
          등록하기
        </Button>
        <Table width="95%" textAlign="center">
          <ListUl>
            <ListLi
              onClick={() => handleTabChange("MAIN")}
              className={tab === "MAIN" && "checked"}
            >
              MAIN
            </ListLi>
            <ListLi
              onClick={() => handleTabChange("LESSON")}
              className={tab === "LESSON" && "checked"}
            >
              LESSON
            </ListLi>
            <ListLi
              onClick={() => handleTabChange("REAL")}
              className={tab === "REAL" && "checked"}
            >
              REAL
            </ListLi>

            <ListLi
              onClick={() => handleTabChange("POP")}
              className={tab === "POP" && "checked"}
            >
              POP
            </ListLi>
          </ListUl>
          <Table.Head>
            <Table.TextHeaderCell>배너 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>대상 기기</Table.TextHeaderCell>
            <Table.TextHeaderCell>배너 이미지</Table.TextHeaderCell>
            <Table.TextHeaderCell>순서</Table.TextHeaderCell>
            <Table.TextHeaderCell>노출여부</Table.TextHeaderCell>
            <Table.TextHeaderCell>클릭 이벤트</Table.TextHeaderCell>
            <Table.TextHeaderCell>생성 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell>업데이트 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={90} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {bannerList.map((banner) => (
              <Table.Row
                key={banner.banner_id}
                isSelectable
                onSelect={async () => {
                  const _item = await getBannerInfo(banner.banner_id);
                  console.log(_item);
                  modifyMusicModal(_item);
                  setCreateObjectURL(banner.image_url);
                }}
              >
                <Table.TextCell>{banner.banner_id}</Table.TextCell>
                <Table.TextCell>
                  {banner.target_device === "T" ? "태블릿" : "모바일"}
                </Table.TextCell>
                <Table.Cell justifyContent="center">
                  <img width="100px" src={banner.image_url}></img>
                </Table.Cell>
                <Table.TextCell>{banner.ordering_number}</Table.TextCell>
                <Table.TextCell>{banner.is_show ? "Y" : "N"}</Table.TextCell>
                <Table.TextCell>{banner.click_event}</Table.TextCell>
                <Table.TextCell>
                  <DateText>{banner.create_date}</DateText>
                </Table.TextCell>
                <Table.TextCell>
                  <DateText>{banner.update_date}</DateText>
                </Table.TextCell>
                <Table.TextCell
                  flexBasis={90}
                  flexShrink={0}
                  flexGrow={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    appearance="primary"
                    intent="danger"
                    onClick={(event) => {
                      deleteBanner(banner.banner_id);
                      // event.preventDefault();
                      event.stopPropagation();
                    }}
                  >
                    삭제
                  </Button>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {
          <Pane
            height={120}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Pagination
              page={currentPage}
              totalPages={Math.ceil(totalCount / PAGE_SIZE)}
              onNextPage={() => {
                setCurrentPage(currentPage + 1);
              }}
              onPreviousPage={() => {
                setCurrentPage(currentPage - 1);
              }}
              onPageChange={(page) => setCurrentPage(page)}
            ></Pagination>
          </Pane>
        }
      </BannerBox>
    </>
  );
};

const BannerBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const BannerH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const ListUl = styled.ul``;
const ListLi = styled.li`
  &:hover,
  &.checked {
    background-color: #7b9cff;
    color: white;
  }
  cursor: pointer;
  display: inline-block;
  padding: 20px 40px;
`;

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;
export default MobileBanner;
