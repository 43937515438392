import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  RadioGroup,
} from "evergreen-ui";
import DateText from "components/DateText";
import Tag from "components/Tag";
import FileUploader from "components/FileUploader";
import { diff, isEmpty, getMod } from "services/util";
// import ModalBasic from "./MusicModal";
// import UpdateModalBasic from "./InstuctorUpdate";

const baseUrl = "/admin/music";
const musicTagUrl = "/admin/music-tag";

const Music = () => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [musicList, setMusicList] = useState([]);
  const [originalMusicInfo, setOriginalMusicInfo] = useState([]);
  const [musicInfo, setMusicInfo] = useState([]);
  const [musicTagList, setMusicTagList] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  const [searchKeyword, setSearchKeyword] = useState("");

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "음악 등록",
      action: () => {
        onAddMusic();
      },
    },
    MOD: {
      button: "수정하기",
      title: "음악 정보 수정",
      action: () => {
        onModifyMusic();
      },
    },
  };

  const [contractOption] = React.useState([
    { label: "계약", value: "1" },
    { label: "미계약", value: "0" },
  ]);

  useEffect(() => {
    getMusicTagList();
  }, []);

  useEffect(() => {
    getMusicList();
  }, [currentPage]);

  const addMusicModal = (music) => {
    openModal(music, "ADD");
  };

  const modifyMusicModal = (music) => {
    openModal(music, "MOD");
  };

  const openModal = (music, type) => {
    setOriginalMusicInfo(music);
    setMusicInfo(music);
    setModalType(type);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setOriginalMusicInfo({});
    setMusicInfo({});
    setIsModalOpened(false);
  };

  const getMusicTagList = async () => {
    try {
      const response = await axios.get(musicTagUrl);
      // console.log("music-tagList", response);
      setMusicTagList(response.data.music_tag_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const beforeSearch = async () => {
    await setCurrentPage(1);
    getMusicList();
  };
  const getMusicList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: searchKeyword,
    };

    // console.log(params)

    axios
      .get(baseUrl, { params })
      .then((res) => {
        const data = res.data.music_list;
        const dataCount = res.data.count;

        const newData = data.map((item) => {
          return {
            ...item,
            _music_tag_ids: (item.music_tag_ids || "").split(","),
          };
        });
        // console.log("newData", newData)
        setTotalCount(dataCount);
        setMusicList(newData);
        setIsModalOpened(false);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //인풋 수정시
  const handleChange = (e) => {
    setMusicInfo({ ...musicInfo, [e.target.name]: e.target.value });
  };

  //음악 등록
  const onAddMusic = () => {
    let newMusicInfo = {
      ...musicInfo,
      duration: Number(musicInfo.duration || 0),
      is_contract: Number(musicInfo.is_contract || 0),
    };
    let params = diff(newMusicInfo, originalMusicInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }

    axios
      .post(baseUrl, params)
      .then((response) => {
        // console.log(response);
        alert("등록되었습니다");
        getMusicList();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //음악 수정
  const onModifyMusic = () => {
    let newMusicInfo = {
      ...musicInfo,
      duration: Number(musicInfo.duration || 0),
      is_contract: Number(musicInfo.is_contract || 0),
    };
    let params = diff(newMusicInfo, originalMusicInfo);
    console.log(params);
    if (isEmpty(params)) {
      closeModal();
      return;
    }

    axios
      .put(`${baseUrl}/${newMusicInfo.music_id}`, params)
      .then((response) => {
        console.log(response);
        alert("수정되었습니다");
        getMusicList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //음악 삭제
  const onDeleteMusic = (music_id) => {
    if (window.confirm("삭제하시겠습니까?")) {
      axios
        .delete(`${baseUrl}/${music_id}`)
        .then((response) => {
          console.log(response);
          alert("삭제되었습니다");
          getMusicList();
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
        });
    }
  };
  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={modalTypeOption[modalType].button}
          onConfirm={modalTypeOption[modalType].action}
          hasClose={false}
          title={modalTypeOption[modalType].title}
        >
          {modalType === "MOD" && (
            <InputDiv>
              <InputSpan>음악 ID</InputSpan>
              <TextInput
                disabled
                name="music_id"
                defaultValue={musicInfo.music_id}
                onChange={handleChange}
              ></TextInput>
            </InputDiv>
          )}
          <InputDiv>
            <InputSpan>음악명</InputSpan>
            <TextInput
              name="title"
              defaultValue={musicInfo.title}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>저작권자</InputSpan>
            <TextInput
              name="artist"
              defaultValue={musicInfo.artist}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>지속시간</InputSpan>
            <TextInput
              name="duration"
              defaultValue={musicInfo.duration}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>계약 여부</InputSpan>
            <RadioGroup
              display="flex"
              columnGap="10px"
              size={16}
              name="is_contract"
              value={String(musicInfo.is_contract)}
              defaultValue={String(musicInfo.is_contract)}
              options={contractOption}
              onChange={handleChange}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>음악</InputSpan>
            <div
              style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <TextInput value={musicInfo.music_url} readOnly />
              <TextInput value={musicInfo.music_original_filename} readOnly />
              <FileUploader
                directory="music"
                targetObj={musicInfo}
                onUploaded={setMusicInfo}
                targetPropName="music"
              />
            </div>
          </InputDiv>
          <InputDiv>
            <InputSpan>음악 태그</InputSpan>
            <Tag
              placeholder={"음악 태그 검색"}
              originalDataInfo={originalMusicInfo}
              dataInfo={musicInfo}
              fullTagList={musicTagList}
              dataPropName={"_music_tag_ids"}
              tagListPropKey={"music_tag_id"}
              tagListPropName={"title"}
              setDataInfo={setMusicInfo}
            />
          </InputDiv>
        </Dialog>
      </Pane>
      <MusicBox>
        <Pane>
          <MusicH1>음악 목록</MusicH1>
        </Pane>
        <Box>
          <SearchBtn>
            <TextInput
              placeholder="제목"
              width={"250px"}
              onChange={(e) => {
                const keyword = e.target.value;
                setSearchKeyword(keyword);
              }}
            ></TextInput>
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "36%",
                bottom: "20px",
                marginLeft: "10px",
              }}
              onClick={beforeSearch}
            >
              검색
            </Button>
          </SearchBtn>
          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={() => addMusicModal({})}
          >
            등록하기
          </Button>
        </Box>
        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell flexBasis={120}>음악 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={35}>
              음악 태그
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={330}>음악명</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120}>
              저작권자
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>지속시간</Table.TextHeaderCell>
            <Table.TextHeaderCell>계약 여부</Table.TextHeaderCell>
            <Table.TextHeaderCell>음악 url</Table.TextHeaderCell>
            <Table.TextHeaderCell>음악 파일 명</Table.TextHeaderCell>
            <Table.TextHeaderCell>생성 날짜</Table.TextHeaderCell>
            {/* <Table.TextHeaderCell>업데이트 날짜</Table.TextHeaderCell> */}
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
            {/* <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell> */}
          </Table.Head>
          <Table.Body height="auto">
            {musicList.map((music) => {
              let a = music.duration / 1000;
              let { share: minute, mod: second } = getMod(a, 60);
              let { share: hour, mod: newMinute } = getMod(minute, 60);

              let minuteStr = String(newMinute).padStart(2, "0");
              let secondStr = String(second).padStart(2, "0");
              let hourStr = String(hour).padStart(2, "0");

              let _musicTagList = (music.music_tag_ids || "").split(",");

              return (
                <Table.Row
                  key={music.music_id}
                  isSelectable
                  onSelect={() => {
                    modifyMusicModal(music);
                  }}
                  height={"auto"}
                  minHeight={"60px"}
                >
                  <Table.TextCell flexBasis={120}>
                    {music.music_id}
                  </Table.TextCell>
                  <Table.TextCell flexBasis={35}>
                    {_musicTagList
                      .filter((i) => i !== "")
                      .map((item) => {
                        let a = musicTagList.find(
                          (i) => i.music_tag_id === item
                        );
                        if (a) {
                          return (
                            <InputDiv key={a.music_tag_id}>{a.title}</InputDiv>
                          );
                        } else {
                          return null; // or handle the case when the object is not found
                        }
                      })}
                  </Table.TextCell>
                  <Table.TextCell flexBasis={330}>{music.title}</Table.TextCell>
                  <Table.TextCell flexBasis={120}>
                    {music.artist}
                  </Table.TextCell>
                  <Table.TextCell>{`${hourStr}:${minuteStr}:${secondStr}`}</Table.TextCell>
                  <Table.TextCell>
                    {music.is_contract === 1 ? "계약" : "미계약"}
                  </Table.TextCell>
                  <Table.TextCell>{music.music_url}</Table.TextCell>
                  <Table.TextCell>
                    {music.music_original_filename}
                  </Table.TextCell>
                  <Table.TextCell>
                    <DateText minimum>{music.create_date}</DateText>
                  </Table.TextCell>
                  {/* <Table.TextCell>
                    <DateText minimum>{music.update_date}</DateText>
                  </Table.TextCell> */}
                  <Table.TextCell
                    flexBasis={120}
                    flexShrink={0}
                    flexGrow={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      appearance="primary"
                      intent="danger"
                      onClick={(event) => {
                        onDeleteMusic(music.music_id);
                        // event.preventDefault();
                        event.stopPropagation();
                      }}
                    >
                      삭제
                    </Button>
                  </Table.TextCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </MusicBox>
    </>
  );
};

// ModalBasic.defaultProps = {};
const SearchBtn = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;
const MusicBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const MusicH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 0px 7px 0;
`;
const InputSpan = styled.span`
  width: 120px;
  flex-shrink: 0;
  font-size: 12px;
`;
const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
`;

export default Music;
