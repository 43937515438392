const LocalStorageService = (function () {
  var _service
  function _getService() {
    if (!_service) {
      _service = this
      return _service
    }
    return _service
  }
  function _setToken(tokenObj) {
    if ( tokenObj.access_token ){
      localStorage.setItem('access_token', tokenObj.access_token)
    }
    if ( tokenObj.refresh_token ){
      localStorage.setItem('refresh_token', tokenObj.refresh_token)
    }
    if ( tokenObj.session_id ){
      localStorage.setItem('session_id', tokenObj.session_id)
    }
  }
  function _getAccessToken() {
    return localStorage.getItem('access_token')
  }
  function _getRefreshToken() {
    return localStorage.getItem('refresh_token')
  }
  function _getSessionId() {
    return localStorage.getItem('session_id')
  }
  function _clearToken() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('session_id')
  }
  function _setUser(user) {
    localStorage.setItem('user', JSON.stringify(user))
  }
  function _clearUser(user) {
    localStorage.removeItem('user')
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    getSessionId: _getSessionId,
    clearToken: _clearToken,
    setUser: _setUser,
    clearUser: _clearUser,
  }
})()
export default LocalStorageService
