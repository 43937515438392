import React, { useState, useEffect, memo } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import { useDrag, useDrop } from "react-dnd";
import { Pane, Dialog, Table, Button, Pagination, TextInput, Textarea,  } from "evergreen-ui";

const baseUrl = "/admin/content/";

const ChooseContent = ({onSelect}) => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [contentList, setContentList] = useState([]);
  const [keyword, setKeyword] = useState("");

  const openModal = () => {
    setCurrentPage(1)
    setKeyword("")
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  useEffect(()=>{
    getContentList()
  },[currentPage])

  const getContentList = async () => {
    try {
      const params = {
        limit: 10,
        offset: PAGE_SIZE * (currentPage - 1),
        keyword: keyword,
        is_inplay_advertisement: 1
      };
      // console.log(params);
      const response = await axios.get(`${baseUrl}`, { params });
      console.log("contentList", response);
      setTotalCount(response.data.count);
      setContentList(response.data.content_list)
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={()=>closeModal()}
          cancelLabel={'취소'}
          confirmLabel={'완료'}
          onConfirm={()=>{console.log("confirm")}}
          title={'컨텐츠 검색'}
          width={'1000px'}
        > 
          <TextInput
            placeholder="제목, 콘텐츠 ID, 강사명"
            width={"250px"}
            onChange={(e) => {
              const keyword = e.target.value;
              setKeyword(keyword);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                getContentList();
              }
            }}
          ></TextInput>
          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
              marginLeft: "10px",
            }}
            onClick={() => {
              getContentList();
            }}
          >
            검색
          </Button>
          <Table textAlign="center">
            <Table.Head>
              <Table.TextHeaderCell>VID</Table.TextHeaderCell>
              <Table.TextHeaderCell>타입</Table.TextHeaderCell>
              <Table.TextHeaderCell>제목</Table.TextHeaderCell>
              <Table.TextHeaderCell>설명</Table.TextHeaderCell>
              <Table.TextHeaderCell>콘텐츠이미지</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body height="auto">
              {contentList.length > 0 && contentList.map((item)=>{
                let _item = item
                return (
                <Table.Row key={_item.content_id} 
                  onClick={() => {
                    if(window.confirm(`[${_item.content_id}] ${_item.title}을(를) 선택하시겠습니까?`)){
                      onSelect(_item)
                      closeModal()
                    }
                  }}
                >
                  <Table.TextCell>{item.content_id}</Table.TextCell>
                  <Table.TextCell>{item.content_type}</Table.TextCell>
                  <Table.TextCell>{item.title}</Table.TextCell>
                  <Table.TextCell>{item.description}</Table.TextCell>
                  <Table.TextCell><ImgPreview src={item.thumbnail_image_url}/></Table.TextCell>
                </Table.Row>
              )})}
            </Table.Body>
          </Table>
          {
            <Pane
              height={120}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Pagination
                page={currentPage}
                totalPages={Math.ceil(totalCount / PAGE_SIZE)}
                onNextPage={() => {
                  setCurrentPage(currentPage + 1);
                }}
                onPreviousPage={() => {
                  setCurrentPage(currentPage - 1);
                }}
                onPageChange={(page) => setCurrentPage(page)}
              ></Pagination>
            </Pane>
          }
        </Dialog>
        <Button onClick={openModal}>컨텐츠 선택</Button>
      </Pane>
    </>
  )
}


const ImgPreview = styled.img`
  width: 200px;
`;

export default ChooseContent