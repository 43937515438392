import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
} from "evergreen-ui";

import { diff, isEmpty, getMod } from "services/util";

const baseUrl = "/admin/content/keyword/search/";

const Keyword = () => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [keywordList, setKeywordList] = useState([]);
  const [originalKewordInfo, setOriginalKeywordInfo] = useState([]);
  const [keywordInfo, setKeywordInfo] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "검색어 등록",
      action: () => {
        onAdd();
      },
    },
    MOD: {
      button: "수정하기",
      title: "검색어 수정",
      action: () => {
        onModify();
      },
    },
  };

  useEffect(() => {
    getKeywordList();
  }, []);

  const addModal = (item) => {
    openModal(item, "ADD");
  };

  const modifyModal = (item) => {
    openModal(item, "MOD");
  };

  const openModal = (item, type) => {
    setOriginalKeywordInfo(item);
    setKeywordInfo(item);
    setModalType(type);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setOriginalKeywordInfo({});
    setKeywordInfo({});
    setIsModalOpened(false);
  };

  const getKeywordList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
    };

    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.content_search_keyword_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        setKeywordList(data);
        setIsModalOpened(false);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //인풋 수정시
  const handleChange = (e) => {
    setKeywordInfo({ ...keywordInfo, [e.target.name]: e.target.value });
  };

  //등록
  const onAdd = () => {
    let params = diff(keywordInfo, originalKewordInfo);

    if (isEmpty(params)) {
      //   alert("ㅇㅇ");
      return;
    }
    if (typeof params.ordering_number === "string") {
      let a = Number(params.ordering_number);
      params.ordering_number = a;
    }

    axios
      .post(baseUrl, params)
      .then((response) => {
        console.log(response);
        alert("등록되었습니다");
        getKeywordList();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //수정
  const onModify = () => {
    let params = diff(keywordInfo, originalKewordInfo);
    if (isEmpty(params)) {
      closeModal();
      return;
    }
    if (typeof params.ordering_number === "string") {
      let a = Number(params.ordering_number);
      params.ordering_number = a;
    }
    axios
      .put(`${baseUrl}${keywordInfo.keyword_id}`, params)
      .then((response) => {
        console.log(response);
        alert("수정되었습니다");
        getKeywordList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //삭제
  const onDelete = (keyword_id) => {
    if (window.confirm("삭제하시겠습니까?")) {
      axios
        .delete(`${baseUrl}${keyword_id}`)
        .then((response) => {
          console.log(response);
          alert("삭제되었습니다");
          getKeywordList();
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
        });
    }
  };
  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={modalTypeOption[modalType].button}
          onConfirm={modalTypeOption[modalType].action}
          hasClose={false}
          title={modalTypeOption[modalType].title}
        >
          {modalType === "MOD" && (
            <InputDiv>
              <InputSpan>검색어 ID</InputSpan>
              <TextInput
                disabled
                name="keyword"
                defaultValue={keywordInfo.keyword}
                onChange={handleChange}
              ></TextInput>
            </InputDiv>
          )}
          <InputDiv>
            <InputSpan>검색어</InputSpan>
            <TextInput
              name="keyword"
              defaultValue={keywordInfo.keyword}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>순서</InputSpan>
            <TextInput
              type="number"
              name="ordering_number"
              defaultValue={keywordInfo.ordering_number}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
        </Dialog>
      </Pane>

      <KeywordBox>
        <Pane>
          <KeywordH1>검색어 관리</KeywordH1>
        </Pane>
        <Button
          appearance="primary"
          intent="none"
          style={{
            position: "inherit",
            left: "36%",
            bottom: "20px",
          }}
          onClick={() => addModal({})}
        >
          등록하기
        </Button>
        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell>검색어 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>검색어</Table.TextHeaderCell>
            <Table.TextHeaderCell>순서</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {keywordList.map((item) => {
              return (
                <Table.Row
                  key={item.keyword_id}
                  isSelectable
                  onSelect={() => {
                    modifyModal(item);
                  }}
                  height={"auto"}
                  minHeight={"60px"}
                >
                  <Table.TextCell>{item.keyword_id}</Table.TextCell>
                  <Table.TextCell>{item.keyword}</Table.TextCell>
                  <Table.TextCell>{item.ordering_number}</Table.TextCell>
                  <Table.TextCell
                    flexBasis={120}
                    flexShrink={0}
                    flexGrow={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      appearance="primary"
                      intent="danger"
                      onClick={(event) => {
                        onDelete(item.keyword_id);
                        event.stopPropagation();
                      }}
                    >
                      삭제
                    </Button>
                  </Table.TextCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </KeywordBox>
    </>
  );
};

// ModalBasic.defaultProps = {};
const KeywordBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const KeywordH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 0px 7px 0;
`;
const InputSpan = styled.span`
  width: 120px;
  flex-shrink: 0;
  font-size: 12px;
`;

export default Keyword;
