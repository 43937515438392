import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Pagination,
  Dialog,
  TagInput,
  RadioGroup,
  TextInput,
  Button,
  Checkbox,
} from "evergreen-ui";
import { diff, isEmpty, getMod } from "services/util";
import ItemSearch from "components/ItemSearch";

const baseUrl = "/admin/user/";
const groupUrl = "/admin/group";
const itemUrl = "/admin/item";

function User() {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isModalOpened2, setIsModalOpened2] = useState(false);
  const [originalUserInfo, setOriginalUserInfo] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  // const [userItem, setUserItem] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [group, setGroup] = useState(null);

  const [itemList, setItemList] = useState([]);
  const [itemIds, setItemIds] = useState([]);
  const [userList, setUserList] = useState([]);

  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");

  const [itemKeyword, setItemKeyword] = useState("");
  const [userItemInfo, setUserItemInfo] = useState("");
  const [userId, setUserId] = useState("");
  const [checked, setChecked] = React.useState(false);

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "회원 등록",
      action: () => {
        // onAddUser();
      },
    },
    MOD: {
      button: "수정하기",
      title: "회원 수정",
      action: () => {
        handleSubmit();
      },
    },
  };

  const [tabOptions] = React.useState([
    { label: "동의", value: "1" },
    { label: "미동의", value: "0" },
  ]);

  useEffect(() => {
    list();
    getGroupList();
    getItemList();
  }, [currentPage]);

  useEffect(() => {
    if (checked === true) {
      setUserInfo({ ...userInfo, is_change_password: 1 });
    } else {
      delete userInfo.is_change_password;
    }
  }, [checked]);

  const modifyUserModal = (user) => {
    openModal(user, "MOD");
  };

  const openModal = async (user, type) => {
    await getGroupList();
    // await getUserItem(user.user_id);

    setOriginalUserInfo(user);
    setUserInfo(user);
    setModalType(type);
    setIsModalOpened(true);
    // getUserIdList();
  };

  const openModal2 = async (item) => {
    setIsModalOpened2(true);
    await getUserItem(item.user_id);
    setUserId(item.user_id);
  };

  const closeModal = () => {
    setOriginalUserInfo({});
    setUserInfo({});
    setIsModalOpened(false);
    list();
    setChecked(false);
  };

  const closeModal2 = () => {
    setIsModalOpened2(false);
    setChecked(false);
  };

  //인풋 수정시
  const handleChange = (e) => {
    // console.log(e);
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const getUserItem = async (user_id) => {
    // let TT = "";
    try {
      const res = await axios.get(`${baseUrl}${user_id}/item`);
      console.log("유저 아이템 리스트", res);
      const content = res.data.user_item_list;
      setUserItemInfo(content);

      setItemIds(content.map((i) => i.item_id));
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
      return {};
    }
    // return TT;
  };

  //정규식 확인
  const handleSubmit = () => {
    const nicknameRegex = /^[a-zA-Z\d가-힣]{2,12}$/; // 닉네임 정규식
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // 이메일 정규식

    const isNicknameValid = nicknameRegex.test(userInfo.nickname);
    const isEmailValid = emailRegex.test(userInfo.email);

    if (!isNicknameValid) {
      alert(
        "2~12자리의 영문자(대소문자), 숫자, 한글로 이루어진 문자열을 입력해주세요"
      );
    }

    if (!isEmailValid) {
      alert("올바른 이메일 형식을 입력해주세요");
    }
    if (isNicknameValid && isEmailValid) {
      onModifyUser();
    }
    return {
      isNicknameValid,
      isEmailValid,
    };
  };

  const onModifyUser = async () => {
    // if (checked === true) {
    //   console.log("수정하기 전 true check");
    // }
    // console.log(userInfo);

    let params = diff(userInfo, originalUserInfo);

    console.log(params);

    if (isEmpty(params)) {
      alert("변경 사항이 없습니다");
      // closeModal();
      return;
    }

    // let a = params.item_id.toString();
    // params.item_id = a;
    // return;
    // console.log(originalUserInfo);
    await axios
      .put(`${baseUrl}${userInfo.user_id}`, params)
      .then((response) => {
        console.log(response);
        alert("수정되었습니다");
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  const getGroupList = async () => {
    try {
      const response = await axios.get(groupUrl);
      const data = response.data.group_list;
      setGroupList(data);

      const newData = Object.fromEntries(
        data.map(({ group_code, title }) => [group_code, title])
      );
      setGroup(newData);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };
  const getItemList = async () => {
    const params = {
      keyword: "ADMIN",
    };
    try {
      const response = await axios.get(itemUrl, { params });
      // console.log(response);
      setItemList(response.data.item_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const setGroupInfo = (profile) => {
    if (group === null) {
      getGroupList();
    } else {
      const CategoryName = group[profile.group_codes];
      return CategoryName;
    }
  };

  const getUserInfo = async (user_id) => {
    // let test = await getUserItem(user_id);
    console.log("TEST", user_id);

    try {
      const res = await axios.get(`${baseUrl}/${user_id}`);
      console.log("getUserInfo", res);
      const data = res.data.user;

      let newContent = {};
      if (data.group_codes === null) {
        newContent = {
          ...data,
        };
        // newContent.user_item_id = test;
      } else {
        newContent = {
          ...data,
          _group_codes: data.group_codes.split(",").map((i) => Number(i)),
        };
        // newContent.user_item_id = test;
      }

      console.log("newContent", newContent);
      return newContent;
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
      return {};
    }
  };

  const handleClick = async () => {
    await getUserItem(userId);
    // await getUserItem(userItemInfo[0].user_id);
  };

  const beforeList = () => {
    setCurrentPage(1);

    list();
  };

  const list = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: itemKeyword,
    };
    axios
      .get(`${baseUrl}`, { params })
      .then(function (response) {
        console.log("userList", response);
        setTotalCount(response.data.count);
        setUserList(response.data.user_list);
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  const getTagInputTitle = (
    originalUserInfo,
    userInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    // console.log(propName, userInfo[propName]);
    // console.log(fullList, "fullList");
    const titleList =
      userInfo[propName] &&
      userInfo[propName].map((item) => {
        return fullList.find((listItem) => listItem[listPropKey] === item)[
          listPropName
        ];
      });
    return titleList;
  };

  const setTagInputValue = (
    originalUserInfo,
    userInfo,
    propName,
    value,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let newUserInfo = { ...userInfo };
    newUserInfo[propName] = value
      .map((valueItem) => {
        let obj = fullList.find((item) => item[listPropName] === valueItem);
        return obj ? obj[listPropKey] : null;
      })
      .filter((item) => item !== null);

    const add_prop_name = `add${propName}`;
    const delete_prop_name = `delete${propName}`;

    const added = newUserInfo[propName]
      ? newUserInfo[propName].filter((i) =>
          originalUserInfo[propName]
            ? !originalUserInfo[propName].includes(i)
            : true
        )
      : [];
    const deleted = originalUserInfo[propName]
      ? originalUserInfo[propName].filter((i) =>
          newUserInfo[propName] ? !newUserInfo[propName].includes(i) : true
        )
      : [];

    newUserInfo[add_prop_name] = added;
    newUserInfo[delete_prop_name] = deleted;

    setUserInfo(newUserInfo);
  };

  const tagInputAutoCompleteList = (
    userInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let data = fullList
      .filter(
        (item) =>
          !(
            userInfo[propName] && userInfo[propName].includes(item[listPropKey])
          )
      )
      .map((item) => item[listPropName]);
    return data;
  };

  const onSetItemIds = useCallback((item_id) => {
    setItemIds((prev) => {
      console.log(prev, item_id);
      setUserInfo((prev) => {
        let newContentInfo = {
          ...prev,
          item_id: item_id,
        };
        return newContentInfo;
      }, []);
      return item_id;
    }, []);
  }, []);

  function renderMethod(value) {
    switch (value) {
      case "PAYMENT":
        return "구매";
      case "ADMIN":
        return "관리자 부여";
      case "ADDITIONAL":
        return "이용권 구매 시 증정";
      default:
        return value;
    }
  }

  function renderState(value) {
    switch (value) {
      case "USABLE":
        return "사용 가능";
      case "USING":
        return "사용중";
      case "USED":
        return "사용 완료";
      default:
        return value;
    }
  }

  return (
    <>
      <Pane>
        <Dialog
          width={"900px"}
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={modalTypeOption[modalType].button}
          onConfirm={modalTypeOption[modalType].action}
          hasClose={false}
          title={modalTypeOption[modalType].title}
        >
          <InputDiv>
            <InputSpan>회원 ID</InputSpan>
            <TextInput
              disabled
              width="280px"
              name="user_id"
              defaultValue={userInfo.user_id}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>핸드폰 번호</InputSpan>
            <TextInput
              disabled
              name="cell_phone"
              defaultValue={userInfo.cell_phone}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>닉네임</InputSpan>
            <TextInput
              // disabled
              maxLength={12}
              minLength={2}
              name="nickname"
              defaultValue={userInfo.nickname}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>가입 방식</InputSpan>
            <TextInput
              disabled
              name="register_type"
              defaultValue={userInfo.register_type}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>생년월일</InputSpan>
            <TextInput
              disabled
              name="birth_date"
              defaultValue={userInfo.birth_date}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>성별</InputSpan>
            <TextInput
              disabled
              name="gender"
              defaultValue={userInfo.gender}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>이메일</InputSpan>
            <TextInput
              // disabled
              name="email"
              defaultValue={userInfo.email}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>키</InputSpan>
            <TextInput
              disabled
              width={"140px"}
              marginRight="6px"
              name="body_height"
              defaultValue={userInfo.body_height}
              onChange={handleChange}
            ></TextInput>
            cm
          </InputDiv>
          <InputDiv>
            <InputSpan>몸무게</InputSpan>
            <TextInput
              disabled
              width={"140px"}
              marginRight="6px"
              name="body_weight"
              defaultValue={userInfo.body_weight}
              onChange={handleChange}
            ></TextInput>
            kg
          </InputDiv>
          <InputDiv>
            <InputSpan>이벤트 및 혜택/정보 수신 동의 여부</InputSpan>
            <RadioGroup
              style={{ pointerEvents: "none" }}
              display="flex"
              columnGap="10px"
              size={16}
              name="is_agree_event_terms"
              options={tabOptions}
              value={String(userInfo.is_agree_event_terms)}
              defaultValue={String(userInfo.is_agree_event_terms)}
              onChange={(e) => {
                handleChange(e);
              }}
            ></RadioGroup>
          </InputDiv>
          <InputDiv>
            <InputSpan>서비스 알림 동의 여부</InputSpan>
            <RadioGroup
              style={{ pointerEvents: "none" }}
              display="flex"
              columnGap="10px"
              size={16}
              name="is_agree_service_noti"
              options={tabOptions}
              value={String(userInfo.is_agree_service_noti)}
              defaultValue={String(userInfo.is_agree_service_noti)}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>sms 수신 동의 여부</InputSpan>
            <RadioGroup
              style={{ pointerEvents: "none" }}
              display="flex"
              columnGap="10px"
              size={16}
              name="is_agree_receive_sms"
              options={tabOptions}
              value={String(userInfo.is_agree_receive_sms)}
              defaultValue={String(userInfo.is_agree_receive_sms)}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>이메일 수신 동의 여부</InputSpan>
            <RadioGroup
              style={{ pointerEvents: "none" }}
              display="flex"
              columnGap="10px"
              size={16}
              name="is_agree_receive_email"
              options={tabOptions}
              value={String(userInfo.is_agree_receive_email)}
              defaultValue={String(userInfo.is_agree_receive_email)}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputDiv>
          {modalType === "MOD" && (
            <InputDiv>
              <InputSpan>비밀번호 초기화</InputSpan>
              <Checkbox
                name="is_change_password"
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
            </InputDiv>
          )}
          <InputDiv>
            <InputSpan>add_group_codes</InputSpan>
            <TagInput
              inputProps={{ placeholder: "그룹을 검색해주세요" }}
              values={getTagInputTitle(
                originalUserInfo,
                userInfo,
                "_group_codes",
                groupList,
                "group_code",
                "title"
              )}
              onChange={(e) => {
                setTagInputValue(
                  originalUserInfo,
                  userInfo,
                  "_group_codes",
                  e,
                  groupList,
                  "group_code",
                  "title"
                );
              }}
              autocompleteItems={tagInputAutoCompleteList(
                userInfo,
                "_group_codes",
                groupList,
                "group_code",
                "title"
              )}
            />
          </InputDiv>
          {/* <InputDiv>
            <InputSpan>이용권 관리</InputSpan>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <ItemSearch
                handleClick={handleClick}
                userInfo={userInfo}
                userItemInfo={userItemInfo}
                userItemIds={itemIds}
                initialFullItemList={itemList}
                onSetItemIds={onSetItemIds}
              />
            </div>
          </InputDiv> */}
        </Dialog>
      </Pane>

      <Pane>
        <Dialog
          width={"900px"}
          isShown={isModalOpened2}
          onCloseComplete={() => closeModal2()}
          confirmLabel={"닫기"}
          onConfirm={() => {
            closeModal2();
          }}
          hasCancel={false}
          hasClose={true}
          title={"이용권"}
        >
          <InputDiv>
            <InputSpan>이용권 관리</InputSpan>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <ItemSearch
                handleClick={handleClick}
                userId={userId}
                userInfo={userInfo}
                userItemInfo={userItemInfo}
                userItemIds={itemIds}
                initialFullItemList={itemList}
                onSetItemIds={onSetItemIds}
              />
            </div>
          </InputDiv>
        </Dialog>
      </Pane>

      <UserBox>
        <Pane>
          <TitleBox>
            <UserH1>회원 목록</UserH1>
          </TitleBox>
        </Pane>
        <Box>
          <SearchBtn>
            <TextInput
              placeholder="ID, 핸드폰번호, 닉네임, 이메일"
              width={"250px"}
              onChange={(e) => {
                const keyword = e.target.value;
                setItemKeyword(keyword);
              }}
            ></TextInput>
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "36%",
                bottom: "20px",
                marginLeft: "10px",
              }}
              onClick={beforeList}
            >
              검색
            </Button>
          </SearchBtn>
        </Box>

        <Table width="95%" textAlign="center">
          <Table.Head>
            {/* <Table.SearchHeaderCell
              flexBasis={120}
              flexShrink={0}
              flexGrow={0}
              onChange={(value) => {
                searchUser(value);
                console.log(value);
              }}
            /> */}
            <Table.TextHeaderCell>회원 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>이메일</Table.TextHeaderCell>
            <Table.TextHeaderCell>닉네임</Table.TextHeaderCell>
            <Table.TextHeaderCell>핸드폰 번호</Table.TextHeaderCell>
            <Table.TextHeaderCell>그룹</Table.TextHeaderCell>
            <Table.TextHeaderCell>이용권</Table.TextHeaderCell>
            {/* <Table.TextHeaderCell>이용권 종류</Table.TextHeaderCell>
            <Table.TextHeaderCell>이용권 상태</Table.TextHeaderCell> */}
            {/* <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell> */}
          </Table.Head>
          <Table.Body height="auto">
            {userList.length === 0 ? (
              <div style={{ margin: "40px 0" }}>
                검색 결과가 존재하지 않습니다
              </div>
            ) : (
              userList.map((profile) => {
                return (
                  <Table.Row
                    key={profile.user_id}
                    isSelectable
                    onSelect={async () => {
                      const _profile = await getUserInfo(profile.user_id);
                      console.log(profile.group_codes.split(","));
                      modifyUserModal(_profile);
                    }}
                  >
                    {/* <Table.TextCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                ></Table.TextCell> */}
                    <Table.TextCell>{profile.user_id}</Table.TextCell>
                    <Table.TextCell>{profile.email}</Table.TextCell>
                    <Table.TextCell>{profile.nickname}</Table.TextCell>
                    {/* <Table.TextCell>{profile.group_codes}</Table.TextCell> */}
                    <Table.TextCell>{profile.cell_phone}</Table.TextCell>

                    <Table.TextCell>
                      {/* {setGroupInfo(profile)}
                       */}
                      {profile.group_codes
                        ? profile.group_codes
                            .split(",")
                            .map((code) => {
                              // console.log(groupList);
                              let selectedGroup = groupList.find(
                                (group) => group.group_code.toString() === code
                              );
                              // console.log(selectedGroup.title);
                              return selectedGroup ? selectedGroup.title : "";
                            })
                            .join(", ")
                        : "그룹 없음"}
                    </Table.TextCell>

                    <Table.TextCell>
                      <Button
                        onClick={(event) => {
                          openModal2(profile);
                          console.log(profile.group_codes);
                          event.stopPropagation();
                        }}
                      >
                        Click
                      </Button>
                    </Table.TextCell>
                    {/* <Table.TextCell>
                  {renderMethod(profile.item_method)}
                </Table.TextCell>
                <Table.TextCell>
                  {renderState(profile.item_state)}
                </Table.TextCell>{" "} */}

                    {/* <Table.TextCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    appearance="primary"
                    intent="danger"
                    onClick={(event) => {
                      deleteUser(profile.user_id);
                      // event.preventDefault();
                      event.stopPropagation();
                    }}
                  >
                    삭제
                  </Button>
                </Table.TextCell> */}
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        </Table>
        {/* <PaginationBar
          postsPerPage={10}
          totalPosts={userList.length}
          paginate={setCurrentPage}
        ></PaginationBar> */}
        {
          <Pane
            height={120}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Pagination
              page={currentPage}
              totalPages={Math.ceil(totalCount / PAGE_SIZE)}
              onNextPage={() => {
                setCurrentPage(currentPage + 1);
              }}
              onPreviousPage={() => {
                setCurrentPage(currentPage - 1);
              }}
              onPageChange={(page) => setCurrentPage(page)}
            ></Pagination>
          </Pane>
        }
      </UserBox>
    </>
  );
}
const UserBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const UserH1 = styled.h1`
  margin: 50px 50px 20px 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 240px;
  font-size: 12px;
`;
const SearchSpan = styled.span`
  margin-right: 10px;
`;
const TitleBox = styled.div`
  position: relative;
`;
const SearchBtn = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;
const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
`;
export default User;
