import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
} from "evergreen-ui";
import DateText from "components/DateText";
import { diff, isEmpty } from "services/util";

const baseUrl = "/admin/group";

const Group = () => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [originalGroupInfo, setOriginalGroupInfo] = useState({});
  const [groupInfo, setGroupInfo] = useState({});
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState(null);

  const [modalType, setModalType] = useState("ADD");

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "그룹 등록",
      action: () => {
        onAddGroup();
      },
    },
    MOD: {
      button: "수정하기",
      title: "그룹 수정",
      action: () => {
        onModifyGroup();
      },
    },
  };

  useEffect(() => {
    getGroupList();
  }, [currentPage]);

  const beforeGetGroupList = () => {
    setCurrentPage(1);

    getGroupList();
  };

  const getGroupList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: searchKeyword,
    };
    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.group_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        setGroupList(data);
        setIsModalOpened(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const addGroupModal = (group) => {
    openModal(group, "ADD");
  };

  const modifyGroupModal = (group) => {
    openModal(group, "MOD");
  };

  const openModal = (group, type) => {
    // console.log("group",group)
    setModalType(type);
    setOriginalGroupInfo(group);
    setGroupInfo(group);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setOriginalGroupInfo({});
    setGroupInfo({});
    setIsModalOpened(false);
  };

  const onAddGroup = () => {
    let params = diff(groupInfo, originalGroupInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }

    axios
      .post(baseUrl, params)
      .then((res) => {
        console.log(res);
        alert("그룹을 등록하였습니다.");
        getGroupList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const onModifyGroup = () => {
    let params = diff(groupInfo, originalGroupInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }

    axios
      .put(`${baseUrl}/${groupInfo.group_code}`, params)
      .then((res) => {
        alert("그룹을 수정하였습니다.");
        getGroupList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const onDeleteGroup = (group_code) => {
    if (!window.confirm("그룹을 삭제하겠습니까?")) {
      closeModal();
      return;
    }
    const params = { group_code: group_code };

    axios
      .delete(`${baseUrl}/${group_code}`)
      .then(() => {
        alert("그룹이 삭제되었습니다.");
        getGroupList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const handleChange = (e) => {
    setGroupInfo({ ...groupInfo, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={`${modalTypeOption[modalType].button}`}
          onConfirm={modalTypeOption[modalType].action}
          hasCancel={false}
          hasClose={false}
          title={`${modalTypeOption[modalType].title}`}
        >
          <InputDiv>
            <InputSpan>그룹명</InputSpan>
            <TextInput
              maxLength={30}
              name="title"
              onChange={handleChange}
              defaultValue={originalGroupInfo.title}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>사용처</InputSpan>
            <TextInput
              maxLength={100}
              name="description"
              onChange={handleChange}
              defaultValue={originalGroupInfo.description}
            />
          </InputDiv>
        </Dialog>
      </Pane>
      <GroupBox>
        <Pane>
          <GroupH1>그룹 목록</GroupH1>
        </Pane>

        <Box>
          <SearchBtn>
            <TextInput
              placeholder="그룹명"
              width={"250px"}
              onChange={(e) => {
                const keyword = e.target.value;
                setSearchKeyword(keyword);
              }}
            ></TextInput>
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "36%",
                bottom: "20px",
                marginLeft: "10px",
              }}
              onClick={beforeGetGroupList}
            >
              검색
            </Button>
          </SearchBtn>

          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={() => addGroupModal({})}
          >
            등록하기
          </Button>
        </Box>

        <Table width="95%" textAlign="center" marginTop="20px">
          <Table.Head>
            <Table.TextHeaderCell>그룹 코드</Table.TextHeaderCell>
            <Table.TextHeaderCell>그룹명</Table.TextHeaderCell>
            <Table.TextHeaderCell>사용처</Table.TextHeaderCell>
            <Table.TextHeaderCell>회원 수</Table.TextHeaderCell>
            <Table.TextHeaderCell>생성 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
            {/* <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell> */}
          </Table.Head>
          <Table.Body height="auto">
            {groupList.map((group) => (
              <Table.Row
                key={group.group_code}
                isSelectable
                onSelect={() => {
                  modifyGroupModal(group);
                }}
              >
                <Table.TextCell>{group.group_code}</Table.TextCell>
                <Table.TextCell>{group.title}</Table.TextCell>
                <Table.TextCell>{group.description}</Table.TextCell>
                <Table.TextCell>{group.user_count}</Table.TextCell>
                <Table.TextCell>
                  <DateText>{group.create_date}</DateText>
                </Table.TextCell>
                <Table.TextCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {group.group_code === 1 || group.group_code === 2 ? (
                    <Button disabled>삭제</Button>
                  ) : (
                    <Button
                      appearance="primary"
                      intent="danger"
                      onClick={(event) => {
                        onDeleteGroup(group.group_code);
                        // event.preventDefault();
                        event.stopPropagation();
                      }}
                    >
                      삭제
                    </Button>
                  )}
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </GroupBox>
    </>
  );
};

// ModalBasic.defaultProps = {};
const GroupBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const GroupH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;
const SearchBtn = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
`;
export default Group;
