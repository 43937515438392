import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  RadioGroup,
  Pagination,
  Textarea,
} from "evergreen-ui";
import { diff, isEmpty } from "services/util";
import ItemAdd from "components/ItemAdd";

const baseUrl = "/admin/item";

const Item = () => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [originalItemInfo, setOriginalItemInfo] = useState({});
  const [itemInfo, setItemInfo] = useState({});
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [modalType, setModalType] = useState("ADD");

  const [itemInfoList, setItemInfoList] = useState([]);
  const [itemIds, setItemIds] = useState([]);
  const [itemType, setItemType] = useState("");
  const [itemKeyword, setItemKeyword] = useState("");
  const [filter, setFilter] = useState("");
  const [itemtype, setItemtype] = useState("");

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "이용권 등록",
      action: () => {
        onAddItem();
      },
    },
    MOD: {
      button: "수정하기",
      title: "이용권 수정",
      action: () => {
        onModifyItem();
      },
    },
  };

  const [sectionIsShowOption] = React.useState([
    { label: "사용", value: "1" },
    { label: "미사용", value: "0" },
  ]);
  const [typeOptions] = React.useState([
    { label: "구독권", value: "SUBSCRIPTION" },
    { label: "횟수권", value: "TICKET" },
  ]);
  const [methodOptions] = React.useState([
    { label: "구매", value: "PAYMENT" },
    { label: "관리자 부여", value: "ADMIN" },
    { label: "이용권 구매 시 증정", value: "ADDITIONAL" },
  ]);
  const [unitOptions] = React.useState([
    // { label: "회", value: "T" },
    { label: "일", value: "D" },
    { label: "월", value: "M" },
    { label: "년", value: "Y" },
  ]);

  const [filterOption] = React.useState([
    { label: "전체", value: "" },
    { label: "사용", value: "1" },
    { label: "미사용", value: "0" },
  ]);
  const [filterType] = React.useState([
    { label: "전체", value: "" },
    { label: "구독권", value: "SUBSCRIPTION" },
    { label: "횟수권", value: "TICKET" },
  ]);
  const [filterMethod] = React.useState([
    { label: "전체", value: "" },
    { label: "구매", value: "PAYMENT" },
    { label: "관리자 부여", value: "ADMIN" },
    { label: "이용권 구매 시 증정", value: "ADDITIONAL" },
  ]);
  useEffect(() => {
    getItemList();
  }, [currentPage, filter, itemtype, itemKeyword]);

  const beforeGetItemList = (e) => {
    setCurrentPage(1);
    setFilter(e);
  };
  const beforeGetItemTypeList = (e) => {
    setCurrentPage(1);
    setItemtype(e);
  };
  const beforeGetItemMethodList = (e) => {
    setCurrentPage(1);
    setItemKeyword(e);
  };

  const getItemList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: itemKeyword,
      is_show: filter,
      item_type: itemtype,
    };
    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.item_list;
        const dataCount = res.data.count;
        setItemInfoList(res.data.item_list);

        setTotalCount(dataCount);
        setItemList(data);
        setIsModalOpened(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };
  const getItemInfo = async (item_id) => {
    try {
      const res = await axios.get(`${baseUrl}/${item_id}`);
      console.log(res);
      const data = res.data.item;
      // setMusicList(.data.music_list);/

      let newContent = {
        ...data,
      };
      setItemIds([data.additional_item_id]);

      //   console.log(newContent);
      return newContent;
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
      return {};
    }
  };

  const onSetItemIds = useCallback((item_id) => {
    setItemIds((prev) => {
      console.log(prev, item_id);
      setItemInfo((prev) => {
        let newContentInfo = {
          ...prev,
          additional_item_id: item_id,
        };
        return newContentInfo;
      }, []);
      return item_id;
    }, []);
  }, []);

  const addItemModal = (item) => {
    openModal(item, "ADD");
  };

  const modifyItemModal = (item) => {
    openModal(item, "MOD");
  };

  const openModal = (item, type) => {
    console.log("item", item);

    // setItemType(item.item_type);
    setModalType(type);
    setOriginalItemInfo(item);
    setItemInfo(item);
    setIsModalOpened(true);

    if (type === "ADD") {
      setItemInfo({
        item_type: "SUBSCRIPTION",
        method: "PAYMENT",
        apply_unit: "D",
      });
      setItemIds([]);
    } else {
      setItemInfo(item);
    }
  };

  const closeModal = () => {
    setOriginalItemInfo({});
    setItemInfo({});
    setIsModalOpened(false);
  };

  const onAddItem = () => {
    let params = diff(itemInfo, originalItemInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }

    if (typeof params.apply_value === "string") {
      let a = Number(params.apply_value);
      params.apply_value = a;
    }
    if (typeof params.price === "string") {
      let a = Number(params.price);
      params.price = a;
    }
    if (params.apply_value === 12 && params.apply_unit === "M") {
      alert(
        "'12'와 '월' 은 동시에 입력할 수 없습니다 \n'1년'으로 입력해주세요"
      );
      return;
    }
    if (params.item_type === "TICKET") {
      params.apply_unit = "T";
    }
    if (
      typeof params.additional_item_id === "object" &&
      params.additional_item_id.length >= 1
    ) {
      let a = params.additional_item_id[0].toString();
      params.additional_item_id = a;
    }

    console.log(params);
    // return;
    axios
      .post(baseUrl, params)
      .then((res) => {
        alert("이용권을 등록하였습니다.");
        getItemList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const onModifyItem = () => {
    let params = diff(itemInfo, originalItemInfo);
    console.log(params);

    if (isEmpty(params)) {
      alert("변경사항이 없습니다");
      return;
    }
    if (typeof params.apply_value === "string") {
      let a = Number(params.apply_value);
      params.apply_value = a;
    }
    if (typeof params.price === "string") {
      let a = Number(params.price);
      params.price = a;
    }
    if (typeof params.is_show === "string") {
      let a = Number(params.is_show);
      params.is_show = a;
    }
    if (!params.additional_item_id) {
    } else {
      let a = params.additional_item_id.toString();
      params.additional_item_id = a;
    }

    console.log(params);

    axios
      .put(`${baseUrl}/${itemInfo.item_id}`, params)
      .then((res) => {
        console.log(res);
        alert("이용권을 수정하였습니다.");
        getItemList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const handleChange = (e) => {
    setItemInfo({ ...itemInfo, [e.target.name]: e.target.value });
  };

  function renderType(value) {
    switch (value) {
      case "SUBSCRIPTION":
        return "구독권";
      case "TICKET":
        return "횟수권";
      default:
        return value;
    }
  }
  function renderMethod(value) {
    switch (value) {
      case "PAYMENT":
        return "구매";
      case "ADMIN":
        return "관리자 부여";
      case "ADDITIONAL":
        return "이용권 구매 시 증정";
      default:
        return value;
    }
  }
  function renderUnit(value) {
    switch (value) {
      case "T":
        return "회";
      case "D":
        return "일";
      case "M":
        return "월";
      case "Y":
        return "년";
      default:
        return value;
    }
  }

  function numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <Pane>
        <Dialog
          width="900px"
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={`${modalTypeOption[modalType].button}`}
          onConfirm={modalTypeOption[modalType].action}
          hasCancel={false}
          hasClose={false}
          title={`${modalTypeOption[modalType].title}`}
        >
          {/* 등록 */}
          {modalType === "ADD" ? (
            <div>
              <InputDiv>
                <InputSpan>이용권 타입</InputSpan>
                <RadioGroup
                  display="flex"
                  columnGap="10px"
                  size={16}
                  name="item_type"
                  options={typeOptions}
                  value={itemInfo.item_type}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </InputDiv>

              {/* 등록 + TICKET */}
              {itemInfo.item_type === "TICKET" ? (
                <div>
                  <InputDiv>
                    <InputSpan>획득 방식</InputSpan>
                    <RadioGroup
                      display="flex"
                      columnGap="10px"
                      size={16}
                      name="method"
                      options={methodOptions}
                      value={itemInfo.method}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </InputDiv>
                  <InputDiv>
                    <InputSpan>기간</InputSpan>
                    <TextInput
                      width="80px"
                      marginRight="10px"
                      name="apply_value"
                      defaultValue={itemInfo.apply_value}
                      onChange={handleChange}
                    ></TextInput>
                    회
                  </InputDiv>
                </div>
              ) : (
                // 등록 + SUBSCRIBETION
                <div>
                  <InputDiv>
                    <InputSpan>획득 방식</InputSpan>
                    <RadioGroup
                      display="flex"
                      columnGap="10px"
                      size={16}
                      name="method"
                      options={methodOptions}
                      value={itemInfo.method}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </InputDiv>
                  <InputDiv>
                    <InputSpan>기간</InputSpan>
                    <TextInput
                      width="80px"
                      marginRight="10px"
                      name="apply_value"
                      defaultValue={itemInfo.apply_value}
                      onChange={handleChange}
                    ></TextInput>
                    <RadioGroup
                      display="flex"
                      columnGap="10px"
                      size={16}
                      name="apply_unit"
                      options={unitOptions}
                      value={itemInfo.apply_unit}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </InputDiv>
                </div>
              )}
              <InputDiv>
                <InputSpan>가격</InputSpan>
                <TextInput
                  name="price"
                  defaultValue={itemInfo.price}
                  onChange={handleChange}
                ></TextInput>
              </InputDiv>
              <InputDiv>
                <InputSpan>이용권명</InputSpan>
                <TextInput
                  name="title"
                  defaultValue={itemInfo.title}
                  onChange={handleChange}
                ></TextInput>
              </InputDiv>
              <InputDiv>
                <InputSpan>부제</InputSpan>
                <TextInput
                  name="subtitle"
                  onChange={handleChange}
                  defaultValue={itemInfo.subtitle}
                ></TextInput>
              </InputDiv>
              <InputDiv>
                <InputSpan>설명</InputSpan>
                <Textarea
                  width="280px"
                  name="description"
                  onChange={handleChange}
                  defaultValue={itemInfo.description}
                ></Textarea>
              </InputDiv>
              {itemInfo.method === "PAYMENT" ? (
                <InputDiv>
                  <InputSpan>증정할 이용권 ID</InputSpan>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                    }}
                  >
                    <ItemAdd
                      itemType={itemType}
                      itemIds={itemIds}
                      initialFullItemList={itemInfoList}
                      onSetItemIds={onSetItemIds}
                    />
                  </div>
                </InputDiv>
              ) : null}
            </div>
          ) : (
            //   수정
            //TICKET
            <div>
              <InputDiv>
                <InputSpan>이용권 타입</InputSpan>
                <TextInput
                  disabled
                  name="item_type"
                  defaultValue={renderType(itemInfo.item_type)}
                  onChange={handleChange}
                ></TextInput>
              </InputDiv>
              <InputDiv>
                <InputSpan>획득 방식</InputSpan>
                <TextInput
                  disabled
                  name="method"
                  defaultValue={renderMethod(itemInfo.method)}
                  onChange={handleChange}
                ></TextInput>
              </InputDiv>
              {itemInfo.item_type === "TICKET" ? (
                <div>
                  <InputDiv>
                    <InputSpan>기간</InputSpan>
                    <TextInput
                      width="80px"
                      marginRight="10px"
                      name="apply_value"
                      defaultValue={itemInfo.apply_value}
                      onChange={handleChange}
                    ></TextInput>
                    회
                  </InputDiv>
                </div>
              ) : (
                //   tiem_type === SUBSCRIBE
                <div>
                  <InputDiv>
                    <InputSpan>기간</InputSpan>
                    <TextInput
                      width="80px"
                      marginRight="10px"
                      name="apply_value"
                      defaultValue={itemInfo.apply_value}
                      onChange={handleChange}
                    ></TextInput>
                    <RadioGroup
                      display="flex"
                      columnGap="10px"
                      size={16}
                      name="apply_unit"
                      options={unitOptions}
                      value={itemInfo.apply_unit}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </InputDiv>
                </div>
              )}
              <InputDiv>
                <InputSpan>가격</InputSpan>
                <TextInput
                  name="price"
                  defaultValue={itemInfo.price}
                  onChange={handleChange}
                ></TextInput>
              </InputDiv>
              <InputDiv>
                <InputSpan>이용권명</InputSpan>
                <TextInput
                  name="title"
                  defaultValue={itemInfo.title}
                  onChange={handleChange}
                ></TextInput>
              </InputDiv>
              <InputDiv>
                <InputSpan>부제</InputSpan>
                <TextInput
                  name="subtitle"
                  onChange={handleChange}
                  defaultValue={itemInfo.subtitle}
                ></TextInput>
              </InputDiv>
              <InputDiv>
                <InputSpan>설명</InputSpan>
                <Textarea
                  width="280px"
                  name="description"
                  onChange={handleChange}
                  defaultValue={itemInfo.description}
                ></Textarea>
              </InputDiv>
              <InputDiv>
                <InputSpan>IOS</InputSpan>
                <TextInput
                  width="280px"
                  name="description"
                  onChange={handleChange}
                  defaultValue={itemInfo.ios_item_id}
                ></TextInput>
              </InputDiv>
              <InputDiv>
                <InputSpan>AOS</InputSpan>
                <TextInput
                  width="280px"
                  name="description"
                  onChange={handleChange}
                  defaultValue={itemInfo.aos_item_id}
                ></TextInput>
              </InputDiv>
              <InputDiv>
                <InputSpan>사용 여부</InputSpan>
                <RadioGroup
                  display="flex"
                  columnGap="10px"
                  size={16}
                  name="is_show"
                  value={String(itemInfo.is_show)}
                  defaultValue={String(itemInfo.is_show)}
                  options={sectionIsShowOption}
                  onChange={handleChange}
                />
              </InputDiv>
              {itemInfo.method === "PAYMENT" ? (
                <InputDiv>
                  <InputSpan>증정할 이용권 ID</InputSpan>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                    }}
                  >
                    <ItemAdd
                      itemType={itemType}
                      itemIds={itemIds}
                      initialFullItemList={itemInfoList}
                      onSetItemIds={onSetItemIds}
                    />
                  </div>
                </InputDiv>
              ) : null}
            </div>
          )}
        </Dialog>
      </Pane>

      <ItemBox>
        <Pane>
          <ItemH1>이용권 목록</ItemH1>
        </Pane>

        <Box>
          <div
            style={{ display: "flex", flexDirection: "column", width: "25%" }}
          >
            <RadioGroup
              style={{
                display: "flex",
                alignItems: "center",
                wordBreak: "keep-all",
                gap: "10px",
              }}
              value={filter}
              options={filterOption}
              onChange={(event) => beforeGetItemList(event.target.value)}
            />
            <RadioGroup
              style={{
                display: "flex",
                alignItems: "center",
                wordBreak: "keep-all",
                gap: "10px",
              }}
              value={itemtype}
              options={filterType}
              onChange={(event) => beforeGetItemTypeList(event.target.value)}
            />
            <RadioGroup
              style={{
                display: "flex",
                alignItems: "center",
                wordBreak: "keep-all",
                gap: "10px",
              }}
              value={itemKeyword}
              options={filterMethod}
              onChange={(event) => beforeGetItemMethodList(event.target.value)}
            />
          </div>

          <SearchBtn>
            <TextInput
              placeholder="이용권명"
              width={"250px"}
              onChange={(e) => {
                const keyword = e.target.value;
                setItemKeyword(keyword);
              }}
            ></TextInput>
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "36%",
                bottom: "20px",
                marginLeft: "10px",
              }}
              onClick={beforeGetItemList}
            >
              검색
            </Button>
          </SearchBtn>

          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={() => addItemModal({})}
          >
            등록하기
          </Button>
        </Box>
        <Table width="95%" textAlign="center" marginTop="20px">
          <Table.Head>
            <Table.TextHeaderCell>이용권 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>이용권 타입</Table.TextHeaderCell>
            <Table.TextHeaderCell>이용권명</Table.TextHeaderCell>
            <Table.TextHeaderCell>획득 방식</Table.TextHeaderCell>
            <Table.TextHeaderCell>적용 기간</Table.TextHeaderCell>
            <Table.TextHeaderCell>가격</Table.TextHeaderCell>
            <Table.TextHeaderCell>사용여부</Table.TextHeaderCell>
            {/* <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell> */}
            {/* <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell> */}
          </Table.Head>
          <Table.Body height="auto">
            {itemList.map((item) => (
              <Table.Row
                key={item.item_id}
                isSelectable
                onSelect={async () => {
                  const _item = await getItemInfo(item.item_id);
                  modifyItemModal(_item);
                }}
              >
                <Table.TextCell>{item.item_id}</Table.TextCell>
                <Table.TextCell>{renderType(item.item_type)}</Table.TextCell>
                <Table.TextCell>{item.title}</Table.TextCell>
                <Table.TextCell>{renderMethod(item.method)}</Table.TextCell>
                <Table.TextCell>
                  {item.apply_value}
                  {renderUnit(item.apply_unit)}
                </Table.TextCell>
                <Table.TextCell>
                  {numberWithCommas(item.price)}원
                </Table.TextCell>
                <Table.TextCell>
                  {item.is_show ? "사용중" : "미사용"}
                </Table.TextCell>
                {/* <Table.TextCell>{item.description}</Table.TextCell> */}
                {/* <Table.TextCell>
                  <DateText value={item.create_date} />
                </Table.TextCell> */}
                {/* <Table.TextCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    appearance="primary"
                    intent="danger"
                    onClick={(event) => {
                      onDeleteItem(item.item_code);
                      // event.preventDefault();
                      event.stopPropagation();
                    }}
                  >
                    삭제
                  </Button>
                </Table.TextCell> */}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </ItemBox>
    </>
  );
};

// ModalBasic.defaultProps = {};
const ItemBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const ItemH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;
const SearchBtn = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
`;
export default Item;
