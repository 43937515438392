import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  RadioGroup,
  Textarea,
  SelectMenu,
  Select,
} from "evergreen-ui";
import DateText from "components/DateText";
import { diff, isEmpty } from "services/util";

const baseUrl = "/admin/faq";

const FAQ = () => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [originalFaqInfo, setOriginalFaqInfo] = useState({});
  const [faqInfo, setFaqInfo] = useState({});
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  const [tagList, setTagList] = useState([]);
  const [category, setCategory] = useState(null);

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "FAQ 등록",
      action: () => {
        onAddFaqInfo();
      },
    },
    MOD: {
      button: "수정하기",
      title: "FAQ 수정",
      action: () => {
        onModifyFaqInfo();
      },
    },
  };
  const [faqIsShowOption] = React.useState([
    { label: "노출", value: "1" },
    { label: "미노출", value: "0" },
  ]);

  useEffect(() => {
    getFagList();
    getCategoryList();
  }, [currentPage]);

  const addFagModal = (item) => {
    openModal(item, "ADD");
  };
  const modifyFagModal = (item) => {
    openModal(item, "MOD");
  };

  const openModal = async (item, type) => {
    await getCategoryList();

    setModalType(type);
    setOriginalFaqInfo(item);
    setFaqInfo(item);
    setIsModalOpened(true);
  };
  const closeModal = () => {
    setOriginalFaqInfo({});
    setFaqInfo({});
    setIsModalOpened(false);
  };

  const getCategoryList = async () => {
    try {
      const response = await axios.get(`${baseUrl}/category`);
      console.log("카테고리", response);
      const data = response.data.faq_category_list;
      setTagList(data);

      const newData = Object.fromEntries(
        data.map(({ category_code, category }) => [category_code, category])
      );
      console.log(newData);
      setCategory(newData);

      // const CategoryName = category[1];

      // console.log(CategoryName);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const setCategoryInfo = (item) => {
    // console.log(category);
    // const CategoryName = category[item.category_code];
    // return CategoryName;

    if (category === null) {
      getCategoryList();
    } else {
      const CategoryName = category[item.category_code];
      return CategoryName;
    }
  };

  //FAQ 목록
  const getFagList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
    };
    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.faq_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        setFaqList(data);
        setIsModalOpened(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };
  //FAQ 상세조회
  const getFaq = async (faq_id) => {
    try {
      const res = await axios.get(`${baseUrl}/${faq_id}`);
      console.log(res);
      const data = res.data.faq;

      let newContent = {};

      if (data.category_code === null) {
        newContent = {
          ...data,
        };
      } else {
        newContent = {
          ...data,
          _category_code: [data.category_code],
        };
      }
      return newContent;
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
      return {};
    }
  };
  //FAQ 등록
  const onAddFaqInfo = () => {
    let params = diff(faqInfo, originalFaqInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }
    // if (typeof params.is_show === "string") {
    //   let before = Number(params.is_show);
    //   params.is_show = before;
    // }
    params.is_show = 1;
    if (typeof params.category_code === "string") {
      let before = Number(params.category_code);
      params.category_code = before;
    }
    if (typeof params.ordering_number === "string") {
      let before = Number(params.ordering_number);
      params.ordering_number = before;
    }
    // params.ordering_number
    console.log(params);
    // return;
    axios
      .post(baseUrl, params)
      .then((res) => {
        console.log(res);
        alert("FAQ을 등록하였습니다.");
        getFagList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const onModifyFaqInfo = () => {
    let params = diff(faqInfo, originalFaqInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }
    if (typeof params.is_show === "string") {
      let before = Number(params.is_show);
      params.is_show = before;
    }
    if (typeof params.category_code === "string") {
      let before = Number(params.category_code);
      params.category_code = before;
    }
    if (typeof params.ordering_number === "string") {
      let before = Number(params.ordering_number);
      params.ordering_number = before;
    }
    console.log(params);

    axios
      .put(`${baseUrl}/${faqInfo.faq_id}`, params)
      .then((res) => {
        console.log(res);
        alert("FAQ을 수정하였습니다.");
        getFagList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const onDeleteFaq = (faq_id) => {
    if (!window.confirm("FAQ을 삭제하겠습니까?")) {
      closeModal();
      return;
    }

    axios
      .delete(`${baseUrl}/${faq_id}`)
      .then(() => {
        alert("FAQ이 삭제되었습니다.");
        getFagList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const handleChange = (e) => {
    setFaqInfo({
      ...faqInfo,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={`${modalTypeOption[modalType].button}`}
          onConfirm={modalTypeOption[modalType].action}
          hasCancel={false}
          hasClose={false}
          title={`${modalTypeOption[modalType].title}`}
        >
          {modalType === "MOD" ? (
            <div>
              <InputDiv>
                <InputSpan>FAQ ID</InputSpan>
                <TextInput
                  name="faq_id"
                  onChange={handleChange}
                  disabled
                  defaultValue={originalFaqInfo.faq_id}
                />
              </InputDiv>
              <InputDiv>
                <InputSpan>노출 여부</InputSpan>
                <RadioGroup
                  display="flex"
                  columnGap="10px"
                  size={16}
                  name="is_show"
                  value={String(faqInfo.is_show)}
                  defaultValue={String(faqInfo.is_show)}
                  options={faqIsShowOption}
                  onChange={handleChange}
                />
              </InputDiv>
            </div>
          ) : null}

          <InputDiv>
            <InputSpan>질문</InputSpan>
            <TextInput
              name="question"
              onChange={handleChange}
              defaultValue={originalFaqInfo.question}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>답변</InputSpan>
            <Textarea
              name="answer"
              onChange={handleChange}
              width={"280px"}
              maxLength={1000}
              defaultValue={originalFaqInfo.answer}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>카테고리 코드</InputSpan>
            {/* <Select
              onChange={(event) => console.log(event.target.value)}
            ></Select> */}
            <SelectMenu
              title="faqCategory"
              closeOnSelect={true}
              options={tagList.map((item) => ({
                label: item.category,
                value: item.category_code,
              }))}
              selected={String(faqInfo.category_code)}
              onSelect={(item) =>
                setFaqInfo({
                  ...faqInfo,
                  category_code: item.value,
                })
              }
            >
              <Button>
                {faqInfo.category_code
                  ? tagList.find(
                      (i) => i.category_code === faqInfo.category_code
                    ).category
                  : "카테고리를 선택해주세요"}
              </Button>
            </SelectMenu>
          </InputDiv>
          <InputDiv>
            <InputSpan>순서</InputSpan>
            <TextInput
              name="ordering_number"
              onChange={handleChange}
              value={faqInfo.ordering_number}
              type="number"
            ></TextInput>
          </InputDiv>
        </Dialog>
      </Pane>

      <FaqBox>
        <Pane>
          <FaqH1>FAQ</FaqH1>
        </Pane>
        <Button
          appearance="primary"
          intent="none"
          style={{
            position: "inherit",
            left: "36%",
            bottom: "20px",
          }}
          onClick={() => addFagModal({})}
        >
          등록하기
        </Button>
        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell>FAQ ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>제목</Table.TextHeaderCell>
            <Table.TextHeaderCell>본문</Table.TextHeaderCell>
            <Table.TextHeaderCell>순서</Table.TextHeaderCell>
            <Table.TextHeaderCell>노출여부</Table.TextHeaderCell>
            <Table.TextHeaderCell>카테고리 코드</Table.TextHeaderCell>
            <Table.TextHeaderCell>생성 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell>업데이트 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {faqList.map((item) => (
              <Table.Row
                key={item.faq_id}
                isSelectable
                onSelect={async () => {
                  const _faq = await getFaq(item.faq_id);
                  console.log(_faq);
                  modifyFagModal(_faq);
                }}
              >
                <Table.TextCell>{item.faq_id}</Table.TextCell>
                <Table.TextCell>{item.answer}</Table.TextCell>
                <Table.TextCell>{item.question}</Table.TextCell>
                <Table.TextCell>{item.ordering_number}</Table.TextCell>
                <Table.TextCell>
                  {item.is_show ? "노출" : "미노출"}
                </Table.TextCell>
                <Table.TextCell> {setCategoryInfo(item)}</Table.TextCell>
                <Table.TextCell>
                  <DateText>{item.create_date}</DateText>
                </Table.TextCell>
                <Table.TextCell>
                  <DateText>{item.update_date}</DateText>
                </Table.TextCell>
                <Table.TextCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    appearance="primary"
                    intent="danger"
                    onClick={(event) => {
                      onDeleteFaq(item.faq_id);
                      // event.preventDefault();
                      event.stopPropagation();
                    }}
                  >
                    삭제
                  </Button>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </FaqBox>
    </>
  );
};

// ModalBasic.defaultProps = {};
const FaqBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const FaqH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;

export default FAQ;
