import React, { useEffect, useInsertionEffect, useState } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  TagInput,
  Pagination,
} from "evergreen-ui";

import DateText from "components/DateText";
import { diff, isEmpty } from "services/util";
// import ImagesUpload from "components/ImagesUpload";
import FileUploader from "components/FileUploader";

const baseUrl = "/admin/screen-saver";
const groupUrl = "/admin/group";

const ScreenSaver = () => {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  let dto = { 
    image_url: null,
    image_original_filename: null    
  }

  const PAGE_SIZE = 10;
  const [screenSaverList, setScreenSaverList] = useState([]);
  const [screenSaverInfo, setScreenSaverInfo] = useState([]);
  const [originalScreenSaverInfo, setOriginalScreenSaverInfo] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [modalType, setModalType] = useState("ADD");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [imgList, setImgList] = useState([dto]);

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "등록",
      action: () => {
        onAddScreenSaver();
      },
    },
    MOD: {
      button: "수정하기",
      title: "정보 수정",
      action: () => {
        onModifyScreenSaver();
      },
    },
  };
  useEffect(() => {
    getGroupList();
    getScreenSaverList();
  }, []);

  useEffect(() => {
    console.log(imgList);
  }, [imgList]);

  useEffect(() => {
    getScreenSaverList();
  }, [currentPage, PAGE_SIZE]);

  //인풋 수정시
  const handleChange = (e) => {
    setScreenSaverInfo({ ...screenSaverInfo, [e.target.name]: e.target.value });
  };

  const getGroupList = async () => {
    try {
      const response = await axios.get(groupUrl);
      // console.log("groupList", response);
      setGroupList(response.data.group_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getScreenSaverInfo = async (id) => {
    try {
      const res = await axios.get(`${baseUrl}/${id}`);
      // console.log(res);
      const data = res.data.screen_saver;
      let newContent = {};
      newContent = {
        ...data,
        _group_codes: data.screen_saver_group_codes
          .split(",")
          .map((i) => Number(i)),
      };
      // console.log(newContent);
      setImgList(data.screen_saver_image_list.map(item=>({...item, _image_preview: item.image_url})));
      return newContent;
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
      //   return {};
    }
  };
  const getScreenSaverList = () => {
    const params = {
      limit: 10,
      offset: PAGE_SIZE * (currentPage - 1),
    };
    axios
      .get(`${baseUrl}`, { params })
      .then((res) => {
        // console.log(res);
        const data = res.data.screen_saver_list;
        const dataCount = res.data.count;

        const newData = data.map((item) => {
          return {
            ...item,
          };
        });
        // console.log("newData", newData)
        setTotalCount(dataCount);
        setScreenSaverList(newData);
        setIsModalOpened(false);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  const addScreenSaverModal = (item) => {
    openModal(item, "ADD");
  };

  const modifyScreenSaverModal = (item) => {
    openModal(item, "MOD");
  };

  const openModal = async (item, type) => {
    // console.log(item);

    setOriginalScreenSaverInfo(item);
    setScreenSaverInfo(item);
    setModalType(type);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setImgList([{ image_url: null }]);
    setOriginalScreenSaverInfo({});
    setScreenSaverInfo({});
    setIsModalOpened(false);
  };

  //등록
  const onAddScreenSaver = () => {
    let newScreenSaverInfo = {
      ...screenSaverInfo,
      screen_saver_images: imgList
    };

    // console.log(newScreenSaverInfo);
    let params = diff(newScreenSaverInfo, originalScreenSaverInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }
    if (typeof params.slide_time === "string") {
      let a = Number(params.slide_time);
      params.slide_time = a;
    }
    if (typeof params.screen_saver_time === "string") {
      let a = Number(params.screen_saver_time);
      params.screen_saver_time = a;
    }

    // console.log(params);
    // return;
    axios
      .post(baseUrl, params)
      .then((response) => {
        // console.log(response);
        alert("등록되었습니다");
        getScreenSaverList();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
        alert(error.response.data.message);
      });
  };

  //수정
  const onModifyScreenSaver = () => {
    let newScreenSaverInfo = {
      ...screenSaverInfo,
      screen_saver_images: imgList
    };
    // console.log(screenSaverInfo);

    let params = diff(newScreenSaverInfo, originalScreenSaverInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }

    if (typeof params.slide_time === "string") {
      let a = Number(params.slide_time);
      params.slide_time = a;
    }
    if (typeof params.screen_saver_time === "string") {
      let a = Number(params.screen_saver_time);
      params.screen_saver_time = a;
    }

    delete params.screen_saver_image_list;
    delete params.image_original_filename;
    delete params.image_url;
    // console.log(params);
    // console.log(originalBannerInfo);

    if (params.screen_saver_images[0].image_url === undefined) {
      delete params.screen_saver_images;
    }
    // return;
    axios
      .put(`${baseUrl}/${originalScreenSaverInfo.screen_saver_id}`, params)
      .then((response) => {
        // console.log(response);
        alert("수정되었습니다");
        getScreenSaverList();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
        alert(error.response.data.message);
      });
  };

  //삭제
  const deleteScreenSaver = (screen_saver_id) => {
    if (!window.confirm("삭제하겠습니까?")) {
      closeModal();
      return;
    }
    // const params = { screen_saver_id: screen_saver_id };

    axios
      .delete(`${baseUrl}/${screen_saver_id}`)
      .then(() => {
        alert("삭제되었습니다.");
        getScreenSaverList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const getTagInputTitle = (
    originalContentInfo,
    screenSaverInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    const titleList =
      screenSaverInfo[propName] &&
      screenSaverInfo[propName].map((item) => {
        return fullList.find((listItem) => listItem[listPropKey] === item)[
          listPropName
        ];
      });
    return titleList;
  };
  const setTagInputValue = (
    originalContentInfo,
    screenSaverInfo,
    propName,
    value,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let newScreenSaverInfo = { ...screenSaverInfo };

    const add_prop_name = `add${propName}`;
    const delete_prop_name = `delete${propName}`;

    newScreenSaverInfo[propName] = value
      .map((valueItem) => {
        let obj = fullList.find((item) => item[listPropName] === valueItem);
        return obj ? obj[listPropKey] : null;
      })
      .filter((item) => item !== null);

    const added = newScreenSaverInfo[propName]
      ? newScreenSaverInfo[propName].filter((i) =>
          originalContentInfo[propName]
            ? !originalContentInfo[propName].includes(i)
            : true
        )
      : [];
    const deleted = originalContentInfo[propName]
      ? originalContentInfo[propName].filter((i) =>
          newScreenSaverInfo[propName] ? !newScreenSaverInfo[propName].includes(i) : true
        )
      : [];

    // console.log(newContentInfo, originalContentInfo)
    // console.log(add_prop_name,added,delete_prop_name,deleted)

    newScreenSaverInfo[add_prop_name] = added;
    newScreenSaverInfo[delete_prop_name] = deleted;

    setScreenSaverInfo(newScreenSaverInfo);
  };

  const tagInputAutoCompleteList = (
    screenSaverInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let data = fullList
      .filter(
        (item) =>
          !(
            screenSaverInfo[propName] &&
            screenSaverInfo[propName].includes(item[listPropKey])
          )
      )
      .map((item) => item[listPropName]);
    return data;
  };

  const onAddScreenSaverImage = () => {
    setImgList([...imgList, dto].map((i,idx)=>({...i, _id:idx})))
    // console.log("add")
  }

  const onRemoveScreenSaverImage = (index) => {
    let newList = [...imgList]
    newList.splice(index,1)
    // console.log("newList", newList)
    setImgList([...newList])
    // console.log(`remove ${index}`)
  }

  const onUploadScreenSaver = (index, dto) => {
    let newList = [...imgList]
    newList[index] = dto
    setImgList(newList)
  }

  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={modalTypeOption[modalType].button}
          onConfirm={modalTypeOption[modalType].action}
          hasClose={false}
          title={modalTypeOption[modalType].title}
        >
          {modalType === "MOD" ? (
            <InputDiv>
              <InputSpan>화면보호기 ID</InputSpan>
              <TextInput
                disabled
                name="screen_saver_id"
                defaultValue={screenSaverInfo.screen_saver_id}
                onChange={handleChange}
              ></TextInput>
            </InputDiv>
          ) : null}

          <InputDiv>
            <InputSpan>제목</InputSpan>
            <TextInput
              name="title"
              defaultValue={screenSaverInfo.title}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>대기 시간(m)</InputSpan>
            <TextInput
              type="number"
              name="screen_saver_time"
              defaultValue={screenSaverInfo.screen_saver_time}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>슬라이드 시간(s)</InputSpan>
            <TextInput
              type="number"
              name="slide_time"
              defaultValue={screenSaverInfo.slide_time}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          {/* <InputDiv>
            <InputSpan>등록(수정)일</InputSpan>
            <TextInput
              name="update_date"
              defaultValue={screenSaverInfo.update_date}
              onChange={handleChange}
            ></TextInput>
          </InputDiv> */}

          <InputDiv>
            <InputSpan>그룹 코드</InputSpan>
            <TagInput
              width="280px"
              inputProps={{ placeholder: "Enter something..." }}
              values={getTagInputTitle(
                originalScreenSaverInfo,
                screenSaverInfo,
                "_group_codes",
                groupList,
                "group_code",
                "title"
              )}
              onChange={(e) => {
                setTagInputValue(
                  originalScreenSaverInfo,
                  screenSaverInfo,
                  "_group_codes",
                  e,
                  groupList,
                  "group_code",
                  "title"
                );
              }}
              autocompleteItems={tagInputAutoCompleteList(
                screenSaverInfo,
                "_group_codes",
                groupList,
                "group_code",
                "title"
              )}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>이미지 리스트</InputSpan>
            <div>
              {imgList &&
                imgList.map((screenSaverDTO, index) => {
                  return (
                  <div style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      borderTop: index === 0 ? "" : "1px solid black",
                    }}
                    key={screenSaverDTO._id}  
                  >
                    <FileUploader
                      directory="screenSaver"
                      preview_src={screenSaverDTO._image_preview}
                      targetObj={screenSaverDTO}
                      onUploaded={(dto)=>onUploadScreenSaver(index,dto)}
                      targetPropName="image"
                      isImage={true}
                      accept={"image/*"}
                    />
                    <Button onClick={(e)=>{
                      let _index = index
                      onRemoveScreenSaverImage(_index)
                    }}>-</Button>
                  </div>
                )})}
              <Button onClick={onAddScreenSaverImage}>추가</Button>
            </div>
          </InputDiv>
        </Dialog>
      </Pane>

      <ScreenSaverBox>
        <Pane>
          <ScreenSaverH1>화면 보호기</ScreenSaverH1>
        </Pane>
        <Button
          appearance="primary"
          intent="none"
          style={{
            position: "inherit",
            left: "36%",
            bottom: "20px",
          }}
          onClick={() => addScreenSaverModal({})}
        >
          등록하기
        </Button>
        <Table width="80%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell>화면보호기 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>그룹</Table.TextHeaderCell>
            <Table.TextHeaderCell>제목</Table.TextHeaderCell>
            <Table.TextHeaderCell>순서</Table.TextHeaderCell>
            <Table.TextHeaderCell>대기 시간</Table.TextHeaderCell>
            <Table.TextHeaderCell>슬라이드 시간</Table.TextHeaderCell>
            <Table.TextHeaderCell>등록 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={90} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {screenSaverList.map((item) => {
              let a = (item.screen_saver_group_codes || "").split(",");
              const _groupList = a.map(Number);
              //   let _groupList = [Number(a)];ㅌ
              //   console.log(_groupList);

              return (
                <Table.Row
                  key={item.screen_saver_id}
                  isSelectable
                  onSelect={async () => {
                    const _item = await getScreenSaverInfo(item.screen_saver_id);
                    modifyScreenSaverModal(_item);
                  }}
                >
                  <Table.TextCell>{item.screen_saver_id}</Table.TextCell>
                  <Table.TextCell>
                    {_groupList
                      .filter((i) => i !== "")
                      .map((item) => {
                        // console.log(item);
                        // console.log(groupList);
                        let a = groupList.find((i) => i.group_code === item);
                        // console.log(a);
                        if (a) {
                          return (
                            <div
                              style={{
                                justifyContent: "center",
                              }}
                              key={a.group_code}
                            >
                              {a.title}
                            </div>
                          );
                        } else {
                          return null; // or handle the undefined case in a different way
                        }
                      })}
                  </Table.TextCell>
                  <Table.TextCell>{item.title}</Table.TextCell>
                  <Table.TextCell>{item.no}</Table.TextCell>
                  <Table.TextCell>{item.screen_saver_time}</Table.TextCell>
                  <Table.TextCell>{item.slide_time}</Table.TextCell>
                  <Table.TextCell>
                    <DateText>{item.update_date}</DateText>
                  </Table.TextCell>

                  <Table.TextCell
                    flexBasis={90}
                    flexShrink={0}
                    flexGrow={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      appearance="primary"
                      intent="danger"
                      onClick={(event) => {
                        deleteScreenSaver(item.screen_saver_id);
                        // event.preventDefault();
                        event.stopPropagation();
                      }}
                    >
                      삭제
                    </Button>
                  </Table.TextCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {
          <Pane
            height={120}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Pagination
              page={currentPage}
              totalPages={Math.ceil(totalCount / PAGE_SIZE)}
              onNextPage={() => {
                setCurrentPage(currentPage + 1);
              }}
              onPreviousPage={() => {
                setCurrentPage(currentPage - 1);
              }}
              onPageChange={(page) => setCurrentPage(page)}
            ></Pagination>
          </Pane>
        }
      </ScreenSaverBox>
    </>
  );
};

const ScreenSaverBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const ScreenSaverH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;
export default ScreenSaver;
