import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  SelectMenu,
  TagInput,
  RadioGroup,
} from "evergreen-ui";
import DateText from "components/DateText";
import { diff, isEmpty } from "services/util";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const baseUrl = "/admin/bike";
const groupUrl = "/admin/group";

const Bike = () => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [bikeList, setBikeList] = useState([]);
  const [bikeIdList, setBikeIdList] = useState([]);
  const [originalBikeInfo, setOriginalBikeInfo] = useState([]);
  const [bikeInfo, setBikeInfo] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [groupList, setGroupList] = useState([]);

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "바이크 등록",
      action: () => {
        onAddBike();
      },
    },
    MOD: {
      button: "수정하기",
      title: "바이크 수정",
      action: () => {
        onModifyBike();
      },
    },
  };
  const [tabOptions] = React.useState([
    { label: "WAITING", value: "WAITING" },
    { label: "SALE", value: "SALE" },
    { label: "RENTAL", value: "RENTAL" },
  ]);

  useEffect(() => {
    getBikeList();
  }, [currentPage]);

  useEffect(() => {
    console.log("bikeInfo", bikeInfo);
  }, [bikeInfo]);
  useEffect(() => {
    getGroupList();
  }, []);
  const addBikeModal = (bike) => {
    openModal(bike, "ADD");
  };

  const modifyBikeModal = (bike) => {
    openModal(bike, "MOD");
  };

  const openModal = async (bike, type) => {
    console.log(bike);
    await getBikeIdList();
    setOriginalBikeInfo(bike);
    if (type === "ADD") {
      setBikeInfo({ ...bikeInfo, state: "WAITING" });
    } else {
      if (bike.group_code) {
        let newContent = {
          ...bike,
          _group_code: Number(bike.group_code),
        };
        setBikeInfo(newContent);
      } else {
        let newContent = {
          ...bike,
        };
        setBikeInfo(newContent);
      }
    }
    setModalType(type);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setOriginalBikeInfo({});
    setBikeInfo({});
    setIsModalOpened(false);
  };
  const getGroupList = async () => {
    try {
      const response = await axios.get(groupUrl);
      console.log("groupList", response);
      setGroupList(response.data.group_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };
  const getBikeList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: searchKeyword,
    };

    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.bike_list;
        const dataCount = res.data.bike_count.total_count;
        setTotalCount(dataCount);
        setBikeList(data);
        setIsModalOpened(false);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  // 목록
  const getBikeIdList = async () => {
    // const params = {
    //   limit: PAGE_SIZE,
    //   offset: PAGE_SIZE * (currentPage - 1),
    // };

    await axios
      .get(baseUrl)
      .then((res) => {
        // console.log(res);

        const data = res.data.bike_list;
        setBikeIdList(data);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //인풋 수정시
  const handleChange = (e) => {
    console.log(e);
    setBikeInfo({ ...bikeInfo, [e.target.name]: e.target.value });
  };

  //바이크 등록
  const onAddBike = (s, e) => {
    // let modifyBikeInfo = {
    //   ...bikeInfo,
    //   // state: "WAITING",
    // };
    // setBikeInfo(modifyBikeInfo);
    // console.log(modifyBikeInfo);

    let params = diff(bikeInfo, originalBikeInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }

    // console.log(params);
    // retur  /n;

    axios
      .post(baseUrl, params)
      .then((response) => {
        console.log(response);
        alert("등록되었습니다");
        getBikeList();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //바이크 수정
  const onModifyBike = () => {
    console.log("수정하기");
    console.log(bikeInfo);
    console.log(originalBikeInfo);
    let params = diff(bikeInfo, originalBikeInfo);
    // params.start_date = startDate;
    // params.end_date = endDate;

    if (isEmpty(params)) {
      closeModal();
      return;
    }
    console.log(params);

    axios
      .put(`${baseUrl}/${bikeInfo.bike_id}`, params)
      .then((response) => {
        console.log(response);
        alert("수정되었습니다");
        getBikeList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  const getTagInputTitle = (
    originalBikeInfo,
    bikeInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    // console.log(propName, bikeInfo[propName]);

    const titleList =
      bikeInfo[propName] &&
      bikeInfo[propName].map((item) => {
        return fullList.find((listItem) => listItem[listPropKey] === item)[
          listPropName
        ];
      });
    return titleList;
  };

  const setTagInputValue = (
    originalBikeInfo,
    bikeInfo,
    propName,
    value,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let newContentInfo = { ...bikeInfo };
    newContentInfo[propName] = value
      .map((valueItem) => {
        let obj = fullList.find((item) => item[listPropName] === valueItem);
        return obj ? obj[listPropKey] : null;
      })
      .filter((item) => item !== null);

    const add_prop_name = `add${propName}`;
    const delete_prop_name = `delete${propName}`;

    const added = newContentInfo[propName]
      ? newContentInfo[propName].filter((i) =>
          originalBikeInfo[propName]
            ? !originalBikeInfo[propName].includes(i)
            : true
        )
      : [];
    const deleted = originalBikeInfo[propName]
      ? originalBikeInfo[propName].filter((i) =>
          newContentInfo[propName]
            ? !newContentInfo[propName].includes(i)
            : true
        )
      : [];

    // console.log(newContentInfo, originalBikeInfo)
    // console.log(add_prop_name,added,delete_prop_name,deleted)

    newContentInfo[add_prop_name] = added;
    newContentInfo[delete_prop_name] = deleted;

    setBikeInfo(newContentInfo);
  };

  const tagInputAutoCompleteList = (
    contentInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let data = fullList
      .filter(
        (item) =>
          !(
            contentInfo[propName] &&
            contentInfo[propName].includes(item[listPropKey])
          )
      )
      .map((item) => item[listPropName]);
    return data;
  };

  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={modalTypeOption[modalType].button}
          onConfirm={modalTypeOption[modalType].action}
          hasClose={false}
          title={modalTypeOption[modalType].title}
        >
          {modalType === "ADD" ? (
            <>
              <InputDiv>
                <InputSpan>바이크 ID</InputSpan>
                <TextInput
                  placeholder="바이크ID"
                  width="280px"
                  name="bike_id"
                  maxLength={19}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                ></TextInput>
              </InputDiv>
              <InputDiv>
                <InputSpan>상태</InputSpan>
                <RadioGroup
                  display="flex"
                  columnGap="10px"
                  size={16}
                  name="state"
                  options={tabOptions}
                  value={bikeInfo.state}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </InputDiv>
              {bikeInfo.state === "WAITING" ? null : (
                <InputDiv>
                  <InputSpan>사용처</InputSpan>
                  <TextInput
                    maxLength={30}
                    name="location"
                    // defaultValue={bikeInfo.location}
                    onChange={handleChange}
                  ></TextInput>
                </InputDiv>
              )}
              <InputDiv>
                <InputSpan>그룹 코드</InputSpan>
                <SelectMenu
                  height={Math.min(groupList.length*33+32,216)}
                  hasTitle={false}
                  closeOnSelect={true}
                  options={groupList.map((item) => ({label: item.title, value: item.group_code}))}
                  selected={bikeInfo._group_code}
                  onSelect={(item) => setBikeInfo({...bikeInfo, group_code:item.value})}
                >
                  <Button>{bikeInfo.group_code?groupList.find(i=>i.group_code == bikeInfo.group_code).title : "선택하기"}</Button>
                </SelectMenu>
              </InputDiv>
            </>
          ) : (
            <div>
              <InputDiv>
                <InputSpan>바이크 ID</InputSpan>
                <TextInput
                  disabled
                  onChange={handleChange}
                  name="bike_id"
                  value={bikeInfo.bike_id}
                ></TextInput>
              </InputDiv>
              <InputDiv>
                <InputSpan>상태</InputSpan>
                <RadioGroup
                  display="flex"
                  columnGap="10px"
                  size={16}
                  name="state"
                  options={tabOptions}
                  value={bikeInfo.state}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </InputDiv>
              {bikeInfo.state === "WAITING" ? null : (<>
                <InputDiv>
                <InputSpan>사용처</InputSpan>
                  <TextInput
                    maxLength={30}
                    name="location"
                    defaultValue={bikeInfo.location}
                    onChange={handleChange}
                  ></TextInput>
                  </InputDiv>
                  </>)}
                <InputDiv>
                <InputSpan>그룹 코드</InputSpan>
                  
                <SelectMenu
                  height={Math.min(groupList.length*33+32,216)}
                  hasTitle={false}
                  closeOnSelect={true}
                  options={groupList.map((item) => ({label: item.title, value: item.group_code}))}
                  selected={bikeInfo._group_code}
                  onSelect={(item) => setBikeInfo({...bikeInfo, group_code:item.value})}
                >
                  <Button>{bikeInfo.group_code?groupList.find(i=>i.group_code == bikeInfo.group_code).title : "선택하기"}</Button>
                </SelectMenu>
              </InputDiv>
            </div>
          )}
        </Dialog>
      </Pane>

      <BikeBox>
        <Pane>
          <BikeH1>바이크 관리</BikeH1>
        </Pane>
        <Box>
          <SearchBtn>
            <TextInput
              placeholder="바이크 ID"
              width={"250px"}
              onChange={(e) => {
                const keyword = e.target.value;
                setSearchKeyword(keyword);
              }}
            ></TextInput>
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "36%",
                bottom: "20px",
                marginLeft: "10px",
              }}
              onClick={getBikeList}
            >
              검색
            </Button>
          </SearchBtn>

          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={() => addBikeModal({})}
          >
            등록하기
          </Button>
        </Box>

        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell>바이크 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>그룹</Table.TextHeaderCell>
            <Table.TextHeaderCell>상태</Table.TextHeaderCell>
            <Table.TextHeaderCell>판매/대여 사용처</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {groupList.length > 0 && bikeList.map((item) => {
              return (
                <Table.Row
                  key={item.bike_id}
                  isSelectable
                  onSelect={async () => {
                    modifyBikeModal(item);
                  }}
                >
                  <Table.TextCell>{item.bike_id}</Table.TextCell>
                  <Table.TextCell>{groupList.find(i=>i.group_code===item.group_code).title}</Table.TextCell>
                  <Table.TextCell>{item.state}</Table.TextCell>
                  <Table.TextCell>{item.location}</Table.TextCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </BikeBox>
    </>
  );
};

const BikeBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const BikeH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;
const DatepickerWrapper = styled.div`
  width: auto;
`;
const SDatePicker = styled(DatePicker)`
  width: 280px;
  border-color: #d8dae5;
  border: 1px solid #d8dae5;
  padding: 0 12px;
  line-height: 16px;
  font-size: 12px;
  transition: box-shadow 80ms ease-in-out;
  height: 32px;
  border-radius: 4px;
  color: #474d66;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
`;
const inputBox = styled.div`
  width: 280px;
`;
const SearchBtn = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;
const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
`;
export default Bike;
