import React, { useEffect, useState, createContext, useContext } from "react";
import axios from "services/auth/jwt/config";
import styled from "styled-components";
import { Pane, Table, Button, Dialog, Textarea } from "evergreen-ui";
import io from "socket.io-client";
const liveRoomUrl = "/admin/live-room/";

// const socket = io.connect("https://dev2023.paprica.fit", {
const socket = io.connect(process.env.REACT_APP_SOCKET_URL, {
  cors: { origin: "*" },
  query: {
    server: "admin",
    access_token: `${localStorage.getItem("access_token")}`,
  },
  transports: ["websocket"],
  path: "/socket.io",
  forceNew: false,
});

const LiveRoomPrompt = () => {
  const [startDate, setStartDate] = useState("");
  const [info, setInfo] = useState("");
  const [message, setMessage] = useState("");
  const [liveRoomInfo, setLiveRoomInfo] = useState(info);

  socket.on("ADMIN:RECEIVE_MESSAGE_FROM_ADMIN", (data) => {
    console.log(data.data.message);
    setMessage(data.data.message);
  });

  socket.on("RECEIVE_LIVE_ROOM_DATA", (data) => {
    console.log(data);
    if (data.status === "SUCCESS") {
      console.log(data);
      console.log(data.data.live_room.actual_start_date);
      setLiveRoomInfo(data.data.live_room);
    }
  });

  useEffect(() => {
    const joinLiveRoom = async () => {
      try {
        const roomInfo = await getLiveRoomInfo();
        console.log(roomInfo);
        const args = {
          live_room_id: roomInfo.live_room_id,
          password: roomInfo.password,
        };

        socket.emit("JOIN_LIVE", args);

        console.log(socket);

        let formattedDateString = roomInfo.start_date
          .replace("T", " ")
          .replace(".000Z", "");
        setStartDate(formattedDateString);
      } catch (error) {
        console.log(error);
        alert(error.response.data.message);
      }
    };
    joinLiveRoom();
  }, []);

  const getLiveRoomInfo = async () => {
    const data = localStorage.getItem("liveRoomId");
    console.log(data);
    try {
      const response = await axios.get(`${liveRoomUrl}${data}`);
      console.log("Res", response);
      const liveRoom = response.data.live_room;
      setInfo(liveRoom);
      return liveRoom;
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  return (
    <>
      <Pane>
        <div style={{ margin: "10px" }}>
          <div
            style={{
              position: "relative",
              top: "0",
              left: "0",
              display: "inline-block",
            }}
          >
            {liveRoomInfo.live_type === "REHEARSAL" ? (
              <div
                style={{
                  backgroundColor: "#0a5ab2",
                  width: "60px",
                  fontSize: "14px",
                  color: "#fff",
                  textAlign: "center",
                  padding: "4px 2px",
                }}
              >
                리허설
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "#b00004",
                  width: "60px",
                  fontSize: "14px",
                  color: "#fff",
                  textAlign: "center",
                  padding: "4px 2px",
                }}
              >
                ON-AIR
              </div>
            )}
          </div>
        </div>

        <LiveH1>강사 프롬프트</LiveH1>
        <Box>
          <Title>{info.title}</Title>
          <Info>
            [비번 : {info.password} &nbsp; 대상 : {info.group_codes}]
          </Info>
          <Date>방송 일시 : {startDate}</Date>
        </Box>
        <hr></hr>
        <Box>
          <Textarea
            lineHeight="1.1"
            fontSize="80px"
            width="95%"
            height="60vh"
            textAlign="center"
            readOnly
            placeholder="스튜디오 메시지 대기 중..."
            value={message}
          ></Textarea>
        </Box>
      </Pane>
    </>
  );
};
const LiveH1 = styled.h1`
  text-align: center;
  font-size: 24px;
  margin: 20px 0;
`;
const Box = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Title = styled.span`
  font-size: 20px;
  margin-right: 20px;
`;
const Info = styled.span`
  font-size: 14px;
  margin-right: 20px;
`;
const Date = styled.span`
  font-size: 14px;
`;
export default LiveRoomPrompt;
