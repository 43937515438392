import React, { useEffect, useState, memo } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
} from "evergreen-ui";

const baseUrl = "/admin/content/";

const LoadMetadata = ({ instructorList, onSelectContent, children }) => {
  console.log(instructorList);
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  const [contentList, setContentList] = useState([]);
  const [keyword, setKeyword] = useState("");

  const modalTypeOption = {
    ADD: {
      button: "설정",
      title: "콘텐츠 검색",
      action: () => {
        _onSelectContent();
      },
    },
  };

  useEffect(() => {
    getContentList();
  }, [currentPage]);

  const openModal = () => {
    getContentList();
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setKeyword("");
    setCurrentPage(1);
    setIsModalOpened(false);
  };

  const beForeGetMusicList = () => {
    if (currentPage !== 1) {
      console.log("ASDF");
      setCurrentPage(1);
      getContentList();
    } else {
      getContentList();
    }
  };

  const getContentList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: keyword,
    };

    console.log(params);

    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.content_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        setContentList(data);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  const getContentInfo = async (content_id) => {
    // return;
    try {
      const response = await axios.get(`${baseUrl}${content_id}`);
      const content = response.data.content;
      let metadata = JSON.parse(content.meta_data);
      metadata = metadata.json ? metadata.json : metadata;
      metadata = metadata.map((item, idx) => ({ ...item, _id: idx }));
      return { content_id, metadata };
    } catch (err) {
      console.log(err);
      // alert(err.response.data.message);
      return {};
    }
  };

  const _onSelectContent = async (id) => {
    // onSetMusicIds(musicIdsInfo);
    console.log("!!", id);
    let { content_id, metadata } = await getContentInfo(id);
    console.log(content_id, metadata);
    onSelectContent(content_id, metadata);
    closeModal();
  };

  return (
    <Pane style={{ width: "100%" }}>
      <Button
        style={{
          width: "30%",
          marginRight: "10px",
        }}
        appearance="primary"
        onClick={() => openModal()}
      >
        {children}
      </Button>
      <Dialog
        isShown={isModalOpened}
        onCloseComplete={() => closeModal()}
        // confirmLabel={modalTypeOption[modalType].button}
        // onConfirm={modalTypeOption[modalType].action}
        hasFooter={false}
        hasClose={false}
        title={modalTypeOption[modalType].title}
        width={"1000px"}
      >
        <InputDiv>
          <TextInput
            onChange={(e) => {
              const keyword = e.target.value;
              setKeyword(keyword);
            }}
          ></TextInput>
          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={beForeGetMusicList}
          >
            검색
          </Button>
        </InputDiv>
        {contentList.length > 0 && (
          <Pane>
            <Table width="100%" textAlign="center">
              <Table.Head>
                <Table.TextHeaderCell>VID</Table.TextHeaderCell>
                <Table.TextHeaderCell>타입</Table.TextHeaderCell>
                <Table.TextHeaderCell>제목</Table.TextHeaderCell>
                <Table.TextHeaderCell>강사</Table.TextHeaderCell>
                <Table.TextHeaderCell>운동강도</Table.TextHeaderCell>
                <Table.TextHeaderCell>상태</Table.TextHeaderCell>
                <Table.TextHeaderCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                >
                  추가
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height="auto">
                {contentList.map((item) => {
                  return (
                    <Table.Row key={item.content_id}>
                      <Table.TextCell>{item.content_id}</Table.TextCell>
                      <Table.TextCell>{item.content_type}</Table.TextCell>
                      <Table.TextCell>{item.title}</Table.TextCell>
                      <Table.TextCell>
                        {item.instructor_ids &&
                          item.instructor_ids
                            .split(",")
                            .map(
                              (id) =>
                                instructorList.find(
                                  (i) => i.instructor_id == id
                                ).name
                            )
                            .join(",")}
                      </Table.TextCell>
                      <Table.TextCell>{item.level}</Table.TextCell>
                      <Table.TextCell>{item.is_show}</Table.TextCell>
                      <Table.TextCell
                        flexBasis={120}
                        flexShrink={0}
                        flexGrow={0}
                      >
                        <Button
                          appearance="primary"
                          onClick={() => {
                            _onSelectContent(item.content_id);
                          }}
                        >
                          추가
                        </Button>
                      </Table.TextCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            <Pane
              height={80}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Pagination
                page={currentPage}
                totalPages={Math.ceil(totalCount / PAGE_SIZE)}
                onNextPage={() => {
                  setCurrentPage(currentPage + 1);
                }}
                onPreviousPage={() => {
                  setCurrentPage(currentPage - 1);
                }}
                onPageChange={(page) => setCurrentPage(page)}
              ></Pagination>
            </Pane>
          </Pane>
        )}
      </Dialog>
    </Pane>
  );
};

const memorizedLoadMetadata = memo(LoadMetadata);

const FlexDiv = styled.div`
  display: flex;
`;

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  // width: 100px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
`;

export default memorizedLoadMetadata;
