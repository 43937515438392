import React, { useEffect, useState } from "react";
import {useLocation} from 'react-router';
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import { Link } from "react-router-dom";
import FileUploader from "components/FileUploader";

import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  RadioGroup,
  SelectMenu,
} from "evergreen-ui";
import DateText from "components/DateText";
import { diff, isEmpty } from "services/util";
// import ModalBasic from "./InstructorModal";
// import UpdateModalBasic from "./InstuctorUpdate";

const baseUrl = "/admin/advertisement/";


const Advertisement = () => {
  const location = useLocation()
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [originalAdvertisementInfo, setOriginalAdvertisementInfo] = useState([]);
  const [advertisementInfo, setAdvertisementInfo] = useState([]);
  const [advertisementList, setAdvertisementList] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");

  const ad_contract_id = location.state.ad_contract_id
  const advertiser = location.state.advertiser
  const advertisementName = location.state.advertisementName

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "등록",
      action: () => {
        onAddAdvertisement();
      },
    },
    MOD: {
      button: "수정하기",
      title: "정보 수정",
      action: () => {
        onModifyAdvertisement();
      },
    },
  };

  const [isClickOptions] = React.useState([
    { label: "가능", value: "1" },
    { label: "불가능", value: "0" },
  ]);

  const [isStateOptions] = React.useState([
    { label: "등록됨", value: "REGISTERATED" },
    { label: "노출중", value: "EXPOSURED" },
    { label: "종료됨", value: "TERMINATED" },
  ]);


  useEffect(() => {
    getAdvertisementList();
  }, [currentPage]);

  // useEffect(() => {
  //   console.log(aabb);
  //   console.log(typeof aabb);
  // }, [aabb]);

  const addAdvertisementModal = (advertisement) => {
    openModal(advertisement, "ADD");
  };

  const modifyAdvertisementModal = (advertisement) => {
    openModal(advertisement, "MOD");
  };

  const openModal = (advertisement, type) => {
    setOriginalAdvertisementInfo(advertisement);
    setAdvertisementInfo(advertisement);
    setModalType(type);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setOriginalAdvertisementInfo({});
    setAdvertisementInfo({});
    setIsModalOpened(false);
  };

  const getAdvertisementList = async () => {
    try{
      const params = {
        limit: PAGE_SIZE,
        offset: PAGE_SIZE * (currentPage - 1),
        ad_contract_id: ad_contract_id,
      };

      const response = await axios.get(`${baseUrl}`,{params})
      setAdvertisementList(response.data.advertisement_list)
      setTotalCount(response.data.count);
    } catch(error) {
      console.log(error);
      alert(error.response) ;
    };
  };

  //인풋 수정시
  const handleChange = (e) => {
    setAdvertisementInfo({ ...advertisementInfo, [e.target.name]: e.target.value });
  };

  // 등록
  const onAddAdvertisement = () => {
    let newAdvertisementInfo = {
      ...advertisementInfo,
      ad_contract_id: ad_contract_id,
      is_click: Number(advertisementInfo.is_click)
    }
    let params = diff(newAdvertisementInfo, originalAdvertisementInfo);

    if (isEmpty(params)) {
      alert("변경사항이 없습니다");
      return;
    }

    axios
      .post(baseUrl, params)
      .then((response) => {
        // console.log(response);
        alert("등록되었습니다");
        getAdvertisementList();
        closeModal();
        setCurrentPage(1);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  // 수정
  const onModifyAdvertisement = () => {
    let params = diff(advertisementInfo, originalAdvertisementInfo);

    if (isEmpty(params)) {
      alert("변경사항이 없습니다");
      return;
    }
    if (typeof params.is_click === "string") {
      let a = Number(params.is_click);
      params.is_click = a;
    }
    axios
      .put(`${baseUrl}${advertisementInfo.ad_id}`, params)
      .then((response) => {
        console.log(response);
        alert("수정되었습니다");
        getAdvertisementList();
        closeModal()
        setCurrentPage(1);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  function renderType(value) {
    switch (value) {
      case "LIVE_START":
        return "라이브 시작";
      case "CONTENT_SEARCH":
        return "콘텐츠 검색";
      case "CONTENT_PAUSE":
        return "콘텐츠 일시정지";
      case "SECTION":
        return "섹션";
      case "BANNER":
        return "배너";
      case "HOME_POPUP":
        return "홈 팝업";
      default:
        return value;
    }
  }
  function renderState(value) {
    switch (value) {
      case "REGISTERATED":
        return "등록됨";
      case "EXPOSURED":
        return "노출중";
      case "TERMINATED":
        return "종료됨";
      default:
        return value;
    }
  }
  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={modalTypeOption[modalType].button}
          onConfirm={modalTypeOption[modalType].action}
          hasClose={false}
          title={modalTypeOption[modalType].title}
        >
          {modalType === "MOD" ? (
            <InputDiv>
              <InputSpan>광고 타입</InputSpan>
              <TextInput
                disabled
                name="ad_type"
                onChange={handleChange}
                value={advertisementInfo.ad_type}
              />
            </InputDiv>
          ) : (
            <InputDiv>
              <InputSpan>광고 타입</InputSpan>
              <SelectMenu
                hasFilter={false}
                hasTitle={false}
                closeOnSelect={true}
                options={[
                  { label: "라이브 시작", value: "LIVE_START" },
                  { label: "콘텐츠 검색", value: "CONTENT_SEARCH" },
                  { label: "콘텐츠 일시정지", value: "CONTENT_PAUSE" },
                  { label: "섹션", value: "SECTION" },
                  { label: "배너", value: "BANNER" },
                  { label: "홈 팝업", value: "HOME_POPUP" },
                ]}
                selected={advertisementInfo.ad_type}
                onSelect={(item) => setAdvertisementInfo({...advertisementInfo, ad_type:item.value})}
              >
                <Button>{renderType(advertisementInfo.ad_type) || "선택하기"}</Button>
              </SelectMenu>
            </InputDiv>
          )}

          <InputDiv>
            <InputSpan>광고 이미지</InputSpan>
            <FileUploader
              directory="advertising/ad"
              preview_src={advertisementInfo.image_url}
              targetObj={advertisementInfo}
              onUploaded={setAdvertisementInfo}
              targetPropName="image"
              isImage={true}
              accept={"image/*"}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>광고명</InputSpan>
            <TextInput
              name="title"
              onChange={handleChange}
              value={advertisementInfo.title}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>클릭 가능 여부</InputSpan>
            <RadioGroup
              flexWrap="wrap"
              display="flex"
              columnGap="20px"
              size={16}
              name="is_click"
              options={isClickOptions}
              value={String(advertisementInfo.is_click)}
              defaultValue={String(advertisementInfo.is_click)}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>상태</InputSpan>
            <RadioGroup
              flexWrap="wrap"
              display="flex"
              columnGap="20px"
              size={16}
              name="state"
              options={isStateOptions}
              value={String(advertisementInfo.state)}
              defaultValue={String(advertisementInfo.state)}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputDiv>
        </Dialog>
      </Pane>

      <AdvertisementBox>
        <Pane>
          <AdvertisementH1>
            [{advertiser}] - [{advertisementName}] 광고 관리
          </AdvertisementH1>
        </Pane>
        <Box>
          <Link to="/contract">
            <Button
              intent="none"
              style={{
                position: "inherit",
                bottom: "20px",
                marginRight: "20px",
              }}
              onClick={() => addAdvertisementModal({})}
            >
              목록으로
            </Button>
          </Link>
          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              right: "36%",
              bottom: "20px",
            }}
            onClick={() => addAdvertisementModal({})}
          >
            등록하기
          </Button>
        </Box>

        <Table width="95%" textAlign="center" marginTop="10px">
          <Table.Head>
            <Table.TextHeaderCell flexBasis={120}>광고 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>광고명</Table.TextHeaderCell>
            <Table.TextHeaderCell>광고 타입</Table.TextHeaderCell>
            <Table.TextHeaderCell>광고 이미지</Table.TextHeaderCell>
            <Table.TextHeaderCell>노출 횟수</Table.TextHeaderCell>
            <Table.TextHeaderCell>클릭 횟수</Table.TextHeaderCell>
            <Table.TextHeaderCell>클릭 가능 여부</Table.TextHeaderCell>
            <Table.TextHeaderCell>상태</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {advertisementList &&
              advertisementList.map((item) => (
                <Table.Row
                  key={item.ad_id}
                >
                  <Table.TextCell flexBasis={120}>{item.ad_id}</Table.TextCell>
                  <Table.TextCell>{item.title}</Table.TextCell>
                  <Table.TextCell>{renderType(item.ad_type)}</Table.TextCell>
                  <Table.TextCell>
                    <ImgPreview
                      style={{ width: "150px" }}
                      src={item.image_url}
                    />
                  </Table.TextCell>
                  <Table.TextCell>{item.view_count ? item.view_count : 0}</Table.TextCell>
                  <Table.TextCell>{item.click_count ? item.click_count : 0}</Table.TextCell>
                  <Table.TextCell>
                    {item.is_click ? "가능" : "불가능"}
                  </Table.TextCell>
                  <Table.TextCell>{renderState(item.state)}</Table.TextCell>
                  {item.state === "TERMINATED" ? (
                    <Table.TextCell
                      flexBasis={120}
                      flexShrink={0}
                      flexGrow={0}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Button disabled>수정</Button>
                    </Table.TextCell>
                  ) : (
                    <Table.TextCell
                      flexBasis={120}
                      flexShrink={0}
                      flexGrow={0}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Button
                        onClick={() => {
                          modifyAdvertisementModal(item);
                        }}
                      >
                        수정
                      </Button>
                    </Table.TextCell>
                  )}
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </AdvertisementBox>
    </>
  );
};

const AdvertisementBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const AdvertisementH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;
const SearchBtn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;
const Box = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const ImgPreview = styled.img`
  width: 200px;
`;
export default Advertisement;
