import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";

function OnTimer(time) {
  const [elapsedDuration, setElapsedDuration] = useState(null);

  let a = time.time.replace("Z", "");
  time = a;

  function getElapsedDuration() {
    if (!time) {
      return "!time";
    }

    const now = new Date().getTime();
    const startDate = new Date(time).getTime();
    const elapsedDurationInMs = now - startDate;

    if (elapsedDurationInMs < 0) {
      return "00:00:00";
    }

    const elapsedDuration = new Date(elapsedDurationInMs);
    const hours = elapsedDuration.getUTCHours().toString().padStart(2, "0");
    const minutes = elapsedDuration.getUTCMinutes().toString().padStart(2, "0");
    const seconds = elapsedDuration.getUTCSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setElapsedDuration(getElapsedDuration());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [time]);

  return <div>방송 시간 &nbsp; {elapsedDuration ?? "00:00:00"}</div>;
}

const ChallengeBox = styled.div``;
export default OnTimer;
