import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  TagInput,
} from "evergreen-ui";
import DateText from "components/DateText";
import { diff, isEmpty } from "services/util";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const baseUrl = "/admin/advertisement/contract";
const groupUrl = "/admin/group";

const Advertisement = () => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [advertisementContractList, setAdvertisementContractList] = useState([]);
  const [originalAdvertisement, setOriginalAdvertisement] = useState([]);
  const [advertisementInfo, setAdvertisementInfo] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  const [itemKeyword, setItemKeyword] = useState("");
  const [isDelete, setIsDelete] = useState("");
  const [adId, setAdId] = useState(null);
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    console.log(adId);

    localStorage.setItem("ad_id", adId);
    // sessionStorage.setItem("liveRoomId", liveRoomId);
  }, [adId]);

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "등록",
      action: () => {
        beforeDate("ADD");
      },
    },
    MOD: {
      button: "수정하기",
      title: "정보 수정",
      action: () => {
        beforeDate("MOD");
      },
    },
  };
  useEffect(() => {
    getGroupList();
  }, []);
  useEffect(() => {
    getAdvertisementContractList();
  }, [currentPage, isDelete]);
  useEffect(() => {
    calculateDuration()
  }, [advertisementInfo.start_date, advertisementInfo.end_date])

  const getGroupList = async () => {
    try {
      const response = await axios.get(groupUrl);
      console.log("groupList", response);
      setGroupList(response.data.group_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  function calculateDuration() {
    // let diff = startDate - endDate;

    if (advertisementInfo.start_date && advertisementInfo.end_date) {
      const start = moment(advertisementInfo.start_date)
      const end = moment(advertisementInfo.end_date)
      // const start = new Date(
      //   advertisementInfo.start_date.getFullYear(),
      //   advertisementInfo.start_date.getMonth(),
      //   advertisementInfo.start_date.getDate()
      // );
      // const end = new Date(
      //   advertisementInfo.end_date.getFullYear(),
      //   advertisementInfo.end_date.getMonth(),
      //   advertisementInfo.end_date.getDate()
      // );
      // const diffInMs = end.getTime() - start.getTime();
      // const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      const diffInDays = end.diff(start, 'days')
      // console.log(`시작 날짜와 종료 날짜 간의 기간은 ${diffInDays}일입니다.`);
      const duration = diffInDays + 1;
      setAdvertisementInfo({
        ...advertisementInfo,
        duration: duration,
      });
    }
  }

  const addModal = (item) => {
    openModal(item, "ADD");
  };

  const modifyModal = (item) => {
    // let sLength = item.start_date.length;
    // let eLength = item.end_date.length;

    // const beforeS = item.start_date.slice(0, sLength - 1);
    // const beforeE = item.end_date.slice(0, eLength - 1);

    const s = moment(item.start_date).format("YYYY-MM-DD")
    const e = moment(item.end_date).format("YYYY-MM-DD")

    openModal({
      ...item,
      start_date: s,
      end_date: e
    }, "MOD");
  };

  const openModal = (item, type) => {
    console.log(item, type);
    setOriginalAdvertisement(item);
    setAdvertisementInfo(item);
    setModalType(type);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setOriginalAdvertisement({});
    setAdvertisementInfo({});
    setIsModalOpened(false);
  };

  const beforeList = () => {
    setCurrentPage(1);

    getAdvertisementContractList();
  };

  const beforeGetList = (e) => {
    setCurrentPage(1);
    setIsDelete(e);
  };

  const getAdvertisementContractList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: itemKeyword,
      is_delete: isDelete,
    };

    console.log(params);

    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.advertisement_contract_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        setAdvertisementContractList(data.map(i => ({
          ...i,
        _group_codes: i.group_codes.split(",").map((i) => Number(i)),
        })));
        setIsModalOpened(false);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //인풋 수정시
  const handleChange = (e) => {
    setAdvertisementInfo({
      ...advertisementInfo,
      [e.target.name]: e.target.value,
    });
  };
  //before
  const beforeDate = (type) => {
    // console.log(Object.keys(advertisementInfo).length);

    // console.log("Where are you");

    if (advertisementInfo.start_date && advertisementInfo.end_date !== null) {
      const afterStartDate = moment(advertisementInfo.start_date).format("YYYY-MM-DD")
      //  new Date(
      //   advertisementInfo.start_date.getTime() - advertisementInfo.start_date.getTimezoneOffset() * 60000
      // ).toISOString();
      const afterEndDate = moment(advertisementInfo.end_date).format("YYYY-MM-DD")
      // new Date(
      //   advertisementInfo.end_date.getTime() - advertisementInfo.end_date.getTimezoneOffset() * 60000
      // ).toISOString();

      console.log(afterStartDate);
      console.log(afterEndDate);

      if (type === "ADD") {
        onAddAdvertisement(afterStartDate, afterEndDate);
      } else {
        onModifyAdvertisement(afterStartDate, afterEndDate);
      }
    } else {
      alert("시작 날짜/종료 날짜 를 확인해주세요");
      return;
    }
  };
  // 등록
  const onAddAdvertisement = (s, e) => {
    let newAdvertisementInfo = {
      ...advertisementInfo,
      start_date: s,
      end_date: e,
      price: Number(advertisementInfo.price),
    }
    let params = diff(newAdvertisementInfo, originalAdvertisement);

    if (isEmpty(params)) {
      alert("변경사항이 없습니다");
      return;
    }

    axios
      .post(baseUrl, params)
      .then((response) => {
        // console.log(response);
        alert("등록되었습니다");
        getAdvertisementContractList();
        closeModal();
        setCurrentPage(1);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  // 수정
  const onModifyAdvertisement = (s, e) => {
    console.log(s,e)
    let newAdvertisementInfo = {
      ...advertisementInfo,
      start_date: s,
      end_date: e,
      price: Number(advertisementInfo.price),
    }
    console.log(newAdvertisementInfo)
    let params = diff(newAdvertisementInfo, originalAdvertisement);

    if (isEmpty(params)) {
      alert("변경사항이 없습니다");
      return;
    }
    console.log(params);
    // return;
    axios
      .put(`${baseUrl}/${advertisementInfo.ad_contract_id}`, params)
      .then((response) => {
        console.log(response);
        alert("수정되었습니다");
        getAdvertisementContractList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  // 삭제
  const onDeleteInstructor = (advertisement_id) => {
    if (window.confirm("계약 해지하시겠습니까?")) {
      axios
        .delete(`${baseUrl}/${advertisement_id}`)
        .then((response) => {
          console.log(response);
          alert("계약 해지되었습니다");
          getAdvertisementContractList();
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
        });
    }
  };

  const getAdvertisementInfo = async (ad_contract_id) => {
    // return;
    try {
      const response = await axios.get(`${baseUrl}/${ad_contract_id}`);
      const content = response.data.advertisement_contract;

      console.log(response);

      let newContent = {
        ...content,
        _group_codes: content.group_codes.split(",").map((i) => Number(i)),
      };

      return newContent;
    } catch (err) {
      console.log(err);
      // alert(err.response.data.message);
      return {};
    }
  };

  const getTagInputTitle = (
    originalContentInfo,
    adInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    // console.log(propName, bannerInfo[propName])
    const titleList =
      adInfo[propName] &&
      adInfo[propName].map((item) => {
        return fullList.find((listItem) => listItem[listPropKey] === item)[
          listPropName
        ];
      });
    return titleList;
  };
  const setTagInputValue = (
    originalAdInfo,
    adInfo,
    propName,
    value,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let newAdInfo = { ...adInfo };

    const add_prop_name = `add${propName}`;
    const delete_prop_name = `delete${propName}`;

    newAdInfo[propName] = value
      .map((valueItem) => {
        let obj = fullList.find((item) => item[listPropName] === valueItem);
        return obj ? obj[listPropKey] : null;
      })
      .filter((item) => item !== null);

    const added = newAdInfo[propName]
      ? newAdInfo[propName].filter((i) =>
          originalAdInfo[propName]
            ? !originalAdInfo[propName].includes(i)
            : true
        )
      : [];
    const deleted = originalAdInfo[propName]
      ? originalAdInfo[propName].filter((i) =>
          newAdInfo[propName] ? !newAdInfo[propName].includes(i) : true
        )
      : [];

    // console.log(newContentInfo, originalContentInfo)
    // console.log(add_prop_name,added,delete_prop_name,deleted)

    newAdInfo[add_prop_name] = added;
    newAdInfo[delete_prop_name] = deleted;

    setAdvertisementInfo(newAdInfo);
  };

  const tagInputAutoCompleteList = (
    adInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let data = fullList
      .filter(
        (item) =>
          !(adInfo[propName] && adInfo[propName].includes(item[listPropKey]))
      )
      .map((item) => item[listPropName]);
    return data;
  };

  function renderState(value) {
    switch (value) {
      case "ONGOING":
        return "진행 중";
      case "END":
        return "종료";
      case "WAIT":
        return "대기";
      default:
        return value;
    }
  }
  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={modalTypeOption[modalType].button}
          onConfirm={modalTypeOption[modalType].action}
          hasClose={false}
          title={modalTypeOption[modalType].title}
        >
          {modalType === "MOD" && (
            <InputDiv>
              <InputSpan>광고 계약 ID</InputSpan>
              <TextInput
                maxLength={30}
                disabled
                name="ad_contract_id"
                defaultValue={advertisementInfo.ad_contract_id}
                onChange={handleChange}
              ></TextInput>
            </InputDiv>
          )}
          <InputDiv>
            <InputSpan>광고명</InputSpan>
            <TextInput
              maxLength={30}
              name="name"
              defaultValue={advertisementInfo.name}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>광고주</InputSpan>
            <TextInput
              maxLength={15}
              name="advertiser"
              defaultValue={advertisementInfo.advertiser}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>시작 날짜</InputSpan>
            <DatepickerWrapper>
              <SDatePicker
                selected={advertisementInfo.start_date ? moment(advertisementInfo.start_date).toDate() : ""}
                onChange={(date) => setAdvertisementInfo({...advertisementInfo, start_date:moment(date).format("YYYY-MM-DD")})}
                // onChange={(date) => dateChange(date, "start")}
                selectsStart
                startDate={moment(advertisementInfo.start_date).toDate()}
                endDate={moment(advertisementInfo.end_date).toDate()}
                dateFormat={"yyyy-MM-dd"}
              />
            </DatepickerWrapper>
          </InputDiv>
          <InputDiv>
            <InputSpan>종료 날짜</InputSpan>
            <DatepickerWrapper>
              <SDatePicker
                selected={advertisementInfo.end_date ? moment(advertisementInfo.end_date).toDate() : ""}
                onChange={(date) => setAdvertisementInfo({...advertisementInfo, end_date:moment(date).format('YYYY-MM-DD')})}
                // onChange={(date) => dateChange(date, "end")}
                selectsEnd
                startDate={moment(advertisementInfo.start_date).toDate()}
                endDate={moment(advertisementInfo.end_date).toDate()}
                minDate={moment(advertisementInfo.start_date).toDate()}
                dateFormat={"yyyy-MM-dd"}
              />
            </DatepickerWrapper>
          </InputDiv>
          <InputDiv>
            <InputSpan>계약일수</InputSpan>
            <TextInput
              readOnly
              name="duration"
              defaultValue={advertisementInfo.duration}
              value={advertisementInfo.duration}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>계약금액</InputSpan>
            <TextInput
              type="number"
              name="price"
              defaultValue={advertisementInfo.price}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>그룹 코드</InputSpan>
            <TagInput
              width="280px"
              inputProps={{ placeholder: "Enter something..." }}
              values={getTagInputTitle(
                originalAdvertisement,
                advertisementInfo,
                "_group_codes",
                groupList,
                "group_code",
                "title"
              )}
              onChange={(e) => {
                setTagInputValue(
                  originalAdvertisement,
                  advertisementInfo,
                  "_group_codes",
                  e,
                  groupList,
                  "group_code",
                  "title"
                );
              }}
              autocompleteItems={tagInputAutoCompleteList(
                advertisementInfo,
                "_group_codes",
                groupList,
                "group_code",
                "title"
              )}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>클릭시 이동 URL</InputSpan>
            <TextInput
              name="click_url"
              defaultValue={advertisementInfo.click_url}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
        </Dialog>
      </Pane>

      <AadvertisementBox>
        <Pane>
          <AdvertisementH1>광고 계약 목록</AdvertisementH1>
        </Pane>
        <Box>
          {/* <RadioGroup
            style={{
              display: "flex",
              alignItems: "center",
              wordBreak: "keep-all",
              gap: "10px",
              width: "240px",
            }}
            value={isDelete}
            options={isDeleteOptions}
            onChange={(event) => beforeGetList(event.target.value)}
          /> */}
          <SearchBtn>
            <TextInput
              placeholder="광고명, 광고주"
              width={"250px"}
              onChange={(e) => {
                const keyword = e.target.value;
                setItemKeyword(keyword);
              }}
            ></TextInput>
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "36%",
                bottom: "20px",
                marginLeft: "10px",
              }}
              onClick={beforeList}
            >
              검색
            </Button>
          </SearchBtn>
          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={() => addModal({})}
          >
            등록하기
          </Button>
        </Box>

        <Table width="95%" textAlign="center" marginTop="20px">
          <Table.Head>
            <Table.TextHeaderCell>광고 계약 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>광고명</Table.TextHeaderCell>
            <Table.TextHeaderCell>그룹</Table.TextHeaderCell>
            <Table.TextHeaderCell>광고주</Table.TextHeaderCell>
            <Table.TextHeaderCell>시작 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell>종료 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell>클릭시 이동 URL</Table.TextHeaderCell>
            <Table.TextHeaderCell>계약금액</Table.TextHeaderCell>
            <Table.TextHeaderCell>상태</Table.TextHeaderCell>
            <Table.TextHeaderCell>광고 관리(앱)</Table.TextHeaderCell>
            {/*<Table.TextHeaderCell>광고 관리(영상)</Table.TextHeaderCell>*/}
            <Table.TextHeaderCell>상세</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {advertisementContractList.map((item) => (
              <Table.Row
                key={item.ad_contract_id}
                // isSelectable
                onSelect={() => {
                  modifyModal(item);
                }}
              >
                <Table.TextCell>{item.ad_contract_id}</Table.TextCell>
                <Table.TextCell>{item.name}</Table.TextCell>
                <Table.TextCell>{item.group_titles}</Table.TextCell>
                <Table.TextCell>{item.advertiser}</Table.TextCell>
                <Table.TextCell>
                  <DateText>{item.start_date.slice(0, 10)}</DateText>
                </Table.TextCell>
                <Table.TextCell>
                  <DateText>{item.end_date.slice(0, 10)}</DateText>
                </Table.TextCell>
                <Table.TextCell>{item.click_url}</Table.TextCell>
                <Table.TextCell>{item.price}</Table.TextCell>
                <Table.TextCell>{renderState(item.state)}</Table.TextCell>
                <Table.TextCell>
                  <Table.Cell
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Link
                      to="/advertising"
                      state={{ 
                        ad_contract_id: item.ad_contract_id,
                        advertiser: item.advertiser,
                        advertisementName: item.name
                      }}
                    >
                      <Button width="60px">
                        관리
                      </Button>
                    </Link>
                  </Table.Cell>
                </Table.TextCell>
                {/*
                <Table.TextCell>
                  <Table.Cell
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Link
                      to="/inplayAdvertising"
                      state={{ ad_contract_id: item.ad_contract_id,
                        advertiser: item.advertiser,
                        advertisementName: item.name }}
                    >
                      <Button width="60px">
                        관리
                      </Button>
                    </Link>
                  </Table.Cell>
                </Table.TextCell>
              */}
                <Table.TextCell>
                  <Button
                    onClick={async () => {
                      // const _content = await getAdvertisementInfo(
                      //   item.ad_contract_id
                      // );
                      console.log("content", item);
                      modifyModal(item);
                    }}
                  >
                    상세
                  </Button>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </AadvertisementBox>
    </>
  );
};
const DatepickerWrapper = styled.div`
  width: auto;
`;
const SDatePicker = styled(DatePicker)`
  width: 280px;
  border-color: #d8dae5;
  border: 1px solid #d8dae5;
  padding: 0 12px;
  line-height: 16px;
  font-size: 12px;
  transition: box-shadow 80ms ease-in-out;
  height: 32px;
  border-radius: 4px;
  color: #474d66;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
`;
const AadvertisementBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const AdvertisementH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;
const SearchBtn = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
`;
export default Advertisement;
