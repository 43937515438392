import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import AWS from "aws-sdk";
import { nanoid } from "nanoid";
import ColorThief from "colorthief";
import { Button } from "evergreen-ui";

const region = "ap-northeast-2";
const bucket = "popprika-cms-version2";
// const directory = "lessonData";

const Palette = ({
  targetObj,
  targetPropName,
  targetImageUrl,
  onColorClick
}) => {
  const [palette, setPalette] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedHexColor, setSelectedHexColor] = useState("");

  useEffect(() => {
    //이미지 색상
    const colorThief = new ColorThief();
    const image = new Image();
    image.crossOrigin = "Anonymous";

    if (targetObj[targetPropName]) {
      let hex = targetObj[targetPropName];
      const rgbColor = hexToRgb(hex);
      const rgbArray = rgbToArray(rgbColor);
      setSelectedColor(rgbArray);
    }

    console.log("!==");
    image.onload = function () {
      const extractedPalette = colorThief.getPalette(image, 10);
      const extractedColor = colorThief.getColor(image);

      setPalette(extractedPalette);

      if (!targetObj.thumbnail_gradient_color) {
        console.log("targetObj.thumbnail_gradient_color가 없는 경우");
      } else {
        console.log("targetObj.thumbnail_gradient_color가 있는 경우");
        setSelectedColor(extractedColor);
      }
    };

    image.addEventListener("load", () => {
      const colorPalette = colorThief.getPalette(image, 7);
      setPalette(colorPalette);
    });
    image.src = targetImageUrl;
    console.log(targetImageUrl)
  }, [targetImageUrl]);

  useEffect(()=>{

  },[selectedColor])

  const rgbToArray = (rgbColor) => {
    const values = rgbColor.substring(4, rgbColor.length - 1).split(", ");
    return values.map((value) => parseInt(value));
  };


  const hexToRgb = (hex) => {
    console.log(hex);
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgb(${r}, ${g}, ${b})`;
  };

  const toHex = (rgbString) => {
    console.log(rgbString);
    let rgbColor = null;
    if (typeof rgbString !== "string") {
      let a = rgbString.toString();
      let b = `rgb(${a})`;
      rgbColor = parseRgbString(b);
    } else {
      rgbColor = parseRgbString(rgbString);
    }

    const hexColor = rgbToHex(...rgbColor);

    setSelectedHexColor(hexColor);
    onColorClick(`${hexColor}`);
    function parseRgbString(rgbString) {
      let match = rgbString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      if (!match) {
        throw new Error(`Invalid rgb string: ${rgbString}`);
      }
      return [parseInt(match[1]), parseInt(match[2]), parseInt(match[3])];
    }

    function rgbToHex(r, g, b) {
      let red = r.toString(16).padStart(2, "0");
      let green = g.toString(16).padStart(2, "0");
      let blue = b.toString(16).padStart(2, "0");
      return `${red}${green}${blue}`;
    }
  };
  
  return (
    <div
      style={{
        margin: "20px 0",
        display: "flex",
        // width: "800px",
        alignItems: "center",
      }}
    >
      {selectedColor != null && selectedColor.length > 0 && (
        <div
          style={{
            backgroundColor: `rgb(${selectedColor})`,
            // borderRadius: "50%",
            width: "60px",
            height: "60px",
            cursor: "pointer",
            marginRight: "10px",
          }}
        />
      )}

      <div>
        <Button
          onClick={() => {
            onColorClick("");
            console.log("제거 버튼");
            setSelectedColor("");
          }}
        >
          X
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          marginLeft: "10px",
        }}
      >
        {palette.map((color, index) => (
          <div
            key={index}
            onClick={(e) => {
              console.log(e.target.style.backgroundColor);
              toHex(e.target.style.backgroundColor);

              let a = e.target.style.backgroundColor;

              const rgbString = a;
              const rgbArray = rgbString
                .substring(4, rgbString.length - 1) // "245, 191, 5"
                .split(", ") // ["245", "191", "5"]
                .map((num) => parseInt(num)); // [245, 191, 5]
              // console.log(rgbArray);
              setSelectedColor(rgbArray); // [245, 191, 5]
            }}
            style={{
              backgroundColor: `rgb(${color.join(",")})`,
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              cursor: "pointer",
              marginLeft: "3px",
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default Palette;
