import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  RadioGroup,
  Textarea,
} from "evergreen-ui";
import DateText from "components/DateText";
import { diff, isEmpty } from "services/util";

const baseUrl = "/admin/user-question";

const 문의사항 = () => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [userQuestionList, setUserQuestionList] = useState([]);
  const [originalUserQuestionInfo, setOriginalUserQuestionInfo] = useState({});
  const [userQuestionInfo, setUserQuestionInfo] = useState({});
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "문의사항 등록",
      action: () => {
        // onAddUserQuestionInfo();
      },
    },
    MOD: {
      button: "답변 등록하기",
      title: "답변 등록",
      action: () => {
        onModifyUserQuestionInfo();
      },
    },
  };
  const [UserQuestionIsShowOption] = React.useState([
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
  ]);
  const [orderingNumberOption] = React.useState([
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
  ]);

  useEffect(() => {
    getUserQuestionList();
  }, [currentPage]);

  const addFagModal = (item) => {
    getCategoryList();

    openModal(item, "ADD");
  };
  const modifyFagModal = (item) => {
    openModal(item, "MOD");
  };

  const openModal = (item, type) => {
    setModalType(type);
    setOriginalUserQuestionInfo(item);
    setUserQuestionInfo(item);
    setIsModalOpened(true);
  };
  const closeModal = () => {
    setOriginalUserQuestionInfo({});
    setUserQuestionInfo({});
    setIsModalOpened(false);
  };

  //카테고리 목록
  const getCategoryList = () => {
    const params = {
      limit: 10000,
      //   offset: PAGE_SIZE * (currentPage - 1),
    };
    axios
      .get(`${baseUrl}/category`, { params })
      .then((res) => {
        console.log(res);
        // setCategoryInfo(res.data.announce_category_list);
        const response = res.data.user_question_category_list;
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };
  //문의사항 목록
  const getUserQuestionList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
    };
    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.user_question_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        setUserQuestionList(data);
        setIsModalOpened(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };
  //문의사항 상세조회
  const getUserQuestion = async (question_id) => {
    try {
      const res = await axios.get(`${baseUrl}/${question_id}`);
      console.log(res);
      const data = res.data.user_question;

      let newContent = {
        ...data,
      };

      return newContent;
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
      return {};
    }
  };

  //답변 등록
  const onModifyUserQuestionInfo = () => {
    console.log(originalUserQuestionInfo);

    if (originalUserQuestionInfo.answer_create_date === null) {
      let params = diff(userQuestionInfo, originalUserQuestionInfo);

      if (isEmpty(params)) {
        closeModal();
        return;
      }

      console.log(params);

      axios
        .post(`${baseUrl}/${userQuestionInfo.question_id}/answer`, params)
        .then((res) => {
          console.log(res);
          alert("답변을 등록하였습니다.");
          getUserQuestionList();
        })
        .catch((err) => {
          console.log(err);
          alert(err.response.data.message);
        });
    } else {
      alert("이미 답변 등록이 완료된 문의사항은 변경되지 않습니다");
    }
  };

  const onDeleteUserQuestion = (question_id) => {
    if (!window.confirm("문의사항을 삭제하겠습니까?")) {
      closeModal();
      return;
    }

    axios
      .delete(`${baseUrl}/${question_id}`)
      .then(() => {
        alert("문의사항이 삭제되었습니다.");
        getUserQuestionList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const handleChange = (e) => {
    setUserQuestionInfo({
      ...userQuestionInfo,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={`${modalTypeOption[modalType].button}`}
          onConfirm={modalTypeOption[modalType].action}
          hasCancel={false}
          hasClose={false}
          title={`${modalTypeOption[modalType].title}`}
        >
          <InputDiv>
            <InputSpan>문의사항 ID</InputSpan>
            <TextInput
              name="question_id"
              onChange={handleChange}
              disabled
              defaultValue={originalUserQuestionInfo.question_id}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>회원 ID</InputSpan>
            <TextInput
              name="user_id"
              disabled
              defaultValue={originalUserQuestionInfo.user_id}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>제목</InputSpan>
            <TextInput
              disabled
              name="title"
              defaultValue={originalUserQuestionInfo.title}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>질문</InputSpan>
            <Textarea
              disabled
              name="question"
              width={"280px"}
              defaultValue={originalUserQuestionInfo.question}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>이메일 알림</InputSpan>
            <RadioGroup
              disabled
              display="flex"
              columnGap="10px"
              flexWrap="wrap"
              width="280px"
              size={16}
              name="email_noti"
              value={String(originalUserQuestionInfo.email_noti)}
              defaultValue={String(originalUserQuestionInfo.email_noti)}
              options={UserQuestionIsShowOption}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>문자 알림</InputSpan>
            <RadioGroup
              disabled
              display="flex"
              columnGap="10px"
              flexWrap="wrap"
              width="280px"
              size={16}
              name="sms_noti"
              value={String(originalUserQuestionInfo.sms_noti)}
              defaultValue={String(originalUserQuestionInfo.sms_noti)}
              options={UserQuestionIsShowOption}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>답변</InputSpan>
            <Textarea
              maxLength={1000}
              name="answer"
              onChange={handleChange}
              width={"280px"}
              defaultValue={originalUserQuestionInfo.answer}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>관리자 ID</InputSpan>
            <TextInput
              name="admin_id"
              disabled
              defaultValue={originalUserQuestionInfo.admin_id}
            />
          </InputDiv>
        </Dialog>
      </Pane>

      <FaqBox>
        <Pane>
          <FaqH1>문의사항</FaqH1>
        </Pane>
        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell>ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>닉네임</Table.TextHeaderCell>
            <Table.TextHeaderCell>제목</Table.TextHeaderCell>
            <Table.TextHeaderCell>본문</Table.TextHeaderCell>
            {/* <Table.TextHeaderCell>노출여부</Table.TextHeaderCell> */}
            <Table.TextHeaderCell>답변 여부</Table.TextHeaderCell>
            <Table.TextHeaderCell>생성 날짜</Table.TextHeaderCell>
            {/* <Table.TextHeaderCell>업데이트 날짜</Table.TextHeaderCell> */}
          </Table.Head>
          <Table.Body height="auto">
            {userQuestionList.map((item) => (
              <Table.Row
                key={item.question_id}
                isSelectable
                onSelect={async () => {
                  const _userQuestion = await getUserQuestion(item.question_id);
                  console.log(_userQuestion);
                  modifyFagModal(_userQuestion);
                }}
              >
                <Table.TextCell>{item.question_id}</Table.TextCell>
                <Table.TextCell>{item.nickname}</Table.TextCell>
                <Table.TextCell>{item.title}</Table.TextCell>
                <Table.TextCell>{item.question}</Table.TextCell>
                {/* <Table.TextCell>
                  {item.is_show ? "노출" : "미노출"}
                </Table.TextCell> */}
                <Table.TextCell>
                  {item.is_answer ? "답변 등록" : "답변 미등록"}
                </Table.TextCell>
                <Table.TextCell>
                  <DateText>{item.create_date}</DateText>
                </Table.TextCell>
                {/* <DateText>{item.update_date}</DateText> */}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </FaqBox>
    </>
  );
};

// ModalBasic.defaultProps = {};
const FaqBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const FaqH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;

export default 문의사항;
