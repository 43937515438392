import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  RadioGroup,
} from "evergreen-ui";
import DateText from "components/DateText";
import { diff, isEmpty } from "services/util";
// import ModalBasic from "./InstructorModal";
// import UpdateModalBasic from "./InstuctorUpdate";

const baseUrl = "/admin/instructor";

const Instructor = () => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [instructorList, setInstructorList] = useState([]);
  const [originalInstructorInfo, setOriginalInstructorInfo] = useState([]);
  const [instructorInfo, setInstructorInfo] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  const [itemKeyword, setItemKeyword] = useState("");
  const [isDelete, setIsDelete] = useState("");

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "강사 등록",
      action: () => {
        handleSubmit();
      },
    },
    MOD: {
      button: "수정하기",
      title: "강사 정보 수정",
      action: () => {
        onModifyInstructor();
      },
    },
  };

  const [isDeleteOptions] = React.useState([
    { label: "전체", value: "" },
    { label: "계약 중", value: "0" },
    { label: "계약 해지", value: "1" },
  ]);

  useEffect(() => {
    getInstructorList();
  }, [currentPage, isDelete]);

  const addInstructorModal = (instructor) => {
    openModal(instructor, "ADD");
  };

  const modifyInstructorModal = (instructor) => {
    openModal(instructor, "MOD");
  };

  const openModal = (instructor, type) => {
    setOriginalInstructorInfo(instructor);
    setInstructorInfo(instructor);
    setModalType(type);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setOriginalInstructorInfo({});
    setInstructorInfo({});
    setIsModalOpened(false);
  };

  const beforeList = () => {
    setCurrentPage(1);

    getInstructorList();
  };

  //정규식 확인
  const handleSubmit = () => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // 이메일 정규식

    const isEmailValid = emailRegex.test(instructorInfo.email);

    if (!isEmailValid) {
      alert("올바른 이메일 형식을 입력해주세요");
    }
    if (isEmailValid) {
      onAddInstructor();
    }
    return {
      isEmailValid,
    };
  };

  const beforeGetList = (e) => {
    setCurrentPage(1);
    setIsDelete(e);
  };

  const getInstructorList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: itemKeyword,
      is_delete: isDelete,
    };

    console.log(params);

    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.instructor_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        setInstructorList(data);
        setIsModalOpened(false);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //인풋 수정시
  const handleChange = (e) => {
    setInstructorInfo({ ...instructorInfo, [e.target.name]: e.target.value });
  };

  //강사 등록
  const onAddInstructor = () => {
    let params = diff(instructorInfo, originalInstructorInfo);

    if (isEmpty(params)) {
      alert("강사명/이메일을 작성해주세요");
      return;
    }

    axios
      .post(baseUrl, params)
      .then((response) => {
        // console.log(response);
        alert("등록되었습니다");
        getInstructorList();
        closeModal();
        setCurrentPage(1);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //강사 수정
  const onModifyInstructor = () => {
    let params = diff(instructorInfo, originalInstructorInfo);

    if (isEmpty(params)) {
      alert("변경사항이 없습니다");
      return;
    }

    axios
      .put(`${baseUrl}/${instructorInfo.instructor_id}`, params)
      .then((response) => {
        console.log(response);
        alert("수정되었습니다");
        getInstructorList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //강사 삭제
  const onDeleteInstructor = (instructor_id) => {
    if (window.confirm("계약 해지하시겠습니까?")) {
      axios
        .delete(`${baseUrl}/${instructor_id}`)
        .then((response) => {
          console.log(response);
          alert("계약 해지되었습니다");
          getInstructorList();
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
        });
    }
  };
  return (
    <>
      <Pane>
        {/*
      <Dialog
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      confirmLabel="등록하기"
      onConfirm={() => onAddInstructor()}
      hasCancel={false}
      hasClose={false}
      title="강사 등록"
      >
      <InputDiv>
      <InputSpan>강사명</InputSpan>
      <TextInput name="name" onChange={handleChange}></TextInput>
      </InputDiv>
      </Dialog>
    */}

        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={modalTypeOption[modalType].button}
          onConfirm={modalTypeOption[modalType].action}
          hasClose={false}
          title={modalTypeOption[modalType].title}
        >
          {modalType === "MOD" && (
            <InputDiv>
              <InputSpan>강사 ID</InputSpan>
              <TextInput
                maxLength={30}
                disabled
                name="instructor_id"
                defaultValue={instructorInfo.instructor_id}
                onChange={handleChange}
              ></TextInput>
            </InputDiv>
          )}
          <InputDiv>
            <InputSpan>강사명</InputSpan>
            <TextInput
              name="name"
              defaultValue={instructorInfo.name}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>이메일</InputSpan>
            <TextInput
              name="email"
              defaultValue={instructorInfo.email}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
        </Dialog>
      </Pane>

      <div>
        {/* {modalOpen && (
          <ModalBasic setModalOpen={setModalOpen} instructorData={modalData} />
        )}
        {updatemodalOpen && (
          <UpdateModalBasic setUpdateModalOpen={setUpdateModalOpen} />
        )} */}
      </div>
      <InstructorBox>
        <Pane>
          <InstructorH1>강사 목록</InstructorH1>
        </Pane>
        <Box>
          <RadioGroup
            style={{
              display: "flex",
              alignItems: "center",
              wordBreak: "keep-all",
              gap: "10px",
            }}
            value={isDelete}
            options={isDeleteOptions}
            onChange={(event) => beforeGetList(event.target.value)}
          />
          <SearchBtn>
            <TextInput
              placeholder="강사명"
              width={"250px"}
              onChange={(e) => {
                const keyword = e.target.value;
                setItemKeyword(keyword);
              }}
            ></TextInput>
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "36%",
                bottom: "20px",
                marginLeft: "10px",
              }}
              onClick={beforeList}
            >
              검색
            </Button>
          </SearchBtn>
          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={() => addInstructorModal({})}
          >
            등록하기
          </Button>
        </Box>

        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell>강사 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>강사명</Table.TextHeaderCell>
            <Table.TextHeaderCell>계약 여부</Table.TextHeaderCell>
            <Table.TextHeaderCell>생성 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell>업데이트 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
            {/* <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell> */}
          </Table.Head>
          <Table.Body height="auto">
            {instructorList.map((instructor) => (
              <Table.Row
                key={instructor.instructor_id}
                isSelectable
                onSelect={() => {
                  modifyInstructorModal(instructor);
                }}
              >
                <Table.TextCell>{instructor.instructor_id}</Table.TextCell>
                <Table.TextCell>{instructor.name}</Table.TextCell>
                <Table.TextCell>
                  {instructor.is_delete ? "계약 해지" : "계약중"}
                </Table.TextCell>
                <Table.TextCell>
                  <DateText>{instructor.create_date}</DateText>
                </Table.TextCell>
                <Table.TextCell>
                  <DateText>{instructor.update_date}</DateText>
                </Table.TextCell>
                <Table.TextCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {instructor.is_delete === 0 ? (
                    <Button
                      appearance="primary"
                      intent="danger"
                      onClick={(event) => {
                        onDeleteInstructor(instructor.instructor_id);
                        // event.preventDefault();
                        event.stopPropagation();
                      }}
                    >
                      계약 해지
                    </Button>
                  ) : (
                    <Button disabled>계약 해지</Button>
                  )}
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </InstructorBox>
    </>
  );
};

// ModalBasic.defaultProps = {};
const InstructorBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const InstructorH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;
const SearchBtn = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;
const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
`;
export default Instructor;
