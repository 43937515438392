import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  RadioGroup,
} from "evergreen-ui";
import DateText from "components/DateText";
import Tag from "components/Tag";
import FileUploader from "components/FileUploader";
import { diff, isEmpty, getMod } from "services/util";
// import ModalBasic from "./MusicModal";
// import UpdateModalBasic from "./InstuctorUpdate";

const baseUrl = "/admin/message/category";
const contentUrl = "/admin/content/";
const groupUrl = "/admin/group";

const LiveRoom = () => {
  const navigate = useNavigate();

  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [liveroomList, setLiveRoomList] = useState([]);
  const [originalInfo, setOriginalInfo] = useState([]);
  const [info, setInfo] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  const [searchKeyword, setSearchKeyword] = useState("");

  const [liveType, setLiveType] = useState("");
  const [onairStatus, setOnairStatus] = useState("");

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "음악 등록",
      action: () => {
        onAdd();
      },
    },
    MOD: {
      button: "수정하기",
      title: "라이브 룸 수정",
      action: () => {
        onModify();
      },
    },
  };

  const [contractOption] = React.useState([
    { label: "계약", value: "1" },
    { label: "미계약", value: "0" },
  ]);

  const [messageOption] = React.useState([
    { label: "전체", value: "" },
    { label: "사용", value: "1" },
    { label: "미사용", value: "0" },
  ]);

  useEffect(() => {
    console.log("liveType", liveType);
  }, [liveType]);
  useEffect(() => {
    console.log("onairStatus", onairStatus);
  }, [onairStatus]);

  useEffect(() => {
    getGroupList();
  }, []);

  useEffect(() => {
    getLiveRoomList();
  }, [currentPage, liveType, onairStatus]);

  const modifyModal = (item) => {
    openModal(item, "MOD");
  };

  const openModal = (item, type) => {
    setOriginalInfo(item);
    setInfo(item);
    setModalType(type);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setOriginalInfo({});
    setInfo({});
    setIsModalOpened(false);
  };

  const getGroupList = async () => {
    try {
      const response = await axios.get(groupUrl);
      console.log("group", response);
      setGroupList(response.data.group_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const beforeSearch = async () => {
    await setCurrentPage(1);
    getLiveRoomList();
  };
  const getLiveRoomList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: searchKeyword,
      onair_status: onairStatus,
      live_type: liveType,
    };

    // console.log(params)

    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.message_category_list;
        const dataCount = res.data.count;

        setTotalCount(dataCount);
        setLiveRoomList(data);
        setIsModalOpened(false);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //인풋 수정시
  const handleChange = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };

  //음악 등록
  const onAdd = () => {
    let newMusicInfo = {
      ...info,
      duration: Number(info.duration || 0),
      is_contract: Number(info.is_contract || 0),
    };
    let params = diff(newMusicInfo, originalInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }

    axios
      .post(baseUrl, params)
      .then((response) => {
        // console.log(response);
        alert("등록되었습니다");
        getLiveRoomList();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //음악 수정
  const onModify = () => {
    let newMusicInfo = {
      ...info,
      duration: Number(info.duration || 0),
      is_contract: Number(info.is_contract || 0),
    };
    let params = diff(newMusicInfo, originalInfo);
    console.log(params);
    if (isEmpty(params)) {
      closeModal();
      return;
    }

    axios
      .put(`${baseUrl}/${newMusicInfo.music_id}`, params)
      .then((response) => {
        console.log(response);
        alert("수정되었습니다");
        getLiveRoomList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //음악 삭제
  const onDeleteMusic = (music_id) => {
    if (window.confirm("삭제하시겠습니까?")) {
      axios
        .delete(`${baseUrl}/${music_id}`)
        .then((response) => {
          console.log(response);
          alert("삭제되었습니다");
          getLiveRoomList();
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
        });
    }
  };

  const isShowButton = (info) => {
    if (!window.confirm("앱 노출 상태를 변경하시겠습니까?")) {
      return;
    }

    if (info.is_app_show === 1) {
      info.is_app_show = 0;
    } else {
      info.is_app_show = 1;
    }

    if (typeof info.is_app_show === "string") {
      let a = Number(info.is_app_show);
      info.is_app_show = a;
    }

    let params = { is_app_show: info.is_app_show };
    console.log(params);
    // return;

    axios
      .put(`${contentUrl}${info.content_id}`, params)
      .then((response) => {
        console.log(response);
        getLiveRoomList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={modalTypeOption[modalType].button}
          onConfirm={modalTypeOption[modalType].action}
          hasClose={false}
          title={modalTypeOption[modalType].title}
        >
          {modalType === "MOD" && (
            <InputDiv>
              <InputSpan>콘텐츠 ID</InputSpan>
              <TextInput
                disabled
                name="content_id"
                defaultValue={info.content_id}
                onChange={handleChange}
              ></TextInput>
            </InputDiv>
          )}
          <InputDiv>
            <InputSpan>음악명</InputSpan>
            <TextInput
              name="title"
              defaultValue={info.title}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>저작권자</InputSpan>
            <TextInput
              name="artist"
              defaultValue={info.artist}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>지속시간</InputSpan>
            <TextInput
              name="duration"
              defaultValue={info.duration}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>계약 여부</InputSpan>
            <RadioGroup
              display="flex"
              columnGap="10px"
              size={16}
              name="is_contract"
              value={String(info.is_contract)}
              defaultValue={String(info.is_contract)}
              options={contractOption}
              onChange={handleChange}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>음악</InputSpan>
            <div
              style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <TextInput value={info.music_url} readOnly />
              <TextInput value={info.music_original_filename} readOnly />
              <FileUploader
                directory="music"
                targetObj={info}
                onUploaded={setInfo}
                targetPropName="music"
              />
            </div>
          </InputDiv>
          <InputDiv>
            <InputSpan>음악 태그</InputSpan>
            <Tag
              placeholder={"음악 태그 검색"}
              originalDataInfo={originalInfo}
              dataInfo={info}
              fullTagList={groupList}
              dataPropName={"_music_tag_ids"}
              tagListPropKey={"music_tag_id"}
              tagListPropName={"title"}
              setDataInfo={setInfo}
            />
          </InputDiv>
        </Dialog>
      </Pane>

      <MusicBox>
        <Pane>
          <MusicH1>라이브 메세지 카테고리 관리</MusicH1>
        </Pane>
        <Box>
          <SearchBtn>
            <TextInput
              placeholder="카테고리 명"
              width={"250px"}
              onChange={(e) => {
                const keyword = e.target.value;
                setSearchKeyword(keyword);
              }}
            ></TextInput>
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "10%",
                bottom: "20px",
                marginLeft: "10px",
              }}
              onClick={beforeSearch}
            >
              검색
            </Button>
          </SearchBtn>
          {/* <Link to="/liveContent">
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "36%",
                bottom: "20px",
              }}
            >
              등록하기
            </Button>
          </Link> */}
        </Box>

        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell>메시지 카테고리 코드</Table.TextHeaderCell>
            <Table.TextHeaderCell>카테고리 명</Table.TextHeaderCell>
            <Table.TextHeaderCell>순서</Table.TextHeaderCell>
            <Table.TextHeaderCell>노출 여부</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {liveroomList.map((item) => {
              return (
                <Table.Row
                  key={item.category_code}
                  isSelectable
                  height={"auto"}
                  minHeight={"60px"}
                  fontSize={"1px"}
                >
                  <Table.TextCell>{item.category_code}</Table.TextCell>
                  <Table.TextCell>{item.category}</Table.TextCell>
                  <Table.TextCell>{item.ordering_number}</Table.TextCell>
                  <Table.TextCell>{item.is_show}</Table.TextCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </MusicBox>
    </>
  );
};

// ModalBasic.defaultProps = {};
// const TableDiv = styled.div``;
const SearchBtn = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;
const MusicBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const MusicH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 0px 7px 0;
`;
const InputSpan = styled.span`
  width: 120px;
  flex-shrink: 0;
  font-size: 12px;
`;
const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
`;
const ImgPreview = styled.img`
  width: 200px;
`;
export default LiveRoom;
