import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  TextareaField,
  RadioGroup,
  TagInput,
  Pagination,
  DialogFooter,
} from "evergreen-ui";

import DateText from "components/DateText";
import MetaData from "pages/studio/LessonData/Metadata";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";

import Timetable from "../LessonData/Timetable";
import LoadMetadata from "../LessonData/LoadMetadata";

import {
  diff,
  isEmpty,
  getLevelFromMetadata,
  removeTempProp,
} from "services/util";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import MusicSearch from "components/MusicSearch";
import FileUploader from "components/FileUploader";
// import ImagePalette from "";

const baseUrl = "/admin/content/";
const liveUrl = "/admin/live-room/";
const tagUrl = "/admin/tag";
const musicTagUrl = "/admin/music-tag";
const groupUrl = "/admin/group";
const instructorUrl = "/admin/instructor";
const musicUrl = "/admin/music";

const PAGE_SIZE = 10;

const LiveContent = () => {
  window.Buffer = window.Buffer || require("buffer").Buffer;

  const [contentList, setContentList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [musicTagList, setMusicTagList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [instructorList, setInstructorList] = useState([]);
  const [contentInfo, setContentInfo] = useState([]);
  const [originalContentInfo, setOriginalContentInfo] = useState([]);
  const [loadedMetadata, setLoadedMetadata] = useState([]);
  const [loadedContentId, setLoadedContentId] = useState([]);
  const [metadata, setMetadata] = useState([]);
  const [timetable, setTimetable] = useState([]);
  const [musicIds, setMusicIds] = useState([]);
  const [musicList, setMusicList] = useState([]);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");

  const [itemKeyword, setItemKeyword] = useState("");

  const [selectedColor, setSelectedColor] = useState("");
  const [liveFilter, setLiveFilter] = useState("");

  const [startDate, setStartDate] = useState("");
  const [isDataVisible, setIsDataVisible] = useState(false);
  const [isTimetableVisible, setIsTimetableVisible] = useState(false);

  const handleColorChange = (hexColor) => {
    console.log(hexColor);
    setSelectedColor(hexColor);
  };

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "라이브 콘텐츠 등록",
      action: () => {
        beforeData();
      },
    },
    MOD: {
      button: "수정하기",
      title: "라이브 콘텐츠 수정",
      action: () => {
        beforeModData();
      },
    },
  };

  const [IsShowOption] = React.useState([
    { label: "등록", value: "1" },
    { label: "미등록", value: "0" },
  ]);

  const contentType = {
    // LESSON: "LESSON",
    // REAL: "REAL",
    LIVE: "LIVE",
    // POP: "POP",
  };

  const [contentTypeOptions] = useState([
    { label: "LIVE", value: contentType.LIVE },
  ]);

  const [liveFilterOption] = React.useState([
    { label: "전체", value: "" },
    { label: "등록중", value: "REGISTERING" },
    { label: "등록 완료", value: "REGISTRATION" },
    { label: "라이브방 개설", value: "LIVEROOMCREATE" },
  ]);

  useEffect(() => {
    getInstructorList();
  }, []);

  useEffect(() => {
    getContentList();
  }, [currentPage, PAGE_SIZE, liveFilter]);

  useEffect(() => {
    console.log("1=>", metadata);
  }, [metadata]);

  useEffect(() => {
    console.log("2=>", loadedMetadata);
  }, [loadedMetadata]);

  useEffect(() => {
    setContentInfo({
      ...contentInfo,
      thumbnail_gradient_color: selectedColor,
    });
  }, [selectedColor]);

  //인풋 수정시
  const handleChange = (e) => {
    setContentInfo({ ...contentInfo, [e.target.name]: e.target.value });
  };

  const addContentModal = (content) => {
    setMetadata([]);
    setTimetable([
      {
        bikeStep: "",
        time: "",
        segment: [],
      },
      {
        bikeStep: "",
        time: "",
        segment: [],
      },
      {
        bikeStep: "",
        time: "",
        segment: [],
      },
    ]);
    openModal(content, "ADD");
  };

  const modifyContentModal = (content) => {
    let aLength = content.start_date.length;

    const beforeA = content.start_date.slice(0, aLength - 1);

    const a = new Date(beforeA);

    console.log(a);

    setStartDate(a);
    const duration = metadata.reduce((a, b) => a + Number(b.time), 0);
    openModal(
      {
        ...content,
        level: getLevelFromMetadata(metadata),
        duration: duration * 1000,
      },
      "MOD"
    );
  };

  const openModal = async (content, type) => {
    // console.log(content)
    await getGroupList();
    await getTagList();
    await getMusicTagList();
    await getInstructorList();
    await getMusicList();

    setOriginalContentInfo(content);
    setContentInfo(content);
    setModalType(type);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setSelectedColor("");
    setStartDate("");
    setIsModalOpened(false);
    setOriginalContentInfo({});
    setContentInfo({});
    setMetadata([]);
    setTimetable([]);
    setMusicIds([]);
  };

  const beforeGetContentList = async (e) => {
    await setCurrentPage(1);
    console.log(e);
    await setLiveFilter(e);
  };

  const getContentList = async () => {
    try {
      const params = {
        limit: 10,
        offset: PAGE_SIZE * (currentPage - 1),
        keyword: itemKeyword,
        content_type: "LIVE",
        live_filter: liveFilter,
      };
      // console.log(params);
      const response = await axios.get(`${baseUrl}`, { params });
      console.log("contentList", response);
      setTotalCount(response.data.count);
      setContentList(response.data.content_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getTagList = async () => {
    try {
      const response = await axios.get(tagUrl);
      // console.log("tagList", response);
      setTagList(response.data.tag_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getMusicTagList = async () => {
    try {
      const response = await axios.get(musicTagUrl);
      // console.log("music-tagList", response);
      setMusicTagList(response.data.music_tag_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getGroupList = async () => {
    try {
      const response = await axios.get(groupUrl);
      // console.log("groupList", response);
      setGroupList(response.data.group_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getInstructorList = async () => {
    try {
      const response = await axios.get(instructorUrl);
      console.log("instructorList", response);
      setInstructorList(response.data.instructor_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getMusicList = async () => {
    try {
      const response = await axios.get(musicUrl);
      // console.log("music-list", response);
      setMusicList(response.data.music_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const isLive = () => {
    return contentInfo.content_type === contentType.LIVE;
  };

  const getContentInfo = async (content_id) => {
    // return;
    try {
      const response = await axios.get(`${baseUrl}${content_id}`);
      const content = response.data.content;
      console.log(
        "레슨 데이터 - 콘텐츠 컬러",
        content.thumbnail_gradient_color
      );

      if (
        content.thumbnail_gradient_color !== null &&
        content.thumbnail_gradient_color.length === 7
      ) {
        let a = content.thumbnail_gradient_color.replace("#", "");
        content.thumbnail_gradient_color = a;
      }
      let newMetadata = JSON.parse(content.meta_data);
      newMetadata = newMetadata.json ? newMetadata.json : newMetadata;
      newMetadata = newMetadata.map((item, idx) => ({ ...item, _id: idx }));
      setMetadata(newMetadata);

      setMusicIds(content.music_list.map((i) => i.music_id));

      let newContent = {
        ...content,
        // meta_data: newMetadata,
        _thumbnail_image_preview: content.thumbnail_image_url,
        _background_image_preview: content.background_image_url,
        _riding_exposure_image_preview: content.riding_exposure_image_url,
        _act_end_image_preview: content.act_end_image_url,
        _onair_wait_image_preview: content.onair_wait_image_url,
        _video_preview: content.video,
        _group_codes: content.group_codes.split(",").map((i) => Number(i)),
        _tag_ids: content.tag_ids ? content.tag_ids.split(",") : [],
      };
      if ([contentType.LIVE].includes(newContent.content_type)) {
        newContent = {
          ...newContent,
          _instructor_ids: newContent.instructor_ids.split(","),
          _tag_ids: newContent.tag_ids ? newContent.tag_ids.split(",") : [],
        };
      }
      return newContent;
    } catch (err) {
      console.log(err);
      // alert(err.response.data.message);
      return {};
    }
  };

  const tagInputAutoCompleteList = (
    contentInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let data = fullList
      .filter(
        (item) =>
          !(
            contentInfo[propName] &&
            contentInfo[propName].includes(item[listPropKey])
          )
      )
      .map((item) => item[listPropName]);
    return data;
  };

  const setTagInputValue = (
    originalContentInfo,
    contentInfo,
    propName,
    value,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let newContentInfo = { ...contentInfo };
    newContentInfo[propName] = value
      .map((valueItem) => {
        let obj = fullList.find((item) => item[listPropName] === valueItem);
        return obj ? obj[listPropKey] : null;
      })
      .filter((item) => item !== null);

    const add_prop_name = `add${propName}`;
    const delete_prop_name = `delete${propName}`;

    const added = newContentInfo[propName]
      ? newContentInfo[propName].filter((i) =>
          originalContentInfo[propName]
            ? !originalContentInfo[propName].includes(i)
            : true
        )
      : [];
    const deleted = originalContentInfo[propName]
      ? originalContentInfo[propName].filter((i) =>
          newContentInfo[propName]
            ? !newContentInfo[propName].includes(i)
            : true
        )
      : [];

    // console.log(newContentInfo, originalContentInfo)
    // console.log(add_prop_name,added,delete_prop_name,deleted)

    newContentInfo[add_prop_name] = added;
    newContentInfo[delete_prop_name] = deleted;

    setContentInfo(newContentInfo);
  };

  const getTagInputTitle = (
    originalContentInfo,
    contentInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    // console.log(propName, contentInfo[propName]);
    const titleList =
      contentInfo[propName] &&
      contentInfo[propName].map((item) => {
        return fullList.find((listItem) => listItem[listPropKey] === item)[
          listPropName
        ];
      });
    return titleList;
  };

  const onCsvUploaded = (content, file) => {
    const fileReader = new FileReader();
    if (file) {
      fileReader.onload = (e) => {
        const text = e.target.result;
        csvToArray(text);
      };
      fileReader.readAsText(file);
    }
    setContentInfo(content);
  };

  const csvToArray = (text) => {
    text = text.replaceAll("\r\n", "\n");
    const csvHeader = text.slice(0, text.indexOf("\n")).split(",");
    const csvRows = text.slice(text.indexOf("\n") + 1).split("\n");

    console.log(csvHeader, csvRows);

    const array = csvRows
      .filter((i) => {
        const values = i.split(",");
        return values.length === csvHeader.length;
      })
      .map((i) => {
        const values = i.split(",");
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index];
          return object;
        }, {});
        return obj;
      });

    console.log(array);

    let newArray = array.map((i) => ({
      height: Number(i.altitude),
      lat: Number(i.position_lat),
      lng: Number(i.position_long),
      speed: Number(i.speed),
    }));

    setMetadata(newArray);
  };

  const onSetMusicIds = useCallback((music_ids) => {
    setMusicIds((prev) => {
      console.log(prev, music_ids);
      setContentInfo((prev) => {
        let newContentInfo = {
          ...prev,
          music_ids: music_ids,
        };
        return newContentInfo;
      }, []);
      return music_ids;
    }, []);
  }, []);

  const beforeData = () => {
    console.log(startDate);

    if (startDate !== "") {
      console.log("A");
      const afterStartDate = new Date(
        startDate.getTime() - startDate.getTimezoneOffset() * 60000
      ).toISOString();

      console.log(afterStartDate);

      onAddContent(afterStartDate);
    } else {
      alert("방송 시작 일시를 확인해주세요");
      return;
    }
  };

  //콘텐츠 등록
  const onAddContent = (a) => {
    let meta_data = JSON.stringify({ json: metadata });
    let loaded_metadata = JSON.stringify({ json: loadedMetadata });

    let newContentInfo = {
      ...contentInfo,
      meta_data_content_id: loadedContentId,
      meta_data: meta_data,
      time_table: JSON.stringify(timetable),
      video_url:
        "https://forcewave.akamaized.net/cmaf/live/2031582/test/master.m3u8",
    };

    if (meta_data !== loaded_metadata) {
      delete newContentInfo["meta_data_content_id"];
      // console.log(newContentInfo)
    }

    console.log(newContentInfo);
    newContentInfo.start_date = a;

    // let params = diff(newContentInfo,newOriginalContentInfo)
    let params = newContentInfo;
    if (params.password) {
      params.is_private = 1;
    } else {
      params.is_private = 0;
    }
    if (typeof params.onair_personnel === "string") {
      let a = Number(params.onair_personnel);
      params.onair_personnel = a;
    }
    if (typeof params.onair_time === "string") {
      let a = Number(params.onair_time);
      params.onair_time = a;
    }
    if (typeof params.is_show === "string") {
      let a = Number(params.is_show);
      params.is_show = a;
    }

    console.log(params);
    if (isEmpty(params)) {
      closeModal();
      return;
    }
    // return;

    axios
      .post(baseUrl, params)
      .then((response) => {
        console.log(response);
        alert("등록되었습니다");
        // window.location.reload();
        closeModal();
        getContentList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  const existLiveRoomButton = (info) => {
    console.log(info.content_id);
    if (!window.confirm("라이브룸을 개설하시겠습니까?")) {
      return;
    }

    // if (info.exist_live_room === 0) {
    //   info.exist_live_room = 1;
    // }

    if (typeof info.exist_live_room === "string") {
      let a = Number(info.exist_live_room);
      info.exist_live_room = a;
    }

    let params = { content_id: info.content_id };
    console.log(params);
    // return;

    axios
      .post(`${liveUrl}`, params)
      .then((response) => {
        console.log(response);
        if (response.status === "SUCCESS") {
          alert("라이브 방이 개설 되었습니다");
        }
        if (response.status === 200) {
          alert("라이브 방이 개설 되었습니다");
        }
        getContentList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  const beforeModData = () => {
    console.log(startDate);

    if (startDate !== "") {
      const afterStartDate = new Date(
        startDate.getTime() - startDate.getTimezoneOffset() * 60000
      ).toISOString();

      console.log(afterStartDate);

      onModifyContent(afterStartDate);
    } else {
      alert("방송 시작 일시를 확인해주세요");
      return;
    }
  };
  //콘텐츠 수정
  const onModifyContent = (a) => {
    console.log(contentInfo);
    console.log(originalContentInfo);
    let newContentInfo = {
      ...contentInfo,
      level: Number(contentInfo.level),
      duration: Number(contentInfo.duration),
      meta_data: JSON.stringify({ json: removeTempProp(metadata) }),
      time_table: JSON.stringify(removeTempProp(timetable)),
      is_all_meta_data_change: 0,
    };
    let newOriginalContentInfo = {
      ...originalContentInfo,
      level: Number(originalContentInfo.level),
      duration: Number(originalContentInfo.duration),
      meta_data: originalContentInfo.meta_data,
      time_table: originalContentInfo.time_table,
    };
    newContentInfo.start_date = a;
    newContentInfo.video_url =
      "https://forcewave.akamaized.net/cmaf/live/2031582/test/master.m3u8";

    if (newContentInfo.thumbnail_gradient_color) {
      console.log("!");
      if (newContentInfo.thumbnail_gradient_color.length === 6) {
        let a = `#${newContentInfo.thumbnail_gradient_color}`;
        newContentInfo.thumbnail_gradient_color = a;
      }
    } else {
      console.log("2");
    }

    // console.log(newContentInfo, newOriginalContentInfo);
    let params = diff(newContentInfo, newOriginalContentInfo);
    if (isEmpty(params)) {
      alert("변경사항이 없습니다");
      return;
    }
    if (typeof params.onair_personnel === "string") {
      let a = Number(params.onair_personnel);
      params.onair_personnel = a;
    }
    if (typeof params.onair_time === "string") {
      let a = Number(params.onair_time);
      params.onair_time = a;
    }
    if (typeof params.is_show === "string") {
      let a = Number(params.is_show);
      params.is_show = a;
    }
    console.log(params);
    // if(params.)
    console.log(params);

    axios
      .put(`${baseUrl}${contentInfo.content_id}`, params)
      .then((response) => {
        console.log(response);
        alert("수정되었습니다");
        closeModal();
        getContentList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //삭제
  const contentDelete = (content_id) => {
    if (!window.confirm("삭제하겠습니까?")) {
      return;
    }

    axios
      .delete(`${baseUrl}/${content_id}`)
      .then(() => {
        alert("삭제되었습니다.");
        getContentList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const onAddMetadata = useCallback(() => {
    setMetadata((prev) => {
      const no = Math.max(Math.max(...(prev || []).map((i) => i._id)), 0);
      const newObj = {
        _id: no + 1,
        description: "",
        duration: "",
        level: "",
        rpm: "",
        time: "",
      };
      let newMetadata = [...(prev || []), newObj];
      return newMetadata;
    }, []);
  }, []);

  const onChangeMetadata = useCallback((idx, data) => {
    setMetadata((prev) => {
      let newMetadata = [...prev];
      newMetadata[idx] = data;
      const duration = newMetadata.reduce((a, b) => a + Number(b.time), 0);
      newMetadata.reduce((a, b) => {
        b.duration = a;
        return a + b.time;
      }, 0);
      setContentInfo((prev) => ({
        ...prev,
        level: getLevelFromMetadata(newMetadata),
        duration: duration * 1000,
      }));
      return newMetadata;
    });
  }, []);

  const onDeleteMetadata = useCallback((idx) => {
    // console.log("delete")

    // console.log(newMetadata)
    setMetadata((prev) => {
      let newMetadata = [...prev];
      const duration = newMetadata.reduce((a, b) => a + Number(b.time), 0);
      newMetadata.reduce((a, b) => {
        b.duration = a;
        return a + b.time;
      }, 0);
      setContentInfo((prev) => ({
        ...prev,
        level: getLevelFromMetadata(newMetadata),
        duration: duration * 1000,
      }));
      return (prev || []).filter((_, index) => {
        // console.log(index,idx)
        return index !== idx;
      });
    });
  }, []);

  const onChangedTimetable = useCallback((idx, data) => {
    setTimetable((prev) => {
      let newTimetable = [...prev];
      newTimetable[idx] = data;
      return newTimetable;
    }, []);
  }, []);

  const handleButtonClick = () => {
    setIsDataVisible(!isDataVisible);
  };

  const handleTimetableButtonClick = () => {
    setIsTimetableVisible(!isTimetableVisible);
  };

  return (
    <>
      <Pane>
        <Dialog
          width={700}
          isShown={isModalOpened}
          onCloseComplete={() => closeModal(false)}
          confirmLabel={modalTypeOption[modalType].button}
          title={modalTypeOption[modalType].title}
          onConfirm={modalTypeOption[modalType].action}
          shouldCloseOnEscapePress={false}
          shouldCloseOnOverlayClick={false}
          hasCancel={false}
          hasClose={true}
          hasFooter={true}
        >
          <InputDiv>
            <InputSpan>콘텐츠 타입</InputSpan>
            {modalType === "MOD" ? (
              contentInfo.content_type
            ) : (
              <RadioGroup
                display="flex"
                columnGap="10px"
                size={16}
                name="content_type"
                options={contentTypeOptions}
                value={contentInfo.content_type}
                defaultValue={contentInfo.content_type}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            )}
          </InputDiv>
          <InputDiv>
            {modalType === "ADD" ? null : (
              <>
                <InputSpan>콘텐츠 ID</InputSpan>
                <TextInput
                  width="100%"
                  readOnly
                  name="content_id"
                  value={contentInfo.content_id}
                />
              </>
            )}
          </InputDiv>
          <InputDiv>
            <InputSpan>방송 시작 일시</InputSpan>
            <DatepickerWrapper>
              <SDatePicker
                locale={ko}
                dateFormat={"yyyy년 MM월 dd일 h:mm aa"}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                showTimeInput
              />
            </DatepickerWrapper>
          </InputDiv>
          <InputDiv>
            <InputSpan>방송 시간(분)</InputSpan>
            <TextInput
              width="100%"
              type="number"
              name="onair_time"
              value={contentInfo.onair_time}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 3) {
                  handleChange(e);
                }
              }}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>입장 비밀번호</InputSpan>
            <TextInput
              width="100%"
              type="number"
              name="password"
              value={contentInfo.password}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 4) {
                  handleChange(e);
                }
              }}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>정원</InputSpan>
            <TextInput
              width="100%"
              type="number"
              name="onair_personnel"
              value={contentInfo.onair_personnel}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 3) {
                  handleChange(e);
                }
                if (value > 250) {
                  alert("최대 정원은 250명 까지 가능합니다");
                  setContentInfo({ ...contentInfo, onair_personnel: "" });
                }
              }}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>방송 대기 배경 이미지</InputSpan>
            <FileUploader
              directory="lessonData/live"
              preview_src={contentInfo._onair_wait_image_preview}
              targetObj={contentInfo}
              onUploaded={setContentInfo}
              targetPropName="onair_wait_image"
              isImage={true}
              accept={"image/*"}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>운동 종료 배경 이미지</InputSpan>
            <FileUploader
              directory="lessonData/live"
              preview_src={contentInfo._act_end_image_preview}
              targetObj={contentInfo}
              onUploaded={setContentInfo}
              targetPropName="act_end_image"
              isImage={true}
              accept={"image/*"}
            />
          </InputDiv>

          <InputDiv>
            {modalType === "ADD" ? (
              <>
                <InputSpan>앱 노출 여부</InputSpan>
                미노출
              </>
            ) : null}
          </InputDiv>

          <InputDiv>
            <InputSpan>콘텐츠 제목</InputSpan>
            <TextareaField
              width="100%"
              name="title"
              onChange={handleChange}
              value={contentInfo.title}
              maxLength={20}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>콘텐츠 설명</InputSpan>
            <TextareaField
              width="100%"
              name="description"
              onChange={handleChange}
              value={contentInfo.description}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>썸네일 이미지</InputSpan>
            <FileUploader
              directory="lessonData/thumbnail"
              preview_src={contentInfo._thumbnail_image_preview}
              targetObj={contentInfo}
              onUploaded={setContentInfo}
              targetPropName="thumbnail_image"
              isImage={true}
              accept={"image/*"}
              onSelectColor={handleColorChange}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>배경 이미지</InputSpan>
            <FileUploader
              directory="lessonData/background"
              preview_src={contentInfo._background_image_preview}
              targetObj={contentInfo}
              onUploaded={setContentInfo}
              targetPropName="background_image"
              isImage={true}
              accept={"image/*"}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>라이딩 노출 이미지</InputSpan>
            <FileUploader
              directory="lessonData/liding"
              preview_src={contentInfo._riding_exposure_image_preview}
              targetObj={contentInfo}
              onUploaded={setContentInfo}
              targetPropName="riding_exposure_image"
              isImage={true}
              accept={"image/*"}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>데이터</InputSpan>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: "1",
              }}
            >
              <VisibleBtn onClick={handleButtonClick}>
                {isDataVisible ? "숨기기" : "보이기"}
              </VisibleBtn>
              {isDataVisible && (
                <>
                  <DndProvider backend={HTML5Backend}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        // , height:"500px", overflowY: "scroll"
                      }}
                    >
                      {metadata &&
                        metadata.map((item, idx) => {
                          return (
                            <MetaData
                              key={item._id}
                              idx={idx}
                              item={item}
                              onDelete={onDeleteMetadata}
                              onChange={onChangeMetadata}
                              disabled={contentInfo.is_show === 1}
                            />
                          );
                        })}
                    </div>
                  </DndProvider>
                  <div>
                    <Button
                      style={{
                        width: "30%",
                        marginRight: "10px",
                      }}
                      appearance="primary"
                      onClick={onAddMetadata}
                    >
                      신규 데이터 추가
                    </Button>
                    <LoadMetadata
                      instructorList={instructorList}
                      onSelectContent={(id, meta) => {
                        console.log(id, meta);
                        let newmeta = meta.map((item, idx) => ({
                          ...item,
                          _id: idx,
                        }));
                        console.log(newmeta);
                        setMetadata(newmeta);
                        setLoadedMetadata(newmeta);
                        setLoadedContentId(id);

                        const duration = newmeta.reduce(
                          (a, b) => a + Number(b.time),
                          0
                        );
                        setContentInfo((prev) => ({
                          ...prev,
                          level: getLevelFromMetadata(newmeta),
                          duration: duration * 1000,
                        }));
                      }}
                    >
                      기존 데이터 추가
                    </LoadMetadata>
                  </div>
                </>
              )}
            </div>
          </InputDiv>
          <InputDiv>
            <InputSpan>타임테이블</InputSpan>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: "1",
              }}
            >
              <VisibleBtn onClick={handleTimetableButtonClick}>
                {isTimetableVisible ? "숨기기" : "보이기"}
              </VisibleBtn>
              {isTimetableVisible && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      padding: "5px 0",
                    }}
                  >
                    {timetable &&
                      timetable.map((item, idx) => {
                        return (
                          <Timetable
                            key={item._id}
                            item={item}
                            idx={idx}
                            onChangedTimetable={onChangedTimetable}
                          />
                        );
                      })}
                  </div>
                </>
              )}
            </div>
          </InputDiv>
          <InputDiv>
            <InputSpan>방송 환경 URL(임시)</InputSpan>
            <TextInput
              width="100%"
              name="video_url"
              value={
                "https://forcewave.akamaized.net/cmaf/live/2031582/test/master.m3u8"
              }
              readOnly
            />
          </InputDiv>

          <InputDiv>
            <InputSpan>그룹</InputSpan>
            <TagInput
              width="100%"
              inputProps={{ placeholder: "Enter something..." }}
              values={getTagInputTitle(
                originalContentInfo,
                contentInfo,
                "_group_codes",
                groupList,
                "group_code",
                "title"
              )}
              onChange={(e) => {
                setTagInputValue(
                  originalContentInfo,
                  contentInfo,
                  "_group_codes",
                  e,
                  groupList,
                  "group_code",
                  "title"
                );
              }}
              autocompleteItems={tagInputAutoCompleteList(
                contentInfo,
                "_group_codes",
                groupList,
                "group_code",
                "title"
              )}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>강사</InputSpan>
            <TagInput
              width="100%"
              inputProps={{ placeholder: "강사 검색" }}
              values={getTagInputTitle(
                originalContentInfo,
                contentInfo,
                "_instructor_ids",
                instructorList,
                "instructor_id",
                "name"
              )}
              onChange={(e) => {
                setTagInputValue(
                  originalContentInfo,
                  contentInfo,
                  "_instructor_ids",
                  e,
                  instructorList,
                  "instructor_id",
                  "name"
                );
              }}
              autocompleteItems={tagInputAutoCompleteList(
                contentInfo,
                "_instructor_ids",
                instructorList,
                "instructor_id",
                "name"
              )}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>태그</InputSpan>
            <TagInput
              width="100%"
              inputProps={{ placeholder: "태그 검색" }}
              values={getTagInputTitle(
                originalContentInfo,
                contentInfo,
                "_tag_ids",
                tagList,
                "tag_id",
                "title"
              )}
              onChange={(e) => {
                setTagInputValue(
                  originalContentInfo,
                  contentInfo,
                  "_tag_ids",
                  e,
                  tagList,
                  "tag_id",
                  "title"
                );
              }}
              autocompleteItems={tagInputAutoCompleteList(
                contentInfo,
                "_tag_ids",
                tagList,
                "tag_id",
                "title"
              )}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>음원</InputSpan>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <MusicSearch
                musicIds={musicIds}
                initialFullMusicList={musicList}
                onSetMusicIds={onSetMusicIds}
              />
            </div>
          </InputDiv>
          <InputDiv>
            <InputSpan>사용 여부</InputSpan>
            <RadioGroup
              display="flex"
              columnGap="10px"
              size={16}
              name="is_show"
              value={String(contentInfo.is_show)}
              defaultValue={String(contentInfo.is_show)}
              options={IsShowOption}
              onChange={handleChange}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>라이브 방 개설 여부</InputSpan>
            {/* <RadioGroup
              display="flex"
              columnGap="10px"
              size={16}
              name="exist_live_room"
              value={String(contentInfo.exist_live_room)}
              defaultValue={String(contentInfo.exist_live_room)}
              options={IsShowOption}
              onChange={handleChange}
            /> */}
            {contentInfo.exist_live_room === 0 ? (
              <>
                <span
                  style={{
                    width: "20%",
                  }}
                >
                  미 개설
                </span>
                <Button
                  intent="success"
                  onClick={() => {
                    existLiveRoomButton(contentInfo);
                  }}
                >
                  개설
                </Button>
              </>
            ) : (
              "개설"
            )}
          </InputDiv>
        </Dialog>
      </Pane>

      <ContentBox>
        <Pane>
          <ContentH1>라이브 콘텐츠</ContentH1>
        </Pane>
        <Box>
          <div
            style={{ display: "flex", flexDirection: "column", width: "25%" }}
          >
            <RadioGroup
              style={{
                display: "flex",
                alignItems: "center",
                wordBreak: "keep-all",
                gap: "10px",
              }}
              value={liveFilter}
              options={liveFilterOption}
              onChange={(event) => beforeGetContentList(event.target.value)}
            />
          </div>

          <SearchBtn>
            <TextInput
              placeholder="VID, 제목, 강사, 등록일(0000-00-00)"
              width={"250px"}
              onChange={(e) => {
                const keyword = e.target.value;
                setItemKeyword(keyword);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  getContentList();
                }
              }}
            ></TextInput>
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "36%",
                bottom: "20px",
                marginLeft: "10px",
              }}
              onClick={() => {
                getContentList();
              }}
            >
              검색
            </Button>
          </SearchBtn>
          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={() => addContentModal({ content_type: contentType.LIVE })}
          >
            등록하기
          </Button>
        </Box>

        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell flexBasis={120}>
              콘텐츠 ID
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>타입</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120}>제목</Table.TextHeaderCell>
            <Table.TextHeaderCell>강사</Table.TextHeaderCell>
            <Table.TextHeaderCell>콘텐츠 이미지</Table.TextHeaderCell>
            <Table.TextHeaderCell>운동 강도</Table.TextHeaderCell>
            <Table.TextHeaderCell>등록일</Table.TextHeaderCell>
            {/* <Table.TextHeaderCell>상태</Table.TextHeaderCell> */}
            <Table.TextHeaderCell>라이브방</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {contentList.map((content) => {
              let _instructorList = (content.instructor_ids || "").split(",");

              return (
                <Table.Row height="auto" key={content.content_id}>
                  <Table.TextCell flexBasis={120}>
                    {content.content_id}
                  </Table.TextCell>
                  <Table.TextCell>{content.content_type}</Table.TextCell>
                  <Table.TextCell flexBasis={120}>
                    {content.title}
                  </Table.TextCell>
                  <Table.TextCell>
                    {_instructorList
                      .filter((i) => i !== "")
                      .map((item) => {
                        let a = instructorList.find(
                          (i) => i.instructor_id == item
                        );
                        if (!a) {
                          return;
                        }
                        return (
                          <InputDiv
                            style={{
                              justifyContent: "center",
                            }}
                            key={a.instructor_id}
                          >
                            {a.name}
                          </InputDiv>
                        );
                      })}
                  </Table.TextCell>
                  <Table.Cell justifyContent="center">
                    <ImgPreview
                      style={{ width: "150px" }}
                      src={content.thumbnail_image_url}
                    />
                  </Table.Cell>
                  <Table.TextCell>{content.level}</Table.TextCell>
                  <Table.TextCell>
                    <DateText minimum>{content.create_date}</DateText>
                  </Table.TextCell>
                  {/* <Table.Cell
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      isShowButton(content);
                    }}
                  >
                    {content.is_show ? "등록 완료" : "등록 중"}
                  </Button>
                </Table.Cell> */}
                  {content.is_show === 1 ? (
                    <Table.Cell
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      {content.exist_live_room === 0 ? (
                        <Button
                          intent="success"
                          onClick={() => {
                            existLiveRoomButton(content);
                          }}
                        >
                          개설
                        </Button>
                      ) : (
                        <Button intent="success" disabled>
                          개설완료
                        </Button>
                      )}
                    </Table.Cell>
                  ) : (
                    <Table.TextCell></Table.TextCell>
                  )}
                  <Table.Cell flexBasis={120} flexShrink={0} flexGrow={0}>
                    <Button
                      onClick={async () => {
                        const _content = await getContentInfo(
                          content.content_id
                        );
                        console.log("content", _content);
                        modifyContentModal(_content);
                      }}
                    >
                      상세
                    </Button>
                    {content.exist_live_room === 0 ? (
                      <Button
                        intent="danger"
                        onClick={() => {
                          contentDelete(content.content_id);
                        }}
                      >
                        삭제
                      </Button>
                    ) : (
                      <Button disabled>삭제</Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {
          <Pane
            height={120}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Pagination
              page={currentPage}
              totalPages={Math.ceil(totalCount / PAGE_SIZE)}
              onNextPage={() => {
                setCurrentPage(currentPage + 1);
              }}
              onPreviousPage={() => {
                setCurrentPage(currentPage - 1);
              }}
              onPageChange={(page) => setCurrentPage(page)}
            ></Pagination>
          </Pane>
        }
      </ContentBox>
    </>
  );
};
const ContentBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const ContentH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const InputSpan = styled.span`
  width: 150px;
  flex-shrink: 0;
  font-size: 12px;
`;

const ImgPreview = styled.img`
  width: 200px;
`;
const SearchBtn = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const VisibleBtn = styled.button`
  margin-bottom: 5px;
  background-color: #52bd95;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 0;
  cursor: pointer;
`;
const Box = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
`;
const DatepickerWrapper = styled.div`
  width: 100%;
`;
const SDatePicker = styled(DatePicker)`
  width: 486px;
  border-color: #d8dae5;
  border: 1px solid #d8dae5;
  padding: 0 12px;
  line-height: 16px;
  font-size: 12px;
  transition: box-shadow 80ms ease-in-out;
  height: 32px;
  border-radius: 4px;
  color: #474d66;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
`;
export default LiveContent;
