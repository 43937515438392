import React, { useEffect, useState, memo } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import { Pane, Table, Dialog, Button, Pagination } from "evergreen-ui";

const baseUrl = "/admin/item/";
const userUrl = "/admin/user/";

const ItemSearch = ({
  handleClick,
  userId,
  userItemInfo,
  userItemIds,
  initialFullItemList,
  onSetItemIds,
}) => {
  const PAGE_SIZE = 5;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [fullItemList, setFullItemList] = useState(initialFullItemList);
  const [itemSearchList, setItemSearchList] = useState([]);
  const [itemIdsInfo, setItemIdsInfo] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");

  const modalTypeOption = {
    ADD: {
      button: "저장",
      title: "이용권 관리",
      action: () => {
        onSaveItemList();
      },
    },
  };

  useEffect(() => {
    getItemList();
  }, [currentPage]);

  const itemSearchModal = () => {
    openModal();
  };

  const openModal = () => {
    console.log(userItemInfo);
    setItemIdsInfo(userItemInfo);
    // setItemIdsInfo(userItemIds);
    getFullItemList();
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setItemIdsInfo([]);
    setIsModalOpened(false);
    handleClick();
  };

  const getFullItemList = async () => {
    const params = {
      keyword: "ADMIN",
    };

    try {
      const response = await axios.get(baseUrl, { params });
      console.log("item-list", response);
      setFullItemList(response.data.item_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getItemList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      // keyword: musicKeyword,
      keyword: "ADMIN",
    };

    // console.log(params);

    axios
      .get(baseUrl, { params })
      .then((res) => {
        // console.log(res);
        const data = res.data.item_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        setItemSearchList(data);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  const onAddItemList = async (item) => {
    await postItem(item);

    // if (addItem === true) {
    //   console.log("ASDFASDF");
    // }

    // console.log(itemIdsInfo);
    // let newItemIdsInfo = [...itemIdsInfo, item.item_id];
    // setItemIdsInfo(newItemIdsInfo);
  };

  const onRemoveItemList = async (item) => {
    // console.log(item);
    // return;
    await deleteItem(item);

    // let newItemIdsInfo = userItemIds.filter((i) => i !== item.item_id);
    // console.log(userItemIds, newItemIdsInfo);
    // // setMusicIdsInfo(newItemIdsInfo)
    // onSetItemIds(newItemIdsInfo);
    // setItemIdsInfo(newItemIdsInfo);
  };

  const onSaveItemList = () => {
    onSetItemIds(itemIdsInfo);
    closeModal();
  };

  const postItem = async (item) => {
    if (window.confirm("이용권을 추가하시겠습니까?")) {
      console.log(userId);
      console.log(item);

      //  if(typeof userItemInfo %% );
      let params = { item_id: item.item_id };

      console.log(params);
      await axios
        .post(`${userUrl}${userId}/item`, params)
        .then((response) => {
          console.log(response);
          alert("이용권 추가가 완료되었습니다");
          // getItemList();
          closeModal();
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
        });
    }
  };
  const deleteItem = async (list) => {
    if (window.confirm("이용권을 삭제하시겠습니까?")) {
      console.log(userItemInfo);
      console.log(list);

      const matchingData = userItemInfo.find(
        (item) => item.item_id === list.item_id
      );

      // console.log(matchingData);

      // let params = { item_id: item.item_id };

      await axios
        .delete(`${userUrl}item/${matchingData.user_item_id}`)
        .then((response) => {
          console.log(response);
          alert("이용권 삭제가 완료되었습니다");
          getItemList();
          // setAddItem(true);
          closeModal();
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
        });
    }
  };

  function renderType(value) {
    switch (value) {
      case "SUBSCRIPTION":
        return "구독권";
      case "TICKET":
        return "횟수권";
      default:
        return value;
    }
  }
  function renderUnit(value) {
    switch (value) {
      case "T":
        return "회";
      case "D":
        return "일";
      case "M":
        return "월";
      case "Y":
        return "년";
      default:
        return value;
    }
  }
  function renderMethod(value) {
    switch (value) {
      case "PAYMENT":
        return "구매";
      case "ADMIN":
        return "관리자 부여";
      case "ADDITIONAL":
        return "이용권 구매 시 증정";
      default:
        return value;
    }
  }
  function renderState(value) {
    switch (value) {
      case "USABLE":
        return "사용 가능";
      case "USING":
        return "사용 중";
      case "USED":
        return "사용 완료";
      case "STOPPED":
        return "중지";
      case "EXPIRED":
        return "만료";
      default:
        return value;
    }
  }

  return (
    <Pane style={{ width: "100%" }}>
      <Dialog
        width={"900px"}
        isShown={isModalOpened}
        onCloseComplete={() => closeModal()}
        confirmLabel={modalTypeOption[modalType].button}
        onConfirm={modalTypeOption[modalType].action}
        hasClose={false}
        title={modalTypeOption[modalType].title}
      >
        {/* <InputDiv>
          <InputSpan>이용권 검색</InputSpan>
          <TextInput
            onChange={(e) => {
              const keyword = e.target.value;
              setMusicKeyword(keyword);
            }}
          ></TextInput>
          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={getMusicList}
          >
            검색
          </Button>
        </InputDiv> */}
        {itemSearchList.length > 0 && (
          <Pane>
            <Table width="100%" textAlign="center">
              <Table.Head>
                <Table.TextHeaderCell>이용권 명</Table.TextHeaderCell>
                <Table.TextHeaderCell>타입</Table.TextHeaderCell>
                <Table.TextHeaderCell>획득 방식</Table.TextHeaderCell>
                <Table.TextHeaderCell>기간</Table.TextHeaderCell>
                <Table.TextHeaderCell>상태</Table.TextHeaderCell>
                <Table.TextHeaderCell
                  flexBasis={180}
                  flexShrink={0}
                  flexGrow={0}
                >
                  관리
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height="auto">
                {itemSearchList.map((item) => {
                  const itemInfo = itemIdsInfo.find(
                    (info) => info.item_id === item.item_id
                  );
                  if (!itemInfo) {
                    return (
                      <Table.Row key={item.item_id}>
                        <Table.TextCell>{item.title}</Table.TextCell>
                        <Table.TextCell>
                          {renderType(item.item_type)}
                        </Table.TextCell>
                        <Table.TextCell>
                          {renderMethod(item.method)}
                        </Table.TextCell>
                        <Table.TextCell>
                          {item.apply_value}
                          {renderUnit(item.apply_unit)}
                        </Table.TextCell>
                        <Table.TextCell>{item.state}</Table.TextCell>
                        <Table.TextCell
                          flexBasis={180}
                          flexShrink={0}
                          flexGrow={0}
                        >
                          <Button
                            appearance="primary"
                            onClick={() => onAddItemList(item)}
                          >
                            추가
                          </Button>
                        </Table.TextCell>
                      </Table.Row>
                    );
                  } else {
                    const mergedItem = { ...item, ...itemInfo }; // item과 itemInfo를 병합하여 새로운 객체 생성
                    return (
                      <Table.Row key={mergedItem.item_id}>
                        <Table.TextCell>{mergedItem.title}</Table.TextCell>
                        <Table.TextCell>
                          {renderType(mergedItem.item_type)}
                        </Table.TextCell>
                        <Table.TextCell>
                          {renderMethod(mergedItem.method)}
                        </Table.TextCell>
                        <Table.TextCell>
                          {mergedItem.apply_value}
                          {renderUnit(mergedItem.apply_unit)}
                        </Table.TextCell>
                        <Table.TextCell>
                          <Table.TextCell>
                            {renderState(mergedItem.state)}
                          </Table.TextCell>
                        </Table.TextCell>
                        <Table.TextCell
                          flexBasis={180}
                          flexShrink={0}
                          flexGrow={0}
                        >
                          {/* {mergedItem.state === "STOPPED" ? (
                            <Button
                              appearance="primary"
                              onClick={() => onAddItemList(mergedItem)}
                            >
                              추가
                            </Button>
                          ) : (
                            <Button
                              appearance="primary"
                              intent="danger"
                              onClick={() => onRemoveItemList(mergedItem)}
                            >
                              제거
                            </Button>
                          )} */}

                          {mergedItem.item_type === "SUBSCRIPTION" ? (
                            mergedItem.state === "STOPPED" ? (
                              <Button
                                appearance="primary"
                                onClick={() => onAddItemList(mergedItem)}
                              >
                                추가
                              </Button>
                            ) : (
                              <Button
                                appearance="primary"
                                intent="danger"
                                onClick={() => onRemoveItemList(mergedItem)}
                              >
                                제거
                              </Button>
                            )
                          ) : (
                            <div>
                              <Button
                                appearance="primary"
                                onClick={() => onAddItemList(mergedItem)}
                              >
                                추가
                              </Button>
                              <Button
                                appearance="primary"
                                intent="danger"
                                onClick={() => onRemoveItemList(mergedItem)}
                              >
                                제거
                              </Button>
                            </div>
                          )}
                        </Table.TextCell>
                      </Table.Row>
                    );
                  }
                })}
              </Table.Body>
            </Table>
            <Pane
              height={80}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Pagination
                page={currentPage}
                totalPages={Math.ceil(totalCount / PAGE_SIZE)}
                onNextPage={() => {
                  setCurrentPage(currentPage + 1);
                }}
                onPreviousPage={() => {
                  setCurrentPage(currentPage - 1);
                }}
                onPageChange={(page) => setCurrentPage(page)}
              ></Pagination>
            </Pane>
          </Pane>
        )}
      </Dialog>

      <InputDiv>
        {userItemIds && userItemIds.length > 0 && (
          <Pane>
            <Table textAlign="center" width="500px">
              <Table.Head>
                <Table.TextHeaderCell>이용권 명</Table.TextHeaderCell>
                <Table.TextHeaderCell>타입</Table.TextHeaderCell>
                <Table.TextHeaderCell>기간</Table.TextHeaderCell>
                <Table.TextHeaderCell>상태</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height="auto">
                {userItemInfo.map((i) => {
                  const item = fullItemList.find(
                    (it) => it.item_id === i.item_id
                  );
                  if (!item) return <></>;

                  return (
                    <Table.Row key={item.user_item_id}>
                      <Table.TextCell>{item.title}</Table.TextCell>
                      <Table.TextCell>
                        {renderType(item.item_type)}
                      </Table.TextCell>
                      <Table.TextCell>
                        {item.apply_value}
                        {renderUnit(item.apply_unit)}
                      </Table.TextCell>
                      <Table.TextCell>{renderState(i.state)}</Table.TextCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Pane>
        )}
      </InputDiv>
      <Button
        appearance="primary"
        intent="none"
        style={{
          position: "inherit",
          left: "36%",
          bottom: "20px",
        }}
        onClick={() => itemSearchModal()}
      >
        검색하기
      </Button>
    </Pane>
  );
};

const memorizedMusicSearch = memo(ItemSearch);

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
`;

export default memorizedMusicSearch;
