import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import { Link } from "react-router-dom";
import FileUploader from "components/FileUploader";

import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  Text,
  Radio,
  SelectMenu,
} from "evergreen-ui";
import DateText from "components/DateText";
import ChooseContent from "./ChooseContent";
import { diff, isEmpty, stateToOptions, msToTime } from "services/util";
// import ModalBasic from "./InstructorModal";
// import UpdateModalBasic from "./InstuctorUpdate";

const baseUrl = "/admin/advertisement/inplay/";
const adUrl = "/admin/advertisement/contract";
const contentListUrl = "/admin/advertisement/inplay/content";
const groupUrl = "/admin/group";

const InplayAdvertisement = () => {
  const PAGE_SIZE = 20;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [originalInplayAdvertisementInfo, setOriginalInplayAdvertisementInfo] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [contractList, setContractList] = useState([]);
  const [searchContent, setSearchContent] = useState(null)
  const [searchGroup, setSearchGroup] = useState(null)
  const [searchState, setSearchState] = useState('EXPOSURED')
  const [inplayAdvertisementInfo, setInplayAdvertisementInfo] = useState([]);
  const [inplayAdvertisementList, setInplayAdvertisementList] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");

  const STATE = {
    "REGISTERATED": "등록됨",
    "EXPOSURED": "노출중",
    "TERMINATED": "종료됨"
  }

  const SEARCH_STATE = {
    "EXPOSURED": "노출중",
    "TERMINATED": "종료됨"
  }

  const AD_TYPE = {
    "INPLAY_CARD": "카드",
    "INPLAY_POPUP": "팝업",
  }
  const [STATE_OPTIONS] = useState(stateToOptions(STATE))
  const [AD_TYPE_OPTIONS] = useState(stateToOptions(AD_TYPE))

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "등록",
      action: () => {
        onAddInplayAdvertisement();
      },
    },
    MOD: {
      button: "수정하기",
      title: "정보 수정",
      action: () => {
        onModifyAdvertisement();
      },
    },
  };
  useEffect(()=>{
    getContentList()
    getGroupList()
    getContractList()
  },[])

  useEffect(() => {
    getInplayAdvertisementList();
  }, [currentPage, searchContent, searchGroup, searchState]);
  
  //인풋 수정시
  const handleChange = (e) => {
    // console.log(e.target.name, e.target.value);
    setInplayAdvertisementInfo({ ...inplayAdvertisementInfo, [e.target.name]: e.target.value });
  };

  const addAdvertisementModal = (advertisement) => {
    openModal(advertisement, "ADD");
  };

  const modifyAdvertisementModal = (advertisement) => {
    openModal(advertisement, "MOD");
  };

  const openModal = (advertisement, type) => {
    // console.log(advertisement)
    setOriginalInplayAdvertisementInfo(advertisement);
    setInplayAdvertisementInfo(advertisement);
    setModalType(type);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setOriginalInplayAdvertisementInfo({
      _use_content: 'false'
    });
    setInplayAdvertisementInfo({
      _use_content: 'false'
    });
    setIsModalOpened(false);
  };

  const onChooseContent = (item) => {
    let vid = item.content_id
    let title = item.title

    setInplayAdvertisementInfo({
      ...inplayAdvertisementInfo,
      content_id: vid,
      _title: title
    })
  }

  const getContentList = async () => {
    try{
      const response = await axios.get(`${contentListUrl}`)
      setContentList([{content_id:null, title:'전체'},{content_id:'0', title:'공통'}, ...response.data.content_list])
    } catch(error) {
      console.log(error);
      alert(error.response) ;
    };
  }

  const getGroupList = async () => {
    try {
      const response = await axios.get(groupUrl);
      // console.log("groupList", response);
      setGroupList([{group_code: null, title: "전체"}, ...response.data.group_list]);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };


  const getContractList = async () => {
    try {
      const response = await axios.get(adUrl);
      // console.log("groupList", response);
      setContractList(response.data.advertisement_contract_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getInplayAdvertisementList = async () => {
    try{
      const params = {
        limit: PAGE_SIZE,
        offset: PAGE_SIZE * (currentPage - 1),
        keyword: searchContent || searchGroup,
        state: searchState
      };

      const response = await axios.get(`${baseUrl}`,{params})
      let newInplayAdvertisementInfo = [
        ...response.data.inplay_advertisement_list
      ]

      newInplayAdvertisementInfo.reverse().reduce((beforeObj, currentObj) => {
        if(beforeObj.content_id !== currentObj.content_id){
          currentObj.content_rowpan = 1
          currentObj.group_rowpan = 1
        }else {
          currentObj.content_rowpan = beforeObj.content_rowpan + 1
          beforeObj.content_rowpan = null
          if( beforeObj.group_title !== currentObj.group_title){
            currentObj.group_rowpan = 1
          }else{
            currentObj.group_rowpan = beforeObj.group_rowpan + 1
            beforeObj.group_rowpan = null
          }
        }
        return currentObj
      },{ content_id:null, group_title:null, content_rowpan:null, group_rowpan:null,})

      newInplayAdvertisementInfo = newInplayAdvertisementInfo.map(item=>{
        return {
          ...item,
          _use_content : item.content_id ? "true" :"false",
          _image_preview: item.image_url,
          start_time: item.start_time/1000,
          end_time: item.end_time/1000,
        }
      })

      // console.log(newInplayAdvertisementInfo)
      setInplayAdvertisementList(newInplayAdvertisementInfo.reverse())
      setTotalCount(response.data.count);
    } catch(error) {
      console.log(error);
      alert(error.response) ;
    };
  }

  const inplayValidation = (inplayInfo) => {
    if(inplayInfo._use_content === "true") {
      if( !inplayInfo.content_id ) {
        alert("콘텐츠를 선택해주세요")
        return false
      }
    }

    if(!inplayInfo.ad_type) {
      alert("광고 타입을 선택해주세요")
      return false
    }

    if(!inplayInfo.ad_contract_id){
      alert("광고 계약 정보를 입력해주세요")
      return false
    }

    if(!inplayInfo.title){
      alert("광고명을 입력해주세요")
      return false
    }

    if( inplayInfo.start_time!=0 && (!inplayInfo.start_time || !inplayInfo.end_time) ){
      alert("시작 시간/종료 시간을 입력해주세요")
      return false
    }

    if( inplayInfo.start_time >= inplayInfo.end_time ){
      alert("시작시간은 종료시간보다 클수 없습니다.")
      return false
    }

    if( !inplayInfo.image_url ){
      alert("광고 이미지를 등록해주세요")
      return false
    }

    if( !inplayInfo.ad_url ){
      alert('발송 url을 입력해주세요')
      return false
    }
    return true
  }

  // 등록
  const onAddInplayAdvertisement = () => {
    let newInplayAdvertisementInfo = {
      ...inplayAdvertisementInfo,
      start_time: Number(inplayAdvertisementInfo.start_time)*1000,
      end_time: Number(inplayAdvertisementInfo.end_time)*1000,
    }
    
    if(newInplayAdvertisementInfo._use_content === "false"){
      delete newInplayAdvertisementInfo.content_id
      // console.log("a",newInplayAdvertisementInfo)
    }

    if( !inplayValidation(newInplayAdvertisementInfo) ) {
      return 
    }

    let params = diff(newInplayAdvertisementInfo, originalInplayAdvertisementInfo);

    if (isEmpty(params)) {
      alert("변경사항이 없습니다");
      return;
    }
    
    let newParams = {}

    if( params.content_id ) {
      newParams = {
        content_id: params.content_id
      }
      delete params.content_id
    }

    newParams = {
      ...newParams,
      advertisements:[
        params
      ]
    }

    // console.log(newParams)

    // return

    axios
      .post(baseUrl, newParams)
      .then((response) => {
        // console.log(response);
        alert("등록되었습니다");
        getInplayAdvertisementList();
        closeModal();
        setCurrentPage(1);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  // 수정
  const onModifyAdvertisement = () => {
    let newInplayAdvertisementInfo = {
      ...inplayAdvertisementInfo,
      content_id: inplayAdvertisementInfo._use_content === "false" ? "" : inplayAdvertisementInfo.content_id,
      start_time: Number(inplayAdvertisementInfo.start_time)*1000,
      end_time: Number(inplayAdvertisementInfo.end_time)*1000,
    }


    let newOriginalInplayAdvertisementInfo = {
      ...originalInplayAdvertisementInfo,
      start_time: Number(originalInplayAdvertisementInfo.start_time)*1000,
      end_time: Number(originalInplayAdvertisementInfo.end_time)*1000,
    }

    delete newInplayAdvertisementInfo.group_titles
    delete newOriginalInplayAdvertisementInfo.ad_id
    delete newOriginalInplayAdvertisementInfo.group_titles


    // console.log("a",newInplayAdvertisementInfo, newOriginalInplayAdvertisementInfo)

    if( !inplayValidation(newInplayAdvertisementInfo) ) {
      return 
    }

    let params = diff(newInplayAdvertisementInfo, newOriginalInplayAdvertisementInfo);

    if (isEmpty(params)) {
      alert("변경사항이 없습니다");
      return;
    }
    
    let newParams = {}

    if( params.hasOwnProperty('content_id') ) {
      newParams = {
        content_id: params.content_id
      }
    }
    
    delete params.content_id

    newParams = {
      ...newParams,
      update_advertisements:[
        params
      ]
    }

    // console.log(newParams)
    
    // return

    axios
      .put(`${baseUrl}${inplayAdvertisementInfo.inplay_ad_id}`, newParams)
      .then((response) => {
        // console.log(response);
        alert("수정되었습니다");
        getInplayAdvertisementList();
        closeModal()
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={modalTypeOption[modalType].button}
          onConfirm={modalTypeOption[modalType].action}
          hasClose={false}
          title={modalTypeOption[modalType].title}
        >
          <InputDiv>
            <InputSpan>광고 적용 범위</InputSpan>
            <div>
              <FlexDiv>
                <Radio checked={inplayAdvertisementInfo._use_content === "false"} name="_use_content" value={'false'} label="공통" style={{marginRight: '20px'}} onChange={handleChange}/>
                <Radio checked={inplayAdvertisementInfo._use_content === "true"} name="_use_content" value={'true'} label="특정 콘텐츠" onChange={handleChange}/>
              </FlexDiv>
              {inplayAdvertisementInfo._use_content === "true" && (
                <div>
                  <ChooseContent
                    onSelect={onChooseContent}
                  />
                  {inplayAdvertisementInfo.content_id && (
                    <FlexDiv style={{alignItems:'center', marginTop: '5px'}}>
                      <div style={{marginLeft: '5px', fontSize: '14px'}}>
                      {`[${inplayAdvertisementInfo.content_id}] 
                        ${inplayAdvertisementInfo._title ? 
                        inplayAdvertisementInfo._title : 
                        contentList.find(i=>i.content_id === inplayAdvertisementInfo.content_id).title}
                      `}
                      </div>
                      <Button
                        appearance="primary"
                        intent="danger"
                        style={{marginLeft:"5px"}}
                        onClick={() => {
                          setInplayAdvertisementInfo({
                            ...inplayAdvertisementInfo,
                            content_id : null,
                            _title: null
                          })
                        }}
                      >
                      X
                      </Button>
                    </FlexDiv>
                  )}
                </div>
              )}
            </div>
          </InputDiv>
          <InputDiv>
            <InputSpan>광고 타입</InputSpan>
            {modalType === "MOD" ? (
              <Button
                disabled
                name="ad_type"
                >
                {AD_TYPE[inplayAdvertisementInfo.ad_type]}
              </Button>
            ) : (
            <SelectMenu
              height={AD_TYPE_OPTIONS.length*33}
              hasFilter={false}
              hasTitle={false}
              closeOnSelect={true}
              options={AD_TYPE_OPTIONS}
              selected={inplayAdvertisementInfo.ad_type}
              onSelect={(item) => setInplayAdvertisementInfo({...inplayAdvertisementInfo, ad_type:item.value})}
            >
              <Button>{AD_TYPE[inplayAdvertisementInfo.ad_type] || "선택하기"}</Button>
            </SelectMenu>
            )}
          </InputDiv>
          <InputDiv>
            <InputSpan>광고 계약 정보</InputSpan>
            {contractList.length > 0 && (
            <SelectMenu
              height={Math.min(contractList.length*33+32,216)}
              hasTitle={false}
              closeOnSelect={true}
              options={contractList.map((item) => ({ label: `[${item.advertiser}] ${item.name}`, value: item.ad_contract_id }))}
              selected={inplayAdvertisementInfo.ad_contract_id}
              onSelect={(item) => setInplayAdvertisementInfo({...inplayAdvertisementInfo, ad_contract_id:item.value})}
            >
              <Button>{contractList.find(i => i.ad_contract_id === inplayAdvertisementInfo.ad_contract_id) ? `[${contractList.find(i => i.ad_contract_id === inplayAdvertisementInfo.ad_contract_id).advertiser}] ${contractList.find(i => i.ad_contract_id === inplayAdvertisementInfo.ad_contract_id).name}` : "광고 계약 선택"}</Button>
            </SelectMenu>
            )}
          </InputDiv>
          <InputDiv>
            <InputSpan>광고명</InputSpan>
            <TextInput
              name="title"
              onChange={handleChange}
              value={inplayAdvertisementInfo.title}
              />
          </InputDiv>
          <InputDiv>
            <InputSpan>광고 노출 타임라인</InputSpan>
            <FlexDiv style={{flexDirection: 'column'}}>
              <FlexDiv style={{flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
                <InputSpan>시작 시간(초)</InputSpan>
                <TextInput
                  name="start_time"
                  style={{width:'100px'}}
                  onChange={handleChange}
                  value={inplayAdvertisementInfo.start_time}
                />
              </FlexDiv>
              <FlexDiv style={{flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
                <InputSpan>종료 시간(초)</InputSpan>
                <TextInput
                  name="end_time"
                  style={{width:'100px'}}
                  onChange={handleChange}
                  value={inplayAdvertisementInfo.end_time}
                />
              </FlexDiv>
            </FlexDiv>
          </InputDiv>
          <InputDiv>
            <InputSpan>광고 이미지</InputSpan>
            <div>
              <FileUploader
                directory="advertising/inplay"
                preview_src={inplayAdvertisementInfo._image_preview}
                targetObj={inplayAdvertisementInfo}
                onUploaded={setInplayAdvertisementInfo}
                targetPropName="image"
                isImage={true}
                accept={"image/*"}
              />
            </div>
          </InputDiv>
          <InputDiv>
            <InputSpan>발송 url</InputSpan>
            <TextInput
              name="ad_url"
              onChange={handleChange}
              value={inplayAdvertisementInfo.ad_url}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>상태</InputSpan>
            <SelectMenu
              height={STATE_OPTIONS.length*33}
              hasFilter={false}
              hasTitle={false}
              closeOnSelect={true}
              options={STATE_OPTIONS}
              selected={inplayAdvertisementInfo.state}
              onSelect={(item) => setInplayAdvertisementInfo({...inplayAdvertisementInfo, state:item.value})}
            >
              <Button>{STATE[inplayAdvertisementInfo.state] || "선택하기"}</Button>
            </SelectMenu>
          </InputDiv>
          {modalType === "MOD" && (<>
          <InputDiv>
          <InputSpan>노출 횟수</InputSpan>
          <StyledText>{inplayAdvertisementInfo.view_count}</StyledText>
          </InputDiv>
          <InputDiv>
          <InputSpan>클릭 횟수</InputSpan>
          <StyledText>{inplayAdvertisementInfo.click_count}</StyledText>
          </InputDiv>
          <InputDiv>
          <InputSpan>sms 발송 횟수</InputSpan>
          <StyledText>{inplayAdvertisementInfo.sms_count}</StyledText>
          </InputDiv>
          </>)}
        </Dialog>
      </Pane>
      <AdvertisementBox>
        <Pane>
          <AdvertisementH1>
            인플레이 광고 관리
          </AdvertisementH1>
        </Pane>
        <Box>
          <Text style={{marginRight:"10px"}}>콘텐츠id : </Text>
          {contentList.length > 0 && (
          <SelectMenu
            height={Math.min(contentList.length*33+32,216)}
            options={contentList.map((item) => ({ label: item.title, value: item.content_id }))}
            hasTitle={false}
            selected={searchContent}
            closeOnSelect={true}
            onSelect={(item) => {
              setSearchContent(item.value)
              setSearchGroup(null)
            }}
          >
            <Button>{contentList.find(i=>(i.content_id===searchContent)) ? contentList.find(i=>(i.content_id===searchContent)).title : `콘텐츠 필터`}</Button>
          </SelectMenu>
          )}
          <Text
            style={{marginLeft:"20px",marginRight:"10px"}}
          >그룹 : </Text>
          {groupList.length > 0 && (
          <SelectMenu
            height={Math.min(groupList.length*33+32,216)}
            options={groupList.map((item) => ({ label: item.title, value: item.group_code }))}
            hasTitle={false}
            selected={searchGroup}
            closeOnSelect={true}
            onSelect={(item) => {
              setSearchGroup(item.value)
              setSearchContent("0")
            }}
          >
            <Button>{groupList.find(i=>(i.group_code===searchGroup)) ? groupList.find(i=>(i.group_code===searchGroup)).title : `그룹 필터`}</Button>
          </SelectMenu>
          )}
          <Text
            style={{marginLeft:"20px",marginRight:"10px"}}
          >상태 : </Text>
          
            <SelectMenu
              height={Math.min(Object.keys(SEARCH_STATE).length * 33 + 32, 216)}
              options={Object.keys(SEARCH_STATE).map((key) => {
                return ({ label: SEARCH_STATE[key], value: key })
              })}
              hasTitle={false}
              selected={searchState}
              closeOnSelect={true}
              onSelect={(item) => {
                console.log(item)
                setSearchState(item.value)
              }}
            >
            <Button>{SEARCH_STATE[searchState]}</Button>
          </SelectMenu>

          <Link to="/contract" style={{
            marginLeft: 'auto'
          }}>
            <Button
              intent="none"
              style={{
                position: "inherit",
                bottom: "20px",
                marginRight: "20px",
              }}
            >
              목록으로
            </Button>
          </Link>
          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              right: "36%",
              bottom: "20px",
            }}
            onClick={() => addAdvertisementModal({
              _use_content: 'false'
            })}
          >
            등록하기
          </Button>
        </Box>
        <div style={{
          overflowX: 'scroll',
          width: '95%'
        }}>
          <Table width='100%' minWidth="1580px" textAlign="center" marginTop="10px">
            <Table.Head>
              <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>VID</Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>그룹</Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={200} flexShrink={0} flexGrow={0}>광고 ID</Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={250} flexShrink={0}>광고명</Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>구간</Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>광고 종류</Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={200} flexShrink={0} flexGrow={0}>광고 이미지</Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>노출 횟수</Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>클릭 횟수</Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>SMS<br/>발송 횟수</Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>상태</Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>
                관리
              </Table.TextHeaderCell>
            </Table.Head>
            <Table.Body height="auto">
              {inplayAdvertisementList &&
                inplayAdvertisementList.map((item) => (
                  <Table.Row
                    key={`${item.content_id}${item.group_title}${item.ad_id}`}  
                  >
                    <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0}>{item.content_id || '공통'}</Table.TextCell>
                    <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0}>{(item.group_titles || ['-']).join(',')}</Table.TextCell>
                    <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>{item.ad_id}</Table.TextCell>
                    <Table.TextCell flexBasis={250} flexShrink={0} style={{textAlign:'left'}}>{`[${item.advertiser} - ${item.name}] ${item.title}`}</Table.TextCell>
                    <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0}>{`${msToTime(item.start_time*1000)} - ${msToTime(item.end_time*1000)}`}</Table.TextCell>
                    <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>{AD_TYPE[item.ad_type]}</Table.TextCell>
                    <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
                      <ImgPreview src={item.image_url}/>
                    </Table.TextCell>
                    <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>{item.view_count ? item.view_count : 0}</Table.TextCell>
                    <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>{item.click_count ? item.click_count : 0}</Table.TextCell>
                    <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>{item.sms_count}</Table.TextCell>
                    <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>{STATE[item.state]}</Table.TextCell>
                    {item.state === "TERMINATED" ? (
                      <Table.TextCell
                        flexBasis={80}
                        flexShrink={0}
                        flexGrow={0}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Button disabled>수정</Button>
                      </Table.TextCell>
                    ) : (
                      <Table.TextCell
                        flexBasis={80} flexShrink={0} flexGrow={0}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Button
                          onClick={() => {
                            modifyAdvertisementModal(item);
                          }}
                        >
                          수정
                        </Button>
                      </Table.TextCell>
                    )}
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        </div>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </AdvertisementBox>
    </>
  )
}

const AdvertisementBox = styled.div`
margin-top: 50px;
margin-left: 170px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
position: relative;
`;
const AdvertisementH1 = styled.h1`
margin: 50px;
font-size: 1.5vw;
`;
const InputDiv = styled.div`
display: flex;
align-items: center;
margin-top: 16px;
`;
const InputSpan = styled.span`
// width: 120px;
flex-basis: 120px;
font-size: 12px;
`;
const SearchBtn = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
margin-bottom: 20px;
`;
const Box = styled.div`
width: 95%;
display: flex;
justify-content: flex-end;
align-items: center;
`;
const ImgPreview = styled.img`
width: 100%;
`;
const FlexDiv = styled.div`
  display: flex;
`;
const StyledText = styled(Text)`
  font-size: 12px;
`

export default InplayAdvertisement