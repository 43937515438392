import React, { useEffect, memo } from "react";
import styled from "styled-components";
import { useDrag, useDrop } from "react-dnd";
import { Button, TextInput, Textarea } from "evergreen-ui";

const MetaData = ({ item, idx, onDelete, onChange, disabled }) => {
  const id = item._id;
  // const [{ isDragging }, drag] = useDrag(() => ({
  //   type: "metadata",
  //   item: {id,idx},
  //   collect: (monitor) => ({
  //     isDragging: !!monitor.isDragging()
  //   }),
  //   end: (item, monitor) => {
  //     const {id: originId, idx:originIndex} = item
  //     const didDrop = monitor.didDrop()
  //     if( !didDrop ){
  //       onPositionChanged(originId, originIndex)
  //     }
  //   }
  // }),[])

  // const [data,setData] = useState({})
  const no = idx + 1;

  useEffect(() => {
    // setData(item)
  }, []);

  const onChangeData = (e, numberOnly) => {
    let value = e.target.value;
    if (numberOnly) {
      value = isNaN(parseInt(value)) ? value : parseInt(value);
    }
    onChange(idx, { ...item, [e.target.name]: value });
    // setData({ ...data, [e.target.name]: e.target.value })
  };
  return (
    <>
      <FlexDiv
        // ref={drag}
        style={{
          flexDirection: "row",
          width: "100%",
          padding: "5px 0",
          // opacity: isDragging ? 0.1 : 1
        }}
      >
        <FlexDiv style={{ width: "50px", flexDirection: "column" }}>
          <TextInput
            disabled={disabled}
            value={String(no).padStart(3, "0")}
            style={{ width: "100%" }}
            readOnly
          />
          <Button
            appearance="primary"
            intent="danger"
            onClick={() => {
              onDelete(idx);
            }}
          >
            삭제
          </Button>
        </FlexDiv>
        <FlexDiv style={{ width: "150px", flexDirection: "column" }}>
          <FlexDiv>
            <InputSpan>시간(초)</InputSpan>
            <TextInput
              disabled={disabled}
              type="number"
              placeholder="시간(초)"
              style={{ width: "80px" }}
              value={item.time}
              name="time"
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 7) {
                  onChangeData(e, true);
                }
              }}
            />
          </FlexDiv>
          <FlexDiv>
            <InputSpan>케이던스</InputSpan>
            <TextInput
              disabled={disabled}
              type="text"
              maxLength={7}
              placeholder="케이던스"
              style={{ width: "80px" }}
              value={item.rpm}
              name="rpm"
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 7) {
                  onChangeData(e, true);
                }
              }}
            />
          </FlexDiv>
          <FlexDiv>
            <InputSpan>저항</InputSpan>
            <TextInput
              disabled={disabled}
              type="text"
              maxLength={7}
              placeholder="저항"
              style={{ width: "80px" }}
              value={item.level}
              name="level"
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 7) {
                  onChangeData(e, true);
                }
              }}
            />
          </FlexDiv>
        </FlexDiv>
        <FlexDiv style={{ flexGrow: 5 }}>
          <Textarea
            maxLength={200}
            placeholder="강사 대사 200자"
            onChange={(e) => {
              onChangeData(e);
            }}
            name="description"
            value={item.description}
          />
        </FlexDiv>
        <FlexDiv style={{ flexGrow: 2 }}>
          <Textarea
            maxLength={30}
            placeholder="다음 구간 대사 (30자)"
            onChange={(e) => {
              onChangeData(e);
            }}
            name="next_description"
            value={item.next_description}
          />
        </FlexDiv>
      </FlexDiv>
    </>
  );
};

const memorizedMetadata = memo(MetaData);

const FlexDiv = styled.div`
  display: flex;
`;

const InputSpan = styled.span`
  // width: 100px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
`;

export default memorizedMetadata;
