import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  Textarea,
  TagInput,
} from "evergreen-ui";
import DateText from "components/DateText";
import { diff, isEmpty } from "services/util";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ContentSearch from "components/ContentSearch";

const baseUrl = "/admin/challenge";
const contentUrl = "/admin/content";
const tagUrl = "/admin/tag";

const Challenge = () => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [challengeList, setChallengeList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [originalChallengeInfo, setOriginalChallengeInfo] = useState([]);
  const [challengeInfo, setChallengeInfo] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [contentIds, setContentIds] = useState([]);
  const [contentList, setContentList] = useState([]);

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "챌린지 등록",
      action: () => {
        beforeDate();
      },
    },
    MOD: {
      button: "수정하기",
      title: "챌린지 수정",
      action: () => {
        onModifyChallenge();
      },
    },
  };

  useEffect(() => {
    getChallengeList();
  }, [currentPage]);

  // useEffect(() => {
  //   console.log("challengeInfo", challengeInfo);
  // }, [challengeInfo]);

  const addChallengeModal = (challenge) => {
    openModal(challenge, "ADD");
  };

  const modifyChallengeModal = (challenge) => {
    console.log(challenge);
    let aLength = challenge.start_date.length;
    let bLength = challenge.end_date.length;

    const beforeA = challenge.start_date.slice(0, aLength - 1);
    const beforeB = challenge.end_date.slice(0, bLength - 1);

    const a = new Date(beforeA);
    const b = new Date(beforeB);

    // console.log(a);
    // console.log(b);

    setStartDate(a);
    setEndDate(b);

    openModal(challenge, "MOD");
  };

  const openModal = async (challenge, type) => {
    await getTagList();
    await getContentList();

    setOriginalChallengeInfo(challenge);
    setChallengeInfo(challenge);
    setModalType(type);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setStartDate(new Date());
    setEndDate(new Date());

    setOriginalChallengeInfo({});
    setChallengeInfo({});
    setIsModalOpened(false);
    setContentIds([]);
  };

  const getChallengeList = () => {
    const params = {
      limit: 10,
      offset: PAGE_SIZE * (currentPage - 1),
    };

    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.challenge_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        setChallengeList(data);
        setIsModalOpened(false);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //첼린지 상세조회
  const getChallengeInfo = async (challenge_id) => {
    try {
      const res = await axios.get(`${baseUrl}/${challenge_id}`);
      // console.log(res);
      const data = res.data.challenge;
      // console.log("ddasdfsdfasdfasdf ", data);x

      let newContent = {
        ...data,
      };

      if (newContent.tag_ids === null) {
        newContent = {
          ...newContent,
          _tag_ids: [],
        };
      } else {
        // console.log("asdfadsfas");
        newContent = {
          ...newContent,
          _tag_ids: newContent.tag_ids.split(","),
        };
      }

      // console.log(newContent);
      return newContent;
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
      return {};
    }
  };
  //콘텐츠 목록
  const getContentList = async () => {
    const params = {
      content_type: "REAL",
    };

    try {
      const response = await axios.get(contentUrl, { params });
      // console.log("콘텐츠", response);

      setContentList(response.data.content_list);

      // setMusicIds(content.map((i) => i.content_id));
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };
  //태그 목록
  const getTagList = async () => {
    try {
      const response = await axios.get(tagUrl);
      // console.log("tagList", response);
      setTagList(response.data.tag_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  //인풋 수정시
  const handleChange = (e) => {
    // console.log(e);
    setChallengeInfo({ ...challengeInfo, [e.target.name]: e.target.value });
  };

  //before
  const beforeDate = () => {
    console.log(startDate);
    console.log(endDate);

    // const afterStartDate = new Date(startDate).toISOString();
    // const afterEndDate = new Date( - endDate).toISOString();
    const afterStartDate = new Date(
      startDate.getTime() - startDate.getTimezoneOffset() * 60000
    ).toISOString();
    const afterEndDate = new Date(
      endDate.getTime() - endDate.getTimezoneOffset() * 60000
    ).toISOString();

    console.log(afterStartDate);
    console.log(afterEndDate);

    onAddChallenge(afterStartDate, afterEndDate);
  };

  const onSetMusicIds = useCallback((content_id) => {
    setContentIds((prev) => {
      console.log(prev, content_id);
      setChallengeInfo((prev) => {
        let newContentInfo = {
          ...prev,
          content_id: content_id,
        };
        return newContentInfo;
      }, []);
      return content_id;
    }, []);
  }, []);

  //챌린지 등록
  const onAddChallenge = (s, e) => {
    // console.log(challengeInfo);
    challengeInfo.start_date = s;
    challengeInfo.end_date = e;

    let params = diff(challengeInfo, originalChallengeInfo);

    if (isEmpty(params)) {
      alert("변경사항이 없습니다");
      return;
    }

    if (typeof params.content_id === "object") {
      let a = params.content_id[0].toString();
      params.content_id = a;
    }
    console.log(params);
    // retur  /n;

    axios
      .post(baseUrl, params)
      .then((response) => {
        // console.log(response);
        alert("등록되었습니다");
        getChallengeList();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //챌린지 수정
  const onModifyChallenge = () => {
    console.log("수정하기");
    let params = diff(challengeInfo, originalChallengeInfo);
    console.log(params);

    const afterStartDate = new Date(
      startDate.getTime() - startDate.getTimezoneOffset() * 60000
    ).toISOString();
    const afterEndDate = new Date(
      endDate.getTime() - endDate.getTimezoneOffset() * 60000
    ).toISOString();

    params.start_date = afterStartDate;
    params.end_date = afterEndDate;

    if (isEmpty(params)) {
      alert("변경사항이 없습니다");
      return;
    }
    console.log(params);
    // return;
    axios
      .put(`${baseUrl}/${challengeInfo.challenge_id}`, params)
      .then((response) => {
        console.log(response);
        alert("수정되었습니다");
        getChallengeList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //챌린지 삭제
  const onDeleteInstructor = (challenge_id) => {
    if (window.confirm("삭제하시겠습니까?")) {
      axios
        .delete(`${baseUrl}/${challenge_id}`)
        .then((response) => {
          console.log(response);
          alert("삭제되었습니다");
          getChallengeList();
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
        });
    }
  };

  const getTagInputTitle = (
    originalChallengeInfo,
    challengeInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    // console.log(fullList);
    // console.log(challengeInfo[propName]);

    const titleList =
      challengeInfo[propName] &&
      challengeInfo[propName].map((item) => {
        // console.log(item);
        return fullList.find((listItem) => listItem[listPropKey] === item)[
          listPropName
        ];
      });
    return titleList;
  };

  const setTagInputValue = (
    originalChallengeInfo,
    challengeInfo,
    propName,
    value,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let newContentInfo = { ...challengeInfo };
    newContentInfo[propName] = value
      .map((valueItem) => {
        let obj = fullList.find((item) => item[listPropName] === valueItem);
        return obj ? obj[listPropKey] : null;
      })
      .filter((item) => item !== null);

    const add_prop_name = `add${propName}`;
    const delete_prop_name = `delete${propName}`;

    const added = newContentInfo[propName]
      ? newContentInfo[propName].filter((i) =>
          originalChallengeInfo[propName]
            ? !originalChallengeInfo[propName].includes(i)
            : true
        )
      : [];
    const deleted = originalChallengeInfo[propName]
      ? originalChallengeInfo[propName].filter((i) =>
          newContentInfo[propName]
            ? !newContentInfo[propName].includes(i)
            : true
        )
      : [];

    // console.log(newContentInfo, originalChallengeInfo)
    // console.log(add_prop_name,added,delete_prop_name,deleted)

    newContentInfo[add_prop_name] = added;
    newContentInfo[delete_prop_name] = deleted;

    setChallengeInfo(newContentInfo);
  };

  const tagInputAutoCompleteList = (
    contentInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let data = fullList
      .filter(
        (item) =>
          !(
            contentInfo[propName] &&
            contentInfo[propName].includes(item[listPropKey])
          )
      )
      .map((item) => item[listPropName]);
    return data;
  };

  const handleClick = async () => {
    await getContentList();
    // await getUserItem(userItemInfo[0].user_id);
  };

  return (
    <>
      <Pane>
        <Dialog
          width="1000px"
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={modalTypeOption[modalType].button}
          onConfirm={modalTypeOption[modalType].action}
          hasClose={false}
          title={modalTypeOption[modalType].title}
        >
          {modalType === "ADD" ? (
            <InputDiv>
              <InputSpan>콘텐츠 검색</InputSpan>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  width: "120px",
                }}
              >
                <ContentSearch
                  // handleClick={handleClick}
                  musicIds={contentIds}
                  // initialFullMusicList={contentList}
                  onSetMusicIds={onSetMusicIds}
                />
              </div>
            </InputDiv>
          ) : (
            <InputDiv>
              <InputSpan>콘텐츠 ID</InputSpan>
              <TextInput
                disabled
                name="content_id"
                defaultValue={challengeInfo.content_id}
              ></TextInput>
            </InputDiv>
          )}
          <InputDiv>
            <InputSpan>제목</InputSpan>
            <TextInput
              name="title"
              defaultValue={challengeInfo.title}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>설명</InputSpan>
            <Textarea
              width="280px"
              name="description"
              defaultValue={challengeInfo.description}
              onChange={handleChange}
            ></Textarea>
          </InputDiv>
          <InputDiv>
            <InputSpan>시작 날짜</InputSpan>
            <DatepickerWrapper>
              <SDatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat={"yyyy-MM-dd"}
              />
            </DatepickerWrapper>
          </InputDiv>
          <InputDiv>
            <InputSpan>종료 날짜</InputSpan>
            <DatepickerWrapper>
              <SDatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat={"yyyy-MM-dd"}
              />
            </DatepickerWrapper>
          </InputDiv>
          {modalType === "MOD" ? (
            <InputDiv>
              <InputSpan>태그 ID</InputSpan>
              <TagInput
                width="280px"
                inputProps={{ placeholder: "태그 검색" }}
                values={getTagInputTitle(
                  originalChallengeInfo,
                  challengeInfo,
                  "_tag_ids",
                  tagList,
                  "tag_id",
                  "title"
                )}
                onChange={(e) => {
                  setTagInputValue(
                    originalChallengeInfo,
                    challengeInfo,
                    "_tag_ids",
                    e,
                    tagList,
                    "tag_id",
                    "title"
                  );
                }}
                autocompleteItems={tagInputAutoCompleteList(
                  challengeInfo,
                  "_tag_ids",
                  tagList,
                  "tag_id",
                  "title"
                )}
              />
            </InputDiv>
          ) : null}
        </Dialog>
      </Pane>

      <ChallengeBox>
        <Pane>
          <ChallengeH1>챌린지</ChallengeH1>
        </Pane>
        <Button
          appearance="primary"
          intent="none"
          style={{
            position: "inherit",
            left: "36%",
            bottom: "20px",
          }}
          onClick={() => addChallengeModal({})}
        >
          등록하기
        </Button>
        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell>챌린지 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>콘텐츠 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>제목</Table.TextHeaderCell>
            <Table.TextHeaderCell>시작 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell>종료 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell>생성 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell>업데이트 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {challengeList.map((item) => (
              <Table.Row
                key={item.challenge_id}
                isSelectable
                onSelect={async () => {
                  const _challenge = await getChallengeInfo(item.challenge_id);
                  console.log(_challenge);
                  modifyChallengeModal(_challenge);
                }}
              >
                <Table.TextCell>{item.challenge_id}</Table.TextCell>
                <Table.TextCell>{item.content_id}</Table.TextCell>
                <Table.TextCell>{item.title}</Table.TextCell>
                <Table.TextCell>
                  <DateText format="datetime">{item.start_date}</DateText>
                </Table.TextCell>
                <Table.TextCell>
                  <DateText format="datetime">{item.end_date}</DateText>
                </Table.TextCell>
                <Table.TextCell>
                  <DateText format="date">{item.create_date}</DateText>
                </Table.TextCell>
                <Table.TextCell>
                  <DateText format="datetime">{item.update_date}</DateText>
                </Table.TextCell>
                <Table.TextCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    appearance="primary"
                    intent="danger"
                    onClick={(event) => {
                      onDeleteInstructor(item.challenge_id);
                      // event.preventDefault();
                      event.stopPropagation();
                    }}
                  >
                    삭제
                  </Button>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </ChallengeBox>
    </>
  );
};

const ChallengeBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const ChallengeH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;
const DatepickerWrapper = styled.div`
  width: auto;
`;
const SDatePicker = styled(DatePicker)`
  width: 280px;
  border-color: #d8dae5;
  border: 1px solid #d8dae5;
  padding: 0 12px;
  line-height: 16px;
  font-size: 12px;
  transition: box-shadow 80ms ease-in-out;
  height: 32px;
  border-radius: 4px;
  color: #474d66;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
`;
const inputBox = styled.div`
  width: 280px;
`;

export default Challenge;
