import React, { useState } from "react";
import styled from "styled-components";
import {} from "evergreen-ui";

function Main() {
  return (
    <MainBox>
      <>
        <MainH1>HELLO POPPRIKA</MainH1>
      </>
    </MainBox>
  );
}

export default Main;

const MainBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const MainH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
