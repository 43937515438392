import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  TextareaField,
  RadioGroup,
  TagInput,
  Pagination,
} from "evergreen-ui";

import DateText from "components/DateText";
import MetaData from "./Metadata";
import Timetable from "./Timetable";
import {
  diff,
  isEmpty,
  getLevelFromMetadata,
  removeTempProp,
} from "services/util";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import MusicSearch from "components/MusicSearch";
import FileUploader from "components/FileUploader";
import Palette from "components/Palette";

const baseUrl = "/admin/content/";
const tagUrl = "/admin/tag";
const musicTagUrl = "/admin/music-tag";
const groupUrl = "/admin/group";
const instructorUrl = "/admin/instructor";
const musicUrl = "/admin/music";

const PAGE_SIZE = 10;

const Content = () => {
  window.Buffer = window.Buffer || require("buffer").Buffer;

  const [contentList, setContentList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [musicTagList, setMusicTagList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [instructorList, setInstructorList] = useState([]);
  const [contentInfo, setContentInfo] = useState([]);
  const [originalContentInfo, setOriginalContentInfo] = useState([]);
  const [metadata, setMetadata] = useState([]);
  const [timetable, setTimetable] = useState([]);
  const [musicIds, setMusicIds] = useState([]);
  const [musicList, setMusicList] = useState([]);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");

  const [itemKeyword, setItemKeyword] = useState("");
  const [tab, setTab] = useState("LESSON");

  const [selectedColor, setSelectedColor] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [duration, setDuration] = useState(0);

  function handleDurationChange(duration) {
    console.log("duration", duration);
    setDuration(duration);
  }

  const setSelectColor = (hexColor) => {
    console.log(hexColor);
    setSelectedColor(hexColor);
  };

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "콘텐츠 등록",
      action: () => {
        onAddContent();
      },
    },
    MOD: {
      button: "수정하기",
      title: "콘텐츠 수정",
      action: () => {
        onModifyContent();
      },
    },
  };

  const contentType = {
    LESSON: "LESSON",
    REAL: "REAL",
    REAL2: "REAL2",
    POP: "POP",
  };

  const [contentTypeOptions] = useState([
    { label: "LESSON", value: contentType.LESSON },
    { label: "REAL", value: contentType.REAL },
    // { label: "REAL2", value: contentType.REAL2 },
    { label: "POP", value: contentType.POP },
  ]);
  const [rideTypeOptions] = useState([
    { label: "GPX 사용", value: contentType.REAL },
    { label: "GPX 미사용", value: contentType.REAL2 },
  ]);

  useEffect(() => {
    getContentList();
  }, [currentPage, PAGE_SIZE, tab]);

  useEffect(() => {
    setContentInfo({ ...contentInfo, duration: duration });
  }, [duration]);

  useEffect(() => {
    console.log(selectedColor);
    setContentInfo({
      ...contentInfo,
      thumbnail_gradient_color: selectedColor,
    });
  }, [selectedColor]);

  //인풋 수정시
  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    setContentInfo({ ...contentInfo, [e.target.name]: e.target.value });
  };

  const addContentModal = (content) => {
    console.log(content);
    setMetadata([]);
    setTimetable([
      {
        bikeStep: "",
        time: "",
        segment: [],
      },
      {
        bikeStep: "",
        time: "",
        segment: [],
      },
      {
        bikeStep: "",
        time: "",
        segment: [],
      },
    ]);
    openModal(content, "ADD");
  };

  const modifyContentModal = (content) => {
    console.log(metadata);
    // const duration = metadata.reduce((a, b) => a + Number(b.time), 0);
    openModal(
      {
        ...content,
        // level: getLevelFromMetadata(metadata),
        // duration: duration * 1000,
      },
      "MOD"
    );
  };

  const openModal = async (content, type) => {
    console.log(content);
    await getGroupList();
    await getTagList();
    await getMusicTagList();
    await getInstructorList();
    await getMusicList();

    setOriginalContentInfo(content);
    setContentInfo(content);
    setModalType(type);
    setIsModalOpened(true);
    console.log(type);
    if (type === "ADD") {
      // content_type = tab
    }
  };

  const closeModal = () => {
    setSelectedColor("");
    setIsModalOpened(false);
    setOriginalContentInfo({});
    setContentInfo([]);
    setMetadata([]);
    setTimetable([]);
    setMusicIds([]);
  };
  const handleTabChange = async (item) => {
    console.log(item);
    await setCurrentPage(1);
    await setTab(item);
    // await getContentList(item);
    // return item;
  };
  const getContentList = async () => {
    try {
      const params = {
        limit: 10,
        offset: PAGE_SIZE * (currentPage - 1),
        keyword: itemKeyword,
        content_type: tab,
      };
      // console.log(params);
      const response = await axios.get(`${baseUrl}`, { params });
      console.log("contentList", response);
      setTotalCount(response.data.count);
      setContentList(response.data.content_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getTagList = async () => {
    try {
      const response = await axios.get(tagUrl);
      // console.log("tagList", response);
      setTagList(response.data.tag_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getMusicTagList = async () => {
    try {
      const response = await axios.get(musicTagUrl);
      // console.log("music-tagList", response);
      setMusicTagList(response.data.music_tag_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getGroupList = async () => {
    try {
      const response = await axios.get(groupUrl);
      // console.log("groupList", response);
      setGroupList(response.data.group_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getInstructorList = async () => {
    try {
      const response = await axios.get(instructorUrl);
      // console.log("instructorList", response);
      setInstructorList(response.data.instructor_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getMusicList = async () => {
    try {
      const response = await axios.get(musicUrl);
      // console.log("music-list", response);
      setMusicList(response.data.music_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const isLesson = () => {
    return contentInfo.content_type === contentType.LESSON;
  };

  const isPop = () => {
    return contentInfo.content_type === contentType.POP;
  };

  const isReal = () => {
    return contentInfo.content_type === contentType.REAL;
  };
  const isReal2 = () => {
    return contentInfo.ride_type === contentType.REAL2;
  };

  const isLive = () => {
    return contentInfo.content_type === contentType.LIVE;
  };

  const getContentInfo = async (content_id) => {
    console.log(content_id);
    // return;
    try {
      const response = await axios.get(`${baseUrl}${content_id}`);
      const content = response.data.content;
      console.log(response.data);
      console.log(
        "레슨 데이터 - 콘텐츠 컬러",
        content.thumbnail_gradient_color
      );

      if (
        content.thumbnail_gradient_color !== null &&
        content.thumbnail_gradient_color.length === 7
      ) {
        let a = content.thumbnail_gradient_color.replace("#", "");
        content.thumbnail_gradient_color = a;
      }
      if (content.content_type !== "REAL2") {
        let newMetadata = JSON.parse(content.meta_data);
        newMetadata = newMetadata.json ? newMetadata.json : newMetadata;
        newMetadata = newMetadata.map((item, idx) => ({ ...item, _id: idx }));
        setMetadata(newMetadata);
      }

      console.log(content.music_list.map((i) => i.music_id));
      setMusicIds(content.music_list.map((i) => i.music_id));

      // console.log(contentInfo.content_type,"LESSON",isLesson(),"REAL",isReal(),"POP",isPop(),"LIVE",isLive())
      let newContent = {
        ...content,
        // meta_data: newMetadata,
        _thumbnail_image_preview: content.thumbnail_image_url,
        _background_image_preview: content.background_image_url,
        _riding_exposure_image_preview: content.riding_exposure_image_url,
        _video_preview: content.video_url,
        _group_codes: content.group_codes.split(",").map((i) => Number(i)),
        // music_list: content.music_list.map((i) => i.music_id),
      };
      
      if ([contentType.LESSON].includes(newContent.content_type)) {
        let newTimetable = JSON.parse(newContent.time_table);
        newTimetable = newTimetable.json ? newTimetable.json : newTimetable;
        newTimetable = newTimetable.map((item, idx) => ({
          ...item,
          _id: idx,
        }));
        newContent = {
          ...newContent,
          // time_table : newTimetable,
          _tag_ids: newContent.tag_ids ? newContent.tag_ids.split(",") : [],
        };
        setTimetable(newTimetable);
      }

      if ([contentType.POP].includes(newContent.content_type)) {
        let newTimetable = JSON.parse(newContent.time_table);
        newTimetable = newTimetable.json ? newTimetable.json : newTimetable;
        newTimetable = newTimetable.map((item, idx) => ({ ...item, _id: idx }));
        newContent = {
          ...newContent,
          // time_table : newTimetable,
          _tag_ids: newContent.tag_ids ? newContent.tag_ids.split(",") : [],
          _music_tag_ids: newContent.music_tag_ids
            ? newContent.music_tag_ids.split(",")
            : [],
        };
        setTimetable(newTimetable);
      }

      if ([contentType.REAL].includes(newContent.content_type)) {
        newContent = {
          ...newContent,
          _tag_ids: newContent.tag_ids ? newContent.tag_ids.split(",") : [],
          _city_name_image_preview: newContent.city_name_image_url,
        };
      }

      if ([contentType.REAL2].includes(newContent.content_type)) {
        delete newContent.content_type;
        newContent = {
          ...newContent,
          _tag_ids: newContent.tag_ids ? newContent.tag_ids.split(",") : [],
          _city_name_image_preview: newContent.city_name_image_url,
          ride_type: "REAL2",
        };
      }
      return newContent;
    } catch (err) {
      console.log(err);
      // alert(err.response.data.message);
      return {};
    }
  };

  const tagInputAutoCompleteList = (
    contentInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    let data = fullList
      .filter(
        (item) =>
          !(
            contentInfo[propName] &&
            contentInfo[propName].includes(item[listPropKey])
          )
      )
      .map((item) => item[listPropName]);
    return data;
  };

  const setTagInputValue = (
    originalContentInfo,
    contentInfo,
    propName,
    value,
    fullList,
    listPropKey,
    listPropName
  ) => {
    if (propName === "_music_tag_ids") {
      console.log("_music_tag_ids");
    }
    console.log(value);
    let newContentInfo = { ...contentInfo };
    newContentInfo[propName] = value
      .map((valueItem) => {
        let obj = fullList.find((item) => item[listPropName] === valueItem);
        return obj ? obj[listPropKey] : null;
      })
      .filter((item) => item !== null);

    const add_prop_name = `add${propName}`;
    const delete_prop_name = `delete${propName}`;

    const added = newContentInfo[propName]
      ? newContentInfo[propName].filter((i) =>
          originalContentInfo[propName]
            ? !originalContentInfo[propName].includes(i)
            : true
        )
      : [];
    const deleted = originalContentInfo[propName]
      ? originalContentInfo[propName].filter((i) =>
          newContentInfo[propName]
            ? !newContentInfo[propName].includes(i)
            : true
        )
      : [];

    // console.log(newContentInfo, originalContentInfo)
    // console.log(add_prop_name,added,delete_prop_name,deleted)

    newContentInfo[add_prop_name] = added;
    newContentInfo[delete_prop_name] = deleted;

    setContentInfo(newContentInfo);
  };

  const getTagInputTitle = (
    originalContentInfo,
    contentInfo,
    propName,
    fullList,
    listPropKey,
    listPropName
  ) => {
    // console.log(contentInfo);
    // console.log(propName);
    // console.log(fullList);
    // console.log(listPropKey);
    // console.log(listPropName);
    const titleList =
      contentInfo[propName] &&
      contentInfo[propName].map((item) => {
        return fullList.find((listItem) => listItem[listPropKey] === item)[
          listPropName
        ];
      });
    // console.log(titleList);
    return titleList;
  };

  const onCsvUploaded = (content, file) => {
    const fileReader = new FileReader();
    if (file) {
      fileReader.onload = (e) => {
        const text = e.target.result;
        csvToArray(text);
      };
      fileReader.readAsText(file);
    }
    setContentInfo(content);
  };

  const csvToArray = (text) => {
    text = text.replaceAll("\r\n", "\n");
    const csvHeader = text.slice(0, text.indexOf("\n")).split(",");
    const csvRows = text.slice(text.indexOf("\n") + 1).split("\n");

    console.log(csvHeader, csvRows);

    const array = csvRows
      .filter((i) => {
        const values = i.split(",");
        return values.length === csvHeader.length;
      })
      .map((i) => {
        const values = i.split(",");
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index];
          return object;
        }, {});
        return obj;
      });

    console.log(array);

    let newArray = array.map((i) => ({
      height: Number(i.altitude),
      lat: Number(i.position_lat),
      lng: Number(i.position_long),
      speed: Number(i.speed),
    }));

    setMetadata(newArray);
  };

  const onAddMetadata = useCallback(() => {
    setMetadata((prev) => {
      const no = Math.max(Math.max(...(prev || []).map((i) => i._id)), 0);
      const newObj = {
        _id: no + 1,
        description: "",
        duration: "",
        level: "",
        rpm: "",
        time: "",
      };
      let newMetadata = [...(prev || []), newObj];
      return newMetadata;
    }, []);
  }, []);

  const onChangeMetadata = useCallback((idx, data) => {
    setMetadata((prev) => {
      let newMetadata = [...prev];
      newMetadata[idx] = data;
      const duration = newMetadata.reduce((a, b) => a + Number(b.time), 0);
      newMetadata.reduce((a, b) => {
        b.duration = a;
        return a + b.time;
      }, 0);
      setContentInfo((prev) => ({
        ...prev,
        level: getLevelFromMetadata(newMetadata),
        duration: duration * 1000,
      }));
      return newMetadata;
    });
  }, []);

  const onDeleteMetadata = useCallback((idx) => {
    // console.log("delete")

    // console.log(newMetadata)
    setMetadata((prev) => {
      let newMetadata = [...prev];
      const duration = newMetadata.reduce((a, b) => a + Number(b.time), 0);
      newMetadata.reduce((a, b) => {
        b.duration = a;
        return a + b.time;
      }, 0);
      setContentInfo((prev) => ({
        ...prev,
        level: getLevelFromMetadata(newMetadata),
        duration: duration * 1000,
      }));
      return (prev || []).filter((_, index) => {
        // console.log(index,idx)
        return index !== idx;
      });
    });
  }, []);

  const onChangedTimetable = useCallback((idx, data) => {
    setTimetable((prev) => {
      let newTimetable = [...prev];
      newTimetable[idx] = data;
      return newTimetable;
    }, []);
  }, []);

  const onSetMusicIds = useCallback((music_ids) => {
    setMusicIds((prev) => {
      console.log(prev, music_ids);
      setContentInfo((prev) => {
        let newContentInfo = {
          ...prev,
          music_ids: music_ids,
        };
        return newContentInfo;
      }, []);
      return music_ids;
    }, []);
  }, []);

  //콘텐츠 등록
  const onAddContent = () => {
    console.log(metadata);
    let newContentInfo = {};
    if (metadata.length > 0) {
      newContentInfo = {
        ...contentInfo,

        meta_data: JSON.stringify({ json: metadata }),
        time_table: JSON.stringify(timetable),
      };
    } else {
      newContentInfo = {
        ...contentInfo,

        time_table: JSON.stringify(timetable),
      };
    }
    let newOriginalContentInfo = {
      ...originalContentInfo,
      // level: Number(originalContentInfo.level),
      // duration: Number(originalContentInfo.duration),
      meta_data: JSON.stringify({ json: originalContentInfo.meta_data }),
      time_table: JSON.stringify(originalContentInfo.time_table),
    };
    console.log(newContentInfo, newOriginalContentInfo);
    // let params = diff(newContentInfo,newOriginalContentInfo)
    let params = removeTempProp(newContentInfo);
    console.log(params);

    if (isEmpty(params)) {
      alert("변경 사항이 없습니다");
      return;
    }
    if (params.ride_type === "REAL2") {
      let a = "REAL2";
      params.content_type = a;
      delete params.ride_type;
    } else {
      delete params.ride_type;
    }
    if (typeof params.distance === "string") {
      let a = Number(params.distance);
      params.distance = a;
    }
    console.log(typeof params.duration);
    axios
      .post(baseUrl, params)
      .then((response) => {
        console.log(response);
        alert("등록되었습니다");
        // window.location.reload();
        closeModal();
        getContentList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  const isShowButton = (info) => {
    if (!window.confirm("노출 상태를 변경하시겠습니까?")) {
      return;
    }

    if (info.is_show === 1) {
      info.is_show = 0;
    } else {
      info.is_show = 1;
    }

    if (typeof info.is_show === "string") {
      let a = Number(info.is_show);
      info.is_show = a;
    }

    let params = { is_show: info.is_show };
    console.log(params);
    // return;

    axios
      .put(`${baseUrl}${info.content_id}`, params)
      .then((response) => {
        console.log(response);
        getContentList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //콘텐츠 수정
  const onModifyContent = () => {
    console.log(contentInfo);
    console.log(originalContentInfo);
    let newContentInfo = {
      ...contentInfo,
      level: Number(contentInfo.level),
      duration: Number(contentInfo.duration),
      meta_data: JSON.stringify({ json: removeTempProp(metadata) }),
      time_table: JSON.stringify(removeTempProp(timetable)),
    };
    let newOriginalContentInfo = {
      ...originalContentInfo,
      level: Number(originalContentInfo.level),
      duration: Number(originalContentInfo.duration),
      meta_data: originalContentInfo.meta_data,
      time_table: originalContentInfo.time_table,
    };

    if (newContentInfo.thumbnail_gradient_color) {
      console.log("!");
      if (newContentInfo.thumbnail_gradient_color.length === 6) {
        let a = `#${newContentInfo.thumbnail_gradient_color}`;
        newContentInfo.thumbnail_gradient_color = a;
      }
    } else {
      console.log("2");
    }
    // console.log(newContentInfo, newOriginalContentInfo);
    let params = diff(newContentInfo, newOriginalContentInfo);

    if (contentInfo.ride_type) {
      delete params.meta_data;
      delete params.time_table;
    }

    if (isEmpty(params)) {
      alert("변경사항이 없습니다");
      return;
    }

    console.log(params);

    if (!params.riding_exposure_image_url) {
      params.riding_exposure_image_url = "";
    }

    console.log(params);
    // return;
    axios
      .put(`${baseUrl}${contentInfo.content_id}`, params)
      .then((response) => {
        console.log(response);
        alert("수정되었습니다");
        closeModal();
        getContentList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //삭제
  const contentDelete = (content_id) => {
    if (!window.confirm("삭제하겠습니까?")) {
      return;
    }

    axios
      .delete(`${baseUrl}/${content_id}`)
      .then(() => {
        alert("삭제되었습니다.");
        getContentList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };
  const handleButtonClick = () => {
    setIsVisible(!isVisible);
  };
  

  return (
    <>
      <Pane>
        <Dialog
          width={700}
          isShown={isModalOpened}
          onCloseComplete={() => closeModal(false)}
          confirmLabel={modalTypeOption[modalType].button}
          title={modalTypeOption[modalType].title}
          onConfirm={modalTypeOption[modalType].action}
          shouldCloseOnEscapePress={false}
          shouldCloseOnOverlayClick={false}
          hasCancel={false}
          hasClose={true}
          hasFooter={true}
        >
          <InputDiv>
            <InputSpan>콘텐츠 타입</InputSpan>
            {modalType === "MOD" ? (
              contentInfo.ride_type || contentInfo.content_type
            ) : (
              <RadioGroup
                display="flex"
                columnGap="10px"
                size={16}
                name="content_type"
                options={contentTypeOptions}
                value={contentInfo.content_type}
                defaultValue={contentInfo.content_type}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            )}
          </InputDiv>

          <InputDiv>
            <InputSpan>콘텐츠 제목</InputSpan>
            <TextInput
              width="100%"
              name="title"
              onChange={handleChange}
              value={contentInfo.title}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>콘텐츠 설명</InputSpan>
            <TextareaField
              width="100%"
              name="description"
              onChange={handleChange}
              value={contentInfo.description}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>콘텐츠 이미지</InputSpan>
            <div>
            <FileUploader
              directory="lessonData/thumbnail"
              preview_src={contentInfo._thumbnail_image_preview}
              targetObj={contentInfo}
              onUploaded={setContentInfo}
              targetPropName="thumbnail_image"
              isImage={true}
              accept={"image/*"}
            />
            {contentInfo.thumbnail_image_url && (
              <Palette
                targetObj={contentInfo}
                targetPropName={"thumbnail_gradient_color"}
                targetImageUrl={contentInfo.thumbnail_image_url}
                onColorClick={setSelectColor}
              />
            )}
            </div>
          </InputDiv>
          <InputDiv>
            <InputSpan>배경 이미지</InputSpan>
            <FileUploader
              directory="lessonData/background"
              preview_src={contentInfo._background_image_preview}
              targetObj={contentInfo}
              onUploaded={setContentInfo}
              targetPropName="background_image"
              isImage={true}
              accept={"image/*"}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>라이딩 노출 이미지</InputSpan>
            <FileUploader
              directory="lessonData/riding"
              preview_src={contentInfo._riding_exposure_image_preview}
              targetObj={contentInfo}
              onUploaded={setContentInfo}
              targetPropName="riding_exposure_image"
              isImage={true}
              accept={"image/*"}
            />
          </InputDiv>

          {contentInfo.content_type === "REAL" ||
          contentInfo.ride_type === "REAL2" ? (
            <>
              {modalType === "ADD" ? (
                <InputDiv>
                  <InputSpan>라이딩 타입</InputSpan>
                  <RadioGroup
                    display="flex"
                    columnGap="10px"
                    size={16}
                    name="ride_type"
                    options={rideTypeOptions}
                    value={contentInfo.ride_type}
                    defaultValue={contentInfo.ride_type}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </InputDiv>
              ) : null}
              {contentInfo.ride_type === "REAL2" ? (
                <>
                  <InputDiv>
                    <InputSpan>라이딩 거리(m)</InputSpan>
                    <TextInput
                      type="number"
                      width="100%"
                      name="distance"
                      onChange={handleChange}
                      value={contentInfo.distance}
                    />
                  </InputDiv>
                  <InputDiv>
                    <InputSpan>영상 길이</InputSpan>
                    <TextInput
                      readOnly
                      width="100%"
                      name="duration"
                      onChange={handleChange}
                      value={contentInfo.duration}
                    />
                  </InputDiv>
                </>
              ) : (
                <>
                  <InputDiv>
                    <InputSpan>데이터(csv)</InputSpan>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: "1",
                        // marginLeft: "10px",
                      }}
                    >
                      <FileUploader
                        directory="lessonData/file"
                        targetObj={contentInfo}
                        onUploaded={onCsvUploaded}
                        targetPropName="csv"
                        accept={".csv"}
                      />
                    </div>
                    {/* <Button
                      onClick={() => {
                        console.log("QQ");
                      }}
                    >
                      csv 다운로드
                    </Button> */}
                  </InputDiv>
                </>
              )}
            </>
          ) : (
            <>
              {contentInfo.is_show === 1 ? (
                <>
                  <InputDiv>
                    <InputSpan>데이터1</InputSpan>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: "1",
                      }}
                    >
                      <VisibleBtn onClick={handleButtonClick}>
                        {isVisible ? "숨기기" : "보이기"}
                      </VisibleBtn>
                      {isVisible && (
                        <>
                          <DndProvider backend={HTML5Backend}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                // , height:"500px", overflowY: "scroll"
                              }}
                            >
                              {metadata &&
                                metadata.map((item, idx) => {
                                  return (
                                    <MetaData
                                      key={item._id}
                                      idx={idx}
                                      item={item}
                                      onDelete={onDeleteMetadata}
                                      onChange={onChangeMetadata}
                                      disabled={contentInfo.is_show === 1}
                                    />
                                  );
                                })}
                            </div>
                          </DndProvider>
                          <Button appearance="primary" onClick={onAddMetadata}>
                            구간 추가
                          </Button>
                        </>
                      )}
                    </div>
                  </InputDiv>
                </>
              ) : (
                <InputDiv>
                  <InputSpan>데이터</InputSpan>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: "1",
                    }}
                  >
                    <VisibleBtn onClick={handleButtonClick}>
                      {isVisible ? "숨기기" : "보이기"}
                    </VisibleBtn>
                    {isVisible && (
                      <>
                        <DndProvider backend={HTML5Backend}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              // , height:"500px", overflowY: "scroll"
                            }}
                          >
                            {metadata &&
                              metadata.map((item, idx) => {
                                return (
                                  <MetaData
                                    key={item._id}
                                    idx={idx}
                                    item={item}
                                    onDelete={onDeleteMetadata}
                                    onChange={onChangeMetadata}
                                  />
                                );
                              })}
                          </div>
                        </DndProvider>
                        <Button appearance="primary" onClick={onAddMetadata}>
                          구간 추가
                        </Button>
                        <VisibleBtn
                          style={{
                            marginTop: "5px",
                          }}
                          onClick={handleButtonClick}
                        >
                          숨기기
                        </VisibleBtn>
                      </>
                    )}
                  </div>
                </InputDiv>
              )}
            </>
          )}

          <InputDiv>
            <InputSpan>그룹</InputSpan>
            <TagInput
              width="100%"
              inputProps={{ placeholder: "Enter something..." }}
              values={getTagInputTitle(
                originalContentInfo,
                contentInfo,
                "_group_codes",
                groupList,
                "group_code",
                "title"
              )}
              onChange={(e) => {
                setTagInputValue(
                  originalContentInfo,
                  contentInfo,
                  "_group_codes",
                  e,
                  groupList,
                  "group_code",
                  "title"
                );
              }}
              autocompleteItems={tagInputAutoCompleteList(
                contentInfo,
                "_group_codes",
                groupList,
                "group_code",
                "title"
              )}
            />
          </InputDiv>
          {(isLesson() || isReal() || isPop()) && modalType === "MOD" && (
            <InputDiv>
              <InputSpan>level</InputSpan>
              <TextInput
                width="50%"
                name="level"
                onChange={handleChange}
                value={contentInfo.level}
                readOnly
              />
            </InputDiv>
          )}
          {(isLesson() || isPop()) && (
            <>
              <InputDiv>
                <InputSpan>duration</InputSpan>
                <TextInput
                  width="50%"
                  name="duration"
                  onChange={handleChange}
                  value={contentInfo.duration}
                  readOnly
                />
              </InputDiv>
              <InputDiv>
                <InputSpan>타임 테이블</InputSpan>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: "1",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      padding: "5px 0",
                    }}
                  >
                    {timetable &&
                      timetable.map((item, idx) => {
                        return (
                          <Timetable
                            key={item._id}
                            item={item}
                            idx={idx}
                            onChangedTimetable={onChangedTimetable}
                          />
                        );
                      })}
                  </div>
                </div>
              </InputDiv>
            </>
          )}
          {(isLesson() || isReal() || isPop() || isReal2()) && (
            <InputDiv>
              <InputSpan>비디오</InputSpan>
              <FileUploader
                directory="lessonData/video"
                preview_src={contentInfo._video_preview}
                targetObj={contentInfo}
                onUploaded={setContentInfo}
                targetPropName="video"
                isVideo={true}
                accept={"video/*"}
                onDurationChange={handleDurationChange}
              />
            </InputDiv>
          )}
          {contentInfo.content_type === "LESSON" && (
            <InputDiv>
              <InputSpan>강사</InputSpan>
              <TagInput
                width="100%"
                inputProps={{ placeholder: "강사 검색" }}
                values={getTagInputTitle(
                  originalContentInfo,
                  contentInfo,
                  "_instructor_ids",
                  instructorList,
                  "instructor_id",
                  "name"
                )}
                onChange={(e) => {
                  setTagInputValue(
                    originalContentInfo,
                    contentInfo,
                    "_instructor_ids",
                    e,
                    instructorList,
                    "instructor_id",
                    "name"
                  );
                }}
                autocompleteItems={tagInputAutoCompleteList(
                  contentInfo,
                  "_instructor_ids",
                  instructorList,
                  "instructor_id",
                  "name"
                )}
              />
            </InputDiv>
          )}
          <InputDiv>
            <InputSpan>태그</InputSpan>
            <TagInput
              width="100%"
              inputProps={{ placeholder: "태그 검색" }}
              values={getTagInputTitle(
                originalContentInfo,
                contentInfo,
                "_tag_ids",
                tagList,
                "tag_id",
                "title"
              )}
              onChange={(e) => {
                setTagInputValue(
                  originalContentInfo,
                  contentInfo,
                  "_tag_ids",
                  e,
                  tagList,
                  "tag_id",
                  "title"
                );
              }}
              autocompleteItems={tagInputAutoCompleteList(
                contentInfo,
                "_tag_ids",
                tagList,
                "tag_id",
                "title"
              )}
            />
          </InputDiv>
          {(isReal() || isReal2()) && (
            <>
              <InputDiv>
                <InputSpan>리얼 썸네일</InputSpan>
                <FileUploader
                  directory="lessonData/thumbnail"
                  preview_src={contentInfo._city_name_image_preview}
                  targetObj={contentInfo}
                  onUploaded={setContentInfo}
                  targetPropName="city_name_image"
                  isImage={true}
                />
              </InputDiv>
            </>
          )}
          {isPop() && (
            <InputDiv>
              <InputSpan>음악태그</InputSpan>
              <TagInput
                width="100%"
                inputProps={{ placeholder: "음악 태그 검색" }}
                values={getTagInputTitle(
                  originalContentInfo,
                  contentInfo,
                  "_music_tag_ids",
                  musicTagList,
                  "music_tag_id",
                  "title"
                )}
                onChange={(e) => {
                  setTagInputValue(
                    originalContentInfo,
                    contentInfo,
                    "_music_tag_ids",
                    e,
                    musicTagList,
                    "music_tag_id",
                    "title"
                  );
                }}
                autocompleteItems={tagInputAutoCompleteList(
                  contentInfo,
                  "_music_tag_ids",
                  musicTagList,
                  "music_tag_id",
                  "title"
                )}
              />
            </InputDiv>
          )}

          <InputDiv>
            <InputSpan>음원</InputSpan>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                width: "100%",
              }}
            >
              <MusicSearch
                musicIds={musicIds}
                initialFullMusicList={musicList}
                onSetMusicIds={onSetMusicIds}
              />
            </div>
          </InputDiv>
        </Dialog>
      </Pane>

      <ContentBox>
        <Pane>
          <ContentH1>콘텐츠</ContentH1>
        </Pane>
        <Box>
          <SearchBtn>
            <TextInput
              placeholder="제목, 콘텐츠 ID, 강사명"
              width={"250px"}
              onChange={(e) => {
                const keyword = e.target.value;
                setItemKeyword(keyword);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  getContentList();
                }
              }}
            ></TextInput>
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "36%",
                bottom: "20px",
                marginLeft: "10px",
              }}
              onClick={() => {
                getContentList();
              }}
            >
              검색
            </Button>
          </SearchBtn>
          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={() => addContentModal({ content_type: tab })}
          >
            등록하기
          </Button>
        </Box>

        <Table width="95%" textAlign="center" marginTop="20px">
          <ListUl>
            <ListLi
              onClick={() => handleTabChange("LESSON")}
              className={tab === "LESSON" && "checked"}
            >
              LESSON
            </ListLi>
            <ListLi
              onClick={() => handleTabChange("REAL")}
              className={tab === "REAL" && "checked"}
            >
              REAL
            </ListLi>
            {/* <ListLi
              onClick={() => handleTabChange("LIVE")}
              className={tab === "LIVE" && "checked"}
            >
              LIVE
            </ListLi> */}
            <ListLi
              onClick={() => handleTabChange("POP")}
              className={tab === "POP" && "checked"}
            >
              POP
            </ListLi>
          </ListUl>
          <Table.Head>
            <Table.TextHeaderCell>콘텐츠 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>타입</Table.TextHeaderCell>
            <Table.TextHeaderCell>제목</Table.TextHeaderCell>
            <Table.TextHeaderCell>설명</Table.TextHeaderCell>
            <Table.TextHeaderCell>콘텐츠 이미지</Table.TextHeaderCell>
            <Table.TextHeaderCell>생성 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell>업데이트 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell>등록</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {contentList.map((content) => (
              <Table.Row key={content.content_id}>
                <Table.TextCell>{content.content_id}</Table.TextCell>
                <Table.TextCell>{content.content_type}</Table.TextCell>
                <Table.TextCell>{content.title}</Table.TextCell>
                <Table.TextCell>{content.description}</Table.TextCell>
                <Table.Cell justifyContent="center">
                  <ImgPreview
                    style={{ width: "150px" }}
                    src={content.thumbnail_image_url}
                  />
                </Table.Cell>
                <Table.TextCell>
                  <DateText minimum>{content.create_date}</DateText>
                </Table.TextCell>
                <Table.TextCell>
                  <DateText minimum>{content.update_date}</DateText>
                </Table.TextCell>
                <Table.Cell style={{ justifyContent: "center" }}>
                  <Button
                    onClick={() => {
                      isShowButton(content);
                    }}
                  >
                    {content.is_show ? "노출중" : "노출 대기"}
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button
                    onClick={async () => {
                      const _content = await getContentInfo(content.content_id);
                      console.log("content", _content);
                      modifyContentModal(_content);
                    }}
                  >
                    상세
                  </Button>
                  <Button
                    intent="danger"
                    onClick={() => {
                      contentDelete(content.content_id);
                    }}
                  >
                    삭제
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {
          <Pane
            height={120}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Pagination
              page={currentPage}
              totalPages={Math.ceil(totalCount / PAGE_SIZE)}
              onNextPage={() => {
                setCurrentPage(currentPage + 1);
              }}
              onPreviousPage={() => {
                setCurrentPage(currentPage - 1);
              }}
              onPageChange={(page) => setCurrentPage(page)}
            ></Pagination>
          </Pane>
        }
      </ContentBox>
    </>
  );
};
const ContentBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const ContentH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const ListUl = styled.ul``;
const ListLi = styled.li`
  &:hover,
  &.checked {
    background-color: #7b9cff;
    color: white;
  }
  cursor: pointer;
  display: inline-block;
  padding: 20px 40px;
`;

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  font-family: "Noto Sans KR", sans-serif;
`;

const InputSpan = styled.span`
  width: 150px;
  flex-shrink: 0;
  font-size: 12px;
  font-family: "Noto Sans KR", sans-serif;
`;

const ImgPreview = styled.img`
  width: 200px;
`;
const SearchBtn = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // margin-bottom: 20px;
`;
const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
`;
const VisibleBtn = styled.button`
  margin-bottom: 5px;
  background-color: #52bd95;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 0;
  cursor: pointer;
`;
export default Content;
