import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import AWS from "aws-sdk";
import { nanoid } from "nanoid";
import { Button } from "evergreen-ui";

const region = "ap-northeast-2";
const bucket = "popprika-cms-version2";
// const directory = "lessonData";

const FileUploader = ({
  directory,
  preview_src: src,
  onUploaded,
  targetObj,
  targetPropName,
  isImage,
  isVideo,
  accept,
  onDurationChange,
}) => {
  let newAccept = accept
    ? accept
    : isImage
    ? "image/*"
    : isVideo
    ? "video/*"
    : "";
  let _isImage = newAccept.includes("image") || isImage;
  let _isVideo = newAccept.includes("video") || isVideo;
  let _isCsv = newAccept.includes("csv");

  const prop_url = `${targetPropName}_url`;
  const original_filename = `${targetPropName}_original_filename`;

  const fileInput = useRef()

  const [url, setUrl] = useState(src);

  const [imageUrl, setImageUrl] = useState(targetObj[targetPropName]);
  const [files, setFiles] = useState([]); // 이미지 파일 배열


  const ref = useRef(null);

  //AWS SETTING
  AWS.config.update({
    region: region,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_SECRETKEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: bucket },

    region: region,
  });

  //이미지 업로드
  const handleFileInput = async (e) => {
    // console.log(e);
    let newContent = { ...targetObj };
    // console.log(newContent);
    const fileName = `${Date.now()}_${nanoid(8)}`;
    // console.log(obj_prop_name);

    const file = e.target.files[0];
    const uploadedUrl = `https://${bucket}.s3.${region}.amazonaws.com/${directory}/${fileName}`;

    //파일 업로드
    await uploadFile(file, fileName, uploadedUrl);

    newContent[prop_url] = uploadedUrl;
    newContent[original_filename] = file.name;
    // console.log("newContent",newContent);
    if (_isCsv) {
      onUploaded(newContent, file);
    } else {
      onUploaded(newContent);
    }

    //sunny
    if (isImage) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      return new Promise((resolve) => {
        reader.onload = () => {
          setImageUrl(reader.result);
          // setImageSrc(reader.result);
          resolve();
        };
      });
    }

    if (_isVideo) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      video.addEventListener("loadedmetadata", () => {
        const b = video.duration * 1000;
        const duration = Math.floor(b);
        onDurationChange(duration);
        console.log("동영상 길이: ", duration, "초");
      });
    }

    // //이미지 색상
    // const colorThief = new ColorThief();
    // const image = new Image();
    // image.crossOrigin = "Anonymous";
    // image.onload = function () {
    //   const extractedPalette = colorThief.getPalette(image, 10);
    //   const extractedColor = colorThief.getColor(image);
    //   setPalette(extractedPalette);
    //   setColor(extractedColor);
    // };
    // // setImageUrl(URL.createObjectURL(file));
    // image.src = URL.createObjectURL(file);
    // // image.src = imageUrl;
    // // console.log(image.src);
  };

  //버킷에 이미지 저장(랜덤값)
  const uploadFile = async (file, fileName, uploadedUrl) => {
    // console.log("fileName", fileName);
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: bucket,
      Key: directory + "/" + fileName,
      ContentType: file.type,
    };
    // console.log("params", params);

    await myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(Math.round((evt.loaded / evt.total) * 100));
      })
      .promise();
    // .send((err) => {
    //   // console.log("send");
    //   if (err) console.log(err);
    //   else {
    // setUrl(uploadedUrl);
    //   }
    // });
    setUrl(uploadedUrl);
    console.log("finished");
  };

  const deleteImage = () => {
    let newTargetObj = { ...targetObj };
    delete newTargetObj[prop_url]
    delete newTargetObj[original_filename]
    onUploaded(newTargetObj);
    fileInput.current.value = ""
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        // width: "300px",
      }}
    >
      {_isImage && url && (
        <div style={{display:"flex"}}>
          <ImgPreview src={url} />
          <Button
            appearance="primary"
            intent="danger"
            style={{marginLeft:"5px"}}
            onClick={() => {
              setUrl("");
              deleteImage()
              // console.log(url);
            }}
          >
          X
          </Button>
        </div>
      )}

      {_isVideo && url && (
        <VideoPreview
          ref={ref}
          key={url}
          playsInline={true}
          muted={true}
          controls={true}
        >
          <source src={url}></source>
        </VideoPreview>
      )}
      <input
        type="file"
        accept={newAccept}
        ref={fileInput}
        onChange={(e) => handleFileInput(e)}
      />
      {/* <input type="text" value={hexColor} /> */}
    </div>
  );
};

const ImgPreview = styled.img`
  width: 200px;
  
  // background-color: black;
`;
const VideoPreview = styled.video`
  width: 500px;
  margin-bottom: 10px;
`;

export default FileUploader;
