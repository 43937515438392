import React, { useEffect, useState, createContext, useContext } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import { Pane, Table, Button, Dialog, Textarea } from "evergreen-ui";
import io from "socket.io-client";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import Timer from "./Timer";
import OnTimer from "./OnTimer";
import Birth from "../../Test/index";
const liveRoomUrl = "/admin/live-room/";
const messageUrl = "/admin/message/";
const groupUrl = "/admin/group";

// const socket = io.connect("https://dev2023.paprica.fit", {
const socket = io.connect(process.env.REACT_APP_SOCKET_URL, {
  cors: { origin: "*" },
  query: {
    server: "admin",
    access_token: `${localStorage.getItem("access_token")}`,
  },
  transports: ["websocket"],
  path: "/socket.io",
  forceNew: false,
});

const LiveRoomOperation = () => {
  let selectOptions = [
    { value: "REHEARSAL", label: "리허설" },
    { value: "ONAIR", label: "ON-AIR" },
  ];

  const [startDate, setStartDate] = useState("");
  const [info, setInfo] = useState("");
  const [tab, setTab] = useState("script");
  const [statusShow, setStatusShow] = React.useState(false);
  const [timeShow, setTimeShow] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState(info.live_type);
  const [selectImg, setselectImg] = useState("");
  const [groupList, setGroupList] = useState(null);
  const [liveRoomInfo, setLiveRoomInfo] = useState(info);
  const [messageOption, setMessageOption] = useState();
  const [messageInfo, setMessageInfo] = useState();
  const [instructorScript, setInstructorScript] = useState("");
  const [condition, setCondition] = useState("nickname");
  const [rankList, setRankList] = useState();
  const [timer, setTimer] = useState();
  const [startTime, setStartTime] = useState();
  let [inputCount, setInputCount] = useState(0);
  let [averageHeartbeat, setAverageHeartbeat] = useState("");
  let [averageCalories, setAverageCalories] = useState("");
  let [averageMatchingRate, setAverageMatchingRate] = useState("");

  socket.on("RECEIVE_LIVE_ROOM_DATA", (data) => {
    console.log(data);
    if (data.status === "SUCCESS") {
      console.log(data);
      console.log(data.data.live_room.actual_start_date);
      setLiveRoomInfo(data.data.live_room);
      getRank();
      setStartTime(data.data.live_room.actual_start_date);
    }
  });

  socket.on("JOIN_LIVE", (data) => {
    // console.log(data);
  });

  socket.on("RECEIVE_LIVE_RANK_LIST", (data) => {
    // console.log("랭크!!!", data);
  });

  //   socket.on("ADMIN:RECEIVE_MESSAGE_FROM_ADMIN", (data) => {
  //     console.log(data);
  //   });

  //   useEffect(() => {
  //     setStartTime(liveRoomInfo.actual_start_date);
  //   }, [liveRoomInfo]);
  useEffect(() => {
    console.log(startTime);
  }, [startTime]);

  useEffect(() => {
    const joinLiveRoom = async () => {
      try {
        const roomInfo = await getLiveRoomInfo();
        console.log(roomInfo);
        const args = {
          live_room_id: roomInfo.live_room_id,
          password: roomInfo.password,
        };

        socket.emit("JOIN_LIVE", args);

        console.log(socket);

        let formattedDateString = roomInfo.start_date
          .replace("T", " ")
          .replace(".000Z", "");
        setStartDate(formattedDateString);

        let time = roomInfo.start_date.replace("Z", "");
        setTimer(time);

        getMessage();
      } catch (error) {
        console.log(error);
        alert(error.response.data.message);
      }
    };
    joinLiveRoom();
  }, []);

  useEffect(() => {
    getGroupList();
    getRank();
  }, []);

  useEffect(() => {
    getRank();
  }, [condition]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getRank();
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const getRank = async () => {
    const id = localStorage.getItem("liveRoomId");

    console.log(id);

    try {
      const response = await axios.get(
        `${liveRoomUrl}${id}/rank/${condition}`,
        {
          params: {
            sort: "desc",
          },
        }
      );
      console.log("rank", response);

      const data = response.data.rank_list;
      setRankList(data);

      let h =
        data.reduce((acc, curr) => acc + curr.avg_heartbeat, 0) / data.length;
      let c = data.reduce((acc, curr) => acc + curr.calories, 0) / data.length;
      let m =
        data.reduce((acc, curr) => acc + curr.matching_rate, 0) / data.length;

      setAverageHeartbeat(h);
      setAverageCalories(Math.ceil(c / 1000));
      setAverageMatchingRate(m);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };
  const getGroupList = async () => {
    try {
      const response = await axios.get(groupUrl);
      console.log("group", response);
      setGroupList(response.data.group_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getLiveRoomInfo = async () => {
    const id = localStorage.getItem("liveRoomId");
    console.log(id);
    try {
      const response = await axios.get(`${liveRoomUrl}${id}`);
      console.log("Res", response);
      const liveRoom = response.data.live_room;
      setInfo(liveRoom);
      setLiveRoomInfo(liveRoom);
      return liveRoom;
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getMessage = async () => {
    try {
      const response = await axios.get(`${messageUrl}`);
      //   console.log("Res", response);
      if (response.status === 200) {
        const transformedData = response.data.message_list.map((message) => ({
          value: message.message_id,
          label: message.comment,
        }));
        setMessageOption(transformedData);
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const changeStatus = (status) => {
    let ab = {
      onair_status: status,
      live_type: liveRoomInfo.live_type,
    };
    console.log(ab);
    socket.emit("ADMIN:CHANGE_LIVE_ROOM_ONAIR_STATUS", ab);
    socket.on("ADMIN:CHANGE_LIVE_ROOM_ONAIR_STATUS", (data) => {
      console.log(data);
    });
  };

  const messageFromAdmin = () => {
    socket.on("RECEIVE_MESSAGE_FROM_ADMIN", (data) => {
      console.log(data);
    });
  };

  //   const preventClose = (e) => {
  //     if (e.clientY < 0) {
  //       // 서버에 필요한 정보를 전송하고 페이지를 떠납니다.
  //       socket.emit("LEAVE_LIVE");

  //       return null;
  //     }
  //     e.returnValue = ""; //Chrome에서 동작하도록; deprecated

  //     // 사용자가 "취소" 버튼을 클릭한 경우
  //     // return e.preventDefault();
  //     // socket.emit("LEAVE_LIVE");
  //   };

  //   useEffect(() => {
  //     (() => {
  //       window.addEventListener("beforeunload", preventClose);
  //     })();

  //     return () => {
  //       window.removeEventListener("beforeunload", preventClose);
  //     };
  //   }, []);

  const handleTabChange = async (item) => {
    await setTab(item);
  };

  const handleChange = (e) => {
    setInputCount(e.target.value.length);
    setMessageInfo({ [e.target.name]: e.target.value });
  };

  const changeLiveType = async () => {
    try {
      const params = {
        live_type: selectedValue,
      };
      // console.log(params);
      const response = await axios.put(
        `${liveRoomUrl}${info.live_room_id}`,
        params
      );
      console.log("Res", response);
      if (response.status === 200) {
        alert("변경되었습니다");
        setStatusShow(false);
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const cancleLive = () => {
    if (
      window.confirm("방송이 불가능해집니다\n정말로 방송 취소 하시겠습니까?")
    ) {
      let cancel = {
        onair_status: "ONAIR_CANCEL",
        live_type: liveRoomInfo.live_type,
      };

      // console.log(live_type)
      socket.emit("ADMIN:CHANGE_LIVE_ROOM_ONAIR_STATUS", cancel);
      socket.on("ADMIN:CHANGE_LIVE_ROOM_ONAIR_STATUS", (data) => {
        console.log(data);
      });
    }
  };

  const handleScript = (e) => {
    // console.log(e.target.name, e.target.value);
    setInstructorScript({ [e.target.name]: e.target.value });
  };

  return (
    <>
      <Pane>
        <Dialog
          isShown={statusShow}
          title="방송 조건 변경"
          onCloseComplete={() => setStatusShow(false)}
          confirmLabel="저장"
          onConfirm={async () => {
            await changeLiveType();
          }}
        >
          <div
            style={{
              width: "90%",
              height: "200px",
              margin: "auto",
              fontSize: "12px",
              lineHeight: "normal",
            }}
          >
            <Select
              styles={{
                width: "100px",
              }}
              className="selectItem"
              onChange={(e) => setSelectedValue(e.value)}
              options={selectOptions}
              placeholder="유형 선택"
              value={selectOptions.filter(function (option) {
                return option.value === selectedValue;
              })}
            />
          </div>
        </Dialog>
      </Pane>
      <Pane>
        <Dialog
          isShown={timeShow}
          title="텍스트 문구 전송"
          onCloseComplete={() => setTimeShow(false)}
          confirmLabel="저장"
          onConfirm={() => {
            messageFromAdmin();
          }}
        ></Dialog>
      </Pane>

      <div style={{ margin: "10px" }}>
        <div
          style={{
            position: "relative",
            top: "0",
            left: "0",
            display: "inline-block",
          }}
        >
          {liveRoomInfo.live_type === "REHEARSAL" ? (
            <div
              style={{
                backgroundColor: "#0a5ab2",
                width: "60px",
                fontSize: "14px",
                color: "#fff",
                textAlign: "center",
                padding: "4px 2px",
              }}
            >
              리허설
            </div>
          ) : (
            <div
              style={{
                backgroundColor: "#b00004",
                width: "60px",
                fontSize: "14px",
                color: "#fff",
                textAlign: "center",
                padding: "4px 2px",
              }}
            >
              ON-AIR
            </div>
          )}
        </div>

        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            display: "inline-block",
            marginLeft: "20px",
          }}
        >
          {(liveRoomInfo.onair_status === "WAIT" &&
            liveRoomInfo.live_type === "ONAIR") ||
          (liveRoomInfo.onair_status === "WAIT" &&
            liveRoomInfo.live_type === "REHEARSAL") ||
          (liveRoomInfo.onair_status === "ONAIR_END" &&
            liveRoomInfo.live_type === "REHEARSAL") ? (
            <>
              <Button onClick={() => setStatusShow(true)}>
                방송 조건 변경
              </Button>
              <Button
                appearance="primary"
                intent="danger"
                onClick={() => {
                  if (window.confirm("방송 운영창을 닫으시겠습니까?")) {
                    socket.emit("LEAVE_LIVE");
                    socket.on("LEAVE_LIVE", (data) => {
                      console.log(data);
                      if (data.status === "SUCCESS") {
                        window.close();
                      }
                    });
                  }
                }}
              >
                나가기
              </Button>
            </>
          ) : (
            <>
              <Button
                style={{
                  color: "#c1c4d6",
                  borderColor: "#E6E8F0",
                }}
                onClick={() => {
                  alert(
                    "대기 또는 리허설 방송 종료 인 경우에만 변경이 가능합니다"
                  );
                }}
              >
                방송 조건 변경
              </Button>
              <Button
                appearance="primary"
                intent="danger"
                onClick={() => {
                  if (window.confirm("방송 운영창을 닫으시겠습니까?")) {
                    socket.emit("LEAVE_LIVE");
                    socket.on("LEAVE_LIVE", (data) => {
                      console.log(data);
                      if (data.status === "SUCCESS") {
                        window.close();
                      }
                    });
                  }
                }}
              >
                나가기
              </Button>
            </>
          )}
        </div>

        <div
          style={{
            position: "absolute",
            left: "10px",
            bottom: "10px",
            display: "inline-block",
          }}
        >
          {(liveRoomInfo.onair_status === "WAIT" &&
            liveRoomInfo.live_type === "ONAIR") ||
          (liveRoomInfo.onair_status === "WAIT" &&
            liveRoomInfo.live_type === "REHEARSAL") ? (
            <Button
              appearance="primary"
              intent="danger"
              onClick={() => cancleLive()}
            >
              방송 취소
            </Button>
          ) : (
            <Button
              style={{
                pointerEvents: "none",
              }}
            >
              방송 취소
            </Button>
          )}
        </div>

        <LiveH1>라이브 방송 운영</LiveH1>
        <Box>
          <Title>{info.title}</Title>
          <Info>
            [비번 : {info.password || "없음"} &nbsp; 대상 :{" "}
            {groupList &&
              info.group_codes &&
              info.group_codes.split(",").map(
                (code) =>
                  groupList.find((group) => {
                    return Number(group.group_code) === Number(code);
                  }).title
              )}
            ]
          </Info>
          <Date>방송 일시 : {startDate}</Date>
        </Box>
        <Room>
          <div
            style={{
              width: "50%",
              float: "left",
              boxSizing: " border-box",
              //   background: "#8977ad",
              display: "flex",
              justifyContent: "center",
              wordBreak: "keep-all",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
              }}
            >
              {liveRoomInfo.onair_status === "ONAIR" && (
                <div div style={{ marginRight: "auto", padding: "20px 10%" }}>
                  {startTime && <OnTimer time={startTime}> </OnTimer>}
                </div>
              )}
              {liveRoomInfo.onair_status === "WAIT" && (
                <div style={{ marginRight: "auto", padding: "20px 10%" }}>
                  <Timer startDate={timer}></Timer>
                </div>
              )}
              {liveRoomInfo.onair_status === "ONAIR_WAIT" && (
                <div style={{ marginRight: "auto", padding: "20px 10%" }}>
                  <Timer startDate={timer}></Timer>
                </div>
              )}

              <Table width="95%" textAlign="center">
                <Table.Head>
                  <Table.TextHeaderCell>
                    {liveRoomInfo.user_count}
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell>
                    {averageCalories ? averageCalories : 0}
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell>
                    {averageHeartbeat ? averageHeartbeat : 0}
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell>
                    {averageMatchingRate ? averageMatchingRate : 0}
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell>
                    <Birth date={rankList}></Birth>
                  </Table.TextHeaderCell>
                </Table.Head>

                <Table.Head>
                  <Table.TextHeaderCell
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCondition("nickname");
                    }}
                  >
                    접속자
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCondition("calories");
                    }}
                  >
                    소모 칼로리
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCondition("avg_heartbeat");
                    }}
                  >
                    심박수
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCondition("matching_rate");
                    }}
                  >
                    레슨(달성률)
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCondition("birth_date");
                    }}
                  >
                    생일
                  </Table.TextHeaderCell>
                </Table.Head>
                <Table.Body height="auto">
                  {rankList && rankList.length > 0 ? (
                    rankList.map((item) => (
                      <Table.Row key={item.bike_id} isSelectable>
                        <Table.TextCell>{item.nickname}</Table.TextCell>
                        <Table.TextCell>
                          {Math.ceil(item.calories / 1000)}
                        </Table.TextCell>
                        <Table.TextCell>{item.avg_heartbeat}</Table.TextCell>
                        <Table.TextCell>{item.matching_rate}</Table.TextCell>
                        <Table.TextCell>{item.birth_date}</Table.TextCell>
                      </Table.Row>
                    ))
                  ) : (
                    <div style={{ margin: "30px" }}>입장한 유저가 없습니다</div>
                  )}
                </Table.Body>
              </Table>
            </div>
          </div>
          <div
            style={{
              width: "50%",
              float: "right",
              boxSizing: " border-box",
              //   background: "#ece6cc",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              margin: "20px 0",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "5px",
              }}
            >
              {liveRoomInfo.onair_status === "ONAIR_CANCEL" && (
                <>
                  <Button
                    appearance="primary"
                    intent="danger"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    방송 취소
                  </Button>
                  <Button disabled>방송 대기</Button>
                  <Button disabled>강사 시작</Button>
                  <Button disabled>방송 시작</Button>
                  <Button disabled>결과 노출</Button>
                  <Button disabled>방송 종료</Button>
                </>
              )}

              {liveRoomInfo.onair_status === "WAIT" && (
                <>
                  <Button
                    appearance="primary"
                    intent="success"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    대기
                  </Button>
                  <Button
                    onClick={() => {
                      changeStatus("ONAIR_WAIT");
                    }}
                  >
                    방송 대기
                  </Button>
                  <Button disabled>강사 시작</Button>
                  <Button disabled>방송 시작</Button>
                  <Button disabled>결과 노출</Button>
                  <Button disabled>방송 종료</Button>
                </>
              )}

              {liveRoomInfo.onair_status === "ONAIR_WAIT" && (
                <>
                  <Button disabled>대기</Button>
                  <Button
                    appearance="primary"
                    intent="success"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    방송 대기
                  </Button>
                  <Button disabled>강사 시작</Button>
                  <Button disabled>방송 시작</Button>
                  <Button disabled>결과 노출</Button>
                  <Button disabled>방송 종료</Button>
                </>
              )}

              {liveRoomInfo.onair_status === "INSTRUCTOR_START" && (
                <>
                  <Button disabled>대기</Button>
                  <Button disabled>방송 대기</Button>
                  <Button
                    appearance="primary"
                    intent="success"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    강사 시작
                  </Button>
                  <Button
                    onClick={() => {
                      changeStatus("ONAIR");
                      getRank();
                    }}
                  >
                    방송 시작
                  </Button>
                  <Button disabled>결과 노출</Button>
                  <Button disabled>방송 종료</Button>
                </>
              )}

              {liveRoomInfo.onair_status === "ONAIR" && (
                <>
                  <Button disabled>대기</Button>
                  <Button disabled>방송 대기</Button>
                  <Button disabled>강사 시작</Button>
                  <Button
                    appearance="primary"
                    intent="success"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    방송 시작
                  </Button>
                  <Button
                    onClick={() => {
                      changeStatus("RESULT_SHOW");
                    }}
                  >
                    결과 노출
                  </Button>
                  <Button disabled>방송 종료</Button>
                </>
              )}

              {liveRoomInfo.onair_status === "RESULT_SHOW" && (
                <>
                  <Button disabled>대기</Button>
                  <Button disabled>방송 대기</Button>
                  <Button disabled>강사 시작</Button>
                  <Button disabled>방송 시작</Button>
                  <Button
                    appearance="primary"
                    intent="success"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    결과 노출
                  </Button>
                  <Button
                    onClick={() => {
                      changeStatus("ONAIR_END");
                    }}
                  >
                    방송 종료
                  </Button>
                </>
              )}

              {liveRoomInfo.onair_status === "ONAIR_END" && (
                <>
                  <Button disabled>대기</Button>
                  <Button disabled>방송 대기</Button>
                  <Button disabled>강사 시작</Button>
                  <Button disabled>방송 시작</Button>
                  <Button disabled>결과 노출</Button>
                  <Button
                    appearance="primary"
                    intent="success"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    방송 종료
                  </Button>
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "500px",
                gap: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  wordBreak: "keep-all",
                  flexDirection: "column",
                }}
              >
                <span>방송 대기 화면 text 메시지 보내기</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {liveRoomInfo.onair_status === "ONAIR_WAIT" ? (
                    <>
                      <Textarea
                        name="comment"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        placeholder="유저에게 노출 할 메시지를 입력해주세요"
                        maxLength={80}
                      ></Textarea>

                      <Button
                        onClick={() => {
                          socket.emit(
                            "ADMIN:SEND_TEXT_MESSAGE_TO_USER",
                            messageInfo
                          );
                        }}
                      >
                        전송
                      </Button>
                    </>
                  ) : (
                    <>
                      <Textarea
                        name="comment"
                        readOnly
                        placeholder="대기 상태에서만 전송 가능합니다"
                      ></Textarea>
                      <Button disabled>전송</Button>
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "block",
                    fontSize: "8px",
                    marginTop: "3px",
                  }}
                >
                  <span>{inputCount}</span>
                  <span>/80 자</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  wordBreak: "keep-all",
                  flexDirection: "column",
                }}
              >
                <span>방송 대기 화면 image 메시지 보내기</span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "inherit",
                  }}
                >
                  {liveRoomInfo.onair_status === "ONAIR_WAIT" ? (
                    <>
                      <div style={{ width: "450px" }}>
                        <Select
                          className="selectItem"
                          onChange={(e) => setselectImg(e.value)}
                          options={messageOption}
                          //   value={messageOption.filter(function (option) {
                          //     return option.value === selectImg;
                          //   })}
                          value={
                            selectImg
                              ? messageOption.filter(function (option) {
                                  return option.value === selectImg;
                                })
                              : null
                          }
                        />
                      </div>
                      <Button
                        onClick={() => {
                          //   console.log(selectImg);
                          let args = {
                            message_id: selectImg,
                          };
                          socket.emit("ADMIN:SEND_MESSAGE_TO_USER", args);
                          socket.on("ADMIN:SEND_MESSAGE_TO_USER", (data) => {
                            console.log(data);
                          });
                        }}
                      >
                        전송
                      </Button>
                    </>
                  ) : (
                    <>
                      <div style={{ width: "450px" }}>
                        <Select isDisabled />
                      </div>
                      <Button disabled>전송</Button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div>
              <ListUl>
                <ListLi
                  onClick={() => handleTabChange("script")}
                  //   className={tab === "script" && "checked"}
                >
                  강사 전송 스크립트
                </ListLi>

                {/* <ListLi
                  onClick={() => handleTabChange("feedback")}
                  className={tab === "feedback" && "checked"}
                >
                  피드백
                </ListLi> */}
              </ListUl>
              {tab === "script" && (
                // <ScriptDiv>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Textarea
                    name="message"
                    style={{
                      width: "480px",
                      //   padding: "10px",
                      //   border: 1px solid #cacaca,
                      height: "300px",
                      marginBottom: "10px",
                    }}
                    onChange={(e) => handleScript(e)}
                  ></Textarea>
                  <Button
                    onClick={() => {
                      console.log(instructorScript);
                      socket.emit(
                        "ADMIN:SEND_MESSAGE_TO_ADMIN",
                        instructorScript
                      );
                      socket.on("ADMIN:RECEIVE_MESSAGE_TO_ADMIN", (data) => {
                        console.log(data);
                      });
                    }}
                  >
                    전송
                  </Button>
                </div>
                // </ScriptDiv>
              )}
              {tab === "feedback" && <FeedbackDiv>b</FeedbackDiv>}
            </div>
          </div>
        </Room>
      </div>
    </>
  );
};

const LiveH1 = styled.h1`
  text-align: center;
  font-size: 24px;
  margin: 20px 0;
`;
const Box = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Title = styled.span`
  font-size: 20px;
  margin-right: 20px;
`;
const Info = styled.span`
  font-size: 14px;
  margin-right: 20px;
`;
const Date = styled.span`
  font-size: 14px;
`;
const Room = styled.div`
  border: 1px solid #cacaca;
  height: 80vh;
  display: flex;
`;

const ListUl = styled.ul`
  text-align: center;
  width: 100%;
  margin-top: 40px;
`;
const ListLi = styled.li`
  width: 250px;
  &:hover,
  &.checked {
    background-color: #7b9cff;
    color: white;
  }
  cursor: pointer;
  display: inline-block;
  padding: 10px 0;
`;
// const ScriptDiv = styled.div`
//   width: 480px;
//   padding: 10px;
//   border: 1px solid #cacaca;
//   height: 300px;
// `;
const FeedbackDiv = styled.div`
  width: 480px;
  border: 1px solid #cacaca;
  padding: 10px;
  height: 300px;
`;
export default LiveRoomOperation;
