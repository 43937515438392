import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
} from "evergreen-ui";
import DateText from "components/DateText";

const baseUrl = "/admin/music-tag";

const MusicTag = () => {
  const PAGE_SIZE = 10;
  const [musicTagList, setmusicTagList] = useState([]);
  const [musictagInfo, setMusicTagInfo] = useState([]);
  const [musictag, setMusicTag] = useState([]);
  const [isShown, setIsShown] = React.useState(false);
  const [isShownInfo, setIsShownInfo] = React.useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [tagKeyword, setTagKeyword] = useState("");

  useEffect(() => {
    list();
  }, [currentPage]);

  //인풋 수정시
  const handleChange = (e) => {
    setMusicTag({ ...musictag, [e.target.name]: e.target.value });
  };

  const beforeSearch = () => {
    setCurrentPage(1);
    list();
  };
  const list = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: tagKeyword,
    };

    axios
      .get(baseUrl, { params })
      .then((res) => {
        const data = res.data.music_tag_list;
        const dataCount = res.data.count;

        setTotalCount(dataCount);
        setmusicTagList(data);
        // setIsModalOpened(false);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  // const list = () => {
  //   let config = {
  //     method: "get",
  //     url: axios.defaults.baseURL + `/admin/music-tag`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //     },
  //   };
  //   // return;
  //   axios(config)
  //     .then(function (response) {
  //       console.log(response);
  //       setmusicTagList(response.data.music_tag_list);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       alert(error.response.data.message);
  //     });
  // };

  //음악태그 등록
  const musictagUpdate = () => {
    let data = { ...musictag };

    let config = {
      method: "POST",
      url: "/admin/music-tag/",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      data: data,
    };
    // console.log(config);
    axios(config)
      .then(function (response) {
        // console.log(response);
        if (response.status === 200) {
          alert("등록되었습니다");
        } else {
        }
        setIsShown(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //음악태그 삭제
  const musictagDelete = () => {
    console.log(musictagInfo);
    if (window.confirm("삭제하시겠습니까?")) {
      // return;
      let config = {
        method: "delete",
        url: "/admin/music-tag/" + musictagInfo.music_tag_id,
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      // console.log(config);
      axios(config)
        .then(function (response) {
          // console.log(response);
          if (response.status === 200) {
            alert("삭제되었습니다");
          } else {
          }
          setIsShown(false);
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
          alert(error.response.data.message);
        });
    }
  };
  return (
    <>
      <Pane>
        <Dialog
          isShown={isShown}
          onCloseComplete={() => setIsShown(false)}
          confirmLabel="등록하기"
          onConfirm={() => musictagUpdate()}
          hasCancel={false}
          hasClose={false}
          title="음악태그 등록"
        >
          <InputDiv>
            <InputSpan>음악태그</InputSpan>
            <TextInput
              maxLength={30}
              name="title"
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
        </Dialog>

        <Dialog
          isShown={isShownInfo}
          onCloseComplete={() => setIsShownInfo(false)}
          confirmLabel="삭제하기"
          onConfirm={() => musictagDelete()}
          intent="danger"
          hasCancel={false}
          hasClose={false}
          title="음악태그 정보 관리"
        >
          <InputDiv>
            <InputSpan>음악태그</InputSpan>
            <TextInput
              disabled
              name="title"
              value={musictagInfo.title}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
        </Dialog>
      </Pane>

      <BannerBox>
        <Pane>
          <BannerH1>음악태그</BannerH1>
        </Pane>
        <Box>
          <SearchBtn>
            <TextInput
              placeholder="태그명"
              width={"250px"}
              onChange={(e) => {
                const keyword = e.target.value;
                setTagKeyword(keyword);
              }}
            ></TextInput>
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "36%",
                bottom: "20px",
                marginLeft: "10px",
              }}
              onClick={beforeSearch}
            >
              검색
            </Button>
          </SearchBtn>
          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={() => setIsShown(true)}
          >
            등록하기
          </Button>
        </Box>
        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell>음악태그 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>제목</Table.TextHeaderCell>
            <Table.TextHeaderCell>생성 날짜</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {musicTagList.map((musictag) => (
              <Table.Row
                key={musictag.music_tag_id}
                isSelectable
                onSelect={() => {
                  //   showModal();
                  setIsShownInfo(true);
                  setMusicTagInfo(musictag);
                  //   console.log(musictag);
                }}
              >
                <Table.TextCell>{musictag.music_tag_id}</Table.TextCell>
                <Table.TextCell>{musictag.title}</Table.TextCell>
                <Table.TextCell>
                  <DateText minimum>{musictag.create_date}</DateText>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </BannerBox>
    </>
  );
};

const BannerBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const BannerH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;
const SearchBtn = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;
const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
`;
export default MusicTag;
