import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  RadioGroup,
  SelectMenu,
  TextareaField,
} from "evergreen-ui";
import DateText from "components/DateText";
import { diff, isEmpty } from "services/util";

const baseUrl = "/admin/announcement";
// const tagUrl = "/admin/announcement";

const Announcement = () => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [announcementList, setAnnouncementList] = useState([]);
  const [originalAnnouncementInfo, setOriginalAnnouncementInfo] = useState({});
  const [announcementInfo, setAnnouncementInfo] = useState({});
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  const [tagList, setTagList] = useState([]);
  const [category, setCategory] = useState(null);

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "공지사항 등록",
      action: () => {
        onAddAnnouncementInfo();
      },
    },
    MOD: {
      button: "수정하기",
      title: "공지사항 수정",
      action: () => {
        onModifyAnnouncementInfo();
      },
    },
  };
  const [announcementIsShowOption] = React.useState([
    { label: "노출", value: "1" },
    { label: "미노출", value: "0" },
  ]);
  const [announcementIsMainOption] = React.useState([
    { label: "미등록", value: "0" },
    { label: "등록", value: "1" },
  ]);

  useEffect(() => {
    getAnnouncementList();
    getCategoryList();
  }, [currentPage]);

  const addAnnouncementModal = (item) => {
    openModal(item, "ADD");
  };
  const modifyAnnouncementModal = (item) => {
    openModal(item, "MOD");
  };

  const openModal = async (item, type) => {
    await getCategoryList();
    setModalType(type);
    setOriginalAnnouncementInfo(item);
    setAnnouncementInfo(item);
    setIsModalOpened(true);
  };
  const closeModal = () => {
    setOriginalAnnouncementInfo({});
    setAnnouncementInfo({});
    setIsModalOpened(false);
  };

  const getCategoryList = async () => {
    try {
      const response = await axios.get(`${baseUrl}/category`);
      const data = response.data.announce_category_list;
      setTagList(data);

      const newData = Object.fromEntries(
        data.map(({ category_code, category }) => [category_code, category])
      );
      setCategory(newData);

      // const CategoryName = category[1];

      // console.log(CategoryName);
    } catch (error) {
      console.log(error);
      // alert(error.response.data.message);
    }
  };

  const setCategoryInfo = (item) => {
    if (category === null) {
      getCategoryList();
    } else {
      const CategoryName = category[item.category_code];
      return CategoryName;
    }
  };

  // //카테고리 목록
  // const getCategoryList = () => {
  //   const params = {
  //     limit: 10000,
  //     //   offset: PAGE_SIZE * (currentPage - 1),
  //   };
  //   axios
  //     .get(`${baseUrl}/category`, { params })
  //     .then((res) => {
  //       console.log(res);
  //       // setCategoryInfo(res.data.announce_category_list);
  //       const response = res.data.announce_category_list;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  //공지사항 목록
  const getAnnouncementList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
    };
    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log("공지사항 목록", res);
        const data = res.data.announcement_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        setAnnouncementList(data);
        setIsModalOpened(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };
  //공지사항 상세조회
  const getAnnouncement = async (announcement_id) => {
    try {
      const res = await axios.get(`${baseUrl}/${announcement_id}`);
      console.log("공지사항 상세", res);
      const data = res.data.announcement;

      // let newContent = {
      //   ...data,
      //   _category_code: data.category_code.split(",").map((i) => Number(i)),
      // };

      let newContent = {};

      if (data.category_code === null) {
        newContent = {
          ...data,
        };
      } else {
        newContent = {
          ...data,
          _category_code: [data.category_code],
        };
      }
      return newContent;
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
      return {};
    }
  };
  //공지사항 등록
  const onAddAnnouncementInfo = () => {
    let params = diff(announcementInfo, originalAnnouncementInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }
    if (typeof params.is_main === "string") {
      let before = Number(params.is_main);
      params.is_main = before;
    }
    params.is_show = 1;
    console.log(params);

    axios
      .post(baseUrl, params)
      .then((res) => {
        console.log(res);
        alert("공지사항을 등록하였습니다.");
        getAnnouncementList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };
  //공지사항 수정
  const onModifyAnnouncementInfo = () => {
    let params = diff(announcementInfo, originalAnnouncementInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }
    if (typeof params.is_show === "string") {
      let before = Number(params.is_show);
      params.is_show = before;
    }
    if (typeof params.is_main === "string") {
      let before = Number(params.is_main);
      params.is_main = before;
    }
    if (typeof params.category_code === "string") {
      let before = Number(params.category_code);
      params.category_code = before;
    }
    console.log(params);

    axios
      .put(`${baseUrl}/${announcementInfo.announcement_id}`, params)
      .then((res) => {
        console.log(res);
        alert("공지사항을 수정하였습니다.");
        getAnnouncementList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };
  //공지사항 삭제
  const onDeleteAnnouncement = (announcement_id) => {
    if (!window.confirm("공지사항을 삭제하겠습니까?")) {
      closeModal();
      return;
    }

    axios
      .delete(`${baseUrl}/${announcement_id}`)
      .then((res) => {
        console.log(res);
        alert("공지사항이 삭제되었습니다.");
        getAnnouncementList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    setAnnouncementInfo({
      ...announcementInfo,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={`${modalTypeOption[modalType].button}`}
          onConfirm={modalTypeOption[modalType].action}
          hasCancel={false}
          hasClose={false}
          title={`${modalTypeOption[modalType].title}`}
        >
          {modalType === "MOD" ? (
            <div>
              <InputDiv>
                <InputSpan>공지사항 ID</InputSpan>
                <TextInput
                  name="announcement_id"
                  onChange={handleChange}
                  disabled
                  defaultValue={originalAnnouncementInfo.announcement_id}
                />
              </InputDiv>
              <InputDiv>
                <InputSpan>노출 여부</InputSpan>
                <RadioGroup
                  display="flex"
                  columnGap="10px"
                  size={16}
                  name="is_show"
                  value={String(announcementInfo.is_show)}
                  defaultValue={String(announcementInfo.is_show)}
                  options={announcementIsShowOption}
                  onChange={handleChange}
                />
              </InputDiv>
            </div>
          ) : null}

          <InputDiv>
            <InputSpan>제목</InputSpan>
            <TextInput
              name="title"
              onChange={handleChange}
              defaultValue={originalAnnouncementInfo.title}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>본문</InputSpan>
            <TextareaField
              maxLength={3000}
              name="body"
              onChange={handleChange}
              defaultValue={originalAnnouncementInfo.body}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>카테고리 코드</InputSpan>
            <SelectMenu
              title="announcementCategory"
              closeOnSelect={true}
              options={tagList.map((item) => ({
                label: item.category,
                value: item.category_code,
              }))}
              selected={String(announcementInfo.category_code)}
              onSelect={(item) =>
                setAnnouncementInfo({
                  ...announcementInfo,
                  category_code: item.value,
                })
              }
            >
              <Button>
                {announcementInfo.category_code
                  ? tagList.find(
                      (i) => i.category_code === announcementInfo.category_code
                    ).category
                  : "카테고리를 선택해주세요"}
              </Button>
            </SelectMenu>
          </InputDiv>
          <InputDiv>
            <InputSpan>메인 공지사항 여부</InputSpan>
            {modalType === "MOD" ? (
              <RadioGroup
                display="flex"
                columnGap="10px"
                size={16}
                name="is_main"
                options={announcementIsMainOption}
                value={String(announcementInfo.is_main)}
                onChange={handleChange}
              />
            ) : (
              <RadioGroup
                display="flex"
                columnGap="10px"
                size={16}
                name="is_main"
                options={announcementIsMainOption}
                onChange={handleChange}
              />
            )}
          </InputDiv>
        </Dialog>
      </Pane>

      <AnnouncementBox>
        <Pane>
          <AnnouncementH1>공지사항</AnnouncementH1>
        </Pane>
        <Button
          appearance="primary"
          intent="none"
          style={{
            position: "inherit",
            left: "36%",
            bottom: "20px",
          }}
          onClick={() => addAnnouncementModal({})}
        >
          등록하기
        </Button>
        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell>공지사항 ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>제목</Table.TextHeaderCell>
            <Table.TextHeaderCell>본문</Table.TextHeaderCell>
            <Table.TextHeaderCell>노출여부</Table.TextHeaderCell>
            <Table.TextHeaderCell>카테고리 코드</Table.TextHeaderCell>
            <Table.TextHeaderCell>메인 여부</Table.TextHeaderCell>
            <Table.TextHeaderCell>생성 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell>업데이트 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {announcementList.map((item) => (
              <Table.Row
                key={item.announcement_id}
                isSelectable
                onSelect={async () => {
                  const _announcement = await getAnnouncement(
                    item.announcement_id
                  );
                  console.log(_announcement);
                  modifyAnnouncementModal(_announcement);
                }}
              >
                <Table.TextCell>{item.announcement_id}</Table.TextCell>
                <Table.TextCell>{item.title}</Table.TextCell>
                <Table.TextCell>{item.body}</Table.TextCell>
                <Table.TextCell>
                  {item.is_show ? "노출" : "미노출"}
                </Table.TextCell>
                {/* <Table.TextCell>{item.category_code}</Table.TextCell> */}
                <Table.TextCell> {setCategoryInfo(item)}</Table.TextCell>
                <Table.TextCell>
                  {item.is_main ? "메인 등록" : "미등록"}
                </Table.TextCell>
                <Table.TextCell>
                  <DateText>{item.create_date}</DateText>
                </Table.TextCell>
                <Table.TextCell>
                  <DateText>{item.update_date}</DateText>
                </Table.TextCell>
                <Table.TextCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    appearance="primary"
                    intent="danger"
                    onClick={(event) => {
                      onDeleteAnnouncement(item.announcement_id);
                      // event.preventDefault();
                      event.stopPropagation();
                    }}
                  >
                    삭제
                  </Button>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </AnnouncementBox>
    </>
  );
};

// ModalBasic.defaultProps = {};
const AnnouncementBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const AnnouncementH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 140px;
  font-size: 12px;
`;

export default Announcement;
