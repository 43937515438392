import React, { useEffect, useState, memo } from "react";
import styled from "styled-components";
import { useDrag, useDrop } from "react-dnd";
import { Button, TextInput, Textarea } from "evergreen-ui";

const Timetable = ({ item, idx, onChangedTimetable }) => {
  // const id = item._id
  const [data, setData] = useState({});
  const [value, setValue] = useState("");
  // console.log(props.data)

  useEffect(() => {
    // let newData = {...item}
    let newSegment = item.segment.map((i, idx) => ({ ...i, _id: idx }));
    let newData = { ...item, segment: newSegment };
    setData(newData);
    setValue(newData.time);
    console.log(newData);
  }, []);

  // useEffect(() => {
  //   onChangeData();
  // }, [data.time]);

  const onAddTimetable = () => {
    // console.log((data.segment||[]).map(i=>i._id))
    const no = Math.max(Math.max(...(data.segment || []).map((i) => i._id)), 0);
    // console.log(no)
    const newObj = {
      _id: no + 1,
      time: "",
      timeSegment: "",
    };
    let newTimetable = { ...data, segment: [...data.segment, newObj] };
    setChangedData(newTimetable);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    let newTimetable = { ...data, time: event.target.value };
    onChangedTimetable(idx, newTimetable);
  };

  const onDeleteTimetable = (idx) => {
    let newSegment = (data.segment || []).filter((_, index) => {
      return index !== idx;
    });
    let newTime = newSegment
      .map((i) => {
        let mmss = i.time.split(":");
        return Number(mmss[0]) * 60 + Number(mmss[1]);
      })
      .reduce((a, b) => a + b, 0);
    let newTimeStr = `${String(parseInt(newTime / 60)).padStart(
      2,
      "0"
    )}:${String(parseInt(newTime % 60)).padStart(2, "0")}`;
    setChangedData({ ...data, time: newTimeStr, segment: newSegment });
  };

  const setChangedData = (data) => {
    setData(data);
    setValue(data.time);
    onChangedTimetable(idx, data);
  };

  const onChangeData = (e, idx, needCheckTime) => {
    // console.log(e.target.name,e.target.value)
    const newData = { ...data };
    const propNames = e.target.name.split(".");
    if (propNames.length > 1) {
      if (needCheckTime) {
        // console.log("needCheckTime", propNames[1],e.target.value)
        const regExp = /^[0-9]{1,3}:[0-9]{1,2}$/;
        let mmss = e.target.value.split(":");
        if (mmss.length === 2) {
          let mm = Number(mmss[0]);
          let ss = Number(mmss[1]);
          // console.log(mm,ss)
          if (mm > 1000) {
            return;
          }
          if (ss >= 100) {
            if (mm < 10) {
              mm = mm * 10 + parseInt(ss / 100);
              ss = ss % 100;
            }
          }
          if (ss >= 60) {
            return;
          }
          newData["segment"][idx][propNames[1]] = `${String(mm).padStart(
            2,
            "0"
          )}:${String(ss).padStart(2, "0")}`;
        } else if (mmss.length === 1) {
          console.log("mmss.length === 1");
          newData["segment"][idx][propNames[1]] = `00:${String(
            e.target.value
          ).padStart(2, "0")}`;
        }
        newData.time = newData.segment
          .map((i) => {
            let mmss = i.time.split(":");
            return Number(mmss[0]) * 60 + Number(mmss[1]);
          })
          .reduce((a, b) => a + b, 0);
        const regExpResult = regExp.test(newData["segment"][idx][propNames[1]]);
        // console.log(regExpResult);
        if (!regExpResult) {
          return;
        }
        newData.time = `${String(parseInt(newData.time / 60)).padStart(
          2,
          "0"
        )}:${String(parseInt(newData.time % 60)).padStart(2, "0")}`;
      } else {
        newData["segment"][idx][propNames[1]] = e.target.value;
      }
    } else {
      newData[e.target.name] = e.target.value;
    }
    // console.log("newData", newData)
    setChangedData(newData);
  };

  return (
    <FlexDiv
      style={{
        flexDirection: "row",
        width: "100%",
        padding: "5px 0",
      }}
    >
      {/* 아래 input */}
      <FlexDiv style={{ flex: 1, flexDirection: "column" }}>
        <TextInput
          name="bikeStep"
          value={data.bikeStep || ""}
          style={{ width: "100%" }}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        <FlexDiv
          style={{
            width: "100%",
            padding: "3px 0",
            flexDirection: "column",
          }}
        >
          {data.segment &&
            data.segment.map((seg, index) => {
              // console.log(`0${idx}${seg._id}${index}`)
              return (
                <FlexDiv
                  key={`0${idx}${seg._id}${index}`}
                  style={{ flex: 1, flexDirection: "row", padding: "3px 0" }}
                >
                  <TextInput
                    name="segment.timeSegment"
                    value={seg.timeSegment}
                    style={{ width: "50%" }}
                    onChange={(e) => {
                      onChangeData(e, index);
                    }}
                  />
                  <TextInput
                    name="segment.time"
                    value={seg.time}
                    style={{ width: "50%" }}
                    onChange={(e) => {
                      onChangeData(e, index, true);
                    }}
                  />
                </FlexDiv>
              );
            })}
        </FlexDiv>
        <Button appearance="primary" onClick={onAddTimetable}>
          구간 추가
        </Button>
      </FlexDiv>

      {/* 위 input */}
      <FlexDiv style={{ flex: 1, flexDirection: "column", paddingLeft: "5px" }}>
        <TextInput
          name="time"
          value={data.time || value}
          // defaultValue={"" || data.time}
          style={{ width: "100%" }}
          onChange={handleChange}
          // placeholder="ALSK"
        />
        <FlexDiv
          style={{
            width: "100%",
            padding: "3px 0",
            flexDirection: "column",
          }}
        >
          {data.segment &&
            data.segment.map((seg, index) => {
              // console.log(`1${idx}${seg._id}${index}`)
              return (
                <FlexDiv
                  style={{
                    padding: "3px 0",
                  }}
                  key={`1${idx}${seg._id}${index}`}
                >
                  <Button
                    appearance="primary"
                    intent="danger"
                    onClick={() => {
                      onDeleteTimetable(index);
                    }}
                  >
                    삭제
                  </Button>
                </FlexDiv>
              );
            })}{" "}
        </FlexDiv>
      </FlexDiv>
    </FlexDiv>
  );
};

const memorizedTimetable = memo(Timetable);

const FlexDiv = styled.div`
  display: flex;
`;

const InputSpan = styled.span`
  // width: 100px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
`;

export default memorizedTimetable;
