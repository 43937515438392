import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, TextInput } from "evergreen-ui";
import axios from "services/auth/jwt/config";
import LocalStorageService from "services/storage/LocalStorageService";

const localStorageService = LocalStorageService.getService();
const baseUrl = "/admin/auth/login";

function Login() {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    userId: "",
    userPw: "",
  });

  const onChange = (e) => {
    // console.log(e.target.value);
    const { name, value } = e.target;
    const nextInputs = { ...inputs, [name]: value };
    setInputs(nextInputs);
    // console.log(setInputs);
  };

  function letsLogin() {
    // console.log(inputs);
    if (inputs.userId === "") {
      alert("아이디를 입력해주세요.");
      return;
    } else if (inputs.userPw === "") {
      alert("비밀번호를 입력해주세요");
      return;
    } else {
      let data = {
        email: inputs.userId,
        password: inputs.userPw,
      };
      // console.log(data);

      axios
        .post(baseUrl, data)
        .then((response) => {
          // console.log(response.data);
          localStorageService.setToken(response.data);
          navigate("/");
        })
        .catch(function (error) {
          console.log(error);
          alert(error.response.data.message);
          console.log(error.response.status);
          console.log(error.response.headers);
        });
    }
  }
  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      letsLogin(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  return (
    <LoginBox>
      <LoginFormBox>
        <LoginH2>POPPRIKA</LoginH2>
        <LoginH3>파프리카 바이크 CMS 페이지 입니다</LoginH3>

        <InputForm>
          <InputFormRow>
            <InputFormItem>
              <InputFormInput>
                <TextInput
                  type="text"
                  id="userId"
                  name="userId"
                  placeholder="아이디"
                  onChange={onChange}
                />
              </InputFormInput>
            </InputFormItem>
          </InputFormRow>

          <InputFormRow>
            <InputFormItem>
              <InputFormInput>
                <TextInput
                  type="password"
                  id="userPw"
                  name="userPw"
                  placeholder="비밀번호"
                  onChange={onChange}
                  onKeyPress={handleOnKeyPress}
                />
              </InputFormInput>
            </InputFormItem>
          </InputFormRow>

          <Button appearance="primary" onClick={letsLogin}>
            Login
          </Button>
        </InputForm>
      </LoginFormBox>
    </LoginBox>
  );
}
const LoginBox = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0;
  text-align: center;
`;
const LoginFormBox = styled.div`
  margin: 30px;
  border: 1px solid #f8f8f8;
  border-radius: 20px;
  background: #f8f8f8;
  padding: 30px;
`;
const LoginH2 = styled.h2`
  color: #d85894;
  font-size: 38px;
  font-weight: bold;
`;
const LoginH3 = styled.h3`
  font-size: 12px;
  color: grey;
  margin-top: 10px;
  margin-bottom: 50px;
`;
const InputForm = styled.div``;
const InputFormRow = styled.div`
  padding: 10px;
`;
const InputFormItem = styled.div``;
const InputFormInput = styled.div``;

export default Login;
