import React, { useState, useEffect, useRef } from "react";
import axios from "services/auth/jwt/config";

import styled from "styled-components";

function Timer(time) {
  console.log(time);
  const [remainingTime, setRemainingTime] = useState(null);

  function getRemainingTime() {
    if (!time) {
      return null; // startDate 값이 없는 경우 null 반환
    }

    const now = new Date().toLocaleString("en-US", { timeZone: "Asia/Seoul" });
    const startDateInMs = new Date(time.startDate).getTime();
    const nowInMs = new Date(now).getTime();
    const remainingTimeInMs = startDateInMs - nowInMs;

    if (remainingTimeInMs < 0) {
      return "0";
    }
    const remainingTime = new Date(remainingTimeInMs);

    const hours = remainingTime.getUTCHours().toString().padStart(2, "0");
    const minutes = remainingTime.getUTCMinutes().toString().padStart(2, "0");
    const seconds = remainingTime.getUTCSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(getRemainingTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [time]);

  return (
    <div>
      방송 시작 남은 시간 &nbsp;
      {remainingTime ?? ""}
    </div>
  );
}

const ChallengeBox = styled.div``;
export default Timer;
