import React, { useEffect, useState, memo } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
} from "evergreen-ui";

const baseUrl = "/admin/music";

const MusicSearch = ({ musicIds, initialFullMusicList, onSetMusicIds }) => {
  const PAGE_SIZE = 5;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [fullMusicList, setFullMusicList] = useState(initialFullMusicList);
  const [musicSearchList, setMusicSearchList] = useState([]);
  const [musicIdsInfo, setMusicIdsInfo] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  const [musicKeyword, setMusicKeyword] = useState("");

  const modalTypeOption = {
    ADD: {
      button: "저장",
      title: "음원 검색",
      action: () => {
        onSaveMusicList();
      },
    },
  };

  useEffect(() => {
    getMusicList();
  }, [currentPage]);

  const musicSearchModal = () => {
    setMusicIdsInfo(musicIds);
    getFullMusicList();
    openModal();
  };

  const openModal = () => {
    getMusicList();
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setMusicKeyword("");
    setMusicIdsInfo([]);
    setIsModalOpened(false);
  };

  const beForeGetMusicList = () => {
    if (currentPage !== 1) {
      console.log("ASDF");
      setCurrentPage(1);
      getMusicList();
    } else {
      getMusicList();
    }
  };
  const getFullMusicList = async () => {
    try {
      const response = await axios.get(baseUrl);
      console.log("music-list", response);
      setFullMusicList(response.data.music_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getMusicList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: musicKeyword,
    };

    console.log(params);

    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.music_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        setMusicSearchList(data);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  const onAddMusicList = (music_id) => {
    let newMusicIdsInfo = [...musicIdsInfo, music_id];
    setMusicIdsInfo(newMusicIdsInfo);
  };

  const onRemoveMusicList = (music_id) => {
    console.log(music_id);
    let newMusicIdsInfo = musicIds.filter((i) => i !== music_id);
    console.log(musicIds, newMusicIdsInfo);
    // setMusicIdsInfo(newMusicIdsInfo)
    onSetMusicIds(newMusicIdsInfo);
  };

  const onSaveMusicList = () => {
    console.log(musicIdsInfo);
    onSetMusicIds(musicIdsInfo);
    closeModal();
  };

  return (
    <Pane style={{ width: "100%" }}>
      <Dialog
        isShown={isModalOpened}
        onCloseComplete={() => closeModal()}
        confirmLabel={modalTypeOption[modalType].button}
        onConfirm={modalTypeOption[modalType].action}
        hasClose={false}
        title={modalTypeOption[modalType].title}
      >
        <InputDiv>
          <InputSpan>음원 검색</InputSpan>
          <TextInput
            onChange={(e) => {
              const keyword = e.target.value;
              setMusicKeyword(keyword);
            }}
          ></TextInput>
          <Button
            appearance="primary"
            intent="none"
            style={{
              position: "inherit",
              left: "36%",
              bottom: "20px",
            }}
            onClick={beForeGetMusicList}
          >
            검색
          </Button>
        </InputDiv>
        {musicSearchList.length > 0 && (
          <Pane>
            <Table width="100%" textAlign="center">
              <Table.Head>
                <Table.TextHeaderCell>음악 명</Table.TextHeaderCell>
                <Table.TextHeaderCell>가수</Table.TextHeaderCell>
                <Table.TextHeaderCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                >
                  관리
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height="auto">
                {musicSearchList.map((item) => {
                  return (
                    <Table.Row key={item.music_id}>
                      <Table.TextCell>{item.title}</Table.TextCell>
                      <Table.TextCell>{item.artist}</Table.TextCell>
                      <Table.TextCell
                        flexBasis={120}
                        flexShrink={0}
                        flexGrow={0}
                      >
                        {musicIdsInfo.find((i) => i === item.music_id) ? (
                          <Button disabled>등록됨</Button>
                        ) : (
                          <Button
                            appearance="primary"
                            onClick={() => {
                              onAddMusicList(item.music_id);
                            }}
                          >
                            추가
                          </Button>
                        )}
                      </Table.TextCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            <Pane
              height={80}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Pagination
                page={currentPage}
                totalPages={Math.ceil(totalCount / PAGE_SIZE)}
                onNextPage={() => {
                  setCurrentPage(currentPage + 1);
                }}
                onPreviousPage={() => {
                  setCurrentPage(currentPage - 1);
                }}
                onPageChange={(page) => setCurrentPage(page)}
              ></Pagination>
            </Pane>
          </Pane>
        )}
      </Dialog>

      <InputDiv>
        {musicIds && musicIds.length > 0 && (
          <Pane width="100%">
            <Table textAlign="center">
              <Table.Head>
                <Table.TextHeaderCell>음악 명</Table.TextHeaderCell>
                <Table.TextHeaderCell>가수</Table.TextHeaderCell>
                <Table.TextHeaderCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                >
                  관리
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height="auto">
                {musicIds.map((i) => {
                  // console.log(i, fullMusicList)
                  const item = fullMusicList.find((it) => it.music_id === i);
                  if (!item) return <></>;

                  return (
                    <Table.Row key={item.music_id}>
                      <Table.TextCell>{item.title}</Table.TextCell>
                      <Table.TextCell>{item.artist}</Table.TextCell>
                      <Table.TextCell
                        flexBasis={120}
                        flexShrink={0}
                        flexGrow={0}
                      >
                        <Button
                          appearance="primary"
                          intent="danger"
                          onClick={() => {
                            onRemoveMusicList(i);
                          }}
                        >
                          제거
                        </Button>
                      </Table.TextCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Pane>
        )}
      </InputDiv>
      <Button
        appearance="primary"
        intent="none"
        style={{
          position: "inherit",
          left: "36%",
          bottom: "20px",
        }}
        onClick={() => musicSearchModal()}
      >
        음원 추가
      </Button>
    </Pane>
  );
};

const memorizedMusicSearch = memo(MusicSearch);

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
`;

export default memorizedMusicSearch;
