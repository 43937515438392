import React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./API/reportWebVitals";
import axios from "services/auth/jwt/config";

import App from "./App";

// axios.defaults.withCredentials = true;
// axios.defaults.baseURL = "https://dev2023.paprica.fit";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
reportWebVitals();
