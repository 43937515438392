import React, { useEffect, useState, memo } from "react";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import { Pane, Table, Dialog, Button, Pagination } from "evergreen-ui";

const baseUrl = "/admin/item";

const ItemAdd = ({ itemType, itemIds, initialFullItemList, onSetItemIds }) => {
  //   console.log(itemType);
  const PAGE_SIZE = 5;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [fullItemList, setFullItemList] = useState(initialFullItemList);
  const [itemSearchList, setItemSearchList] = useState([]);
  const [itemIdsInfo, setItemIdsInfo] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");

  const modalTypeOption = {
    ADD: {
      button: "저장",
      title: "이용권 검색",
      action: () => {
        onSaveItemList();
      },
    },
  };

  useEffect(() => {
    getItemList();
  }, [currentPage]);

  // useEffect(() => {
  //   console.log(itemSearchList);
  // }, [itemSearchList]);

  const itemSearchModal = () => {
    openModal();
  };

  const openModal = () => {
    setItemIdsInfo(itemIds);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    getFullItemList();
    setItemIdsInfo([]);
    setItemIdsInfo([]);
    setIsModalOpened(false);
    itemIds = [];
  };

  const getFullItemList = async () => {
    const params = {
      keyword: "ADDITIONAL",
      //   item_type: itemType,
    };

    try {
      const response = await axios.get(baseUrl, params);
      console.log("DDDDDDDDDDDDDD", response);
      setFullItemList(response.data.item_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const getItemList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      //   content_type: "REAL",
      keyword: "ADDITIONAL",
      item_type: itemType,
    };

    // console.log(params);

    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.item_list;
        console.log(data);
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        setItemSearchList(data);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  const onAddItemList = (item_id) => {
    console.log(item_id);
    let newItemIdsInfo = itemIdsInfo
      .filter((item) => item !== "")
      .concat(item_id);
    if (!newItemIdsInfo.includes(item_id)) {
      newItemIdsInfo.push(item_id);
    }

    console.log(newItemIdsInfo);
    setItemIdsInfo(newItemIdsInfo);
  };

  const onRemoveItemList = (item_id) => {
    // console.log(item_id);
    let newItemIdsInfo = itemIds.filter((i) => i !== item_id);
    // console.log(musicIds, newItemIdsInfo);
    // setMusicIdsInfo(newItemIdsInfo)
    onSetItemIds(newItemIdsInfo);
  };

  const onSaveItemList = () => {
    console.log(itemIdsInfo);
    onSetItemIds(itemIdsInfo);
    closeModal();
  };

  function renderType(value) {
    switch (value) {
      case "SUBSCRIPTION":
        return "구독권";
      case "TICKET":
        return "횟수권";
      default:
        return value;
    }
  }
  function renderMethod(value) {
    switch (value) {
      case "PAYMENT":
        return "구매";
      case "ADMIN":
        return "관리자 부여";
      case "ADDITIONAL":
        return "이용권 구매 시 증정";
      default:
        return value;
    }
  }
  function renderUnit(value) {
    switch (value) {
      case "T":
        return "회";
      case "D":
        return "일";
      case "M":
        return "월";
      case "Y":
        return "년";
      default:
        return value;
    }
  }
  return (
    <Pane style={{ width: "100%" }}>
      <Dialog
        width={"900px"}
        isShown={isModalOpened}
        onCloseComplete={() => closeModal()}
        confirmLabel={modalTypeOption[modalType].button}
        onConfirm={modalTypeOption[modalType].action}
        hasClose={false}
        title={modalTypeOption[modalType].title}
      >
        {itemSearchList.length > 0 ? (
          <Pane>
            <Table width="100%" textAlign="center">
              <Table.Head>
                <Table.TextHeaderCell>아이템명</Table.TextHeaderCell>
                <Table.TextHeaderCell>타입</Table.TextHeaderCell>
                <Table.TextHeaderCell>획득 방식</Table.TextHeaderCell>
                <Table.TextHeaderCell>기간</Table.TextHeaderCell>
                <Table.TextHeaderCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                >
                  관리
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height="auto">
                {itemSearchList.map((item) => {
                  return (
                    <Table.Row key={item.item_id}>
                      <Table.TextCell>{item.title}</Table.TextCell>
                      <Table.TextCell>
                        {renderType(item.item_type)}
                      </Table.TextCell>
                      <Table.TextCell>
                        {renderMethod(item.method)}
                      </Table.TextCell>
                      <Table.TextCell>
                        {item.apply_value}
                        {renderUnit(item.apply_unit)}
                      </Table.TextCell>
                      <Table.TextCell
                        flexBasis={120}
                        flexShrink={0}
                        flexGrow={0}
                      >
                        {itemIdsInfo.find((i) => i === item.item_id) ? (
                          <Button disabled>등록됨</Button>
                        ) : (
                          <Button
                            appearance="primary"
                            onClick={() => {
                              onAddItemList(item.item_id);
                            }}
                          >
                            추가
                          </Button>
                        )}
                      </Table.TextCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            <Pane
              height={80}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Pagination
                page={currentPage}
                totalPages={Math.ceil(totalCount / PAGE_SIZE)}
                onNextPage={() => {
                  setCurrentPage(currentPage + 1);
                }}
                onPreviousPage={() => {
                  setCurrentPage(currentPage - 1);
                }}
                onPageChange={(page) => setCurrentPage(page)}
              ></Pagination>
            </Pane>
          </Pane>
        ) : (
          "사용 가능한 이용권이 없습니다"
        )}
      </Dialog>
      <InputDiv>
        {itemIds && itemIds.length > 0 && (
          <Pane>
            <Table textAlign="center" width="500px">
              <Table.Head>
                <Table.TextHeaderCell>아이템명</Table.TextHeaderCell>
                <Table.TextHeaderCell>타입</Table.TextHeaderCell>
                <Table.TextHeaderCell>획득 방식</Table.TextHeaderCell>
                <Table.TextHeaderCell>기간</Table.TextHeaderCell>
                <Table.TextHeaderCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                >
                  관리
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height="auto">
                {itemIds.map((i) => {
                  const item = itemSearchList.find((it) => it.item_id === i);
                  if (!item) return <></>;

                  return (
                    <Table.Row key={item.item_id}>
                      <Table.TextCell>{item.title}</Table.TextCell>
                      <Table.TextCell>
                        {renderType(item.item_type)}
                      </Table.TextCell>
                      <Table.TextCell>
                        {renderMethod(item.method)}
                      </Table.TextCell>

                      <Table.TextCell>
                        {item.apply_value}
                        {renderUnit(item.apply_unit)}
                      </Table.TextCell>
                      <Table.TextCell
                        flexBasis={120}
                        flexShrink={0}
                        flexGrow={0}
                      >
                        <Button
                          appearance="primary"
                          intent="danger"
                          onClick={() => {
                            onRemoveItemList(i);
                          }}
                        >
                          제거
                        </Button>
                      </Table.TextCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Pane>
        )}
      </InputDiv>
      <Button
        appearance="primary"
        intent="none"
        style={{
          position: "inherit",
          left: "36%",
          bottom: "20px",
        }}
        onClick={() => itemSearchModal()}
      >
        이용권 검색
      </Button>
    </Pane>
  );
};

const memorizedMusicSearch = memo(ItemAdd);

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
`;

export default memorizedMusicSearch;
