import React from "react";
import styled from "styled-components";
import { Pane } from "evergreen-ui";

function Bike() {
  return (
    <>
      <BikeBox>
        <Pane>
          <BikeH1>FreeBike화면입니다</BikeH1>
        </Pane>
      </BikeBox>
    </>
  );
}
const BikeBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const BikeH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;

export default Bike;
