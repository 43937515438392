import React from "react";
import moment from "moment";
import "moment-timezone";

function DateText(props) {
  // const date = props.children.slice(0, props.children.length - 1);
  // const isMin = date.minimum;
  // const Moment = require("moment-timezone");
  const Moment = require("moment-timezone");
  const isMin = props.minimum;
  const format = props.format || "datetime";
  // console.log(isMin);

  const date = Moment.tz(props, "Asia/Seoul").format("YYYY-MM-DD hh:mm:ss");
  // console.log(date);

  return (
    <>
      {/* {isMin
        ? moment(props.children).format("YYYY-MM-DD")
        : moment(props.children).format("YYYY-MM-DD hh:mm:ss")} */}
      {isMin
        ? props.children.slice(0, 10)
        : props.children.slice(0, 19).replace("T", " ")}
      {/* {isMin
        ? moment(props.children).format("YYYY-MM-DD")
        : moment(props.children).format(
            format === "date" ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss"
          )} */}
    </>
  );
}
export default DateText;
