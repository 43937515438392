import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  RadioGroup,
} from "evergreen-ui";
import DateText from "components/DateText";
import Tag from "components/Tag";
import FileUploader from "components/FileUploader";
import { diff, isEmpty, getMod } from "services/util";
// import ModalBasic from "./MusicModal";
// import UpdateModalBasic from "./InstuctorUpdate";

const baseUrl = "/admin/live-room";
const contentUrl = "/admin/content/";
const groupUrl = "/admin/group";
const instuctorUrl = "/admin/instructor";

export const UserContext = React.createContext();

const LiveRoom = () => {
  const navigate = useNavigate();

  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [liveroomList, setLiveRoomList] = useState([]);
  const [originalInfo, setOriginalInfo] = useState([]);
  const [info, setInfo] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [instructorList, setInstructorList] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [liveRoomId, setLiveRoomId] = useState("");

  const [liveType, setLiveType] = useState("");
  const [onairStatus, setOnairStatus] = useState("");

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "음악 등록",
      action: () => {
        onAdd();
      },
    },
    MOD: {
      button: "수정하기",
      title: "라이브 룸 수정",
      action: () => {
        onModify();
      },
    },
  };

  const [contractOption] = React.useState([
    { label: "계약", value: "1" },
    { label: "미계약", value: "0" },
  ]);

  const [liveTypeOption] = React.useState([
    { label: "전체", value: "" },
    { label: "리허설", value: "REHEARSAL" },
    { label: "ON-AIR", value: "ONAIR" },
  ]);

  const [onairStatusOption] = React.useState([
    { label: "전체", value: "" },
    { label: "대기", value: "WAIT" },
    { label: "방송 대기", value: "ONAIR_WAIT" },
    { label: "강사 시작", value: "INSTRUTOR_START" },
    { label: "방송 시작", value: "ONAIR" },
    { label: "결과 노출", value: "RESULT_SHOW" },
    { label: "방송 종료", value: "ONAIR_END" },
    { label: "방송 취소", value: "ONAIR_CANCEL" },
  ]);

  useEffect(() => {
    console.log(liveRoomId);

    localStorage.setItem("liveRoomId", liveRoomId);
    // sessionStorage.setItem("liveRoomId", liveRoomId);
  }, [liveRoomId]);

  useEffect(() => {
    console.log("onairStatus", onairStatus);
  }, [onairStatus]);

  useEffect(() => {
    getInstuctorList();
    getGroupList();
  }, []);

  useEffect(() => {
    getLiveRoomList();
  }, [currentPage, liveType, onairStatus]);

  const modifyModal = (item) => {
    openModal(item, "MOD");
  };

  const openModal = (item, type) => {
    setOriginalInfo(item);
    setInfo(item);
    setModalType(type);
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setOriginalInfo({});
    setInfo({});
    setIsModalOpened(false);
  };

  const getInstuctorList = async () => {
    try {
      const response = await axios.get(instuctorUrl);
      console.log("instructor", response);
      setInstructorList(response.data.instructor_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };
  const getGroupList = async () => {
    try {
      const response = await axios.get(groupUrl);
      console.log("group", response);
      setGroupList(response.data.group_list);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const beforeSearch = async () => {
    await setCurrentPage(1);
    getLiveRoomList();
  };
  const beforeOnairStatus = async (e) => {
    await setCurrentPage(1);
    setOnairStatus(e);
  };
  const beforeLiveType = async (e) => {
    await setCurrentPage(1);
    setLiveType(e);
  };

  const getLiveRoomList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      keyword: searchKeyword,
      onair_status: onairStatus,
      live_type: liveType,
    };

    // console.log(params)

    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.live_room_list;
        const dataCount = res.data.count;

        setTotalCount(dataCount);
        setLiveRoomList(data);
        setIsModalOpened(false);
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  //인풋 수정시
  const handleChange = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };

  //음악 등록
  const onAdd = () => {
    let newMusicInfo = {
      ...info,
      duration: Number(info.duration || 0),
      is_contract: Number(info.is_contract || 0),
    };
    let params = diff(newMusicInfo, originalInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }

    axios
      .post(baseUrl, params)
      .then((response) => {
        // console.log(response);
        alert("등록되었습니다");
        getLiveRoomList();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //음악 수정
  const onModify = () => {
    let newMusicInfo = {
      ...info,
      duration: Number(info.duration || 0),
      is_contract: Number(info.is_contract || 0),
    };
    let params = diff(newMusicInfo, originalInfo);
    console.log(params);
    if (isEmpty(params)) {
      closeModal();
      return;
    }

    axios
      .put(`${baseUrl}/${newMusicInfo.music_id}`, params)
      .then((response) => {
        console.log(response);
        alert("수정되었습니다");
        getLiveRoomList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //음악 삭제
  const onDeleteMusic = (music_id) => {
    if (window.confirm("삭제하시겠습니까?")) {
      axios
        .delete(`${baseUrl}/${music_id}`)
        .then((response) => {
          console.log(response);
          alert("삭제되었습니다");
          getLiveRoomList();
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
        });
    }
  };

  const isShowButton = (info) => {
    if (!window.confirm("앱 노출 상태를 변경하시겠습니까?")) {
      return;
    }

    if (info.is_app_show === 1) {
      info.is_app_show = 0;
    } else {
      info.is_app_show = 1;
    }

    if (typeof info.is_app_show === "string") {
      let a = Number(info.is_app_show);
      info.is_app_show = a;
    }

    let params = { is_app_show: info.is_app_show };
    console.log(params);
    // return;

    axios
      .put(`${contentUrl}${info.content_id}`, params)
      .then((response) => {
        console.log(response);
        getLiveRoomList();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };

  function renderStatus(value) {
    switch (value) {
      case "WAIT":
        return "대기";
      case "ONAIR_WAIT":
        return "방송 대기";
      case "INSTRUCTOR_START":
        return "강사 시작";
      case "ONAIR":
        return "방송 시작";
      case "RESULT_SHOW":
        return "결과 노출";
      case "ONAIR_END":
        return "방송 종료";
      case "ONAIR_CANCEL":
        return "방송 취소";
      default:
        return value;
    }
  }
  function renderLiveType(value) {
    switch (value) {
      case "ONAIR":
        return "ON-AIR";
      case "REHEARSAL":
        return "리허설";
      default:
        return value;
    }
  }

  //   const testPage = <Link to="/liveRoomOperation"></Link>;
  //   testPage.addEventListener("unload", () => {
  //     console.log("5252...나는 window.close()도 잡아낼 수 있다구..~");
  //   });

  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={modalTypeOption[modalType].button}
          onConfirm={modalTypeOption[modalType].action}
          hasClose={false}
          title={modalTypeOption[modalType].title}
        >
          {modalType === "MOD" && (
            <InputDiv>
              <InputSpan>콘텐츠 ID</InputSpan>
              <TextInput
                disabled
                name="content_id"
                defaultValue={info.content_id}
                onChange={handleChange}
              ></TextInput>
            </InputDiv>
          )}
          <InputDiv>
            <InputSpan>음악명</InputSpan>
            <TextInput
              name="title"
              defaultValue={info.title}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>저작권자</InputSpan>
            <TextInput
              name="artist"
              defaultValue={info.artist}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>지속시간</InputSpan>
            <TextInput
              name="duration"
              defaultValue={info.duration}
              onChange={handleChange}
            ></TextInput>
          </InputDiv>
          <InputDiv>
            <InputSpan>계약 여부</InputSpan>
            <RadioGroup
              display="flex"
              columnGap="10px"
              size={16}
              name="is_contract"
              value={String(info.is_contract)}
              defaultValue={String(info.is_contract)}
              options={contractOption}
              onChange={handleChange}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>음악</InputSpan>
            <div
              style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <TextInput value={info.music_url} readOnly />
              <TextInput value={info.music_original_filename} readOnly />
              <FileUploader
                directory="music"
                targetObj={info}
                onUploaded={setInfo}
                targetPropName="music"
              />
            </div>
          </InputDiv>
          <InputDiv>
            <InputSpan>음악 태그</InputSpan>
            <Tag
              placeholder={"음악 태그 검색"}
              originalDataInfo={originalInfo}
              dataInfo={info}
              fullTagList={groupList}
              dataPropName={"_music_tag_ids"}
              tagListPropKey={"music_tag_id"}
              tagListPropName={"title"}
              setDataInfo={setInfo}
            />
          </InputDiv>
        </Dialog>
      </Pane>

      <MusicBox>
        <Pane>
          <MusicH1>라이브 방송 관리</MusicH1>
        </Pane>
        <Box>
          <div
            style={{ display: "flex", width: "70%", flexDirection: "column" }}
          >
            <RadioGroup
              style={{
                display: "flex",
                alignItems: "center",
                wordBreak: "keep-all",
                gap: "10px",
              }}
              label="방송 조건"
              value={liveType}
              options={liveTypeOption}
              //   onChange={(e) => setLiveType(e.target.value)}
              onChange={(e) => beforeLiveType(e.target.value)}
            />
            <RadioGroup
              style={{
                display: "flex",
                alignItems: "center",
                wordBreak: "keep-all",
                gap: "10px",
              }}
              label="상태"
              value={onairStatus}
              options={onairStatusOption}
              //   onChange={(e) => setOnairStatus(e.target.value)}
              onChange={(e) => beforeOnairStatus(e.target.value)}
            />
          </div>
          <SearchBtn>
            <TextInput
              placeholder=" VID, 제목, 강사, 대상"
              width={"250px"}
              onChange={(e) => {
                const keyword = e.target.value;
                setSearchKeyword(keyword);
              }}
            ></TextInput>
            <Button
              appearance="primary"
              intent="none"
              style={{
                position: "inherit",
                left: "36%",
                bottom: "20px",
                marginLeft: "10px",
              }}
              onClick={beforeSearch}
            >
              검색
            </Button>
          </SearchBtn>
        </Box>

        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell flexBasis={125}>
              콘텐츠 ID
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={195}>제목</Table.TextHeaderCell>
            <Table.TextHeaderCell>강사</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120}>
              썸네일 이미지
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={40}>
              방송 타입
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120}>
              방송 일시
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={40}>
              방송 상태
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={40}>그룹</Table.TextHeaderCell>
            <Table.TextHeaderCell>
              제한 <br></br>인원
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              비밀<br></br>번호
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>스튜디오</Table.TextHeaderCell>
            <Table.TextHeaderCell>강사</Table.TextHeaderCell>
            <Table.TextHeaderCell>앱 노출</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {liveroomList.length === 0 ? (
              <div style={{ margin: "40px 0" }}>
                검색 결과가 존재하지 않습니다
              </div>
            ) : (
              liveroomList.map((item) => {
                //   console.log(liveroomList.length);
                let _groupList = (item.group_codes || "").split(",");
                let _instructorList = (item.instructor_ids || "").split(",");

                return (
                  <Table.Row
                    //   height={60}
                    key={item.content_id}
                    isSelectable
                    //   onSelect={() => {
                    //     modifyModal(item);
                    //   }}
                    height={"auto"}
                    minHeight={"60px"}
                  >
                    <Table.TextCell flexBasis={125}>
                      {item.content_id}
                    </Table.TextCell>
                    <Table.TextCell flexBasis={195}>
                      {item.title}
                    </Table.TextCell>
                    <Table.TextCell>
                      {_instructorList
                        .filter((i) => i !== "")
                        .map((item) => {
                          let a = instructorList.find(
                            (i) => i.instructor_id == item
                          );
                          if (!a) {
                            return;
                          }
                          return (
                            <InputDiv
                              style={{
                                justifyContent: "center",
                              }}
                              key={a.instructor_id}
                            >
                              {a.name}
                            </InputDiv>
                          );
                        })}
                    </Table.TextCell>
                    <Table.TextCell flexBasis={120}>
                      <Table.Cell justifyContent="center">
                        <ImgPreview
                          style={{ width: "120px" }}
                          src={item.thumbnail_image_url}
                        />
                      </Table.Cell>
                    </Table.TextCell>
                    <Table.TextCell flexBasis={40}>
                      {renderLiveType(item.live_type)}
                    </Table.TextCell>
                    <Table.TextCell flexBasis={120}>
                      <DateText>{item.start_date}</DateText>
                    </Table.TextCell>
                    <Table.TextCell flexBasis={40}>
                      {renderStatus(item.onair_status)}
                    </Table.TextCell>

                    <Table.TextCell flexBasis={40}>
                      {_groupList
                        .filter((i) => i !== "")
                        .map((item) => {
                          let a = groupList.find((i) => i.group_code == item);
                          if (!a) {
                            return;
                          }
                          return (
                            <InputDiv
                              style={{
                                justifyContent: "center",
                              }}
                              key={a.group_code}
                            >
                              {a.title}
                            </InputDiv>
                          );
                        })}
                    </Table.TextCell>
                    <Table.TextCell>{item.onair_personnel}</Table.TextCell>
                    <Table.TextCell>{item.password}</Table.TextCell>

                    <Table.Cell
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <Link
                        to="/liveRoomOperation"
                        target="_blank"
                        state={{ info: item.live_room_id }}
                      >
                        <Button
                          width="60px"
                          onClick={(e) => {
                            setLiveRoomId(item.live_room_id);

                            e.stopPropagation();
                          }}
                        >
                          방송 운영
                        </Button>
                      </Link>
                    </Table.Cell>

                    <Table.Cell
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <Link to="/liveRoomPrompt" target="_blank">
                        <Button
                          width="55px"
                          onClick={(e) => {
                            setLiveRoomId(item.live_room_id);
                            e.stopPropagation();
                          }}
                        >
                          프롬프트
                        </Button>
                      </Link>
                    </Table.Cell>

                    <Table.Cell
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          isShowButton(item);
                        }}
                      >
                        {item.is_app_show ? "노출중" : "미노출"}
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        </Table>

        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </MusicBox>
    </>
  );
};

// ModalBasic.defaultProps = {};
// const TableDiv = styled.div``;
const SearchBtn = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const MusicBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const MusicH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 0px 7px 0;
`;
const InputSpan = styled.span`
  width: 120px;
  flex-shrink: 0;
  font-size: 12px;
`;
const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
`;
const ImgPreview = styled.img`
  width: 200px;
`;
export default LiveRoom;
