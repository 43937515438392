import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "services/auth/jwt/config";
import {
  Pane,
  Table,
  Dialog,
  Button,
  TextInput,
  Pagination,
  RadioGroup,
} from "evergreen-ui";
import DateText from "components/DateText";
import { diff, isEmpty } from "services/util";

const baseUrl = "/admin/user-question/category";

const Category = () => {
  const PAGE_SIZE = 10;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [originalCategoryInfo, setOriginalCategoryInfo] = useState({});
  const [categoryInfo, setCategoryInfo] = useState({});
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("ADD");

  const modalTypeOption = {
    ADD: {
      button: "등록하기",
      title: "카테고리 등록",
      action: () => {
        onAddCategoryInfo();
      },
    },
    MOD: {
      button: "수정하기",
      title: "카테고리 수정",
      action: () => {
        onModifyCategoryInfo();
      },
    },
  };
  const [categoryIsShowOption] = React.useState([
    { label: "노출", value: "1" },
    { label: "미노출", value: "0" },
  ]);

  useEffect(() => {
    getCategoryList();
  }, [currentPage]);

  const addCategoryModal = (item) => {
    openModal(item, "ADD");
  };
  const modifyCategoryModal = (item) => {
    openModal(item, "MOD");
  };

  const openModal = (item, type) => {
    setModalType(type);
    setOriginalCategoryInfo(item);
    setCategoryInfo(item);
    setIsModalOpened(true);
  };
  const closeModal = () => {
    setOriginalCategoryInfo({});
    setCategoryInfo({});
    setIsModalOpened(false);
  };

  //카테고리 목록
  const getCategoryList = () => {
    const params = {
      limit: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
    };
    axios
      .get(baseUrl, { params })
      .then((res) => {
        console.log(res);
        const data = res.data.user_question_category_list;
        const dataCount = res.data.count;
        setTotalCount(dataCount);
        setCategoryList(data);
        // console.log(data);
        setIsModalOpened(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };
  //카테고리 등록
  const onAddCategoryInfo = () => {
    let params = diff(categoryInfo, originalCategoryInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }
    if (typeof params.is_show === "string") {
      let before = Number(params.is_show);
      params.is_show = before;
    }
    if (typeof params.ordering_number === "string") {
      let before = Number(params.ordering_number);
      params.ordering_number = before;
    }
    console.log(params);

    axios
      .post(baseUrl, params)
      .then((res) => {
        console.log(res);
        alert("문의사항 카테고리를 등록하였습니다.");
        getCategoryList();
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  //카테고리 수정
  const onModifyCategoryInfo = () => {
    let params = diff(categoryInfo, originalCategoryInfo);

    if (isEmpty(params)) {
      closeModal();
      return;
    }
    if (typeof params.is_show === "string") {
      let before = Number(params.is_show);
      params.is_show = before;
    }
    if (typeof params.ordering_number === "string") {
      let before = Number(params.ordering_number);
      params.ordering_number = before;
    }
    console.log(params);

    axios
      .put(`${baseUrl}/${categoryInfo.category_code}`, params)
      .then((res) => {
        console.log(res);
        alert("문의사항 카테고리를 수정하였습니다.");
        getCategoryList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const onDeleteCategory = (category_code) => {
    if (!window.confirm("문의사항 카테고리를 삭제하겠습니까?")) {
      closeModal();
      return;
    }

    axios
      .delete(`${baseUrl}/${category_code}`)
      .then(() => {
        alert("문의사항 카테고리가 삭제되었습니다.");
        getCategoryList();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  const handleChange = (e) => {
    setCategoryInfo({
      ...categoryInfo,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Pane>
        <Dialog
          isShown={isModalOpened}
          onCloseComplete={() => closeModal()}
          confirmLabel={`${modalTypeOption[modalType].button}`}
          onConfirm={modalTypeOption[modalType].action}
          hasCancel={false}
          hasClose={false}
          title={`${modalTypeOption[modalType].title}`}
        >
          <InputDiv>
            <InputSpan>카테고리명</InputSpan>
            <TextInput
              name="category"
              onChange={handleChange}
              defaultValue={originalCategoryInfo.category}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>노출 여부</InputSpan>
            <RadioGroup
              display="flex"
              columnGap="10px"
              size={16}
              name="is_show"
              value={String(categoryInfo.is_show)}
              defaultValue={String(categoryInfo.is_show)}
              options={categoryIsShowOption}
              onChange={handleChange}
            />
          </InputDiv>
          <InputDiv>
            <InputSpan>순서</InputSpan>
            <TextInput
              type="number"
              name="ordering_number"
              onChange={handleChange}
              value={categoryInfo.ordering_number}
            ></TextInput>
          </InputDiv>
        </Dialog>
      </Pane>

      <AnnouncementBox>
        <Pane>
          <AnnouncementH1>문의사항 카테고리 관리</AnnouncementH1>
        </Pane>
        <Button
          appearance="primary"
          intent="none"
          style={{
            position: "inherit",
            left: "36%",
            bottom: "20px",
          }}
          onClick={() => addCategoryModal({})}
        >
          등록하기
        </Button>
        <Table width="95%" textAlign="center">
          <Table.Head>
            <Table.TextHeaderCell>카테고리 코드</Table.TextHeaderCell>
            <Table.TextHeaderCell>카테고리명</Table.TextHeaderCell>
            <Table.TextHeaderCell>순서</Table.TextHeaderCell>
            <Table.TextHeaderCell>노출여부</Table.TextHeaderCell>
            <Table.TextHeaderCell>생성 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell>업데이트 날짜</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
              관리
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {categoryList.map((item) => (
              // console.log(item)
              <Table.Row
                key={item.category_code}
                isSelectable
                onSelect={() => {
                  modifyCategoryModal(item);
                  console.log(item);
                }}
              >
                <Table.TextCell>{item.category_code}</Table.TextCell>
                <Table.TextCell>{item.category}</Table.TextCell>
                <Table.TextCell>{item.ordering_number}</Table.TextCell>
                <Table.TextCell>
                  {item.is_show ? "노출" : "미노출"}
                </Table.TextCell>
                <Table.TextCell>
                  <DateText>{item.create_date}</DateText>
                </Table.TextCell>
                <Table.TextCell>
                  <DateText>{item.update_date}</DateText>
                </Table.TextCell>
                <Table.TextCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    appearance="primary"
                    intent="danger"
                    onClick={(event) => {
                      onDeleteCategory(item.category_code);
                      // event.preventDefault();
                      event.stopPropagation();
                    }}
                  >
                    삭제
                  </Button>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pane
          height={120}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            page={currentPage}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onNextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
            onPreviousPage={() => {
              setCurrentPage(currentPage - 1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </Pane>
      </AnnouncementBox>
    </>
  );
};

// ModalBasic.defaultProps = {};
const AnnouncementBox = styled.div`
  margin-top: 50px;
  margin-left: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;
const AnnouncementH1 = styled.h1`
  margin: 50px;
  font-size: 1.5vw;
`;
const InputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const InputSpan = styled.span`
  width: 120px;
  font-size: 12px;
`;

export default Category;
